import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DualListBox from 'react-dual-listbox';

export const CreateMenuOptionTemplate = () => {
  const { user } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Menu Option Template");
  const history = useHistory();
  const [template_name, setTemplate_name] = useState();
  const [template_name_en, setTemplate_name_en] = useState();
  const [optionSelected, setOptionSelected] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [optionListOriginal, setOptionListOriginal] = useState([]);
  const [state, setState] = useState(true);

  const AlertError = (message) => {
    Swal.fire("Menu Option Template!", message, "error");
  };
  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    let apiUrls = `${baseURL}/master/menu/option/list`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        var newState = []

        res.data.data.map((data) => {
          newState.push({
            id: data.group_id,
            label: data.group_name + " pick min >= " + data.pick_min,
            options: changeNameObject(data.list)
          })
        })
        setOptionListOriginal(res.data.original_data)
        setOptionList(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeNameObject = (data) => {
    var newState = []
    data.map((d) => {
      newState.push({ value: d.option_id, label: d.option_name })
    })
    return newState
  }
  const handleChangeSwitch = () => {
    setState(!state);
  };

  const onSubmit = async (data) => {
    if (template_name === undefined || template_name === "") {
      return AlertError("please insert option template name th.");
    }

    if (template_name_en === undefined || template_name_en === "") {
      return AlertError("please insert option template name en.");
    }

    if (optionSelected === undefined || optionSelected === "" || optionSelected.length === 0) {
      return AlertError("please insert option list.");
    }

    var groupCount = []
    optionSelected.map((selected) => {
      const option = optionListOriginal.find((opt) => opt.option_id === selected)
      const count = groupCount.filter((opt) => opt.group_id === option.group_id)
      var i = count.length
      if (i === 0) {
        groupCount.push({
          group_id: option.group_id, group_name: option.option_group_name,
          pick_min: option.option_pick_min, pick_current: i + 1,
          is_vaild: (i + 1) >= option.option_pick_min ? false : true
        })
      }
      else {
        const index = groupCount.findIndex((x) => x.group_id === option.group_id)
        groupCount[index].pick_current = i + 1
        groupCount[index].is_vaild = (i + 1) >= option.option_pick_min ? false : true
      }
    })
    const is_vaild = groupCount.filter((opt) => opt.is_vaild === true)
    if (is_vaild.length > 0) {
      return AlertError(`please insert group option (${groupCount[0].group_name}) more than or equal to  ${groupCount[0].pick_min} `);
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");


    var events = {
      shop_id: Number(get_shop_id),
      branch_id: Number(get_branch_id),
      template_name,
      template_name_en,
      template_order: 1,
      template_option: optionSelected,
      enabled: state,
    };

    let apiUrls = `${baseURL}/management/optiontemplate`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "Your can not create template. !! " + err,
          "error"
        );
      });
  };
  const onChange = (selected) => {
    setOptionSelected(selected)
  };
  return (
    <>
      <Card>
        <CardHeader title="Create Menu Option Template"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
            </span>
              Name TH:{" "}
            </p>
            <input
              name="template_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setTemplate_name(e.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
            </span>
              Name EN:{" "}
            </p>
            <input
              name="template_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setTemplate_name_en(e.target.value)}
            />
          </div>

          <DualListBox
            canFilter
            options={optionList}
            selected={optionSelected}
            onChange={onChange}
            showOrderButtons 
            style={{
              height: "500px",
            }}
          />

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Enabled:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/MenuOptionTemplate">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
