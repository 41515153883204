import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {  baseURL } from "../../../service/API.js"
import { useSelector } from "react-redux";
import SVG from 'react-inlinesvg'
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
    toAbsoluteUrl
} from "../../../../_metronic/_helpers"
import FormGroup from '@material-ui/core/FormGroup';

export const CreateRole = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: "113175", defaultMessage: "Role Management" }));
    const history = useHistory();
    const [data, setData] = useState([]);
    const [test, setTest] = useState([])
    const [order_item, setOrder_item] = useState(1)
    const [titleEN, setTitleEN] = useState('');
    const [role, setRole] = useState('');
    const [descriptionEN, setDescriptionEN] = useState('');
    const [descriptionTH, setDescriptionTH] = useState('');
    const [listPage, setListPage] = useState([]);
    const [groupDeal, setGroupDeal] = useState([]);
    const [listDeal, setListDeal] = useState([]);
    const [bannerList, setbannerList] = useState([]);
    const { user, authToken, accessTokenPrivilege } = useSelector((state) => state.auth);
    const [listGroup2, setListGroup2] = useState([]);
    const [typeList, setTypeList] = useState([
        'VIEW', 'CREATE', 'EDIT', 'DELETE', 'IMPORT', 'EXPORT', 'ADMIN'
    ]);
    const [projectList, setProjectList] = useState([])
    const [isUpload, setIsUpload] = useState(false);
    const [progress, setProgress] = useState(0);

    const [fixedlist, setfixedList] = useState([])
    const [brandList, setBrandtList] = useState([])
    const [is_active, setIs_active] = useState(true);
    const [for_you, setFor_you] = useState(false);
    const [summary, setSummary] = useState([]);
    const [targetlist, setTargetlist] = useState([]);


    const [whiteList, setWhiteList] = useState([])
    const setMapData = (data) => {
        let temp = []
        data.map(item => {
            temp.push({
                key: item.key,
                condition: item.condition,
                value: item.value,
                value_string: item.value_string,
                options: item.options,
                id: item.id,
                showDate: item.showDate,
                showOneDate: item.showOneDate,
                showListValue: item.showListValue,
                showOption: item.showOption,
                showValue: item.showValue,
                groupName: item.groupName,
                listValue: item.listValue,

                selectOption: item.selectOption,
                one_date: item.one_date,
                start_date: item.start_date,
                end_date: item.end_date
            })

        })
        setData(temp)
        setTest(temp)
    }

    const getBrand = async () => {
        const apiUrls = `${baseURL}/brand`;
        let token = localStorage.getItem("Token");
        let allData = [];

        axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                res.data.map(data => {

                    allData.push(data);
                })

                setBrandtList(allData)
            })
            .catch((err) => {
                if (err.response) {

                }
            });
    };

    const getProject = async () => {
        const apiUrls = `${baseURL}/project`;
        let token = localStorage.getItem("Token");
        let allData = [];

        axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                res.data.map(data => {

                    allData.push(data);
                })

                setProjectList(allData)
            })
            .catch((err) => {
                if (err.response) {

                }
            });
    };

    const getListPage = async () => {
        let token = localStorage.getItem("Token")
        axios({
            method: "get",
            url: `${baseURL}/role/page?time=1&page=1&limit=1000`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.message) {
            } else {
                setListPage(res.data.data)
            }
        }).catch((err) => {
        });
    };

    const updateFieldChanged = (index, value, name) => {
        let newArr = [...data]; // copying the old datas array
        newArr[index][name] = value; // replace e.target.value with whatever you want to change it to
        setData(newArr)
        
    }
    useEffect(() => {
        getListPage()

        setMapData([{
            page: '',
            page_id: '',
            
            permission: []
        }])
    }, []);

    const AlertError = (message) => {
        Swal.fire("Menu Management!", message, "error");
    };
    //'TEXTBOX','DDL','CHECKBOX','IMAGE'
    const getTargetList = async () => {
        const apiUrls = `${baseURL}/targetGroup/targetTopic?limit=10000&&page=1`;
        let token = localStorage.getItem("Token");
        //let allData = [];
        let allData0 = []

        axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                console.log(res.data.data)
                res.data.data.map(data => {
                    allData0.push(data);
                })
                setTargetlist(allData0)

            })
            .catch((err) => {
                if (err.response) {

                }
            });
    };
    const saveImport = async (e, index, field) => {
        if (e.target.files.length) {
            e.preventDefault();
            let file = e.target.files[0];
            var reader = new FileReader();
            e.target.value = '';
            console.log(e, index, field)
            handleChangeFile(file, index, field)
        }

    }

    const handleChangeFile = async (file, index, field) => {
        console.log('in')
        let check_error = false;
        setIsUpload(true);
        setProgress(25)

        //e.preventDefault();
        /*let file = e.target.files[0];
        var reader = new FileReader();
        e.target.value = '';
        reader.readAsDataURL(file);*/
        let data_all = [];
        console.log('in')
    };

    const checkSummary = async () => {
        let check = false
        let conditions = []
        console.log('data', data);

        for (let i in data) {

            if (data[i].groupName == [] || data[i].groupName.length == 0) {
                return false
            }
            if (data[i].selectOption == undefined || data[i].selectOption == '') {
                return false
            }
            if (data[i].value == [] || data[i].value?.length == 0) {
                if (!(data[i].showDate || data[i].showOneDate) && (data[i].selectOption != 'like' && data[i].selectOption != 'not like')) {
                    return false
                }

            }
            if (data[i].showOneDate && (data[i].one_date == '' || data[i].one_date == undefined)) {
                return false
            }

            let temp = []
            if (data[i].value != null) {
                data[i].value.map(item2 => {
                    temp.push(item2.value)
                })
            } else {
                temp = []
            }

            console.log(temp)
            conditions.push({
                key: data[i].groupName.key,
                condition: data[i].selectOption.length != 0 ? data[i].selectOption : '=',
                value: temp.length == 0 ? data[i].selectOption == 'like' || data[i].selectOption == 'not like' ? [] : null : temp,
                value_string: data[i].value_string,
                ...data[i].showDate ? { options: { dateFrom: data[i].start_date, dateTo: data[i].end_date } } : {},
                ...data[i].showOneDate ? { options: { date: data[i].groupName.key == 'birthdate_from_sa' ? data[i].one_date.replaceAll('-', '') : data[i].one_date } } : {}
            })
        }

        if (check) {
            return
        }

        var events = {
            conditions: conditions,
        };

        let apiUrls = `${baseURL}/targetGroup/count`;
        let token = localStorage.getItem("Token");

        axios({
            method: "post",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                setSummary(res.data.data)
            })
            .catch((err) => {

            });

        return false;
    };

    const onSubmit = async () => {
        if (role == undefined || role == "") {
            return AlertError("Please insert Role");
        }
        console.log('data', data);
        // return false;

        let temp = []
        for (let i in data) {
            if (data[i].page == undefined || data[i].page == '') {
                return AlertError(`Please insert page at permission number ${Number(i) + 1}`);
            }
            if (data[i].permission == [] || data[i].permission.length == 0) {
                return AlertError(`Please insert type at permission number ${Number(i) + 1}`);
            }
            data[i].permission.map((item)=>{
                temp.push({
                    page: data[i].page,
                    page_id: data[i].page_id,
                    type: item,
                })
            })
            console.log(temp)
        }
        var events = {
            role: role,
            permission: temp
        };
        let apiUrls = `${baseURL}/role`;
        let token = localStorage.getItem("Token");
        Swal.fire({
            title: "",
            text: 'Confirm Create',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                axios({
                    method: "post",
                    url: apiUrls,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: events,
                })
                    .then(async (res) => {
                        history.goBack();
                    })
                    .catch((err) => {
                        Swal.fire(
                            "Create !",
                            "Your can not create. !! " + err,
                            "error"
                        );
                    });
            }
        })


        return false;
    };

    const addPermission = () => {
        let temp = {
            page: '',
            page_id: '',
            
            permission: []
        }
        setData(data => [...data, temp])
    }

    const DeleteQuestion = (index) => {
        var array = [...data]; // make a separate copy of the array
        array.splice(index, 1);
        setData(array);
    }

    return (
        <>
            <Card>
                <CardHeader title={intl.formatMessage({ id: "113180", defaultMessage: "Create Role" })}>
                    <CardHeaderToolbar>
                        {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>

                            </span>
                            {intl.formatMessage({ id: "113178", defaultMessage: "Target Group Name" })}<span style={{ color: "red" }}>
                                *
                            </span>:{" "}
                        </p>
                        <input
                            name="modelGroup"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                        />

                    </div>

                    

                    {data.length == 0 ?
                        <button type="button" className="btn btn-primary mr-2" onClick={() => {
                            addPermission()
                        }}>
                            {intl.formatMessage({ id: "099027", defaultMessage: "ADD" })}
                        </button>
                        : null}


                    {data.map((item, index) => (
                        <Card className="Small shadow" key={index}>
                            <CardHeader title={index + 1}>
                            </CardHeader>
                            <CardBody>
                                <div className="form-inline" style={{ marginBottom: "25px" }}>
                                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                                        {intl.formatMessage({ id: "113179", defaultMessage: "Page" })}<span style={{ color: "red" }}>
                                            *
                                        </span>:{" "}
                                    </p>
                                    <Autocomplete
                                        id="grouped-demo"
                                        options={listPage}
                                        getOptionLabel={(option) => option.page_name}
                                        //getOptionSelected={(option, value) => option.key == value.key}
                                        value={item.groupName}
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            updateFieldChanged(index, newValue.page_name, 'page')
                                            updateFieldChanged(index, newValue.id, 'page_id')

                                        }}
                                        style={{
                                            width: "530px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params} variant="outlined" />
                                        }
                                    />
                                </div>

                                <div className="form-inline" style={{ marginBottom: "25px" }}>
                                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                                        {intl.formatMessage({ id: "113181", defaultMessage: "Permission type" })}<span style={{ color: "red" }}>
                                            *
                                        </span>:{" "}
                                    </p>
                                    <Autocomplete
                                        id="grouped-demo"
                                        options={typeList}
                                        multiple
                                        //getOptionLabel={(option) => option.label}
                                        //getOptionSelected={(option, value) => option.key == value.key}
                                        value={item.groupName}
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            updateFieldChanged(index, newValue, 'permission')
                                        }}
                                        style={{
                                            width: "530px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params} variant="outlined" />
                                        }
                                    />
                                </div>

                                <CardFooter>
                                    <CardHeaderToolbar>

                                        <button type="button" className="btn btn-danger mr-2" onClick={() => { DeleteQuestion(index) }}>
                                            {intl.formatMessage({ id: "099027", defaultMessage: "DELETE" })}
                                        </button>

                                        {index == data.length - 1 ?
                                            <button type="button" className="btn btn-primary mr-2" onClick={() => {
                                                addPermission()
                                            }}>
                                                {intl.formatMessage({ id: "099027", defaultMessage: "ADD" })}
                                            </button>
                                            : null}
                                    </CardHeaderToolbar>
                                </CardFooter>
                            </CardBody>
                        </Card>
                    ))}

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>
                        <Link to="/Role">
                            <button type="button" className="btn btn-secondary wbt mr-5">
                                {intl.formatMessage({ id: "099065", defaultMessage: "Cancel" })}
                            </button>
                        </Link>
                        <button className="btn btn-primary" onClick={() => onSubmit()}>
                            {intl.formatMessage({ id: "099064", defaultMessage: "Save" })}
                        </button>
                    </div>

                </CardBody>
            </Card>
        </>
    );
};
