/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { roleCheckPage } from "../service/rolePage";
import { useHistory } from "react-router-dom";

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const [pageList, setPageList] = useState([]);
  const [stock, setStock] = useState(false);

  useEffect(() => {
    console.log("in");
    checkAuth();
    checkStock();

  }, []);
  const checkAuth = async () => {
    const data = await roleCheckPage("MENU");

    if (!data) {
      history.push("/logout");
    }
    console.log("----pagelist----",data)

    setPageList(data);
    console.log(data);
    console.log('roleCheckPage length', data.length);

    if (data.length == 0) {
      history.push("/logout");
    }
  };
  const checkStock = async () => {
    const data = localStorage.getItem('is_enable_transaction')
    if (data == '1') {
      setStock(true)
    }
  };
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {pageList.includes("REPORT") && (
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">
                {intl.formatMessage({
                  id: "102010",
                  defaultMessage: "Dashboard",
                })}
              </span>
            </NavLink>
          </li>
        )}
        {/* Components */}
        {/* begin::section */}

        <li className="menu-section ">
          <h4 className="menu-text">
            {/* จัดการ
             */}
            {intl.formatMessage({ id: "109034", defaultMessage: "default content" })}
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/",
            false
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          {/* <NavLink className="menu-link menu-toggle" to="/">
            <span className="menu-text">
              {intl.formatMessage({
                id: "102001",
                defaultMessage: "menu management",
              })}
            </span>
            <i className="menu-arrow" />
          </NavLink> */}
          <div className="menu-link menu-toggle">
            <span className="menu-text">
              {intl.formatMessage({
                id: "102001",
                defaultMessage: "menu management",
              })}
            </span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item d-none ${getMenuItemActive("/Menu")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/Menu">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">เมนู</span>
                </NavLink>
              </li>
              {pageList.includes("MENU") && (
                <li
                  className={`menu-item ${getMenuItemActive("/MenuNew")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/MenuNew">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "102002",
                        defaultMessage: "create a new menu",
                      })}
                    </span>
                  </NavLink>
                  <NavLink className="menu-link d-none" to="/CreateMenuNew">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "102002",
                        defaultMessage: "create a new menu",
                      })}
                    </span>
                  </NavLink>
                  <NavLink className="menu-link d-none" to="/EditMenuNew">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "104049",
                        defaultMessage: "edit menu",
                      })}
                    </span>
                  </NavLink>
                </li>
              )}

              {pageList.includes("BUFFET_MENU") && (
                <li
                  className={`menu-item ${getMenuItemActive("/MenuBuffet")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/MenuBuffet">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "102014",
                        defaultMessage: "Create Buffet Price",
                        // defaultMessage: "Create Buffet Menu",
                      })}
                    </span>
                  </NavLink>
                  <NavLink className="menu-link d-none" to="/CreateMenuBuffet">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "112014",
                        defaultMessage: "Create buffet menu",
                      })}
                    </span>
                  </NavLink>
                  <NavLink className="menu-link d-none" to="/EditMenuBuffet">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "112047",
                        defaultMessage: "Edit buffet menu",
                      })}
                    </span>
                  </NavLink>
                </li>
              )}

              <span
                className="d-block"
                style={{
                  border: "1px solid #ffffff47",
                  margin: "0 35px",
                }}
              ></span>

              {pageList.includes("MENU_GROUP") && (
                <li
                  className={`menu-item ${getMenuItemActive("/MenuGroup")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/MenuGroup">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "102003",
                        defaultMessage: "Display the category",
                      })}
                    </span>
                  </NavLink>
                  <NavLink className="menu-link d-none" to="/CreateMenuGroup">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "103012",
                        defaultMessage: "Create a group menu",
                      })}
                    </span>
                  </NavLink>
                  <NavLink className="menu-link d-none" to="/EditMenuGroup">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "103028",
                        defaultMessage: "Edit menu group",
                      })}
                    </span>
                  </NavLink>
                </li>
              )}

              {pageList.includes("MENU_GROUP") && (
                <li
                  className={`menu-item d-none ${getMenuItemActive(
                    "/MenuOptionTemplate"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/MenuOptionTemplate">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Menu Option Template</span>
                  </NavLink>
                </li>
              )}
                {pageList.includes("SALES_CHANNEL") && (
                <li
                  className={`menu-item ${getMenuItemActive("/SalesChannel")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/SalesChannel">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatMessage({
                        id: "113292",
                        defaultMessage: "Sales channels",
                      })}
                    </span>
                  </NavLink>
                  </li>
              )}

             
            </ul>
          </div>
        </li>

        {(pageList.includes("ADMIN_MENU") ||
          pageList.includes("ADMIN_BUFFET_MENU") ||
          pageList.includes("ADMIN_MENU_GROUP")) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/",
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              {/* <NavLink className="menu-link menu-toggle" to="/">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113156",
                  defaultMessage: "Menu Management (Supervisor)",
                })}
              </span>
              <i className="menu-arrow" />
            </NavLink> */}

              <div className="menu-link menu-toggle">
                <span className="menu-text">
                  {intl.formatMessage({
                    id: "113156",
                    defaultMessage: "Menu Management (Supervisor)",
                  })}
                </span>
                <i className="menu-arrow" />
              </div>
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/MenuNewAdmin"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/MenuNewAdmin">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">เมนู</span>
                    </NavLink>
                  </li> */}
                  {pageList.includes("ADMIN_MENU") && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/MenuNewAdmin"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/MenuNewAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text"> 
                          {intl.formatMessage({
                            id: "113158",
                            defaultMessage: "Menu Branch Setting",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/CreateMenuNewAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text"> 
                          {intl.formatMessage({
                            id: "104014",
                            defaultMessage: "Create New Menu",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/EditMenuNewAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text"> 
                          {intl.formatMessage({
                            id: "104049",
                            defaultMessage: "Edit Menu",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/MenuNewAdminList">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text"> 
                          {intl.formatMessage({
                            id: "113280",
                            defaultMessage: "Menu list (Supervisor)",
                          })}
                        </span>
                      </NavLink>
                    </li>
                  )}

                  {pageList.includes("ADMIN_BUFFET_MENU") && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/MenuBuffetAdmin"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/MenuBuffetAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113159",
                            defaultMessage: "Menu Buffet Branch Setting",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/CreateMenuBuffetAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113281",
                            defaultMessage: "Create Buffet Menu (Supervisor)",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/MenuBuffetAdminList">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113282",
                            defaultMessage: "Buffet Menu list (Supervisor)",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/EditMenuBuffetAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113283",
                            defaultMessage: "Edit buffet menu (Supervisor)",
                          })}
                        </span>
                      </NavLink>
                    </li>
                  )}

                  <span
                    className="d-block"
                    style={{
                      border: "1px solid #ffffff47",
                      margin: "0 35px",
                    }}
                  ></span>

                  {pageList.includes("MENU_GROUP") && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/MenuGroupAdmin"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/MenuGroupAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113193",
                            defaultMessage: "Menu Group Admin",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/CreateMenuGroupAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "103012",
                            defaultMessage: "Create a group menu",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/MenuGroupAdminList">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "103001",
                            defaultMessage: "Display menu type",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/EditMenuGroupAdmin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "103028",
                            defaultMessage: "Edit menu group",
                          })}
                        </span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}

        {stock /*(pageList.includes('ADMIN_MENU') || pageList.includes('ADMIN_BUFFET_MENU') || pageList.includes('MENU_GROUP'))*/ && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {/* <NavLink className="menu-link menu-toggle" to="/">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113200",
                  defaultMessage: "material management",
                })}
              </span>
              <i className="menu-arrow" />
            </NavLink> */}
            <div className="menu-link menu-toggle">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113200",
                  defaultMessage: "material management",
                })}
              </span>
              <i className="menu-arrow" />
            </div>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                

              {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/Unit")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/Unit">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113203",
                          defaultMessage: "Unit",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/CreateUnit">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113229",
                          defaultMessage: "Create unit",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/EditUnit">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113274",
                          defaultMessage: "Edit Unit",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}

                {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/UnitConvert")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/UnitConvert">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113226",
                          defaultMessage: "Unit Convert Management",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/CreateUnitConvert">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113232",
                          defaultMessage: "Create Unit Convert",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/EditUnitConvert">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113284",
                          defaultMessage: "Edit unit convert",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}

                {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/Material")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/Material">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113202",
                          defaultMessage: "Material",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/CreateMaterial">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113212",
                          defaultMessage: "Create Material",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}

                

                {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/WasteReport")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/WasteReport">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113396",
                          defaultMessage: "Waste Report",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/CreateMaterial">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113212",
                          defaultMessage: "Create Material",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}

                

                {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/Inventory")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/Inventory">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113201",
                          defaultMessage: "BOM",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/EditInventory">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{intl.formatMessage({
                        id: "113204",
                        defaultMessage: "Edit BOM",
                      })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/CreateInventory">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{intl.formatMessage({
                        id: "113195",
                        defaultMessage: "Create BOM",
                      })}
                      </span>
                    </NavLink>
                  </li>
                )}

                {pageList.includes("REPORT") && (
                  <li
                    className={`menu-item ${getMenuItemActive("/Stock")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/Stock">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113206",
                          defaultMessage: "Stock",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/CreateStock">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113275",
                          defaultMessage: "Create Stock",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/StockList">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113285",
                          defaultMessage: "Stock list",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/EditStock">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113223",
                          defaultMessage: "Adjust Stock",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}
                {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/PurchasePlan")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/PurchasePlan">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113397",
                          defaultMessage: "Purchase Plan",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}
                {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/PurchaseRequest")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/PurchaseRequest">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113383",
                          defaultMessage: "PR",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}
                {true /*pageList.includes('ADMIN_MENU')*/ && (
                  <li
                    className={`menu-item ${getMenuItemActive("/PurchaseOrder")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/PurchaseOrder">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113384",
                          defaultMessage: "PO",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {(pageList.includes("PERMISSON") ||
          //pageList.includes("REPORT_MANAGEMENT") ||
          pageList.includes("SHOP_GROUP_MANAGEMENT")) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/",
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              {/* <NavLink className="menu-link menu-toggle" to="/">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113174",
                  defaultMessage: "Permission Management (Supervisor)",
                })}
              </span>
              <i className="menu-arrow" />
            </NavLink> */}
              <div className="menu-link menu-toggle">
                <span className="menu-text">
                  {intl.formatMessage({
                    id: "113174",
                    defaultMessage: "Permission Management (Supervisor)",
                  })}
                </span>
                <i className="menu-arrow" />
              </div>
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {pageList.includes("PERMISSON") && (
                    <li
                      className={`menu-item ${getMenuItemActive("/Role")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/Role">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113175",
                            defaultMessage: "Role Management",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/CreateRole">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113180",
                            defaultMessage: "Create Role",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/EditRole">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113188",
                            defaultMessage: "Edit Role",
                          })}
                        </span>
                      </NavLink>
                    </li>
                  )}

                {pageList.includes("REPORT_MANAGEMENT") && false && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/ReportAccess"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/ReportAccess">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113176",
                          defaultMessage: "Display the Category",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}

                {/* {pageList.includes("SHOP_GROUP_MANAGEMENT") && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/ShopGroupManagement"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/ShopGroupManagement">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113177",
                            defaultMessage: "Shop Group Management",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/CreateShopGroup">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113182",
                            defaultMessage: "Create Shop Group",
                          })}
                        </span>
                      </NavLink>
                      <NavLink className="menu-link d-none" to="/EditShopGroup">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113189",
                            defaultMessage: "Edit Shop Group",
                          })}
                        </span>
                      </NavLink>
                    </li>
                  )} */}
                {pageList.includes("SHOP_ROLE_GROUP") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/ShopGroupManagement"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/ShopGroupManagement">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113429",
                          defaultMessage: "Shop Role Group Management",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/CreateShopGroup">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113182",
                          defaultMessage: "Create Shop Group",
                        })}
                      </span>
                    </NavLink>
                    <NavLink className="menu-link d-none" to="/EditShopGroup">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: "113189",
                          defaultMessage: "Edit Shop Group",
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}
                {pageList.includes("SHOP_GROUP_RELATION") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/ShopGroupRelation"
                    )}`}
                    aria-haspopup="true"
                  >
                    
                    <NavLink className="menu-link " to="/ShopGroupRelation">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          {intl.formatMessage({
                            id: "113432",
                            defaultMessage: "Shop Group Relation",
                          })}
                        </span>
                      </NavLink>

                  </li>
                )}
                </ul>
              </div>
            </li>
          )}

        {pageList.includes("PRINTER_ZONE") && (
          <li
            className={`menu-item ${getMenuItemActive("/SettingPrinterZone")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/SettingPrinterZone">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "107033",
                  defaultMessage: "Edit Setting Printer Zone",
                })}
              </span>
            </NavLink>
            <NavLink className="menu-link d-none" to="/EditSettingPrinterZone">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "107001",
                  defaultMessage: "Set up printer zone",
                })}
              </span>
            </NavLink>
            <NavLink className="menu-link d-none" to="/CreateSettingPrinterZone">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "107012",
                  defaultMessage: "Create Setting Printer Zone",
                })}
              </span>
            </NavLink>
          </li>
        )}

        {pageList.includes("DELIVERY_NOTE") && (
          <li
            className={`menu-item ${getMenuItemActive("/DeliveryNote")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/DeliveryNote">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113162",
                  defaultMessage: "Delivery Note",
                })}
              </span>
            </NavLink>
            <NavLink className="menu-link d-none" to="/CreateDeliveryNote">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113163",
                  defaultMessage: "Create Delivery Note",
                })}
              </span>
            </NavLink>
          </li>
        )}

        {pageList.includes("SHOP") && (
          <li
            className={`menu-item ${getMenuItemActive("/Shop")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Shop">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "102007",
                  defaultMessage: "Shop/Branch",
                })}
              </span>
            </NavLink>
            <NavLink className="menu-link d-none" to="/EditShop">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "108037",
                  defaultMessage: "Edit Shop",
                })}
              </span>
            </NavLink>
            <NavLink className="menu-link d-none" to="/EditBranch">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "108015",
                  defaultMessage: "edit branch",
                })}
              </span>
            </NavLink>
          </li>
        )}
        {/*-------QR Table Zone -------*/}
        {pageList.includes("TABLE") && (
          <li
            className={`menu-item ${getMenuItemActive("/TableZone")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/TableZone">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113064",
                  defaultMessage: "QR/Table/Zone",
                })}
              </span>
            </NavLink>
          </li>
        )}

        {pageList.includes("ACCOUNT") && (
          <li
            className={`menu-item ${getMenuItemActive("/User")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/User">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113366",
                  defaultMessage: "User Management",
                })}
              </span>
            </NavLink>
          </li>
        )}
        {/*-------authorization -------*/}
        {/* {pageList.includes("AUTHORIZATION") && (
          <li
            className={`menu-item ${getMenuItemActive("/Authorization")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Authorization">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113375",
                  defaultMessage: "Authorization",
                })}
              </span>
            </NavLink>
          </li>
        )} */}
                {/*-------transaction-------*/}
        {pageList.includes("TRANSACTION") && (
          <li
            className={`menu-item ${getMenuItemActive("/Transaction")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Transaction">
              <span className="menu-text">
                {intl.formatMessage({
                  id: "113402",
                  defaultMessage: "Transaction",
                })}
              </span>
            </NavLink>
          </li>
        )}

        {pageList.includes("PROMOTION") && (
          <li
            className={`menu-item ${getMenuItemActive("/Promotion")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Promotion">
              {/* <i className="menu-bullet menu-bullet-dot">
                <span />
              </i> */}
              <span className="menu-text">
                {intl.formatMessage({ id: "113269", defaultMessage: "Promotion" })}
              </span>
            </NavLink>
          </li>
        )}

        {pageList.includes("REPORT") && (
          <li
            className={`menu-item ${getMenuItemActive("/Report")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Report">
              <span className="menu-text">
                {intl.formatMessage({ id: "102009", defaultMessage: "Report" })}
              </span>
            </NavLink>
          </li>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
