import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditPayment = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  const classes = useStyles();
  suhbeader.setTitle(
    intl.formatMessage({ id: "102007", defaultMessage: "Shop/Branch" })
  );
  const history = useHistory();
  const [enableFeedback, setEnableFeedback] = useState(false);
  const [efeedbackLink, setEfeedbackLink] = useState('');
  const [enableShowOrder, setEnableShowOrder] = useState(false);
  const [cashstatus, setCashStatus] = useState(false);
  const [qrcodestatus, setQRCodeStatus] = useState(false);
  const [creditstatus, setCreditStatus] = useState(false);
  const AlertError = (message) => {
    Swal.fire("EditShop!", message, "error");
  };

  const onsubmit = async () => {

    if (!cashstatus && !qrcodestatus && !creditstatus ) {
        Swal.fire("Please turn on at least one payment. !");
    }else{


    var events = {
        is_payment_type_cash: cashstatus,
        is_payment_type_creditcard: creditstatus,
        is_payment_type_qr_code: qrcodestatus,
 
    };

    let apiUrls = `${baseURL}/management/branch`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Payment success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire("Save !", "Your can not save payment. !! " + err, "error");
      });
    }
  };

  useEffect(() => {
    getBranch();
  }, []);


  const getBranch = async () => {
      axios({
          method: "get",
          url: `${baseURL}/management/branch`,
          headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
          },
      }).then((res) => {
          if (res.message) {
              history.push("/logout");
          } else {
              setCashStatus(res.data.data.is_payment_type_cash);
              setQRCodeStatus(res.data.data.is_payment_type_qr_code);
              setCreditStatus(res.data.data.is_payment_type_creditcard)
              console.log(cashstatus+"/"+qrcodestatus+"/"+creditstatus)
              
          }
      });
  };

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "113312",
          defaultMessage: "Edit Payment",
        })}
      ></CardHeader>
      <CardBody>
        <div className="container">

          <div className="container" style={{border:"1px solid",borderRadius:"20px",borderColor:"#CECDCD",marginBottom: "20px",marginTop: "20px"}}>
          <div className="h6" style={{ paddingTop:"20px" }}> 
        
              {intl.formatMessage({
                id: "113313",
                defaultMessage: "Payment option",
              })}
            </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113307", defaultMessage: "Cash" })}
            </p>
            <Switch
              checked={cashstatus}
              onChange={() => {setCashStatus(!cashstatus)}}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>




          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113308", defaultMessage: "QR Code" })}
            </p>
            <Switch
              checked={qrcodestatus}
              onChange={() => {setQRCodeStatus(!qrcodestatus)}}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            </div>



          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113309", defaultMessage: "Credit Card" })}
            </p>
            <Switch
              checked={creditstatus}
              onChange={() => {setCreditStatus(!creditstatus)}}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          </div>

          
         
   

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            
            
            <Link to="/Shop">
              <button type="button" className="btn btn-secondary wbt">
                {intl.formatMessage({ id: "108011", defaultMessage: "cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onsubmit()}>
              {intl.formatMessage({ id: "108032", defaultMessage: "Save" })}
              {/* {intl.formatMessage({ id: "108012", defaultMessage: "record" })} */}
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
