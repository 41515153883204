import { jsPDF } from 'jspdf'
import 'jspdf-autotable';

export const generatePdfInvoiceTaxFull = async (data, branchData, menu) => {
    console.log("data---------->", data)
    console.log("menu---------->", menu)
    const date = new Date();
    const options = { day: 'numeric', month: 'numeric', year: 'numeric'  };
    const formattedDate = date.toLocaleDateString(undefined, options);
    let rows = []
    let columns = []
    if(menu.length>0){
    const transformedData = menu.map((innerArray, index) => {
        return innerArray.map((item, innerIndex) => {
            return {
                ...item,
                number: (index * innerArray.length) + innerIndex + 1
            };
        });
    });
    console.log("transformedData====>",transformedData[0])
    
    rows = transformedData[0].map(item => ({
       no: item?.number,
       menu_name: item?.menu_name,
       qty: item?.qty,
       unit_price: parseFloat(item?.unit_price).toFixed(2),
       total: parseFloat(item?.qty*item?.unit_price).toFixed(2)
   }));
   console.log("rows========>",rows)
   columns = [
    { header: 'ลำดับ', dataKey: 'no' },
    { header: 'รายการ', dataKey: 'menu_name' },
    { header: 'จำนวน', dataKey: 'qty' },
    { header: 'ราคาต่อหน่วย', dataKey: 'unit_price' },
    { header: 'จำนวนเงิน', dataKey: 'total' }
];

    }


        const doc = new jsPDF();
        let yPosition = 110;
        let yPositionEnd ;
        let lineText = "............................................"
        doc.setFont('SukhumvitSet-Medium');
        doc.setFontSize(20);
        doc.text(`ใบเสร็จรับเงิน/ใบกำกับภาษี`,100, 30);

        doc.setFontSize(10);
        doc.text(`วันที่ : ${data?.table_close}`,15, 45);
        doc.text(`เลขที่ : ${data?.invoice_no}`,150, 45);
        doc.text(`ชื่อผู้ขาย : ${branchData[0]?.shop_name}`,15, 60);
        doc.text(`สาขา : ${branchData[0]?.branch_name}`, 80, 60);
        doc.text(`ที่อยู่ : ${branchData[0]?.address}`, 15, 65);
        doc.text(`เลขประจำตัวผู้เสียภาษี : ${branchData[0]?.tax_id ?? ""}`, 15, 70);
        doc.text(`เบอร์โทรศัพท์ : ${branchData[0]?.tel}`, 100, 70);

        doc.text(`ชื่อผู้ซื้อ : ${data?.customer_name}`, 15, 80);
        doc.text(`ที่อยู่ : ${data?.customer_address}`, 15, 85);
        doc.text(`เลขประจำตัวผู้เสียภาษี : ${data?.customer_tax_id}`, 15,90 );
        doc.text(`เบอร์โทรศัพท์ : ${data?.customer_phone}`, 100,90 );

        doc.setFont('SukhumvitSet-Medium');
        
       
        const rowCount = rows.length;
        yPositionEnd = yPosition+((rowCount+1)*10)


        try {
        doc.autoTable({
            startY: yPosition,
            
            head: [columns.map(col => col.header)],
            body: rows.map(row => Object.values(row)),
            theme: 'grid',
            headStyles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 12,
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255]
            },
            styles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                total: { halign: 'right' } // Align 'จำนวนเงิน' column to the right
            },
        });
    
    doc.text(`มูลค่ารวมก่อนเสียภาษี `, 120, yPositionEnd+10);
    doc.text(`${parseFloat(data?.sub_total).toFixed(2)}`, 180, yPositionEnd+10);

    doc.text(`ภาษีมูลค่าเพิ่ม(${data?.vat_rate})%`, 120, yPositionEnd+14);
    doc.text(`${parseFloat(data?.vat_amount).toFixed(2)}`, 180, yPositionEnd+15);

    doc.text(`ยอดรวม : `, 120,yPositionEnd+20);
    doc.text(`${parseFloat(data?.total_amount).toFixed(2)}`, 180, yPositionEnd+20);

    doc.text(`${data?.customer_name}`, 10,250)
    doc.text(`${lineText}`, 10,270)
    doc.text(`Paid by`, 10,275)

    doc.text(`${lineText}`, 50,270)
    doc.text(`Date`, 50,275)

    doc.text(`${branchData[0]?.shop_name}`, 120,250)
    doc.text(`${lineText}`, 120,270)
    doc.text(`Collected by`, 120,275)

    doc.text(`${formattedDate}`, 160,267)
    doc.text(`${lineText}`, 160,270)
    doc.text(`Date`, 160,275)

        const filename = `${data?.invoice_no}.pdf`;
        doc.save(filename);
    } catch (error) {
        console.error('Error while generating PDF:', error);
    }
    
}

