import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Modal, Button } from "react-bootstrap"
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";
import { EditDeliveryHours } from "../edit/EditDeliveryHours.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const CreateTable = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "113021", defaultMessage: "Create Table" }));
  const classes = useStyles();
  const history = useHistory();
  const [zone_id, setZone_id] = useState();
  const [table_name, settable_name] = useState();
  const [table_name_en, settable_name_en] = useState();
  const [table_desc, setDescription] = useState();
  const [table_desc_en, setDescription_en] = useState();
  const [max_seat, setMax_seat] = useState();
  const [state, setState] = useState(true);
  const [dynamicQR, setDynamicQR] = useState(false);
  const [staticQR, setStaticQR] = useState(false);
  const [time_period, setTimePeriod] = useState(0);
  const [zone, setZone] = useState([]);
  const [qr_address, setQRAddress] = useState();
  const [is_pickup, setIsPickup] = useState(false);
  const [is_delivery, setIsDelivery] = useState(false);
  const [is_member_no, setIsMemberNo] = useState(false);
  const [show, setShow] = useState(false);
  const [table_type,setTable_type] = useState();



  const allDataGroup = [];
  const table_type_name = ["Scan Menu","Dining Restaurants","Fast Service,Kiosk","Pickup / Delivery"]

  const AlertError = (message) => {
    Swal.fire(intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }), message, "error");
  };

  useEffect(() => {
    getZone();
  }, []);

  const getZone = async () => {
    let apiUrls = `${baseURL}/management/tablezone?page=1&limit=10000`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setZone(res.data.data);
        console.log("----zone result----",res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeGroup = (event) => {
    console.log("set zone >>>>>>>>>>",event.target.value)
    setZone_id(event.target.value);
  };
  
  const  handleChangeTableType = (event) => {
    setTable_type(event.target.value);
    console.log("*******tabletype after select********",event.target.value)
  }

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleChangeDynamicQR = () => {
    setDynamicQR(!dynamicQR);
  };

  const handleChangeStaticQR = () => {
    setStaticQR(!staticQR);
  };

  const handleChangeIsPickUp = () => {
    setIsPickup(!is_pickup);
  };
  const handleChangeDelivery = () => {
    setIsDelivery(!is_delivery);
  };
  const handleChangeMemberNo = () => {
    setIsMemberNo(!is_member_no);

  }
  const setTableTypeName = (index) =>{
    switch(index) {
      case 0:
        return(intl.formatMessage({ id: "113361", defaultMessage: "Scan Menu" }))
      case 1:
        return(intl.formatMessage({ id: "113362", defaultMessage: "Dining Restaurants" }))
      case 2:
        return(intl.formatMessage({ id: "113363", defaultMessage: "Fast Service,Kiosk" }))
      case 3:
        return(intl.formatMessage({ id: "113364", defaultMessage: "Pickup / Delivery" }))
    }

  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePass = (data) => {
    handleClose()
  }

  const onSubmit = async () => {
    if (table_type === undefined || table_type === "") {
      return AlertError(intl.formatMessage({ id: "113365", defaultMessage: "Please select table type." }));
    }
    if (table_name === undefined || table_name === "") {
      return AlertError(intl.formatMessage({ id: "113051", defaultMessage: "Please insert item name." }));
    }
    if (table_name_en === undefined || table_name_en === "") {
      return AlertError(intl.formatMessage({ id: "113054", defaultMessage: "Please insert item name en." }));
    }
    if (zone_id === undefined || zone_id === "") {
      return AlertError("please select group.");
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");
  
    if (table_type === "3") {
      var events = {
        shop_id: get_shop_id,
        branch_id: get_branch_id,
        zone_id,
        table_name,
        table_name_en,
        table_desc,
        table_desc_en,
        max_seat,
        qr_address,
        enabled: state,
        is_dynamic: dynamicQR,
        time_period: time_period !== 0 ? time_period : null,
        is_pickup: true,
        is_delivery: is_delivery ? is_delivery : false,
        is_member_no: is_member_no ? is_member_no : false,
        table_type: null,
      };
    } else {
      var events = {
        shop_id: get_shop_id,
        branch_id: get_branch_id,
        zone_id,
        table_name,
        table_name_en,
        table_desc,
        table_desc_en,
        max_seat,
        qr_address,
        enabled: state,
        is_dynamic: dynamicQR,
        time_period: time_period !== 0 ? time_period : null,
        is_pickup: false,
        is_delivery: is_delivery ? is_delivery : false,
        is_member_no: is_member_no ? is_member_no : false,
        table_type: table_type === '0' ? '1' : table_type === '1' ? '2' : table_type === '2' ? null : null,
        //hide kios/pay first
        // table_type: table_type === '0' ? '1' : table_type === '1' ? '2' : table_type === '2' ? '3' : null,
      };
    }

    let apiUrls = `${baseURL}/management/table`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save create dynamic success", "success");
        history.goBack();
      })
      .catch((err) => {
        console.log("Error JAaaaa", err)
        Swal.fire(
          intl.formatMessage({ id: "113059", defaultMessage: "Create !" }),
          intl.formatMessage({ id: "113060", defaultMessage: "Sorry for the inconvenience. Please check your data again." }), + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113021",
            defaultMessage: "Create Table",
          })}
        ></CardHeader>
        <CardBody>
          {/*-----------table type-----------*/}
          <div
            className="container"
            style={{
              border: "1px solid",
              borderRadius: "20px",
              borderColor: "#CECDCD",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <div
              className="form-inline"
              style={{ marginBottom: "25px", marginTop: "25px" }}
            >
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113360",
                  defaultMessage: "Table Type",
                })}
                :{" "}
              </p>
              <select
                as="select"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={table_type}
                onChange={handleChangeTableType}
              >
                <option value="">
                  {intl.formatMessage({
                    id: "113023",
                    defaultMessage: "Please Select",
                  })}
                </option>
                {table_type_name &&
                  table_type_name.map((result, index) => {
                    return (
                      <option key={index} value={index}>
                        {setTableTypeName(index)}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {/*-----------table detail-----------*/}
          <div
            className="container"
            style={{
              border: "1px solid",
              borderRadius: "20px",
              borderColor: "#CECDCD",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <div
              className="form-inline"
              style={{ marginBottom: "25px", marginTop: "25px" }}
            >
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113022",
                  defaultMessage: "Zone",
                })}:{" "}
              </p>
              <select
                as="select"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={zone_id}
                onChange={handleChangeGroup}
              >
                <option value="">
                  {intl.formatMessage({
                    id: "113023",
                    defaultMessage: "Please Select",
                  })}
                </option>
                {zone &&
                  zone.map((result, index) => {
                    return (
                      <option key={index} value={result.id}>
                        {result.zone_name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113024",
                  defaultMessage: "Table name th",
                })}
                :{" "}
                <span style={{ color: "red" ,fontSize: "10px"}}>( {intl.formatMessage({
                  id: "113450",
                  defaultMessage: "limit 10 characters",
                })} )</span>
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                maxLength={10}
                onChange={(table_name) =>
                  settable_name(table_name.target.value)
                }
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113025",
                  defaultMessage: "Table name en",
                })}
                :{" "}
                <span style={{ color: "red" , fontSize: "10px" }}>( {intl.formatMessage({
                  id: "113450",
                  defaultMessage: "limit 10 characters",
                })} )</span>
              </p>
              <input
                name="table_name_en"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                maxLength={10}
                onChange={(table_name_en) =>
                  settable_name_en(table_name_en.target.value)
                }
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113026",
                  defaultMessage: "Description",
                })}
                :{" "}
              </p>
              <input
                name="description"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(description) =>
                  setDescription(description.target.value)
                }
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113027",
                  defaultMessage: "Description en",
                })}
                :{" "}
              </p>
              <input
                name="description_en"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(description_en) =>
                  setDescription_en(description_en.target.value)
                }
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113028",
                  defaultMessage: "Max Seat",
                })}
                :{" "}
              </p>
              <input
                name="table_name_en"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(max_seat) => setMax_seat(max_seat.target.value)}
              />
            </div>
            {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            {intl.formatMessage({ id: "113316" ,defaultMessage:"QR Address" })}:{" "}
            </p>
            <input
              name="qr_address"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(qr_address) =>
                setQRAddress(qr_address.target.value)
              }
            />
          </div>*/}
          </div>
          {/*-----------Enabled-----------*/}
          <div
            className="container"
            style={{
              border: "1px solid",
              borderRadius: "20px",
              borderColor: "#CECDCD",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                {intl.formatMessage({
                  id: "113029",
                  defaultMessage: "Enabled",
                })}
                :{" "}
              </p>
              <Switch
                checked={state}
                onChange={() => handleChangeSwitch()}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          </div>

          {/* ----------Dynamic------------ */}
          {table_type !== "3" && (
            <div
              className="container"
              style={{
                border: "1px solid",
                borderRadius: "20px",
                borderColor: "#CECDCD",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  {intl.formatMessage({
                    id: "113347",
                    defaultMessage: "Dynamic QR",
                  })}
                  :{" "}
                </p>
                <Switch
                  checked={dynamicQR}
                  onChange={() => handleChangeDynamicQR()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({ id: "113259", defaultMessage: "Time period (minute)" })}:{" "}
              </p>
              <input
                type="number"
                name="time_period"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setTimePeriod(e.target.value)
                }
              />
            </div> */}
            </div>
          )}

           
            {/* <div
              className="container"
              style={{
                border: "1px solid",
                borderRadius: "20px",
                borderColor: "#CECDCD",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  {intl.formatMessage({
                    id: "113493",
                    defaultMessage: "Static QR",
                  })}
                  :{" "}
                </p>
                <Switch
                  checked={staticQR}
                  onChange={() => handleChangeStaticQR()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div> */}

          {/*-----------QR Pickup-----------*/}
          {table_type === "3" && (
            <div
              className="container"
              style={{
                border: "1px solid",
                borderRadius: "20px",
                borderColor: "#CECDCD",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  {intl.formatMessage({
                    id: "113321",
                    defaultMessage: "Delivery",
                  })}
                  :{" "}
                </p>
                <div
                  style={{
                    width: "530px",
                  }}
                >
                  {/* <div className="d-flex align-items-center">
                  <Switch
                    checked={is_}
                    onChange={() => handleChangeDelivery()}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="input-cheked-show"
                  />
                </div> */}
                  {/* <div className={`box-cheked-show${is_pickup ? ' d-block' : ''}`}> */}
                  {/* <div className="template mb-3 d-flex align-items-center"
                    style={{
                      width: "530px",
                    }}>

                  </div> */}
                  <div className="d-flex align-items-center">
                    {/* <span className="ml-3">
                      {intl.formatMessage({ id: "113321", defaultMessage: "Delivery" })}:{" "}
                    </span> */}
                    <Switch
                      checked={is_delivery}
                      onChange={() => handleChangeDelivery()}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="input-cheked-show"
                    />
                  </div>
                  <div
                    className={`box-cheked-show${
                      is_delivery ? " d-block" : ""
                    }`}
                  >
                    <div
                      className="template mb-3 d-flex align-items-center"
                      style={{
                        width: "530px",
                      }}
                    ></div>
                    <div className="container" style={{ marginLeft: "60px" }}>
                      <Button
                        variant="light"
                        className="ml-3 text-primary"
                        onClick={handleShow}
                      >
                        <i className="fa fa-pen mr-3 text-primary" />
                        {intl.formatMessage({
                          id: "113330",
                          defaultMessage: "Edit Delivery Hours",
                        })}
                      </Button>
                      <Modal show={show} onHide={handleClose} animation={false}>
                        <Modal.Body>
                          <EditDeliveryHours
                            type={'dialog'}
                            handle={handlePass}
                          />
                        </Modal.Body>
                        {/* <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                          Cancel
                          </Button>
                          <Button variant="primary" onClick={handleClose}>
                          Save
                          </Button>
                          </Modal.Footer> */}
                      </Modal>
                    </div>
                    <div
                      className={`template name mt-3 mb-3 align-items-center${
                        is_delivery ? " d-flex" : ""
                      }`}
                      style={{
                        width: "530px",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      <span className="mr-3">
                        {intl.formatMessage({
                          id: "113316",
                          defaultMessage: "QR Address",
                        })}
                        :{" "}
                      </span>
                      <input
                        name="qr_address"
                        type="text"
                        min="0"
                        style={{
                          height: "38px",
                          flexGrow: "1",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        onChange={(qr_address) =>
                          setQRAddress(qr_address.target.value)
                        }
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="ml-3">
                        {intl.formatMessage({
                          id: "113320",
                          defaultMessage: "Member No.",
                        })}
                        :{" "}
                      </span>
                      <Switch
                        checked={is_member_no}
                        onChange={() => handleChangeMemberNo()}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        className="input-cheked-show"
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          )}

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>

            <Link to="/TableZone">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113030", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
