import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({

  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const CreateMenuGroup = (props) => {
  const intl = useIntl();
  console.log('props CreateMenuGroup', props);
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "103012", defaultMessage: "Create a group menu" }));
  const history = useHistory();
  const [Remark, setRemark] = useState();
  const [QTY, setQTY] = useState();
  const [id, setID] = useState();
  const [Remark_cn, setRemark_cn] = useState(null);
  const [state, setState] = useState(true);
  const [displayOrder, setDisplayOrder] = useState(0)
  const [type, setType] = useState('component')

  useEffect(() => {
      setQTY(props.qty);
      setID(props.id);
      setRemark(props.remark)
  }, []);

  const AlertError = (message) => {
    Swal.fire("Menu Group!", message, "error");
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const onSubmit = async (data) => {
    if (Remark === undefined || Remark === "") {
      return AlertError("please insert Remark.");
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");

    var events = {
      material_qty: Number(QTY),
      remark: Remark,
    };

    let apiUrls = `${baseURL}/stock/${props.id}`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        props.handle('save')
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "You can not create group. !! " + err,
          "error"
        );
      });
  };

  const cancelItem = (data) => {
    if (type == 'dialog') {
      props.handle('close')
    } else {
      history.goBack();
    }
  };

  // const handleChangezoneprinter = (event, values) => {
  //   if (values) {
  //     setzoneprinter_id(values.id);
  //   } else {
  //     setzoneprinter_id("");
  //   }
  // };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "113144", defaultMessage: "Change Quantity" })}></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113146", defaultMessage: "Quantity" })}:{" "}
            </p>
            <input
            type="number"
              name="QTY"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={QTY}
              onChange={(e) => setQTY(e.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113147", defaultMessage: "Remark" })}:{" "}
            </p>
            <input
              name="Remark"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={Remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => cancelItem()}>
              {intl.formatMessage({ id: "103017", defaultMessage: "Cancel" })}
            </button>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "103018", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
