import React from 'react';
import A6HorizaltalOrder from './A6HorizaltalOrder';

class A6HorizantalTemplate extends React.Component {
  render() {
    const { table_name, qr_img, cover, logo } = this.props;

    return (
      <>
        <div
          id="testqrdiv"
          className="qrdiv"
          style={{
            position: 'relative',
            height: '105mm',
            width: '150mm',
            overflow: 'hidden',
            display: 'block',
          }}
        >
          {/* Cover Image (Background) */}
          {/* {cover && (
            <img
              src={cover}
              alt="Cover"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 0,
              }}
            />
          )} */}
          {cover && (
            <div
              style={{
                height: '100%',
                width: '100%',
                backgroundImage: `url(${cover})`, // ใช้ Background Image
                backgroundSize: 'cover', // ให้ภาพครอบคลุมพื้นที่
                backgroundPosition: 'center', // จัดให้อยู่ตรงกลาง
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 0,
              }}
            />
          )}

          {/* Additional Background Overlay */}
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <A6HorizaltalOrder />
          </div>

          {/* Logo Image */}
          {logo && (
            <img
              src={logo}
              width={111}
              height={111}
              style={{
                position: 'absolute',
                top: '125px',
                right: '65px',
                zIndex: 2,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              alt="Logo"
            />
          )}

          {/* QR Code */}
          {qr_img && (
            <div
              style={{
                position: 'absolute',
                background: '#fff',
                top: '120px',
                left: '140px',
                zIndex: 3,
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <img src={qr_img} alt="QR Code" width={160} height={160} />
            </div>
          )}

          {/* Table Name */}
          <h1
            style={{
              position: 'absolute',
              right: '50px',
              top: '30px',
              fontSize: '60px',
              fontWeight: '900',
              color: '#fff',
              zIndex: 4,
            }}
          >
            {table_name}
          </h1>
        </div>
      </>
    );
  }
}

export default A6HorizantalTemplate;

