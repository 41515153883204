import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Modal, Button } from "react-bootstrap"
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import AccessTimeOutlined from '@material-ui/icons/AccessTimeOutlined';
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const CreateAccountRole = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "113375", defaultMessage: "Authorization" }));
  const classes = useStyles();
  const history = useHistory();
  const [zone_id, setZone_id] = useState();
  const [table_name, settable_name] = useState();
  const [table_name_en, settable_name_en] = useState();
  const [table_desc, setDescription] = useState();
  const [table_desc_en, setDescription_en] = useState();
  const [max_seat, setMax_seat] = useState();
  const [state, setState] = useState(true);
  const [dynamicQR, setDynamicQR] = useState(false);
  const [staticQR, setStaticQR] = useState(false);
  const [time_period, setTimePeriod] = useState(0);
  const [zone, setZone] = useState([]);
  const [qr_address, setQRAddress] = useState();
  const [is_pickup, setIsPickup] = useState(false);
  const [is_delivery, setIsDelivery] = useState(false);
  const [is_member_no, setIsMemberNo] = useState(false);
  const [show, setShow] = useState(false);
  const [table_type,setTable_type] = useState();
  const [isAllChecked,setIsAllChecked] = useState(false);
  const [isViewChecked,setIsViewChecked] = useState(false);
  const [isCreateChecked,setIsCreateChecked] = useState(false);
  const [isEditChecked,setIsEditChecked] = useState(false);
  const [isDeleteChecked,setIsDeleteChecked] = useState(false);
  const [platform,setPlatform] = useState();
  const platform_name = ["POS","Slash QR Partner","Backoffice"]
  



  const allDataGroup = [];
  const buttonDefault = {border: "2px dashed", borderRadius: "5px", borderColor: "#F4F4F4" ,height:"80%",width: "100%",justifyContent: "flex-start",}


  const AlertError = (message) => {
    Swal.fire(intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }), message, "error");
  };

  useEffect(() => {
    getZone();
  }, []);

  const getZone = async () => {
    let apiUrls = `${baseURL}/management/tablezone?page=1&limit=10000`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setZone(res.data.data);
        console.log("----zone result----",res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxAllChange = (event) => {
    setIsAllChecked(event.target.checked);
    console.log("-----isCheck-----",event.target.checked)
    setIsViewChecked(!isViewChecked);
    setIsDeleteChecked(!isDeleteChecked);
    setIsEditChecked(!isEditChecked);
    setIsCreateChecked(!isCreateChecked);

  }
  const handleChangePlatform = (event) => {
    setPlatform(event.target.value)
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePass = (data) => {
    handleClose()
  }

  const onSubmit = async () => {
    if (table_type === undefined || table_type === "") {
      return AlertError(intl.formatMessage({ id: "113365", defaultMessage: "Please select table type." }));
    }
    if (table_name === undefined || table_name === "") {
      return AlertError(intl.formatMessage({ id: "113051", defaultMessage: "Please insert item name." }));
    }
    if (table_name_en === undefined || table_name_en === "") {
      return AlertError(intl.formatMessage({ id: "113054", defaultMessage: "Please insert item name en." }));
    }
    if (zone_id === undefined || zone_id === "") {
      return AlertError("please select group.");
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");
    
  
    if (table_type === "3") {
      var events = {
        shop_id: get_shop_id,
        branch_id: get_branch_id,
        zone_id,
        table_name,
        table_name_en,
        table_desc,
        table_desc_en,
        max_seat,
        qr_address,
        enabled: state,
        is_dynamic: dynamicQR,
        time_period: time_period !== 0 ? time_period : null,
        is_pickup: true,
        is_delivery: is_delivery ? is_delivery : false,
        is_member_no: is_member_no ? is_member_no : false,
        table_type: null,
      };
    } else {
      var events = {
        shop_id: get_shop_id,
        branch_id: get_branch_id,
        zone_id,
        table_name,
        table_name_en,
        table_desc,
        table_desc_en,
        max_seat,
        qr_address,
        enabled: state,
        is_dynamic: dynamicQR,
        time_period: time_period !== 0 ? time_period : null,
        is_pickup: false,
        is_delivery: is_delivery ? is_delivery : false,
        is_member_no: is_member_no ? is_member_no : false,
        table_type: table_type === '0' ? '1' : table_type === '1' ? '2' : table_type === '2' ? '3' : null,
      };
    }

    let apiUrls = `${baseURL}/management/table`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save create dynamic success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          intl.formatMessage({ id: "113059", defaultMessage: "Create !" }),
          intl.formatMessage({ id: "113060", defaultMessage: "Sorry for the inconvenience. Please check your data again." }), + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "113367", defaultMessage: "Create Table" })}></CardHeader>
        <CardBody>

          <div className="container">
            <div className="h6" style={{ paddingTop: "20px" }}>

              {intl.formatMessage({
                id: "113370",
                defaultMessage: "Info",
              })}

            </div>
            <form className="form-inline">
              <input className="input-col-6"
                name="Name"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="Name"
                value={""}

              />
            </form>


            <div className="h6" style={{ paddingTop: "20px" }}>

              {intl.formatMessage({
                id: "113379",
                defaultMessage: "Platform",
              })}

            </div>
            <div className= "row">
              <div className="col-6 col-sm-4">
                <select className="form-select"
                  as="select"
                  style={{
                    height: "38px",
                    width: "100%",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                    
                  }}
                  value={platform}
                  onChange={handleChangePlatform}
                >
                  <option value="">{intl.formatMessage({ id: "113381", defaultMessage: "Select Platform" })}</option>
                   { platform_name &&
                     platform_name.map((result, index) => {
                    return (
                      <option key={index} value={index}>
                      {result}
                      </option>
                    );
                  })} 
                </select>
              </div>
            </div>
                      <form className="form-inline">
                          <div className="h6" style={{ paddingTop: "20px" }}>

                              {intl.formatMessage({
                                  id: "113380",
                                  defaultMessage: "Role Management",
                              })}

                          </div>
                          <div className="checkbox-container">
                              <input type="checkbox" id="checkbox1" 
                               
                              onChange={handleCheckboxAllChange}/>
                              
                              <label className="h6" htmlFor="checkbox1">All</label>
                              
                          </div>
                          <div className="checkbox-container">
                              <input type="checkbox" id="view"
                              checked={isViewChecked} />
                              <label className="h6" htmlFor="view">View</label>
                          </div>
                          <div className="checkbox-container">
                              <input type="checkbox" id="create" 
                              checked={isCreateChecked} />
                              <label className="h6" htmlFor="create">Create</label>
                          </div>
                          <div className="checkbox-container">
                              <input type="checkbox" id="edit" 
                              checked={isEditChecked} />
                              <label className="h6" htmlFor="edit">Edit</label>
                          </div>
                          <div className="checkbox-container">
                              <input type="checkbox" id="delete" 
                              checked={isDeleteChecked} />
                              <label className="h6" htmlFor="delete">Delete</label>
                          </div>
                      </form>   
          </div>
          <div
            className="mt-5 form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>

            <Link to="/UserManagement">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113030", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
