import React from 'react';

function StickerOrder() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 155.91 226.77">
            <defs>
                <style>
                    {`
            .cls-1 { fill: none; }
            .cls-2 { fill: #f1f1f1; }
            .cls-3 { fill: #efeeee; }
            .cls-4 { fill: #f9fcff; }
            .cls-5 { fill: #cacac9; }
            .cls-6 { fill: #bebfc0; }
            .cls-7 { fill: #dfdfdf; }
            .cls-8 { fill: #bec0c2; }
            .cls-9 { fill: #e4e5e5; }
            .cls-10 { fill: #adaeb0; }
            .cls-11 { fill: #eaeaea; }
            .cls-12 { fill: #d1d2d3; }
            .cls-13 { fill: #c1c3c4; }
            .cls-14 { fill: #dbdada; }
            .cls-15 { fill: #f7fbff; }
            .cls-16 { fill: #c6c7c8; }
            .cls-17 { fill: #b6b7ba; }
            .cls-18 { fill: #c8c9cb; }
            .cls-19 { fill: #e7e6e6; }
            .cls-20 { fill: #d4d6d6; }
            .cls-21 { fill: #e0e1e1; }
            .cls-22 { fill: #b9b9bc; }
            .cls-23 { fill: #e4e3e3; }
            .cls-24 { fill: #b0b3b5; }
            .cls-25 { fill: #c5c7c8; }
            .cls-26 { isolation: isolate; }
            .cls-27 { fill: #dadbdb; }
            .cls-28 { fill: #b1b1b3; }
            .cls-29 { fill: #f0eff0; }
            .cls-30 { fill: #afb0b3; }
            .cls-31 { fill: #d0d1d2; }
            .cls-32 { fill: #f1f2f2; }
            .cls-33 { fill: #ededee; }
            .cls-34 { fill: #c8c9ca; }
            .cls-35 { fill: #c4c4c6; }
            .cls-36 { fill: #cdcfd1; }
            .cls-37 { fill: #c9cacc; }
            .cls-38 { fill: #c4c5c7; }
            .cls-39 { fill: #e8e8e9; }
            .cls-40 { fill: #bbbcbe; }
            .cls-41 { fill: #fff; }
            .cls-42 { fill: #b6b8bb; }
            .cls-43 { fill: #c1c1c2; }
            .cls-44 { fill: #d7d9d9; }
            .cls-45 { fill: #d6d6d7; }
            .cls-46 { fill: #f6fbfb; }
            .cls-47 { fill: #f4f5f5; }
            .cls-48 { fill: #c5c5c5; }
            .cls-49 { fill: #f3f4f4; }
            .cls-50 { fill: #e2e3e4; }
            .cls-51 { fill: #d2d4d6; }
            .cls-52 { fill: #ced1d2; }
            .cls-53 { fill: #b5b5b8; }
            .cls-54 { fill: #221f1f; }
            .cls-55 { fill: #cbced0; }
            .cls-56 { fill: #d0d2d5; }
            .cls-57 { fill: #dadcdc; }
            .cls-58 { fill: #f6f7f7; }
            .cls-59 { fill: #f6f7f8; }
            .cls-60 { fill: #b1b3b6; }
            .cls-61 { fill: #e4e4e5; }
            .cls-62 { fill: #aeafb1; }
            .cls-63 { fill: #b3b4b7; }
            .cls-64 { fill: #dfe0e0; }
            .cls-65 { fill: #bdbdbf; }
            .cls-66 { fill: #afb2b5; }
            .cls-67 { fill: #e8e8ea; }
            .cls-68 { fill: #bdbfc1; }
            .cls-69 { fill: #b6b8b9; }
            .cls-70 { fill: #efeeef; }
            .cls-71 { clip-path: url(#clippath); }
            .cls-72 { fill: #d6d7d7; }
            .cls-73 { fill: #f2f3f4; }
            .cls-74 { fill: #dbdcdd; }
            .cls-75 { fill: #babbbd; }
            .cls-76 { fill: #eaeaeb; }
            .cls-77 { mix-blend-mode: darken; opacity: .58; }
          `}
                </style>
                <clipPath id="clippath">
                    <polygon className="cls-1" points="60.43 212.65 64.57 212.65 61.61 217.2 57.39 217.2 60.43 212.65" />
                </clipPath>
            </defs>
            {/* SVG content here */}
            <g class="cls-26">
                <g id="Layer_1">
                    <rect class="cls-54" x="-.48" y="-.57" width="156.86" height="227.92" />
                    <g>
                        <g>
                            <polygon class="cls-41" points="61.61 204.64 65.79 204.64 62.74 209.17 66.83 209.19 61.61 217.2 57.39 217.2 60.43 212.65 56.28 212.65 61.61 204.64" />
                            <polygon class="cls-41" points="61.61 204.64 65.79 204.64 62.74 209.17 66.83 209.19 61.61 217.2 57.39 217.2 60.43 212.65 56.28 212.65 61.61 204.64" />
                            <g class="cls-77">
                                <g class="cls-71">
                                    <rect class="cls-10" x="57.39" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-62" x="57.48" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-30" x="57.57" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-28" x="57.66" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-66" x="57.75" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-24" x="57.83" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-60" x="57.92" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-63" x="58.01" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-53" x="58.1" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-69" x="58.19" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-17" x="58.28" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-42" x="58.37" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-22" x="58.45" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-75" x="58.54" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-75" x="58.63" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-40" x="58.72" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-65" x="58.81" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-6" x="58.9" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-68" x="58.99" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-8" x="59.08" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-43" x="59.16" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-13" x="59.25" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-13" x="59.34" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-35" x="59.43" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-48" x="59.52" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-38" x="59.61" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-25" x="59.7" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-16" x="59.78" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-34" x="59.87" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-18" x="59.96" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-37" x="60.05" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-55" x="60.14" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-36" x="60.23" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-52" x="60.32" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-52" x="60.4" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-31" x="60.49" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-12" x="60.58" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-56" x="60.67" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-51" x="60.76" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-51" x="60.85" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-20" x="60.94" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-45" x="61.03" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-72" x="61.11" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-44" x="61.2" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-14" x="61.29" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-27" x="61.38" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-57" x="61.47" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-74" x="61.56" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-7" x="61.65" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-7" x="61.73" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-64" x="61.82" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-64" x="61.91" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-21" x="62" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-50" x="62.09" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-23" x="62.18" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-61" x="62.27" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-9" x="62.36" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-19" x="62.44" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-39" x="62.53" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-39" x="62.62" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-67" x="62.71" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-76" x="62.8" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-11" x="62.89" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-33" x="62.98" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-3" x="63.06" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-70" x="63.15" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-29" x="63.24" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-2" x="63.33" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-32" x="63.42" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-73" x="63.51" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-73" x="63.6" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-49" x="63.68" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-47" x="63.77" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-58" x="63.86" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-59" x="63.95" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-46" x="64.04" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-15" x="64.13" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-4" x="64.22" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-41" x="64.31" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-41" x="64.39" y="212.65" width=".09" height="4.55" />
                                    <rect class="cls-41" x="64.48" y="212.65" width=".09" height="4.55" />
                                </g>
                            </g>
                            <polygon class="cls-5" points="60.43 212.65 64.56 212.64 59.51 214.02 60.43 212.65" />
                        </g>
                        <g>
                            <path class="cls-41" d="M69.62,212.35c.78.12,1.55.2,2.34.2.23,0,.7,0,.7-.41,0-.31-.12-.37-1.35-.37-.63,0-1.32-.27-1.32-1.25,0-1.35.55-2.44,1.37-2.74.46-.17,1.12-.18,1.63-.18.68,0,1.78.04,2.78.24l-.43,1.94c-.66-.11-1.32-.19-1.99-.19-.81,0-1,.15-1,.45,0,.25.14.32,1.21.32.78,0,1.46.04,1.46,1.36,0,.49-.18,1.17-.32,1.47-.42.88-1.11,1.04-2.45,1.04-1.09,0-2.02-.08-2.93-.29l.31-1.58Z" />
                            <path class="cls-41" d="M76.5,207.78h2.11l-.83,4.11h2.12l-.44,2.15h-4.22l1.26-6.25Z" />
                            <path class="cls-41" d="M82.95,207.78h3.66v6.25h-2.1v-.91h-1.72l-.42.91h-2.34l2.93-6.25ZM84.5,209.36h-.01l-1.08,2.25h1.09v-2.25Z" />
                            <path class="cls-41" d="M87.34,212.35c.78.12,1.55.2,2.34.2.23,0,.7,0,.7-.41,0-.31-.12-.37-1.35-.37-.63,0-1.32-.27-1.32-1.25,0-1.35.55-2.44,1.37-2.74.46-.17,1.12-.18,1.63-.18.68,0,1.78.04,2.78.24l-.43,1.94c-.66-.11-1.32-.19-1.99-.19-.81,0-1,.15-1,.45,0,.25.14.32,1.21.32.78,0,1.46.04,1.46,1.36,0,.49-.18,1.17-.32,1.47-.42.88-1.11,1.04-2.45,1.04-1.09,0-2.02-.08-2.93-.29l.31-1.58Z" />
                            <path class="cls-41" d="M94.19,207.78h2.08l-.47,2.27h1.28l.47-2.27h2.08l-1.31,6.25h-2.08l.46-2.23h-1.28l-.46,2.23h-2.08l1.31-6.25Z" />
                        </g>
                    </g>
                    <rect class="cls-41" x="17.86" y="41.85" width="122.08" height="122.08" rx="3.51" ry="3.51" />
                    <g>
                        <path class="cls-41" d="M53.58,196.82c-.32,0-.6-.03-.86-.1-.25-.06-.48-.15-.68-.27l.24-.76c.17.1.37.18.58.24.22.07.43.1.65.1.27,0,.5-.05.69-.16.19-.11.28-.27.28-.5,0-.11-.03-.2-.08-.28-.05-.08-.13-.14-.22-.2-.09-.06-.2-.11-.31-.15-.12-.04-.24-.08-.36-.12-.15-.05-.3-.11-.47-.17s-.32-.16-.46-.27c-.14-.11-.26-.25-.35-.42s-.14-.37-.14-.61c0-.18.03-.35.1-.51.06-.16.17-.3.3-.42.14-.12.31-.21.53-.28.21-.07.47-.1.77-.1.27,0,.52.03.75.08.23.05.44.13.65.24l-.24.71c-.18-.08-.37-.14-.56-.19-.2-.04-.38-.06-.54-.06-.29,0-.5.05-.64.15-.14.1-.21.23-.21.38,0,.11.03.2.08.28.05.08.12.15.21.2.09.06.18.11.29.15.11.04.22.08.34.12.17.06.35.12.52.2.18.07.34.17.48.28.14.11.26.25.35.42.09.16.14.37.14.6s-.05.45-.14.63c-.09.18-.22.32-.38.44-.16.12-.35.21-.57.26-.22.06-.46.09-.71.09Z" />
                        <path class="cls-41" d="M58.15,196.82c-.37,0-.7-.06-.99-.19-.29-.13-.53-.3-.73-.53-.2-.22-.34-.49-.44-.79-.1-.3-.15-.63-.15-.99s.05-.68.15-.99c.1-.3.25-.57.44-.79.2-.22.44-.4.73-.53.29-.13.62-.19.99-.19.26,0,.5.03.72.08.22.05.43.13.64.24l-.23.71c-.34-.18-.69-.27-1.06-.27-.48,0-.84.15-1.09.46s-.36.73-.36,1.28.12.97.37,1.28c.25.3.61.46,1.08.46.14,0,.31-.02.51-.07.2-.05.39-.12.59-.23l.21.71c-.19.11-.4.19-.64.25-.24.06-.48.09-.75.09Z" />
                        <path class="cls-41" d="M62.87,195.65h-1.74l-.36,1.08h-.97l1.74-4.81h.94l1.74,4.81h-.97l-.36-1.08ZM61.4,194.88h1.2l-.24-.72-.36-1.23h-.01l-.36,1.23-.24.72Z" />
                        <path class="cls-41" d="M67.69,191.92h.93v4.81h-.93l-1.64-2.73c-.1-.17-.19-.33-.27-.49-.08-.16-.15-.33-.2-.51h-.02c.04.2.07.39.09.58s.03.36.03.51v2.64h-.93v-4.81h1l1.57,2.73c.1.17.19.33.27.49.08.16.15.33.2.51h.02c-.04-.2-.07-.39-.09-.58s-.03-.36-.03-.51v-2.64Z" />
                        <path class="cls-41" d="M73.08,196.73h-.93v-4.03h-1.38s0-.79,0-.79h3.69v.8h-1.38s0,4.01,0,4.01Z" />
                        <path class="cls-41" d="M74.67,194.32c0-.35.05-.68.14-.99.1-.3.24-.57.43-.79.19-.22.42-.4.7-.53.28-.13.61-.19.98-.19s.7.06.98.19c.28.13.52.3.7.53.19.22.33.49.42.79.1.3.14.63.14.99s-.05.68-.14.99c-.09.3-.24.57-.42.79-.19.22-.42.4-.7.53s-.61.19-.98.19-.7-.06-.98-.19c-.28-.13-.52-.3-.7-.53-.19-.22-.33-.49-.43-.79-.1-.3-.14-.63-.14-.99ZM75.6,194.32c0,.55.11.97.34,1.28.22.3.55.46.99.46s.76-.15.99-.46c.22-.3.34-.73.34-1.28s-.11-.97-.34-1.28c-.22-.3-.55-.46-.99-.46s-.76.15-.99.46c-.22.3-.34.73-.34,1.28Z" />
                        <path class="cls-41" d="M81.33,194.32c0-.35.05-.68.14-.99.09-.3.24-.57.42-.79.19-.22.42-.4.7-.53.28-.13.61-.19.98-.19s.7.06.98.19c.28.13.52.3.7.53.19.22.33.49.42.79.1.3.14.63.14.99s-.05.68-.14.99c-.09.3-.24.57-.42.79-.19.22-.42.4-.7.53-.28.13-.61.19-.98.19s-.7-.06-.98-.19c-.28-.13-.52-.3-.7-.53-.19-.22-.33-.49-.42-.79-.1-.3-.14-.63-.14-.99ZM82.26,194.32c0,.55.11.97.34,1.28.22.3.55.46.99.46s.76-.15.99-.46c.22-.3.34-.73.34-1.28s-.11-.97-.34-1.28c-.22-.3-.55-.46-.99-.46s-.76.15-.99.46-.34.73-.34,1.28Z" />
                        <path class="cls-41" d="M90.12,193.41c0,.33-.09.62-.28.85-.19.23-.43.4-.73.51l1.14,1.96h-.99l-1.04-1.8h-.71v1.8h-.93v-4.81h1.81c.28,0,.52.04.74.12.21.08.4.18.54.32.15.13.26.29.34.48.08.18.11.38.11.58ZM89.19,193.41c0-.21-.07-.38-.2-.5-.13-.13-.33-.19-.6-.19h-.89v1.41h.89c.25,0,.44-.06.58-.19.14-.13.21-.3.21-.53Z" />
                        <path class="cls-41" d="M94.84,194.3c0,.8-.21,1.4-.63,1.81-.42.41-1.03.61-1.83.61h-1.5v-4.81h1.5c.8,0,1.41.2,1.83.61.42.41.63,1,.63,1.78ZM93.92,194.3c0-.29-.04-.53-.11-.73-.07-.2-.17-.37-.3-.49-.13-.13-.29-.22-.48-.27-.19-.06-.4-.09-.64-.09h-.57v3.21h.57c.24,0,.45-.03.64-.09.19-.06.35-.16.48-.29.13-.13.23-.3.3-.51.07-.2.11-.45.11-.74Z" />
                        <path class="cls-41" d="M95.62,191.92h3.13v.79h-2.23v1.18h2.09v.79h-2.09v1.28h2.27v.79h-3.17v-4.81Z" />
                        <path class="cls-41" d="M103.11,193.41c0,.33-.09.62-.28.85-.19.23-.43.4-.73.51l1.14,1.96h-.99l-1.04-1.8h-.71v1.8h-.93v-4.81h1.81c.28,0,.52.04.74.12.21.08.4.18.54.32.15.13.26.29.34.48.08.18.11.38.11.58ZM102.18,193.41c0-.21-.07-.38-.2-.5-.13-.13-.33-.19-.6-.19h-.89v1.41h.89c.25,0,.44-.06.58-.19.14-.13.21-.3.21-.53Z" />
                    </g>
                    <g>
                        <path class="cls-41" d="M36.12,186.33v-3.78h-1.88c-.58,0-1.04.08-1.38.25-.34.17-.52.48-.52.93,0,.37.12.66.36.87.24.21.53.32.88.32.19,0,.38-.02.58-.05s.4-.11.61-.21l.32,1.47c-.27.12-.54.21-.83.26-.28.05-.58.08-.9.08-.42,0-.81-.06-1.17-.17-.36-.11-.67-.28-.94-.51-.27-.23-.48-.51-.63-.85-.15-.34-.23-.74-.23-1.19,0-.96.33-1.65,1-2.09.66-.44,1.67-.66,3.02-.66h1.73v-.29c0-.22-.04-.42-.11-.63-.07-.2-.18-.38-.32-.53-.14-.15-.33-.28-.55-.37-.22-.09-.48-.14-.78-.14-.64,0-1.2.09-1.67.28-.48.19-.92.43-1.34.73l-.63-1.39c.4-.3.92-.57,1.57-.8.64-.24,1.37-.35,2.17-.35.24,0,.47.02.7.06.23.04.43.1.61.17l.52-.65h2.21l-1.27,1.51c.27.29.48.61.63.97.15.36.22.74.22,1.14v5.62h-1.96Z" />
                        <path class="cls-41" d="M42.14,177.68v8.65h-2.01v-8.65h2.01ZM45.66,177.68v8.65h-2.01v-8.65h2.01Z" />
                        <path class="cls-41" d="M53.31,186.33v-5.37c0-.62-.17-1.08-.5-1.39-.33-.31-.79-.46-1.37-.46-.22,0-.43.03-.64.09-.21.06-.41.13-.59.23-.18.1-.34.21-.48.34-.14.13-.24.26-.32.39l1.3,1.1c-.32.11-.56.29-.73.52-.16.23-.25.54-.25.93v3.63h-2.01v-3.61c0-.33.08-.62.23-.88.15-.26.35-.46.59-.6l-1.25-1.02c.1-.35.28-.69.53-1.01.25-.32.56-.61.92-.86.36-.25.77-.46,1.24-.61.46-.15.96-.23,1.5-.23.62,0,1.16.08,1.64.25.47.17.87.41,1.2.71.32.3.57.67.74,1.1.17.43.25.89.25,1.4v5.37h-2.01Z" />
                        <path class="cls-41" d="M59.33,183.29c0,.59.12,1,.37,1.25s.6.37,1.05.37.86-.13,1.24-.39c.39-.26.68-.6.87-1.01v-5.82h2.01v8.65h-1.79l-.22-1.07c-.11.15-.26.31-.44.46s-.38.28-.6.4c-.22.12-.47.21-.73.29-.27.07-.55.11-.83.11-.92,0-1.63-.25-2.15-.76-.52-.5-.78-1.3-.78-2.38v-5.7h2.01v5.6Z" />
                        <path class="cls-41" d="M72.14,186.33v-3.78h-1.88c-.58,0-1.04.08-1.38.25-.34.17-.52.48-.52.93,0,.37.12.66.36.87.24.21.53.32.88.32.19,0,.38-.02.58-.05s.4-.11.61-.21l.32,1.47c-.27.12-.54.21-.83.26-.28.05-.58.08-.9.08-.42,0-.81-.06-1.17-.17-.36-.11-.67-.28-.94-.51-.27-.23-.48-.51-.63-.85-.15-.34-.23-.74-.23-1.19,0-.96.33-1.65,1-2.09.66-.44,1.67-.66,3.02-.66h1.73v-.29c0-.22-.04-.42-.11-.63-.07-.2-.18-.38-.32-.53-.14-.15-.33-.28-.55-.37-.22-.09-.48-.14-.78-.14-.64,0-1.2.09-1.68.28-.48.19-.92.43-1.34.73l-.63-1.39c.4-.3.92-.57,1.57-.8.64-.24,1.37-.35,2.17-.35.24,0,.47.02.7.06.23.04.43.1.61.17l.52-.65h2.21l-1.27,1.51c.27.29.48.61.63.97.15.36.22.74.22,1.14v5.62h-1.96Z" />
                        <path class="cls-41" d="M72.05,174.73c0,.24.12.35.36.35h3.95v1.51h-4.42c-.25,0-.48-.03-.69-.09-.21-.06-.39-.16-.55-.3-.15-.14-.28-.32-.37-.54-.09-.22-.14-.5-.14-.83v-.77h1.85v.66Z" />
                        <path class="cls-41" d="M72.31,172.79v-2.53h1.79v2.53h-1.79Z" />
                        <path class="cls-41" d="M77.72,186.33l-2.61-8.65h1.98l2.12,7.04h.17c.25,0,.48-.07.71-.22.23-.14.43-.35.61-.61.18-.26.32-.58.42-.96.1-.38.15-.79.15-1.24,0-.94-.15-1.6-.46-2-.3-.4-.69-.59-1.17-.59-.32,0-.66.07-1.03.2l-.29-1.47c.21-.11.46-.2.75-.25.29-.06.6-.08.92-.08.46,0,.9.09,1.3.28.4.19.75.46,1.04.82.29.36.52.8.7,1.32.17.52.25,1.11.25,1.78,0,.72-.1,1.37-.29,1.94-.2.57-.47,1.06-.83,1.46-.36.4-.77.71-1.26.92-.48.21-1.01.32-1.59.32h-1.59Z" />
                        <path class="cls-41" d="M86.67,182.91c-.19,0-.28.1-.28.29v.05c0,1.1.61,1.65,1.84,1.65.73,0,1.31-.24,1.73-.73.42-.48.63-1.24.63-2.27,0-.91-.23-1.6-.69-2.08-.46-.48-1.06-.73-1.8-.73-.63,0-1.19.11-1.7.33-.5.22-.95.48-1.34.78l-.65-1.5c.43-.31.96-.59,1.59-.84s1.37-.38,2.24-.38c.64,0,1.22.1,1.76.29.54.2,1,.48,1.38.86.39.38.68.85.9,1.4.21.56.32,1.19.32,1.91,0,1.52-.38,2.66-1.14,3.41-.76.75-1.78,1.13-3.08,1.13-.66,0-1.24-.08-1.73-.23-.49-.15-.9-.38-1.23-.66-.32-.29-.57-.63-.73-1.03-.17-.4-.25-.84-.25-1.33v-.26c0-.57.12-.99.37-1.27.25-.28.65-.42,1.2-.42h1.98v1.61h-1.33Z" />
                        <path class="cls-41" d="M97.74,186.33v-5.56c0-1.11-.46-1.67-1.39-1.67-.46,0-.92.08-1.37.25-.45.17-.85.36-1.2.59l-.51-1.44c.42-.28.91-.51,1.47-.71.56-.2,1.15-.29,1.77-.29,1.06,0,1.86.3,2.41.9.55.6.82,1.41.82,2.44v5.5h-2.01Z" />
                        <path class="cls-41" d="M107.87,186.33l-1.22-3.69c-.15-.43-.35-.74-.58-.92-.23-.18-.49-.28-.76-.3l-1.51,1.3v3.61h-2.01v-8.65h2.01v2.93l3.5-2.93h2.53l-3.15,2.59c.52.14.92.38,1.2.69.27.32.49.71.66,1.17l1.39,4.18h-2.05Z" />
                        <path class="cls-41" d="M114.66,186.33v-5.56c0-1.11-.46-1.67-1.39-1.67-.46,0-.92.08-1.37.25-.45.17-.85.36-1.2.59l-.51-1.44c.42-.28.91-.51,1.47-.71.56-.2,1.15-.29,1.77-.29,1.06,0,1.86.3,2.41.9.55.6.82,1.41.82,2.44v5.5h-2.01Z" />
                        <path class="cls-41" d="M121.27,184.91c.54,0,.95-.08,1.24-.23s.44-.39.44-.71c0-.19-.05-.34-.16-.48-.11-.13-.25-.25-.43-.35-.18-.1-.38-.19-.6-.26-.22-.08-.44-.15-.66-.22-.38-.12-.74-.26-1.09-.41-.34-.15-.65-.33-.9-.55-.26-.22-.46-.47-.62-.76-.15-.29-.23-.65-.23-1.06,0-.34.08-.65.24-.94.16-.29.38-.54.67-.76.29-.22.63-.38,1.03-.5.4-.12.83-.18,1.3-.18.66,0,1.24.07,1.74.22.5.14.96.33,1.4.56l-.53,1.42c-.4-.22-.81-.37-1.21-.46-.41-.09-.84-.13-1.3-.13-.39,0-.72.06-.99.18-.27.12-.4.31-.4.58,0,.3.17.53.51.7.34.17.78.35,1.33.53.43.14.82.3,1.17.46s.65.36.9.58c.25.22.45.48.58.79.13.3.2.66.2,1.07,0,.37-.07.71-.22,1.01-.14.3-.36.57-.65.8-.29.23-.65.4-1.08.52-.43.12-.94.19-1.53.19-.62,0-1.19-.06-1.72-.19-.53-.13-1-.31-1.41-.55l.48-1.53c.38.21.78.37,1.2.49.42.12.85.18,1.3.18Z" />
                    </g>
                </g>
            </g>
        </svg>
    );
}


export default StickerOrder