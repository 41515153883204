import React, { useState, useRef } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

export const CreateSettingPrinterZone = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(intl.formatMessage({ id: "107001" ,defaultMessage:"Set up printer zone"}));
  const history = useHistory();
  const [printer_zone_name, setprinter_zone_name] = useState();
  const [printer_zone_name_en, setprinter_zone_name_en] = useState();
  const [state, setState] = useState(true);

  const AlertError = (message) => {
    Swal.fire("Create Setting Printer Zone!", message, "error");
  };
  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleSend = async () => {
    if (printer_zone_name === undefined || printer_zone_name === "") {
      return AlertError("Please insert printer zone name th !!");
    }
    if (printer_zone_name_en === undefined || printer_zone_name_en === "") {
      return AlertError("Please insert printer zone name en !!");
    }
    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");
    
    var events = {
      shop_id: Number(get_shop_id),
      branch_id: Number(get_branch_id),
      printer_zone_name: printer_zone_name,
      printer_zone_name_en: printer_zone_name_en,
      enabled: state,
    };

    let apiUrls = `${baseURL}/management/printerzone`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Create Setting Printer Zone success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Create Setting Printer Zone. !! " + err,
          "error"
        );
      });
  };

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "107012",defaultMessage:"Create Setting Printer Zone" })}></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              <span style={{ color: "red" }}>
              *
            </span>
              {intl.formatMessage({ id: "107013" ,defaultMessage:"Printer zone name th"})}:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(printer_zone_name) => setprinter_zone_name(printer_zone_name.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              <span style={{ color: "red" }}>
              *
            </span>
             {intl.formatMessage({ id: "107014",defaultMessage:"Printer zone name en"})}:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(printer_zone_name_en) => setprinter_zone_name_en(printer_zone_name_en.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "107015",defaultMessage:"Enabled" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/SettingPrinterZone">
              <button type="button" className="btn btn-secondary wbt">
                {intl.formatMessage({ id: "107016",defaultMessage:"Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              {intl.formatMessage({ id: "107017" ,defaultMessage:"Save"})}
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
