import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const CreateUnit = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113203", defaultMessage: "Unit" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [material_unit_id, setMaterial_unit_id] = useState(null);
  const [name, setName] = useState(null);
  const [nameEN, setNameEN] = useState(null);
  const [description, setDescription] = useState(null);
  const [price_per_main_unit, setPrice_per_main_unit] = useState(null);
  const [role, setRole] = useState("");
  const [listUnit, setListUnit] = useState([]);
  const [shop, setShop] = useState([]);

  useEffect(() => {
  }, []);

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'
  const onSubmit = async () => {
    
    if (name == undefined || name == "" || name == null) {
      return AlertError("Please insert name");
    }
    if (nameEN == undefined || nameEN == "" || nameEN == null) {
      return AlertError("Please insert english name");
    }
    // if (description == undefined || description == "" || description == null) {
    //   return AlertError("Please insert description");
    // }
    const shop_id = localStorage.getItem('shop_id')
    const branch_id = localStorage.getItem('branch_id')

    var events = {
      shop_id: shop_id,
      branch_id: branch_id,
      name: name,
      name_en: nameEN,
      description: description,
    };
    let apiUrls = `${baseURL}/management/stock/material_unit`;
    let token = localStorage.getItem("Token");
    console.log(events)
    Swal.fire({
      title: "",
      text: "Confirm Create",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            Swal.fire("Create !", "Your can not create. !! " + err, "error");
          });
      }
    });

    return false;
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113229",
            defaultMessage: "Create Unit",
          })}
        >
          <CardHeaderToolbar>
            {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113214",
                defaultMessage: "Name (TH)",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113215",
                defaultMessage: "Name (EN)",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={nameEN}
              onChange={(e) => setNameEN(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113216",
                defaultMessage: "Description",
              })}
              {" "}:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

        
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/Unit">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113038", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
