export default function setupAxios(axios, store) {

  axios.interceptors.response.use(response => {
    return response;
  }, err => {
    if (err.response.status === 401) {
      window.location.assign("/logout");
    }
    return Promise.reject(err)
  })
}