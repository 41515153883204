import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from '../service/role';
export {DatePickerField} from "../../_metronic/_partials/controls/forms/DatePickerField.js";

export const WasteReport = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const allData = [];
    const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalSize, setTotalSize] = useState(100);
    suhbeader.setTitle(intl.formatMessage({ id: "113396" ,defaultMessage:"Waste Report"}));
    const { authToken } = useSelector((state) => state.auth);
    const [campaigns_id_headder, setCampaigns_id_headder] = useState();
    const [roleList, setRoleList] = useState([]);

    const history = useHistory();
    useEffect(() => {
        localStorage.removeItem('edit')        
        checkAuth();
        
    }, []);
    useEffect(()=>{
        setSettingPrinterZoneData(prev=>prev)
     },[settingPrinterZone]);
    const checkAuth =async()=> {
        const data = await roleCheck('DELIVERY_NOTE')
        setRoleList(data)
        console.log(data)
        if (!data.includes('VIEW')) {
          history.push('/auth/login')
        }
        let value = 1
        if(localStorage.getItem("pagePrinterZone") != null){
            value = localStorage.getItem("pagePrinterZone")
        }
        let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
        setCampaigns_id_headder(campaigns_id_headders);
        getList(value, 10, search, campaigns_id_headders);

      }

    const getList = async (page, limit, search, campaigns_id_headders) => {
        setPage(page);
        axios({
            method: "get",
            url: `${baseURL}/inventory/wastereport?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
                }`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                console.log(res.data.data)
                res.data.data.map((data, index) => {
                    data = {
                        id: data.id,
                        item:data.name,
                        amount:data.amount,
                        unit:data.unit,
                        created_at:data.created_at
                    };
                    allData.push(data);
                });
            }

            var totalpage = Math.ceil(res.data.total / res.data.limit);
            setTotalPage(totalpage);
            setTotalSize(res.data.total);
            setLimit(res.data.limit);
            setSettingPrinterZoneData(allData);
            
        });
        /* var mockdata = {
            id: 1,
            item:'ปูอัด',
            amount:'10',
            unit:'แพค',
            created_at:'10-02-2025 10:00:00:53'
        };

        allData.push(mockdata); */
   
    };

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "item",
            text: "Item",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "amount",
            text: "Amount",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
       
        {
            dataField: "unit",
            text:'Unit',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "created_at",
            text: 'created at',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        // {
        //     dataField: "created_at",
        //     text: "Create Date",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
    ];

    const [search, setSearch] = useState();
    const handleSearch = () => {
        setPage(1);
        getList(1, limit, search, campaigns_id_headder);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        localStorage.setItem("pagePrinterZone", value)
        getList(value, limit, search, campaigns_id_headder);
    };

    const handlePageSizeChange = (event) => {
        setLimit(event.target.value);
        getList(1, event.target.value, search, campaigns_id_headder);
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113396",defaultMessage:"Waste Report" })}>
                
            </CardHeader>
            <CardBody>
                <div className="row form-inline mb-2">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "107002" ,defaultMessage:"Text Search"})}
                                style={{ width: "100%" }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                        <div className="form-group">
                            <button
                                type="button"
                                id="button"
                                className="btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={() => handleSearch()}
                            >
                                {intl.formatMessage({ id: "107003",defaultMessage:"search" })}
              </button>
                        </div>
                    </div>
                </div>

                <BootstrapTable
                    keyField="id"
                    data={settingPrinterZone}
                    columns={columns}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                >
                    <PleaseWaitMessage entities={settingPrinterZone} />
                    <NoRecordsFoundMessage entities={settingPrinterZone} />
                </BootstrapTable>
                <div className=" col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-3">
                                {intl.formatMessage({ id: "107032",defaultMessage:"Item per page" })}
                                <select onChange={handlePageSizeChange} value={limit}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Pagination2
                                className="mt-3 "
                                count={totalPage}
                                page={localStorage.getItem("pagePrinterZone") != null ? Number(localStorage.getItem("pagePrinterZone")) : page}
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
