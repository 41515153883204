import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const CreateShopGroup = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113177",defaultMessage: "Shop Group Management" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [test, setTest] = useState([]);
  const [order_item, setOrder_item] = useState(1);
  const [titleEN, setTitleEN] = useState("");
  const [role, setRole] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionTH, setDescriptionTH] = useState("");
  const [listPage, setListPage] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [brandList, setBrandtList] = useState([]);
  const [shopList, setShoplist] = useState([]);
  const [branch, setBranch] = useState([]);

  useEffect(() => {
    getShopList();
  }, []);

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'
  const getShopList = async () => {
    const apiUrls = `${baseURL}/shopGroup?limit=10000&&page=1`;
    let token = localStorage.getItem("Token");
    //let allData = [];
    let allData0 = [];

    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        console.log("shop data===========>",res.data.data);
        res.data.data.map((data) => {
          allData0.push(data.posBranch);
        });
        setShoplist(allData0);
      })
      .catch((err) => {
        if (err.response) {
        }
      });
  };
  const onSubmit = async () => {
    if (role == undefined || role == "") {
      return AlertError("Please insert Role");
    }
    console.log("branch===========>", branch);
    // return false;
    let temp = [];

    branch.map((item) => {
      temp.push({
        name: item.name,
        name_en: item.name_en,
        shop_id: item.shop_id,
        branch_id: item.id,
      });
    });

    var events = {
      name: role,
      name_en: role,
      detail: temp,
    };
    let apiUrls = `${baseURL}/shopGroup/role`;
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: "",
      text: "Confirm Create",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            Swal.fire("Create !", "Your can not create. !! " + err, "error");
          });
      }
    });

    return false;
  };

  const addPermission = () => {
    let temp = {
      page: "",
      page_id: "",

      permission: [],
    };
    setData((data) => [...data, temp]);
  };

  const DeleteQuestion = (index) => {
    var array = [...data]; // make a separate copy of the array
    array.splice(index, 1);
    setData(array);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113182",
            defaultMessage: "Create Shop Group",
          })}
        >
          <CardHeaderToolbar>
            {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113184",
                defaultMessage: "Target Group Name",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113185",
                defaultMessage: "Permission type",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              id="grouped-demo"
              options={shopList}
              multiple
              getOptionLabel={(option) => option.name}
              //getOptionSelected={(option, value) => option.key == value.key}
              value={branch}
              onChange={(event, newValue) => {
                console.log(newValue);
                setBranch(newValue);
                //updateFieldChanged(index, newValue, 'permission')
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            {/* <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div> */}
            <Link to="/ShopGroupManagement">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "099065", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "099064", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
