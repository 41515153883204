import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Modal, Button } from "react-bootstrap"
import { useIntl } from "react-intl";
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import AccessTimeOutlined from '@material-ui/icons/AccessTimeOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const CreateUserManagement = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "113366", defaultMessage: "User Management" }));
  
  const classes = useStyles();
  const history = useHistory();
  const buttonDefault = {border: "2px dashed", borderRadius: "5px", borderColor: "#F4F4F4" ,height:"80%",width: "100%",justifyContent: "flex-start"}
  const buttonOnclick = {border: "2px solid", borderRadius: "10px", borderColor: "#8FC5FF" ,height:"80%",width: "100%",justifyContent: "flex-start"}
  const [zone_id, setZone_id] = useState();
  const [zone, setZone] = useState([]);
  const [show, setShow] = useState(false);
  const [role_type_name , setRole_type_name] = useState();
  const [role_type , setRole_type] = useState();
  const [zoneCreate, setZoneCreate] = useState();
  const [first_name_th, setfirstname_th] = useState();
  const [first_name_en, setfirstname_en] = useState();
  const [last_name_th, setlastname_th] = useState();
  const [last_name_en, setlastname_en] = useState();
  const [mobile_no, setMobile_no] = useState();
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [employee_type,setEmployee_type] = useState();
  const [buttonPartTimeStyle,setButtonPartTimeStyle] = useState(buttonDefault);
  const [buttonFullTimeStyle,setButtonFullTimeStyle] = useState(buttonDefault);
  const [isUserBackOffice,setIsUserBackOffice] = useState(false);
  const [isUserPos,setIsUserPos] = useState(false);




  const allDataGroup = [];

  

  const AlertError = (message) => {
    Swal.fire(intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }), message, "error");
  };

  useEffect(() => {
    getZone();
    getRole();
  }, []);

  const getZone = async () => {
    let apiUrls = `${baseURL}/management/tablezone?page=1&limit=10000`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setZone(res.data.data);
        console.log("----zone result----",res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRole = async () => {
    let apiUrls = `${baseURL}/role/role`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setRole_type_name(res.data.data);
        console.log("----role result----",res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSetFulltime = () => {
    setButtonFullTimeStyle(buttonOnclick)
    setButtonPartTimeStyle(buttonDefault)
    setEmployee_type(2)


  }
  const handleSetParttime = () => {
    setButtonPartTimeStyle(buttonOnclick)
    setButtonFullTimeStyle(buttonDefault)
    setEmployee_type(1)

  }
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'userBackOffice') {
      setIsUserBackOffice(checked);
    } else if (name === 'userPos') {
      setIsUserPos(checked);
    }

  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePass = (data) => {
    handleClose()
  }
  const setroletype = (e)=>{
    setRole_type(e.target.value)
  }
  const onSubmit = async () => {
    if(isUserBackOffice === false && isUserPos === false){
      return AlertError("please select at least one platform.");
    }
    if (role_type === undefined || role_type === "") {
      return AlertError("please select role type.");
    }
    if(role_type !== '3' && role_type !== '2'){
      if (employee_type === undefined || employee_type === "") {
        return AlertError("please select employee type.");
      }
    }
    if (zone_id === undefined || zone_id === "") {
      return AlertError("please select zone.");
    }
    if (username === undefined || username === "") {
      return AlertError("please insert username.");
    }
    if (password === undefined || password === "") {
      return AlertError("please insert password.");
    }
    if (mobile_no === undefined || mobile_no === "") {
      return AlertError("please insert mobile no.");
    }
    if (email === undefined || email === "") {
      return AlertError("please insert email");
    }
    if (first_name_th === undefined || first_name_th === "") {
      return AlertError("please insert thai first name");
    }
    if (last_name_th === undefined || last_name_th === "") {
      return AlertError("please insert thai last name");
    }
    if (first_name_en === undefined || first_name_en === "") {
      return AlertError("please insert english last name");
    }
    if (last_name_en === undefined || last_name_en === "") {
      return AlertError("please insert english last name");
    }
    
        let get_shop_id = localStorage.getItem("shop_id");
        let get_branch_id = localStorage.getItem("branch_id");
        if(zone_id === "All"){
          var data = {
            shopId: get_shop_id,
            branchId: get_branch_id,
            firstname: first_name_th,
            firstname_en: first_name_en,
            lastname: last_name_th,
            lastname_en: last_name_en,
            mobile: mobile_no,
            username: username,
            password: password,
            email: email,
            zone_id: null,
            is_all_zone: true,
            role_type: role_type,
            employee_type: employee_type
          };
          var dataUserBackoffice = {
            shopId: get_shop_id,
            branchId: get_branch_id,
            first_name: first_name_th,
            firstname_en: first_name_en,
            last_name: last_name_th,
            lastname_en: last_name_en,
            mobile: mobile_no,
            username: username,
            password: password,
            email: email,
            zone_id: null,
            is_all_zone: true,
            role_id: role_type,
            employee_type: employee_type
          };

        }else{
        var data = {
          shopId: get_shop_id,
          branchId: get_branch_id,
          firstname: first_name_th,
          firstname_en: first_name_en,
          lastname: last_name_th,
          lastname_en: last_name_en,
          mobile: mobile_no,
          username: username,
          password: password,
          email: email,
          zone_id: zone_id,
          is_all_zone: false,
          role_type: role_type,
          employee_type: employee_type
        };
        var dataUserBackoffice = {
          shopId: get_shop_id,
          branchId: get_branch_id,
          first_name: first_name_th,
          firstname_en: first_name_en,
          last_name: last_name_th,
          lastname_en: last_name_en,
          mobile: mobile_no,
          username: username,
          password: password,
          email: email,
          zone_id: null,
          is_all_zone: false,
          role_id: role_type,
          employee_type: employee_type
        };
      }
    if (isUserPos) {
      console.log("this UserPos===================>")

      let apiUrls = `${baseURL}/management/employee`;
      let token = localStorage.getItem("Token");
      axios({
        method: "post",
        url: apiUrls,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((res) => {
          Swal.fire({
            title: intl.formatMessage({
              id: "113101",
              defaultMessage: "Save !",
            }),
            text: intl.formatMessage({
              id: "113106",
              defaultMessage: "Create user success",
            }),
            icon: "success",
            confirmButtonText: intl.formatMessage({
              id: "113116",
              defaultMessage: "ok",
            }),
          });
          history.goBack();
        })
        .catch((err) => {
          console.log(
            err.toString().substring(39),
            err.toString().substring(39) == "422"
          );
          if (err.toString().substring(39) == "422") {
            Swal.fire({
              title: intl.formatMessage({
                id: "113101",
                defaultMessage: "Save !",
              }),
              text: intl.formatMessage({
                id: "113117",
                defaultMessage: "Your can not update user. !! ",
              }),
              icon: "error",
              confirmButtonText: intl.formatMessage({
                id: "113116",
                defaultMessage: "ok",
              }),
            });
          } else {
            Swal.fire({
              title: intl.formatMessage({
                id: "113101",
                defaultMessage: "Save !",
              }),
              text:
                intl.formatMessage({
                  id: "113118",
                  defaultMessage: "Your can not update user. !! ",
                }) + err,
              icon: "error",
              confirmButtonText: intl.formatMessage({
                id: "113116",
                defaultMessage: "ok",
              }),
            });
          }
        });
    } else if (isUserBackOffice) {
      console.log("this UserBackOffice===================>")
      let apiUrls = `${baseURL}/management/backOfficeUser`;
      let token = localStorage.getItem("Token");
      axios({
        method: "post",
        url: apiUrls,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: dataUserBackoffice,
      })
        .then((res) => {
          Swal.fire({
            title: intl.formatMessage({
              id: "113101",
              defaultMessage: "Save !",
            }),
            text: intl.formatMessage({
              id: "113106",
              defaultMessage: "Create user success",
            }),
            icon: "success",
            confirmButtonText: intl.formatMessage({
              id: "113116",
              defaultMessage: "ok",
            }),
          });
          history.goBack();
        })
        .catch((err) => {
          console.log(
            err.toString().substring(39),
            err.toString().substring(39) == "422"
          );
          if (err.toString().substring(39) == "422") {
            Swal.fire({
              title: intl.formatMessage({
                id: "113101",
                defaultMessage: "Save !",
              }),
              text: intl.formatMessage({
                id: "113117",
                defaultMessage: "Your can not update user. !! ",
              }),
              icon: "error",
              confirmButtonText: intl.formatMessage({
                id: "113116",
                defaultMessage: "ok",
              }),
            });
          } else {
            Swal.fire({
              title: intl.formatMessage({
                id: "113101",
                defaultMessage: "Save !",
              }),
              text:
                intl.formatMessage({
                  id: "113118",
                  defaultMessage: "Your can not update user. !! ",
                }) + err,
              icon: "error",
              confirmButtonText: intl.formatMessage({
                id: "113116",
                defaultMessage: "ok",
              }),
            });
          }
        });

    }
  };
  const onCancel = () => {
    history.goBack();

  }

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "109036", defaultMessage: "Create Account Management" })}></CardHeader>
        <CardBody>

          <div className="container">
            <div className="h6" style={{ paddingTop: "20px" }}>

              {intl.formatMessage({
                id: "113372",
                defaultMessage: "Select Role",
              })}
              <span className="text-danger">*</span>

            </div>
            <div className= "row">
              <div className="col-6 col-sm-4">
              <select
                  as="select"
                  style={{
                    height: "38px",
                    width: "100%",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={role_type}
                  onChange={ setroletype }
                >
                  <option value="">{intl.formatMessage({ id: "113372", defaultMessage: "Select Role" })}</option>
                  {role_type_name &&
                  role_type_name.map((result, index) => {
                    return (
                      <option key={index} value={result.id}>
                      {result.role}
                      </option>

                    );
                  })}
                </select>
              </div>
            </div>
 
            <div className={`template name mt-3 mb-3 align-items-center${role_type !== '3' && role_type !== '2' ? ' d-inline' : ''}`}>
            <div className="h6" style={{ paddingTop: "20px" }}>

              {intl.formatMessage({
                id: "113368",
                defaultMessage: "Employee Type",
              })}
              <span className="text-danger">*</span>

            </div>
            <div className="row">
              <div className="col-6 col-sm-4">
                <button
                  className="btn-custom-2 mr-3"
                  style={buttonPartTimeStyle}
                  onClick={handleSetParttime}
                
                >
                  <span className="mb-5 mt-5"
                    style={{
                      fontSize: "1em",
                      color: "#000000",
                    }}
                  >
                   <AccessTimeOutlined  style={{  paddingBottom: "2px",color: "#858585" }}/> Part Time

                  </span>
                </button>
              </div>
              <div className="col-6 col-sm-4">
                <button
                  className="btn-custom-2 mr-3 ml-3"
                  style={buttonFullTimeStyle}
                  onClick={handleSetFulltime}
                
                >
                  <span className="mb-5 mt-5"
                    style={{
                      fontSize: "1em",
                      color: "#000000",
                      
                    }}
                   
                  >
                    
                   <AccountBoxSharpIcon  style={{  paddingBottom: "2px",color: "#858585" }} />Full Time

                  </span>
                </button>
              </div>
            </div>
            </div>
            <div className="h6" style={{ paddingTop: "20px" }}>

              {intl.formatMessage({
                id: "113369",
                defaultMessage: "Zone / Branch",
              })}
              <span className="text-danger">*</span>

            </div>
            <div className= "row">
              <div className="col-6 col-sm-4">
                <select className="form-select"
                  as="select"
                  style={{
                    height: "38px",
                    width: "100%",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                    
                  }}
                  value={zone_id}
                  onChange={(e) => setZone_id(e.target.value)}
                >
                  <option value="">{intl.formatMessage({ id: "113373", defaultMessage: "Select Zone" })}</option>
                  <option value="All">{intl.formatMessage({ id: "113417", defaultMessage: "All" })}</option>
                  {zone &&
                  zone.map((result, index) => {
                    return (
                      <option key={index} value={result.id}>
                        {result.zone_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="h6" style={{ paddingTop: "20px" }}>

              {intl.formatMessage({
                id: "113370",
                defaultMessage: "Info",
              })}
              <span className="text-danger">*</span>

            </div>

            <form className="form-inline">
              <input className="input-col-4"

                name="first_name_th"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="Thai Name"
                onChange={(first_name_th) => setfirstname_th(first_name_th.target.value)}

              />
              <input className="input-col-4"
                name="last_name_th"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="Thai Surname"
                onChange={(last_name_th) => setlastname_th(last_name_th.target.value)}
               

              />
              <input className="input-col-4"

                name="first_name_en"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="English Name"
                onChange={(first_name_en) => setfirstname_en(first_name_en.target.value)}

              />
              <input className="input-col-4"
                name="last_name_en"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="English Surname"
                onChange={(last_name_en) => setlastname_en(last_name_en.target.value)}


              />
              <input className="input-col-4"
                name="mobile_no"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="Mobile No."
                maxLength={10}
                onChange={(mobile_no) => setMobile_no(mobile_no.target.value)}
                

              />
              <input className="input-col-4"
                name="email"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                type="email"
                placeholder="Email"
                onChange={(email) => setEmail(email.target.value)}


              />
            </form>
            <div className="h6" style={{ paddingTop: "20px" }}>

              {intl.formatMessage({
                id: "113371",
                defaultMessage: "Username / Password",
              })}
              <span className="text-danger">*</span>

            </div>    
            <form className="form-inline">
              <input className="input-col-4"

                name="username"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="Username"
                onChange={(username) => setUsername(username.target.value)}
                

              />
              <input className="input-col-4"
                name="password"
                type="password"
                style={{
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                placeholder="Password"
                onChange={(password) => setPassword(password.target.value)}

                

              />
            </form>
            <form className="form-inline">
                          <div className="h6" style={{ paddingTop: "20px" }}>

                              {intl.formatMessage({
                                  id: "113379",
                                  defaultMessage: "Platform",
                              })}<span className="text-danger">*</span>

                          </div>
                          {/* <div className="checkbox-container">
                              <input type="checkbox" name="userBackOffice" 
                              checked={isUserBackOffice}
                              onChange={handleCheckboxChange}/>
                              
                              
                              <label className="h6" htmlFor="checkbox1">Backoffice</label>
                              
                          </div> */}
                          <div className="checkbox-container">
                              <input type="checkbox" name="userPos"
                              checked={isUserPos}
                              onChange={handleCheckboxChange} />
                              <label className="h6" htmlFor="view">POS</label>
                          </div>
    
                      </form>   
 
        
          </div>



          <div
            className="mt-5 form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>

            <Link to="/UserManagement">
              <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => onCancel()}>
                {intl.formatMessage({ id: "113030", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
