import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { temp_store } from "../../../modules/function/temp_store";
import { BorderColor } from "@material-ui/icons";
import { object } from "prop-types";
import { objectOf } from "prop-types";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

export const ViewPromotion = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113442", defaultMessage: "Promotion" })
  );
  const history = useHistory();
  const [listData, setListData] = useState([]);
  const [promo_name_th, setPromoNameTh] = useState("");
  const [promo_name_en, setPromoNameEn] = useState("");
  const [promotion_desc_th, setPromoDescTh] = useState("");
  const [promotion_desc_en, setPromoDescEn] = useState("");
  const [start_date_time, setStartDateTime] = useState("");
  const [expiry_date_time, setExpiryDateTime] = useState("");
  const [discount_percent, setDiscountPercent] = useState("");
  const [discount_value, setDiscountValue] = useState("");
  const [amount_value, setAmountValue] = useState("");
  const [promotion_channels, setPromoChannel] = useState([
    {
      text: "ทานที่ร้าน",
      text_en: "Dine in",
      img_path: "./media/icon/dinein.jpg",
    },
    {
      text: "ซื้อกลับ",
      text_en: "Take away",
      img_path: "./media/icon/Takeaway.jpg",
    },
    {
      text: "เดลิเวอรี่",
      text_en: "Delivery",
      img_path: "./media/icon/delivery.jpg",
    },
    {
      text: "รับที่ร้าน",
      text_en: "Pick up",
      img_path: "./media/icon/pickup.jpg",
    },
  ]);
  let lang = localStorage.getItem("Language");
  useEffect(() => {
    console.log("tempstore===========>", temp_store);
    gettingData();
    console.log("lang=======>", lang);
  }, []);

  const gettingData = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/management/promotionnew/${
        temp_store(props).location.state.row.id
      }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      let dataresponse = res.data.data;
      //เซตรายละเอียด promotion
      setPromoNameTh(dataresponse[0].promotion_name_th);
      setPromoNameEn(dataresponse[0].promotion_name_en);
      setPromoDescEn(dataresponse[0].promotion_desc_en);
      setPromoDescTh(dataresponse[0].promotion_type[0].desc_th);
      setStartDateTime(
        moment(dataresponse[0].start_date_time).format("DD MMM YYYY hh:mm:ss A")
      );
      setExpiryDateTime(
        moment(dataresponse[0].start_date_time).format("DD MMM YYYY hh:mm:ss A")
      );
      setDiscountPercent(dataresponse[0].discount_percent);
      setDiscountValue(dataresponse[0].discount_value);
      setAmountValue(dataresponse[0].amount_value)
      let allData = [];
      let total_price = 0;
      let item_price = 0;
      if (Object.keys(dataresponse[0].promotion_item_list).length > 0) {
        dataresponse[0].promotion_item_list.map((item) => {
            const { discount_value, discount_percent, amount_value } = dataresponse[0];
            let item_price = item.price * (amount_value || 1);
            let total_price = item_price;
            if (discount_value > 0 && amount_value == 0) { // fixed price discount
                total_price = Math.max(0, item.price - discount_value);
            } else if (discount_percent > 0) { // percent discount && bundle percent
                total_price = Math.max(0, item_price - (item.price * discount_percent * (amount_value || 1)) / 100);
            } else if (discount_value > 0 && amount_value > 0) { // bundle fixed price
                total_price = Math.max(0, item_price - discount_value);
            }
            allData.push({
                item_id: item.id,
                item_name: item.item_name,
                item_name_en: item.item_name_en,
                price: amount_value > 0 ? item_price : item.price,
                total_price: total_price,
                item_img: item.item_image_path || "./media/icon/Please_upload_image.jpg",
                amount_value: amount_value
            });
        });
    } else {
        console.log(
          "promotion_group_list===========>",
          dataresponse[0].promotion_group_list
        );
        dataresponse[0].promotion_group_list.map((item) => {
          console.log("item=============>", item);
          allData.push({
            item_id: item.id,
            group_id: item.group_id,
            item_name: item.group_name,
            item_name_en: item.group_name_en,
            price: "",
            total_item: item.total_item, // จำนวนเมนูทั้งหมดใน category
            item_img: item.first_img ? item.first_img :"./media/icon/slash.jpg", //เอารูปแรกที่หาเจอมาใส่ถ้าไม่เจอใส่รูป logo slash
            amount_value: amount_value
          });
        });
      }
      console.log("allData==========>", allData);
      setListData(allData);
    });
  };


  return (
    <div>
      <Card>
        {temp_store(props).location.state.row.status == "pending" && (
          <CardHeader
            title={intl.formatMessage({
              id: "113408",
              defaultMessage: "Approve Purchase Request",
            })}
          ></CardHeader>
        )}

        {temp_store(props).location.state.row.status != "pending" && (
          <CardHeader
            title={intl.formatMessage({
              id: "113443",
              defaultMessage: "Promotion Detail",
            })}
          ></CardHeader>
        )}

        <CardBody>
          <div className="row">
            <div className="col-md  d-flex justify-content-center">
              <img
                style={{
                  maxWidth: "50%",
                  maxHeight: "70%",
                  borderRadius: "10%",
                }}
                src="./media/icon/default_promotion_img.jpg"
              />
            </div>
            <div className="col-md-8 ">
              <div className="">
                <div className="form-inline">
                  {lang == "th" ? (
                    <p className="h3" style={{ float: "left", width: "40%" }}>
                      {promo_name_th}
                    </p>
                  ) : (
                    <p className="h3" style={{ float: "left", width: "40%" }}>
                      {promo_name_en}
                    </p>
                  )}
                </div>

                <div className="" style={{ marginBottom: "15px" }}>
                  <div className="form-inline">
                    <span
                      className="h6"
                      style={{ float: "left", width: "25%" }}
                    >
                      {intl.formatMessage({
                        id: "113445",
                        defaultMessage: "Promotion Period",
                      })}
                    </span>
                    <button
                      type="button"
                      id="button"
                      className="btn mb-3 mr-3 mt-1"
                      style={{
                        border: "2px solid",
                        borderRadius: "10px",
                        borderColor: "#F4F4F4",
                      }}
                    >
                      <CalendarTodayIcon
                        style={{ fontSize: "small", paddingBottom: "2px" }}
                      />
                      {start_date_time} - {expiry_date_time}
                    </button>
                  </div>
                  <div className="form-inline">
                    <p className="h6" style={{ float: "left", width: "25%" }}>
                      {intl.formatMessage({
                        id: "113444",
                        defaultMessage: "Discount",
                      })}
                    </p>
                    {discount_value ? (
                      <input
                        style={{
                          height: "38px",
                          width: "365px",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        value={`${discount_value} บาท`}
                        readOnly
                      />
                    ) : (
                      <input
                        style={{
                          height: "38px",
                          width: "365px",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        value={`${discount_percent} %`}
                        readOnly
                      />
                    )}
                  </div>
                </div>

                <div className="form-inline">
                  <p className="h6" style={{ float: "left", width: "25%" }}>
                    {intl.formatMessage({
                      id: "113446",
                      defaultMessage: "Condition",
                    })}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "365px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={lang == "th" ? promotion_desc_th : promotion_desc_en}
                    readOnly
                  />
                </div>
                <div className="form-inline" style={{ marginTop: "15px" }}>
                  <p
                    className="h4 d-flex justify-content-center"
                    style={{ float: "center", width: "100%" }}
                  >
                    {intl.formatMessage({
                      id: "113448",
                      defaultMessage: "Types of orders for promotions",
                    })}
                  </p>
                  <div style={{ width: "100%" }}>
                    <div className="d-flex justify-content-center">
                      <div className="d-flex flex-wrap">
                        {promotion_channels.map((item, index) => (
                          <div className="p-7 "style={{
                            width: "25%",
                          }} key={index}>
                            <img
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                                marginBottom: "10px",
                              }}
                              src={item.img_path}
                            />
                            {lang == "th" ? (
                              <p className="text-center">{item.text}</p>
                            ) : (
                              <p className="text-center">{item.text_en}</p>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "15px" }}></div>
          </div>
          <hr></hr>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                borderTop: "1px dashed black",
                flex: 1,
                backgroundColor: "#F4F6F9",
              }}
            />
            <span
              className="h4"
              style={{ padding: "0 10px", fontSize: "24px" }}
            >
              {intl.formatMessage({
                id: "113447",
                defaultMessage: "Participating products",
              })}
            </span>
            <div
              style={{
                borderTop: "1px dashed black",
                flex: 1,
                backgroundColor: "#F4F6F9",
              }}
            />
          </div>
          <div className="d-flex flex-wrap">
            {listData.map((item, index) => (
              <div className="p-2" key={index} style={{ flex: "1 0 21%" }}>
                <Card
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderRadius: "20px",
                    borderColor: "#CECDCD",
                  }}
                >
                  <CardBody>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    >
                      <img
                        style={{
                          width: "50%",
                          height: "50%",
                          borderRadius: "10%",
                        }}
                        src={item.item_img}
                        alt={item.item_name}
                      />
                    </div>
                    <div className="text-center">

                      {amount_value == 0 ?
                     
                      (<p className="h4">{item.item_name}</p>)
                      :(
                      <p className="h4">{item.item_name}({amount_value}items)</p>
    
                    
                    )
                      }
                      
                      
                      {item.price ? (
                        <div>
                          <p
                            className="h4"
                            style={{ textDecoration: "line-through" }}
                          >
                            ฿{parseFloat(item.price).toFixed(2)}
                          </p>
                          <p className="h4" style={{ color: "red" }}>
                            ฿{parseFloat(item.total_price).toFixed(2)}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.total_item && amount_value == 0 ? (
                        <p className="h4">{item.total_item} items</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
