import React from 'react';

function ThermalV2Order() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 340.16 212.6">
            <defs>
                <style>
                    {`.cls-1 { fill: none; }
            .cls-2 { fill: #f1f1f1; }
            .cls-3 { fill: #efeeee; }
            .cls-4 { fill: #f9fcff; }
            .cls-5 { fill: #cacac9; }
            .cls-6 { fill: #bebfc0; }
            .cls-7 { fill: #dfdfdf; }
            .cls-8 { fill: #bec0c2; }
            .cls-9 { fill: #e4e5e5; }
            .cls-10 { fill: #adaeb0; }
            .cls-11 { fill: #eaeaea; }
            .cls-12 { fill: #d1d2d3; }
            .cls-13 { fill: #c1c3c4; }
            .cls-14 { fill: #f7fbff; }
            .cls-15 { fill: #c6c7c8; }
            .cls-16 { fill: url(#linear-gradient-2); }
            .cls-17 { fill: #c8c9cb; }
            .cls-18 { fill: #e7e6e6; }
            .cls-19 { fill: #d4d6d6; }
            .cls-20 { fill: #e0e1e1; }
            .cls-21 { fill: url(#linear-gradient); }
            .cls-22 { fill: #b9b9bc; }
            .cls-23 { fill: #e4e3e3; }
            .cls-24 { fill: #b0b3b5; }
            .cls-25 { isolation: isolate; }
            .cls-26 { fill: #dadbdb; }
            .cls-27 { fill: #b1b1b3; }
            .cls-28 { fill: #f0eff0; }
            .cls-29 { fill: #afb0b3; }
            .cls-30 { fill: #d0d1d2; }
            .cls-31 { fill: #f1f2f2; }
            .cls-32 { fill: #ededee; }
            .cls-33 { fill: #c8c9ca; }
            .cls-34 { fill: #cdcfd1; }
            .cls-35 { fill: #c9cacc; }
            .cls-36 { fill: #c4c5c7; }
            .cls-37 { fill: #e8e8e9; }
            .cls-38 { fill: #80ced6; }
            .cls-39 { fill: #bbbcbe; }
            .cls-40 { fill: #b1b3b7; }
            .cls-41 { fill: #fff; }
            .cls-42 { fill: #b6b8bb; }
            .cls-43 { fill: #c1c1c2; }
            .cls-44 { fill: #d7d9d9; }
            .cls-45 { fill: #d6d6d7; }
            .cls-46 { fill: #f6fbfb; }
            .cls-47 { fill: #c5c6c8; }
            .cls-48 { fill: #f4f5f5; }
            .cls-49 { fill: #c5c5c5; }
            .cls-50 { fill: #f3f4f4; }
            .cls-51 { fill: #e2e3e4; }
            .cls-52 { fill: #bcbdbf; }
            .cls-53 { fill: #d2d4d6; }
            .cls-54 { fill: #ced1d2; }
            .cls-55 { fill: #b5b5b8; }
            .cls-56 { fill: #d4397e; }
            .cls-57 { fill: #221f1f; }
            .cls-58 { fill: #b6b7bb; }
            .cls-59 { fill: #cbced0; }
            .cls-60 { fill: #d0d2d5; }
            .cls-61 { fill: #dadcdc; }
            .cls-62 { fill: #dbdadb; }
            .cls-63 { fill: #6466ae; }
            .cls-64 { fill: #f6f7f7; }
            .cls-65 { fill: #f6f7f8; }
            .cls-66 { fill: #e4e4e5; }
            .cls-67 { fill: #aeafb1; }
            .cls-68 { fill: #b3b4b7; }
            .cls-69 { fill: #dfe0e0; }
            .cls-70 { fill: #afb2b5; }
            .cls-71 { fill: #e8e8ea; }
            .cls-72 { fill: #bdbfc1; }
            .cls-73 { fill: #b6b8b9; }
            .cls-74 { fill: #efeeef; }
            .cls-75 { clip-path: url(#clippath); }
            .cls-76 { fill: #d6d7d7; }
            .cls-77 { fill: #c2c4c6; }
            .cls-78 { fill: #f2f3f4; }
            .cls-79 { fill: #dbdcdd; }
            .cls-80 { fill: #babbbd; }
            .cls-81 { fill: #eaeaeb; }
            .cls-82 { mix-blend-mode: darken; opacity: .58; }`}
                </style>
                <linearGradient id="linear-gradient" x1="-4546.04" y1="1942.93" x2="-4546.04" y2="1944.88" gradientTransform="translate(-4387.79 2006.55) rotate(-180)" gradientUnits="userSpaceOnUse">
                    <stop offset=".02" stopColor="#f8a263" />
                    <stop offset="1" stopColor="#fab31c" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="-4503.21" y1="1944.88" x2="-4503.21" y2="1942.93" gradientTransform="translate(-4344.96 2049.37) rotate(-180)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#745ca6" />
                    <stop offset="1" stopColor="#745ca6" />
                </linearGradient>
                <clipPath id="clippath">
                    <polygon className="cls-1" points="39.65 185.4 48.66 185.4 42.23 195.31 33.04 195.31 39.65 185.4" />
                </clipPath>
            </defs>
            <g class="cls-25">
                <g id="Layer_1">
                    <rect class="cls-57" x="-2.04" y="-2.86" width="344.25" height="218.31" />
                    <g>
                        <rect class="cls-38" x="136.83" y="190.2" width="42.84" height="1.95" transform="translate(-32.93 349.43) rotate(-90)" />
                        <rect class="cls-56" x="136.81" y="18.82" width="42.88" height="1.95" transform="translate(138.45 178.05) rotate(-90)" />
                        <rect class="cls-21" x="136.85" y="61.66" width="42.81" height="1.95" transform="translate(95.61 220.89) rotate(-90)" />
                        <rect class="cls-16" x="136.83" y="104.49" width="42.84" height="1.95" transform="translate(52.79 263.71) rotate(-90)" />
                        <rect class="cls-63" x="136.81" y="147.35" width="42.88" height="1.95" transform="translate(9.93 306.57) rotate(-90)" />
                    </g>
                    <rect class="cls-41" x="175.7" y="16.24" width="149.74" height="149.74" rx="4.3" ry="4.3" />
                    <g>
                        <g>
                            <polygon class="cls-41" points="42.23 167.98 51.32 167.98 44.67 177.84 53.57 177.87 42.23 195.31 33.04 195.31 39.65 185.4 30.63 185.4 42.23 167.98" />
                            <polygon class="cls-41" points="42.23 167.98 51.32 167.98 44.67 177.84 53.57 177.87 42.23 195.31 33.04 195.31 39.65 185.4 30.63 185.4 42.23 167.98" />
                            <g class="cls-82">
                                <g class="cls-75">
                                    <rect class="cls-10" x="33.04" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-67" x="33.23" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-29" x="33.43" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-27" x="33.62" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-70" x="33.81" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-24" x="34" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-40" x="34.2" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-68" x="34.39" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-55" x="34.58" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-73" x="34.78" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-58" x="34.97" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-42" x="35.16" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-22" x="35.35" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-80" x="35.55" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-80" x="35.74" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-39" x="35.93" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-52" x="36.13" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-6" x="36.32" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-72" x="36.51" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-8" x="36.7" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-43" x="36.9" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-13" x="37.09" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-13" x="37.28" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-77" x="37.48" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-49" x="37.67" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-36" x="37.86" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-47" x="38.06" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-15" x="38.25" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-33" x="38.44" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-17" x="38.63" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-35" x="38.83" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-59" x="39.02" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-34" x="39.21" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-54" x="39.41" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-54" x="39.6" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-30" x="39.79" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-12" x="39.98" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-60" x="40.18" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-53" x="40.37" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-53" x="40.56" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-19" x="40.76" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-45" x="40.95" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-76" x="41.14" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-44" x="41.33" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-62" x="41.53" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-26" x="41.72" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-61" x="41.91" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-79" x="42.11" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-7" x="42.3" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-7" x="42.49" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-69" x="42.68" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-69" x="42.88" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-20" x="43.07" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-51" x="43.26" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-23" x="43.46" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-66" x="43.65" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-9" x="43.84" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-18" x="44.03" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-37" x="44.23" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-37" x="44.42" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-71" x="44.61" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-81" x="44.81" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-11" x="45" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-32" x="45.19" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-3" x="45.38" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-74" x="45.58" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-28" x="45.77" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-2" x="45.96" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-31" x="46.16" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-78" x="46.35" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-78" x="46.54" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-50" x="46.73" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-48" x="46.93" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-64" x="47.12" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-65" x="47.31" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-46" x="47.51" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-14" x="47.7" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-4" x="47.89" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-41" x="48.08" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-41" x="48.28" y="185.4" width=".19" height="9.91" />
                                    <rect class="cls-41" x="48.47" y="185.4" width=".19" height="9.91" />
                                </g>
                            </g>
                            <polygon class="cls-5" points="39.65 185.4 48.64 185.39 37.65 188.38 39.65 185.4" />
                        </g>
                        <g>
                            <path class="cls-41" d="M59.66,184.75c1.7.27,3.38.44,5.08.44.49,0,1.53,0,1.53-.9,0-.67-.27-.8-2.93-.8-1.37,0-2.87-.59-2.87-2.72,0-2.93,1.19-5.32,2.97-5.96,1-.36,2.44-.4,3.54-.4,1.49,0,3.88.1,6.05.51l-.94,4.21c-1.44-.23-2.87-.42-4.33-.42-1.77,0-2.19.32-2.19.97,0,.55.3.7,2.63.7,1.69,0,3.18.1,3.18,2.95,0,1.07-.39,2.55-.7,3.2-.92,1.91-2.41,2.27-5.34,2.27-2.38,0-4.4-.17-6.38-.63l.67-3.43Z" />
                            <path class="cls-41" d="M74.62,174.8h4.59l-1.8,8.94h4.61l-.95,4.67h-9.19l2.74-13.6Z" />
                            <path class="cls-41" d="M88.65,174.8h7.95v13.6h-4.58v-1.98h-3.75l-.92,1.98h-5.08l6.38-13.6ZM92.02,178.25h-.03l-2.35,4.9h2.38v-4.9Z" />
                            <path class="cls-41" d="M98.19,184.75c1.7.27,3.38.44,5.09.44.49,0,1.53,0,1.53-.9,0-.67-.27-.8-2.93-.8-1.37,0-2.87-.59-2.87-2.72,0-2.93,1.19-5.32,2.97-5.96,1-.36,2.44-.4,3.54-.4,1.49,0,3.88.1,6.05.51l-.94,4.21c-1.44-.23-2.87-.42-4.33-.42-1.77,0-2.19.32-2.19.97,0,.55.3.7,2.63.7,1.7,0,3.18.1,3.18,2.95,0,1.07-.39,2.55-.7,3.2-.92,1.91-2.41,2.27-5.34,2.27-2.38,0-4.4-.17-6.38-.63l.67-3.43Z" />
                            <path class="cls-41" d="M113.1,174.8h4.52l-1.03,4.93h2.78l1.03-4.93h4.52l-2.84,13.6h-4.52l1.01-4.86h-2.78l-1.01,4.86h-4.52l2.84-13.6Z" />
                        </g>
                    </g>
                    <g>
                        <path class="cls-41" d="M219.1,187.83v-4.27h-2.13c-.65,0-1.17.1-1.56.29-.39.19-.58.54-.58,1.05,0,.42.13.75.4.99.27.24.6.36.99.36.21,0,.43-.02.65-.06.23-.04.46-.12.69-.24l.37,1.66c-.3.14-.61.24-.93.3s-.66.09-1.02.09c-.48,0-.92-.06-1.32-.19-.41-.13-.76-.32-1.06-.58s-.54-.58-.71-.96-.26-.83-.26-1.34c0-1.08.37-1.87,1.12-2.36.75-.49,1.89-.74,3.41-.74h1.95v-.33c0-.24-.04-.48-.12-.71s-.2-.43-.37-.6c-.16-.17-.37-.31-.62-.42-.25-.11-.54-.16-.88-.16-.72,0-1.35.1-1.89.31s-1.04.48-1.51.82l-.71-1.57c.45-.34,1.04-.64,1.77-.91.73-.27,1.54-.4,2.45-.4.27,0,.53.02.79.07s.49.11.69.19l.59-.73h2.49l-1.43,1.71c.3.33.54.69.71,1.1.17.41.25.84.25,1.29v6.35h-2.21Z" />
                        <path class="cls-41" d="M225.9,178.06v9.77h-2.27v-9.77h2.27ZM229.88,178.06v9.77h-2.27v-9.77h2.27Z" />
                        <path class="cls-41" d="M238.51,187.83v-6.07c0-.7-.19-1.22-.57-1.57-.38-.35-.89-.52-1.54-.52-.24,0-.49.03-.72.1-.24.06-.46.15-.66.26s-.38.24-.54.38c-.16.15-.28.29-.36.44l1.46,1.24c-.36.13-.63.32-.82.58-.19.26-.28.61-.28,1.05v4.1h-2.27v-4.08c0-.37.09-.7.26-.99.17-.29.4-.52.66-.68l-1.41-1.15c.12-.39.32-.78.6-1.14.28-.37.63-.69,1.04-.98s.87-.51,1.39-.69c.52-.17,1.09-.26,1.69-.26.7,0,1.31.1,1.85.29.54.19.99.46,1.35.8s.64.76.84,1.24.29,1.01.29,1.58v6.07h-2.27Z" />
                        <path class="cls-41" d="M245.31,184.39c0,.66.14,1.13.42,1.41.28.28.67.42,1.18.42s.97-.15,1.4-.44c.44-.3.76-.68.99-1.14v-6.57h2.27v9.77h-2.02l-.24-1.2c-.13.17-.29.35-.5.51-.2.17-.43.32-.68.45-.25.13-.53.24-.83.32s-.62.12-.94.12c-1.04,0-1.85-.28-2.43-.85s-.88-1.47-.88-2.69v-6.44h2.27v6.33Z" />
                        <path class="cls-41" d="M260.61,187.83v-5.91c0-.69-.2-1.23-.6-1.64s-.95-.61-1.65-.61c-.85,0-1.5.3-1.96.92-.46.61-.69,1.44-.69,2.49,0,.58.06,1.07.18,1.46.12.39.28.71.48.95s.41.42.65.52c.24.1.47.16.71.16s.45-.02.65-.05c.2-.03.42-.1.65-.21l.4,1.64c-.24.15-.51.26-.81.33-.3.07-.67.11-1.11.11-.52,0-1.03-.1-1.52-.29-.49-.19-.92-.49-1.31-.89-.38-.4-.69-.91-.92-1.53-.23-.62-.34-1.35-.34-2.2,0-.73.11-1.42.32-2.05.21-.63.53-1.19.93-1.66.41-.47.91-.84,1.52-1.11.61-.27,1.3-.4,2.09-.4.74,0,1.4.1,1.97.31.57.21,1.05.5,1.43.86.38.37.67.8.87,1.3.2.5.3,1.04.3,1.6v5.9h-2.27Z" />
                        <path class="cls-41" d="M260.26,192.88c-.84,0-1.48-.18-1.92-.55-.44-.37-.66-.84-.66-1.42v-.37h-1.01v-1.59h2.97v1.97c0,.2.06.34.19.43.13.09.27.13.44.13.17,0,.32-.04.45-.13.12-.09.18-.23.18-.43v-1.97h1.99v1.95c0,.58-.23,1.05-.68,1.42s-1.1.55-1.94.55Z" />
                        <path class="cls-41" d="M267.46,178.06v9.77h-2.27v-9.77h2.27Z" />
                        <path class="cls-41" d="M276.08,178.06h2.27v6.26c0,1.23-.3,2.16-.89,2.78-.59.62-1.41.93-2.46.93-.34,0-.65-.04-.94-.13-.29-.09-.56-.2-.81-.33-.25-.13-.47-.28-.67-.45-.2-.17-.36-.33-.49-.5l-.24,1.2h-2.02v-9.77h2.27v6.57c.11.22.24.43.4.62.16.19.35.36.56.51.21.15.43.26.67.34.24.08.48.12.72.12,1.09,0,1.64-.67,1.64-2.01v-6.16Z" />
                        <path class="cls-41" d="M282.88,184.39c0,.66.14,1.13.42,1.41.28.28.67.42,1.18.42s.97-.15,1.4-.44c.44-.3.76-.68.99-1.14v-6.57h2.27v9.77h-2.02l-.24-1.2c-.13.17-.29.35-.5.51-.2.17-.43.32-.68.45-.25.13-.53.24-.83.32s-.62.12-.94.12c-1.04,0-1.85-.28-2.43-.85s-.88-1.47-.88-2.69v-6.44h2.27v6.33Z" />
                        <path class="cls-41" d="M286.56,192.88c-.84,0-1.48-.18-1.92-.55-.44-.37-.66-.84-.66-1.42v-.37h-1.01v-1.59h2.97v1.97c0,.2.06.34.19.43.13.09.27.13.44.13.17,0,.32-.04.45-.13.12-.09.18-.23.18-.43v-1.97h1.99v1.95c0,.58-.23,1.05-.68,1.42s-1.1.55-1.94.55Z" />
                    </g>
                    <g>
                        <path class="cls-41" d="M207.7,200.69c0,.2.04.37.11.52s.17.27.28.37c.12.1.26.17.42.22.16.05.33.07.5.07.08,0,.16,0,.25-.01.09,0,.18-.02.27-.04s.17-.05.25-.09c.08-.04.15-.08.21-.14.06-.05.11-.12.15-.2.04-.08.06-.17.06-.27s-.02-.2-.07-.27c-.05-.08-.11-.14-.19-.2-.08-.06-.18-.1-.29-.14-.11-.04-.24-.07-.37-.11-.13-.03-.27-.07-.42-.1s-.29-.06-.44-.1c-.23-.06-.45-.13-.66-.22s-.4-.19-.56-.32-.29-.29-.39-.49c-.1-.19-.14-.43-.14-.71,0-.2.03-.39.09-.56.06-.17.14-.32.25-.45.11-.13.23-.25.38-.35s.3-.18.47-.25c.17-.07.34-.12.53-.15.18-.03.37-.05.55-.05.21,0,.42.02.62.05.2.03.39.09.56.16.18.07.34.16.48.27.15.11.27.23.38.38s.19.31.24.5c.06.19.09.4.09.63h-1.3c-.01-.18-.04-.33-.1-.45-.06-.12-.13-.22-.23-.29-.1-.07-.21-.13-.34-.16-.13-.03-.28-.05-.44-.05-.11,0-.22.01-.33.03-.11.02-.21.06-.3.11-.09.05-.16.12-.22.21-.06.09-.09.19-.09.32,0,.08,0,.14.03.2s.05.11.1.16c.05.05.11.09.2.13.08.04.19.08.32.13.13.04.29.09.48.14s.41.11.66.17c.08.02.17.04.29.07.12.03.24.07.37.12.13.05.26.12.39.2.13.08.25.18.35.3.1.12.19.26.25.43s.1.36.1.59c0,.28-.05.53-.16.77-.11.24-.27.44-.49.61-.21.17-.48.3-.8.4-.32.1-.68.15-1.1.15-.23,0-.45-.02-.66-.06-.21-.04-.42-.1-.61-.19s-.37-.19-.53-.31c-.16-.13-.3-.27-.41-.43s-.2-.35-.27-.55-.09-.43-.09-.68h1.3Z" />
                        <path class="cls-41" d="M216.32,198.86c-.02-.15-.07-.29-.15-.42-.08-.13-.17-.25-.29-.34-.12-.1-.25-.17-.4-.23-.15-.06-.3-.09-.47-.09-.19,0-.37.03-.53.08s-.29.13-.41.22c-.12.09-.22.2-.31.33-.09.13-.16.27-.21.42-.05.15-.09.31-.12.48-.03.17-.04.34-.04.51s.01.33.04.49c.03.16.07.32.12.47s.13.28.21.41.19.24.31.33c.12.09.26.17.41.22s.33.08.53.08.38-.03.54-.1.3-.16.41-.27c.11-.11.21-.24.28-.39.07-.15.11-.3.13-.45h1.27c-.03.35-.12.66-.26.95s-.32.53-.55.73c-.23.2-.5.36-.8.47s-.64.17-1.01.17c-.31,0-.59-.04-.86-.11s-.5-.18-.72-.31c-.22-.13-.41-.29-.58-.48s-.31-.4-.42-.63c-.11-.23-.2-.48-.26-.74-.06-.26-.09-.54-.09-.83s.03-.58.09-.85c.06-.27.15-.52.26-.75.11-.23.26-.44.42-.63s.36-.35.58-.49c.22-.14.46-.24.72-.31.26-.07.55-.11.86-.11.33,0,.64.05.93.15.29.1.56.24.79.43.23.19.42.41.57.68s.24.58.29.92h-1.27Z" />
                        <path class="cls-41" d="M223.97,202.77h-1.39l-.51-1.43h-2.23l-.51,1.43h-1.39l2.32-5.97h1.38l2.32,5.97ZM220.2,200.37h1.5l-.74-2.09-.76,2.09Z" />
                        <path class="cls-41" d="M224.53,196.8h1.31l2.57,4h.01v-4h1.26v5.97h-1.32l-2.56-4h-.01v4h-1.26v-5.97Z" />
                        <path class="cls-41" d="M232.45,197.94v-1.14h4.72v1.14h-1.71v4.84h-1.32v-4.84h-1.69Z" />
                        <path class="cls-41" d="M237.5,199.79c0-.27.03-.54.08-.8.06-.26.14-.51.25-.74.11-.23.25-.44.42-.64s.36-.36.59-.5c.22-.14.48-.25.76-.33.28-.08.59-.12.93-.12s.65.04.93.12c.28.08.54.19.76.33.23.14.42.31.59.5s.31.41.42.64c.11.23.2.48.25.74.06.26.08.53.08.8s-.03.53-.08.79c-.06.26-.14.5-.25.73-.11.23-.25.44-.42.63-.17.19-.37.36-.59.5-.23.14-.48.25-.76.32-.28.08-.59.12-.93.12s-.65-.04-.93-.12c-.28-.08-.53-.19-.76-.32-.22-.14-.42-.3-.59-.5-.17-.19-.31-.4-.42-.63-.11-.23-.19-.47-.25-.73s-.08-.52-.08-.79ZM238.82,199.79c0,.24.03.48.09.72s.16.45.3.64c.14.19.31.34.53.45.21.11.47.17.78.17s.57-.06.79-.17c.22-.11.4-.27.54-.45.14-.19.24-.4.3-.64s.09-.48.09-.72c0-.16-.01-.32-.04-.49-.03-.16-.07-.32-.13-.46s-.13-.28-.23-.41c-.09-.13-.2-.24-.33-.33-.13-.09-.27-.17-.44-.22s-.35-.08-.56-.08-.38.03-.55.08c-.16.05-.31.13-.43.22s-.23.21-.32.33c-.09.13-.17.26-.22.41-.06.15-.1.3-.13.46-.03.16-.04.33-.04.49Z" />
                        <path class="cls-41" d="M248.02,202.77l-2.09-5.97h1.42l1.32,4.13,1.32-4.13h1.4l-2.11,5.97h-1.27Z" />
                        <path class="cls-41" d="M253.36,196.8v5.97h-1.3v-5.97h1.3Z" />
                        <path class="cls-41" d="M259.08,196.8v1.08h-3.2v1.28h2.82v1.05h-2.82v1.5h3.21v1.06h-4.52v-5.97h4.51Z" />
                        <path class="cls-41" d="M262.29,202.77h-1.36l-1.58-5.97h1.34l.97,4.13h.01l1.09-4.13h1.17l1.09,4.13h.01l.97-4.13h1.34l-1.58,5.97h-1.36l-1.06-4.03-1.06,4.03Z" />
                        <path class="cls-41" d="M270.09,196.8h1.79l1.5,4.1h.01l1.42-4.1h1.79v5.97h-1.25v-4.24h-.02l-1.55,4.24h-.84l-1.56-4.2h-.01v4.2h-1.26v-5.97Z" />
                        <path class="cls-41" d="M282.23,196.8v1.08h-3.2v1.28h2.82v1.05h-2.82v1.5h3.21v1.06h-4.52v-5.97h4.51Z" />
                        <path class="cls-41" d="M283.01,196.8h1.31l2.57,4h.01v-4h1.26v5.97h-1.32l-2.56-4h-.01v4h-1.26v-5.97Z" />
                        <path class="cls-41" d="M294.48,200.47c0,.39-.06.74-.17,1.04-.11.3-.28.55-.49.75-.22.2-.48.35-.8.46-.31.1-.67.15-1.07.15s-.79-.05-1.12-.15c-.33-.1-.6-.25-.83-.44-.22-.2-.4-.45-.51-.75s-.18-.65-.18-1.06v-3.67h1.31v3.67c0,.21.03.39.08.55s.13.3.24.42c.11.11.24.2.41.26.17.06.37.09.6.09.22,0,.4-.03.56-.09.15-.06.28-.15.38-.26.1-.11.17-.25.22-.42s.07-.35.07-.55v-3.67h1.31v3.67Z" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ThermalV2Order;
