import { baseURL } from "../service/API.js";
import axios from "axios";
export const roleCheckPage = async (page) => {
    let result = [];
    let token = localStorage.getItem("Token");
    await axios({
        url:`${baseURL}/role/checkPage`,
        method: 'POST',
        data: {
            page_name: page,
        },
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => {
            res.data.data.map(item=>{
                if (item.page) {
                    result.push(item.page.page_name)
                }
            })
        })
        .catch((error) => {
            result = false;
            console.error('Error:', error);
        });

    
    return result;
}