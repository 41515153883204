import axios from 'axios'
import { baseURL } from '../../../service/API'

export const LOGIN_URL = `${baseURL}/auth/backoffice/login`
export const ME_URL = `${baseURL}/auth/backoffice/me`;
export function login(email, password) {
  localStorage.setItem("email", email)
  localStorage.setItem("pass", password)
  return axios.post(LOGIN_URL, { email, password })
}

export function getUserByToken() {
  let token = localStorage.getItem("Token");
  return axios({
    method: "get",
    url: ME_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}
