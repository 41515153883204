import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "./component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const Datetime = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    const classes = useStyles(); 
    suhbeader.setTitle(intl.formatMessage({ id: "113317", defaultMessage: "Opening Hours" }));
    const history = useHistory();
    const [daydata,setDayData] = useState([]);
    const [state, setState] = useState(true);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [dayworkingdata,setDayWorkingData] = useState([]);
    let lang = localStorage.getItem("Language");



    const AlertError = (message) => {
        Swal.fire("Payment!", message, "error");
    };
    function convertTimeFormat(inputTime) {
        if(inputTime!==null){
        var timeParts = inputTime.split(':');
        var hours = timeParts[0];
        var minutes = timeParts[1];
        var convertedTime = hours + ':' + minutes;
        return convertedTime;
        }
    }

    useEffect(() => {
        getShopOpenTime().then(()=>{
            getDeliveryTime();
            getWorkingTime();
        });
        console.log("workingdata======>",dayworkingdata);
        console.log(daydata);
      
    }, [startTime,endTime]);
    const getWorkingTime = async () => {
        let token = localStorage.getItem("Token");
        axios({
            method: "get",
            url: `${baseURL}/management/workingslot?page=1&limit=10`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log("==========>",res.data.data.date_id)
                if (Object.keys(res.data.data).length > 0) {
                
                    console.log("responseworkingtime=======>", res.data.data);
                    let dayworkdata = []
                    res.data.data.map((item)=>{
                        dayworkdata.push({
                            date_id: item.date_id,
                            is_closed: item.is_closed,
                            datename_en: item.days_datename_en,
                            datename_th: item.days_datename_th,
                            time_slot: item.time_slot,
                          });
                    })
                    console.log("dayworkdata==========>",dayworkdata)
                    setDayWorkingData(dayworkdata)    
                }else{
                    console.log("=========no data found ==========");
                  

                    let newArr = [];
                    newArr = [
                        {
                          date_id: 1,
                          is_closed: false,
                          datename_en: "Monday",
                          datename_th: "จันทร์",
                          time_slot: [{
                            id: null,
                            date_id: 1,
                            open_time: startTime,
                            close_time: endTime ,
                          }],
                        },
                        {
                          date_id: 2,
                          is_closed: false,
                          datename_en: "TuesDay",
                          datename_th: "อังคาร",
                          time_slot: [{
                            id: null,
                            date_id: 2,
                            open_time: startTime,
                            close_time: endTime ,
                          }],
                        },
                        {
                          date_id: 3,
                          is_closed: false,
                          datename_en: "Wednesday",
                          datename_th: "พุธ",
                          time_slot: [{
                            id: null,
                            date_id: 3,
                            open_time: startTime,
                            close_time: endTime ,
                          }],
                        },
                        {
                          date_id: 4,
                          is_closed: false,
                          datename_en: "Thursday",
                          datename_th: "พฤหัส",
                          time_slot: [{
                            id: null,
                            date_id: 4,
                            open_time: startTime,
                            close_time: endTime ,
                          }],
                        },
                        {
                          date_id: 5,
                          is_closed: false,
                          datename_en: "Friday",
                          datename_th: "ศุกร์",
                          time_slot: [{
                            id: null,
                            date_id: 5,
                            open_time: startTime,
                            close_time: endTime ,
                          }],
                        },
                        {
                          date_id: 6,
                          is_closed: false,
                          datename_en: "Saturday",
                          datename_th: "เสาร์",
                          time_slot: [{
                            id: null,
                            date_id: 6,
                            open_time: startTime,
                            close_time: endTime ,
                          }],
                        },
                        {
                          date_id: 7,
                          is_closed: false,
                          datename_en: "Friday",
                          datename_th: "ศุกร์",
                          time_slot: [{
                            id: null,
                            date_id: 7,
                            open_time: startTime,
                            close_time: endTime ,
                          }],
                        },
                      ];
                      setDayWorkingData(newArr);
                }
            })
            .catch((err) => { });
    };
    const getShopOpenTime = async () => {
        let token = localStorage.getItem("Token");
        axios({
            method: "get",
            url: `${baseURL}/management/branch`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.data.data) {
                    console.log("response=======>", res.data.data.open);
                    setStartTime(res.data.data.open);
                    setEndTime(res.data.data.close);

                }
            })
            .catch((err) => { });
    };
    const getDeliveryTime = async () => {
        let token = localStorage.getItem("Token");
        axios({
            method: "get",
            url: `${baseURL}/management/deliveryslot?page=1&limit=10`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log("==========>",res.data.data)
                
                if (Object.keys(res.data.data).length>0) {
                
                    console.log("responsedeliverytime=======>", res.data.data);
                    console.log("typeresponsedeliverytime=======>", typeof(res.data.data));
                    let temp = []
                    res.data.data.map((item)=>{
                        temp.push({
                            date_id: item.date_id,
                            is_closed: item.is_closed,
                            datename_en: item.days_datename_en,
                            datename_th: item.days_datename_th,
                            time_slot: item.time_slot,
                          });
                    })
                    setDayData(temp)
                    console.log("temp daydata==========>",temp)

                    
                }else{
                    console.log("=========no data found ==========");

                    
          let newArr = [];
          newArr = [
            {
              date_id: 1,
              is_closed: false,
              datename_en: "Monday",
              datename_th: "จันทร์",
              time_slot: [{
                id: null,
                date_id: 1,
                open_time: startTime,
                close_time: endTime ,
              }],
            },
            {
              date_id: 2,
              is_closed: false,
              datename_en: "TuesDay",
              datename_th: "อังคาร",
              time_slot: [{
                id: null,
                date_id: 2,
                open_time: startTime,
                close_time: endTime ,
              }],
            },
            {
              date_id: 3,
              is_closed: false,
              datename_en: "Wednesday",
              datename_th: "พุธ",
              time_slot: [{
                id: null,
                date_id: 3,
                open_time: startTime,
                close_time: endTime ,
              }],
            },
            {
              date_id: 4,
              is_closed: false,
              datename_en: "Thursday",
              datename_th: "พฤหัส",
              time_slot: [{
                id: null,
                date_id: 4,
                open_time: startTime,
                close_time: endTime ,
              }],
            },
            {
              date_id: 5,
              is_closed: false,
              datename_en: "Friday",
              datename_th: "ศุกร์",
              time_slot: [{
                id: null,
                date_id: 5,
                open_time: startTime,
                close_time: endTime ,
              }],
            },
            {
              date_id: 6,
              is_closed: false,
              datename_en: "Saturday",
              datename_th: "เสาร์",
              time_slot: [{
                id: null,
                date_id: 6,
                open_time: startTime,
                close_time: endTime ,
              }],
            },
            {
              date_id: 7,
              is_closed: false,
              datename_en: "Friday",
              datename_th: "ศุกร์",
              time_slot: [{
                id: null,
                date_id: 7,
                open_time: startTime,
                close_time: endTime ,
              }],
            },
          ];
                      setDayData(newArr);
                }
            })
            .catch((err) => { });
    };
   

    return (
      <div>
        {/*---------------------------working hours----------------------------*/}
        <Card>
          <CardHeader
            title={intl.formatMessage({
              id: "113318",
              defaultMessage: "Working Hours",
            })}
          >
            <CardHeaderToolbar>
              <Link to="/EditWorkingHours">
                <button type="button" className="btn btn-primary">
                  {intl.formatMessage({ id: "108014", defaultMessage: "edit" })}
                </button>
              </Link>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="container">
              <div>
                {dayworkingdata.map((day, indexDay) => (
                  <div key={day.date_id}>
                    <div
                      className="form-inline"
                      style={{ marginBottom: "25px" }}
                    >
                      <p
                        style={{
                          marginTop: "15px",
                          marginLeft: "15px",
                          float: "left",
                          width: "25%",
                        }}
                      >
                        {lang == "en" ? (
                          <>{day.datename_en}</>
                        ) : (
                          <>{day.datename_th}</>
                        )}
                        :{" "}
                      </p>

                      {day.is_closed !== 1 ? (
                        <div className="">
                          {day.time_slot.map((time, timeIndex) => (
                            <div
                              className="d-flex flex-wrap"
                              style={{ flex: "1 0 21%" }}
                              key={timeIndex}
                            >
                              <label
                                style={{
                                  marginTop: "15px",
                                  marginLeft: "15px",
                                  float: "left",
                                  width: "50%",
                                }}
                              >
                                {convertTimeFormat(time.open_time)}-
                                {convertTimeFormat(time.close_time)}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <label
                          style={{
                            marginTop: "15px",
                            marginLeft: "15px",
                            float: "left",
                          }}
                        >
                          ( {intl.formatMessage({ id: "109022", defaultMessage: "Closed" })} )
                        </label>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                ></p>
              </div>
            </div>
          </CardBody>
        </Card>
        {/*---------------------------delivery hours----------------------------*/}
        <Card>
          <CardHeader
            title={intl.formatMessage({
              id: "113319",
              defaultMessage: "Delivery Hours",
            })}
          >
            <CardHeaderToolbar>
              <Link to="/EditDeliveryHours">
                <button type="button" className="btn btn-primary">
                  {intl.formatMessage({ id: "108014", defaultMessage: "edit" })}
                </button>
              </Link>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="container">
              <div>
                {daydata.map((day, indexDay) => (
                  <div key={day.date_id}>
                    <div
                      className="form-inline"
                      style={{ marginBottom: "25px" }}
                    >
                      <p
                        style={{
                          marginTop: "15px",
                          marginLeft: "15px",
                          float: "left",
                          width: "25%",
                        }}
                      >
                        {lang == "en" ? (
                          <>{day.datename_en}</>
                        ) : (
                          <>{day.datename_th}</>
                        )}
                        :{" "}
                      </p>

                      {day.is_closed !== 1 ? (
                        <div className="">
                          {day.time_slot.map((time, timeIndex) => (
                            <div
                              className="d-flex flex-wrap"
                              style={{ flex: "1 0 21%" }}
                              key={timeIndex}
                            >
                              <label
                                style={{
                                  marginTop: "15px",
                                  marginLeft: "15px",
                                  float: "left",
                                  width: "50%",
                                }}
                              >
                                {convertTimeFormat(time.open_time)}-
                                {convertTimeFormat(time.close_time)}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <label
                          style={{
                            marginTop: "15px",
                            marginLeft: "15px",
                            float: "left",
                          }}
                        >
                          ( {intl.formatMessage({ id: "109022", defaultMessage: "Closed" })} )
                        </label>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                ></p>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
};
