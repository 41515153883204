import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from '../service/role';

export const DeliveryNote = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const allData = [];
    const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalSize, setTotalSize] = useState(100);
    suhbeader.setTitle(intl.formatMessage({ id: "113168" ,defaultMessage:"Delivery Note"}));
    const { authToken } = useSelector((state) => state.auth);
    const [campaigns_id_headder, setCampaigns_id_headder] = useState();
    const [roleList, setRoleList] = useState([]);

    const history = useHistory();
    useEffect(() => {
        localStorage.removeItem('edit')        
        checkAuth();
        
    }, []);

    const checkAuth =async()=> {
        const data = await roleCheck('DELIVERY_NOTE')
        setRoleList(data)
        console.log(data)
        if (!data.includes('VIEW')) {
          history.push('/auth/login')
        }
        let value = 1
        if(localStorage.getItem("pagePrinterZone") != null){
            value = localStorage.getItem("pagePrinterZone")
        }
        let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
        setCampaigns_id_headder(campaigns_id_headders);
        getList(value, 10, search, campaigns_id_headders);

      }

    const getList = async (page, limit, search, campaigns_id_headders) => {
        setPage(page);
        axios({
            method: "get",
            url: `${baseURL}/deliveryNote/bo?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
                }`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                console.log(res.data.data)
                res.data.data.map((data, index) => {
                    data = {
                        id: data.id,
                        zone_name: data.zone_name,
                        zone_name_en: data.zone_name_en,
                        shop_id: data.shop_id,
                        branch_id: data.branch_id,
                        enabled: data.enabled ? true : false,
                        description: data.description,
                        status: data.status,
                        created_at: data.created_at,
                        name: data.posBranch ? data.posBranch.name : 'NONE',
                        // created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
                    };
                    allData.push(data);
                });
            }

            var totalpage = Math.ceil(res.data.total / res.data.limit);
            setTotalPage(totalpage);
            setTotalSize(res.data.total);
            setLimit(res.data.limit);
            setSettingPrinterZoneData(allData);
        });
    };

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "status",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "description",
            text: "description",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
       
        {
            dataField: "name",
            text:intl.formatMessage({ id: "113170" ,defaultMessage:"Branch id"}),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "created_at",
            text: 'created at',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        // {
        //     dataField: "created_at",
        //     text: "Create Date",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
        {
            text: intl.formatMessage({ id: "107008",defaultMessage:"Action" }),
            dataField: "id",
            formatter: (id, row, rowIndex) => {
                return (
                    <div>
                        {roleList.includes('DELETE') && ( row.status == 'PENDING') && <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => deleteItem({ row, rowIndex })}
                        >
                            {intl.formatMessage({ id: "107011",defaultMessage:"delete" })}
                        </button>}
                    </div>
                );
            },
        },
    ];
    const editItem = (data) => {
        history.push("/EditDeliveryNote", data);
    };

    const deleteItem = (data) => {
        let token = localStorage.getItem("Token");
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this template !",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                axios.delete(`${baseURL}/deliveryNote/${data.row.id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }).then((res) => {
                    Swal.fire("Deleted!", "Your template has been deleted.", "success");
                    getList(1, limit, search, campaigns_id_headder);
                }).catch((err) => {
                    Swal.fire(
                        "Deleted!",
                        "Your template has been fail to deleted.",
                        "error"
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your template is safe :)", "error");
            }
        });
    };
    const [search, setSearch] = useState();
    const handleSearch = () => {
        setPage(1);
        getList(1, limit, search, campaigns_id_headder);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        localStorage.setItem("pagePrinterZone", value)
        getList(value, limit, search, campaigns_id_headder);
    };

    const handlePageSizeChange = (event) => {
        setLimit(event.target.value);
        getList(1, event.target.value, search, campaigns_id_headder);
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113168",defaultMessage:"Delivery Note" })}>
                <CardHeaderToolbar>
                    {roleList.includes('CREATE') && <Link to="/CreateDeliveryNote">
                        <button type="button" className="btn btn-primary">
                            {intl.formatMessage({ id: "107009",defaultMessage:"Create" })}
                        </button>
                    </Link>}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="row form-inline mb-2">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "107002" ,defaultMessage:"Text Search"})}
                                style={{ width: "100%" }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                        <div className="form-group">
                            <button
                                type="button"
                                id="button"
                                className="btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={() => handleSearch()}
                            >
                                {intl.formatMessage({ id: "107003",defaultMessage:"search" })}
              </button>
                        </div>
                    </div>
                </div>

                <BootstrapTable
                    keyField="id"
                    data={settingPrinterZone === undefined ? [] : settingPrinterZone}
                    columns={columns}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                >
                    <PleaseWaitMessage entities={settingPrinterZone} />
                    <NoRecordsFoundMessage entities={settingPrinterZone} />
                </BootstrapTable>
                <div className=" col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-3">
                                {intl.formatMessage({ id: "107032",defaultMessage:"Item per page" })}
                                <select onChange={handlePageSizeChange} value={limit}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Pagination2
                                className="mt-3 "
                                count={totalPage}
                                page={localStorage.getItem("pagePrinterZone") != null ? Number(localStorage.getItem("pagePrinterZone")) : page}
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
