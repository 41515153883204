import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "./component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { Branch } from './Branch';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useIntl } from "react-intl";
import { Payment } from './Payment.js';
import { Datetime } from './Datetime.js';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    root_tab: {
        flexGrow: 1,
    },
}));



export const Shop = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    const classes = useStyles();
    suhbeader.setTitle(intl.formatMessage({ id: "102007" ,defaultMessage:"Shop/Branch"}));
    const history = useHistory();
    const [name, setname] = useState();
    const [name_en, setname_en] = useState();
    const [description, setdescription] = useState();
    const [description_en, setdescription_en] = useState();
    const [address, setaddress] = useState();
    const [address_en, setaddress_en] = useState();
    const AlertError = (message) => {
        Swal.fire("Shop!", message, "error");
    };

    const handleSend = async () => {
        if (name === undefined || name === "") {
            return AlertError("Please insert printer zone name th !!");
        }
        if (name_en === undefined || name_en === "") {
            return AlertError("Please insert Restaurant branch !!");
        }
        if (description === undefined || description === "") {
            return AlertError("Please insert printer zone description th !!");
        }
        if (description_en === undefined || description_en === "") {
            return AlertError("Please insert Restaurant branch !!");
        }
        if (address === undefined || address === "") {
            return AlertError("Please insert printer zone address th !!");
        }
        if (address_en === undefined || address_en === "") {
            return AlertError("Please insert Restaurant branch !!");
        }

        var events = {
            name: name,
            name_en: name_en,
            description: description,
            description_en: description_en,
            address: address,
            address_en: address_en,
        };
        let apiUrls = `${baseURL}/management/shop`;
        axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            data: events,
        })
            .then(async (res) => {
                Swal.fire("Save !", "Save Shop success", "success");
                getShop();
            })
            .catch((err) => {
                Swal.fire(
                    "Save !",
                    "Your can not Shop. !! " + err,
                    "error"
                );
            });
    };


    useEffect(() => {
        getShop();
    }, []);

    const getShop = async () => {
        axios({
            method: "get",
            url: `${baseURL}/management/shop`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                setname(res.data.data.name);
                setname_en(res.data.data.name_en);
                setdescription(res.data.data.description);
                setdescription_en(res.data.data.description_en);
                setaddress(res.data.data.address);
                setaddress_en(res.data.data.address_en);
            }
        });
    };


    const [value, setValue] = useState(() => {
        // ดึงค่าเริ่มต้นจาก localStorage ถ้ามีค่าเก็บไว้ ถ้าไม่มีให้ตั้งเป็น 0
        const storedValue = localStorage.getItem('storedValue');
        return storedValue ? Number(storedValue) : 0;
      });
      useEffect(() => {
        const storedValue = localStorage.getItem('storedValue');
        if (storedValue) {
          setValue(Number(storedValue));
        }
      }, []);


      const handleChange = (event, newValue) => {
        // เก็บค่าใน state
        setValue(newValue);
      
        // เก็บค่าใน localStorage
        localStorage.setItem('storedValue', newValue);
      };

    return (
        <Card>
            <div className={classes.root_tab}>
                <AppBar position="static" style={{ background: '#1E1E2D', color: "#fff" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label={intl.formatMessage({ id: "108001",defaultMessage:"store" })} {...a11yProps(0)} />
                        <Tab label={intl.formatMessage({ id: "108002",defaultMessage:"branch" })} {...a11yProps(1)} />    
                        <Tab label={intl.formatMessage({ id: "113306",defaultMessage:"payment" })} {...a11yProps(2)} />
                        <Tab label={intl.formatMessage({ id: "113317",defaultMessage:"payment" })} {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Card>
                        <CardHeader title={intl.formatMessage({ id: "108001" ,defaultMessage:"store"})}>
                            <CardHeaderToolbar>
                                <Link to="/EditShop">
                                    <button type="button" className="btn btn-primary">
                                    {intl.formatMessage({ id: "108003",defaultMessage:"Edit" })}
            </button>
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <div className="container">
                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                       {intl.formatMessage({ id: "108005" ,defaultMessage:"Thai name:"})}:{" "}
                                    </p>
                                    <span
                                    >{name}
                                    </span>
                                </div>

                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "108006" ,defaultMessage:"English name:"})}:{" "}
                                    </p>
                                    <span
                                    >{name_en}
                                    </span>
                                </div>

                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                       {intl.formatMessage({ id: "108007" ,defaultMessage:"Details:"})}:{" "}
                                    </p>
                                    <span
                                    >{description}
                                    </span>
                                </div>

                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "108008",defaultMessage:"English details:"})}:{" "}
                                    </p>
                                    <span
                                    >{description_en}
                                    </span>
                                </div>

                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "108009",defaultMessage:"Address:" })}:{" "}
                                    </p>
                                    <span
                                    >{address}
                                    </span>
                                </div>

                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "108010" ,defaultMessage:"English address:"})}:{" "}
                                    </p>
                                    <span
                                    >{address_en}
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Branch />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Payment />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Datetime />
                </TabPanel>
            </div>


        </Card>
    );
};
