import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Modal, Button } from "react-bootstrap"
import { useIntl } from "react-intl";
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import AccessTimeOutlined from '@material-ui/icons/AccessTimeOutlined';
import { temp_store } from "../../../modules/function/temp_store"
import sha256 from "sha256";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const EditUserManagement = (props) => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: "113366", defaultMessage: "User Management" }));

    const classes = useStyles();
    const history = useHistory();
    const buttonDefault = { border: "2px dashed", borderRadius: "5px", borderColor: "#F4F4F4", height: "80%", width: "100%", justifyContent: "flex-start" }
    const buttonOnclick = { border: "2px solid", borderRadius: "10px", borderColor: "#8FC5FF", height: "80%", width: "100%", justifyContent: "flex-start" }
    const [zone_id, setZone_id] = useState();
    const [zone, setZone] = useState([]);
    const [show, setShow] = useState(false);
    const [role_type_name, setRole_type_name] = useState();
    const [role_type, setRole_type] = useState();
    const [zoneCreate, setZoneCreate] = useState();
    const [first_name_th, setfirstname_th] = useState();
    const [first_name_en, setfirstname_en] = useState();
    const [last_name_th, setlastname_th] = useState();
    const [last_name_en, setlastname_en] = useState();
    const [mobile_no, setMobile_no] = useState();
    const [email, setEmail] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [employee_type, setEmployee_type] = useState();
    const [buttonPartTimeStyle, setButtonPartTimeStyle] = useState(buttonDefault);
    const [buttonFullTimeStyle, setButtonFullTimeStyle] = useState(buttonDefault);
    const [id, setId] = useState();
    const [openCheck, setOpenCheck] = useState(false);



    const allDataGroup = [];



    const AlertError = (message) => {
        Swal.fire(intl.formatMessage({ id: "109006", defaultMessage: "Edit Account management!" }), message, "error");
    };

    useEffect(() => {
        console.log("prop>>>>>>",temp_store(props).location.state.row)
        const employee = temp_store(props).location.state.row.employee_type
        setId(temp_store(props).location.state.row.id);
        setfirstname_th(temp_store(props).location.state.row.firstname);
        setfirstname_en(temp_store(props).location.state.row.firstnameEn);
        setlastname_th(temp_store(props).location.state.row.lastname);
        setlastname_en(temp_store(props).location.state.row.lastnameEn);
        setUsername(temp_store(props).location.state.row.username);
        setEmail(temp_store(props).location.state.row.email);
        setMobile_no(temp_store(props).location.state.row.mobile);
        setEmployee_type(temp_store(props).location.state.row.employee_type);
        var zone_type = temp_store(props).location.state.row.zone_id;
        setZone_id( zone_type == null ? "All" : zone_type)
        setRole_type(temp_store(props).location.state.row.role_type)
        if (employee === "PART_TIME") {
            handleSetParttime()
        } else if (employee === "FULL_TIME") {
            handleSetFulltime()
        }
        getZone();
        getRole();
    }, []);

    const getZone = async () => {
        let apiUrls = `${baseURL}/management/tablezone?page=1&limit=10000`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                setZone(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getRole = async () => {
        let apiUrls = `${baseURL}/role/role`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                setRole_type_name(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSetFulltime = () => {
        setButtonFullTimeStyle(buttonOnclick)
        setButtonPartTimeStyle(buttonDefault)
        setEmployee_type(2)


    }
    const handleSetParttime = () => {
        setButtonPartTimeStyle(buttonOnclick)
        setButtonFullTimeStyle(buttonDefault)
        setEmployee_type(1)

    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePass = (data) => {
        handleClose()
    }
    const setroletype = (e) => {
        setRole_type(e.target.value)
    }
    const onSubmit = async () => {
        let dataPass = {}

        if (role_type === undefined || role_type === "") {
            return AlertError("please select role type.");
        }
        if (role_type !== '3' && role_type !== '2') {
            if (employee_type === undefined || employee_type === "") {
                return AlertError("please select employee type.");
            }
        }
        if (zone_id === undefined || zone_id === "") {
            return AlertError("please select zone.");
        }
        if (username === undefined || username === "") {
            return AlertError("please insert username.");
        }
        if (mobile_no === undefined || mobile_no === "") {
            return AlertError("please insert mobile no.");
        }
        if (email === undefined || email === "") {
            return AlertError("please insert email");
        }
        if (first_name_th === undefined || first_name_th === "") {
            return AlertError("please insert thai first name");
        }
        if (last_name_th === undefined || last_name_th === "") {
            return AlertError("please insert thai last name");
        }
        if (first_name_en === undefined || first_name_en === "") {
            return AlertError("please insert english first name");
        }
        if (last_name_en === undefined || last_name_en === "") {
            return AlertError("please insert english last name");
        }
        if (openCheck && password?.length < 6 || password?.length > 12) {
            return AlertError(intl.formatMessage({ id: "113075", defaultMessage: "password is required at least 6 characters and most 12 characters" }));
        }
        if (openCheck && password != confirmPassword) {
            return AlertError(intl.formatMessage({ id: "113094", defaultMessage: "password and confirm password is not match" }));
        }
        if (openCheck) {
            dataPass = { password: sha256(password) }
        }

        if(zone_id === "All"){
            var data = {
              firstname: first_name_th,
              firstname_en: first_name_en,
              lastname: last_name_th,
              lastname_en: last_name_en,
              mobile: mobile_no,
              username: username,
              password: password,
              email: email,
              zone_id: null,
              is_all_zone: true,
              role_type: role_type,
              employee_type: employee_type,
              ...dataPass
            };
  
          }else{
          var data = {
            firstname: first_name_th,
            firstname_en: first_name_en,
            lastname: last_name_th,
            lastname_en: last_name_en,
            mobile: mobile_no,
            username: username,
            password: password,
            email: email,
            zone_id: zone_id,
            is_all_zone: false,
            role_type: role_type,
            employee_type: employee_type,
            ...dataPass
          };
        }

        let apiUrls = `${baseURL}/management/employee/${id}`;
        let token = localStorage.getItem("Token");
        axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then((res) => {
                Swal.fire({
                    title: intl.formatMessage({ id: "113101", defaultMessage: "Save !" }),
                    text: intl.formatMessage({ id: "113102", defaultMessage: "Update user success" }),
                    icon: 'success',
                    confirmButtonText: intl.formatMessage({ id: "113116", defaultMessage: "ok" }),

                });
                history.goBack();
            })
            .catch((err) => {
                console.log(
                    err.toString().substring(39),
                    err.toString().substring(39) == "422"
                );
                if (err.toString().substring(39) == "422") {
                    Swal.fire({
                        title: intl.formatMessage({
                            id: "113101",
                            defaultMessage: "Save !",
                        }),
                        text: intl.formatMessage({
                            id: "113117",
                            defaultMessage: "Your can not update user. !! ",
                        }),
                        icon: "error",
                        confirmButtonText: intl.formatMessage({
                            id: "113116",
                            defaultMessage: "ok",
                        }),
                    });
                } else {
                    Swal.fire({
                        title: intl.formatMessage({
                            id: "113101",
                            defaultMessage: "Save !",
                        }),
                        text:
                            intl.formatMessage({
                                id: "113118",
                                defaultMessage: "Your can not update user. !! ",
                            }) + err,
                        icon: "error",
                        confirmButtonText: intl.formatMessage({
                            id: "113116",
                            defaultMessage: "ok",
                        }),
                    });
                }
            });
    };
    const onCancel = () => {
        history.goBack();

    }

    return (
        <>
            <Card>
                <CardHeader title={intl.formatMessage({ id: "109006", defaultMessage: "Edit Account Management" })}></CardHeader>
                <CardBody>

                    <div className="container">
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113382",
                                defaultMessage: "Edit Account",
                            })}

                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-4">
                                <select
                                    as="select"
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    value={role_type}
                                    onChange={setroletype}
                                >
                                    <option value="">{intl.formatMessage({ id: "113372", defaultMessage: "Select Role" })}</option>
                                    {role_type_name &&
                                        role_type_name.map((result, index) => {
                                            return (
                                                <option key={index} value={result.id}>
                                                    {result.role}
                                                </option>

                                            );
                                        })}
                                </select>
                            </div>
                        </div>

                        <div className={`template name mt-3 mb-3 align-items-center${role_type !== '3' && role_type !== '2' ? ' d-inline' : ''}`}>
                            <div className="h6" style={{ paddingTop: "20px" }}>

                                {intl.formatMessage({
                                    id: "113368",
                                    defaultMessage: "Employee Type",
                                })}

                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-4">
                                    <button
                                        className="btn-custom-2 mr-3"
                                        style={buttonPartTimeStyle}
                                        onClick={handleSetParttime}

                                    >
                                        <span className="mb-5 mt-5"
                                            style={{
                                                fontSize: "1em",
                                                color: "#000000",
                                            }}
                                        >
                                            <AccessTimeOutlined style={{ paddingBottom: "2px", color: "#858585" }} /> Part Time

                                        </span>
                                    </button>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <button
                                        className="btn-custom-2 mr-3 ml-3"
                                        style={buttonFullTimeStyle}
                                        onClick={handleSetFulltime}

                                    >
                                        <span className="mb-5 mt-5"
                                            style={{
                                                fontSize: "1em",
                                                color: "#000000",

                                            }}

                                        >

                                            <AccountBoxSharpIcon style={{ paddingBottom: "2px", color: "#858585" }} />Full Time

                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113369",
                                defaultMessage: "Zone / Branch",
                            })}

                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-4">
                                <select className="form-select"
                                    as="select"
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",

                                    }}
                                    value={zone_id}
                                    onChange={(e) => setZone_id(e.target.value)}
                                >
                                    <option value="">{intl.formatMessage({ id: "113373", defaultMessage: "Select Zone" })}</option>
                                    <option value="All">{intl.formatMessage({ id: "113417", defaultMessage: "All" })}</option>
                                    {zone &&
                                        zone.map((result, index) => {
                                            return (
                                                <option key={index} value={result.id}>
                                                    {result.zone_name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113370",
                                defaultMessage: "Info",
                            })}

                        </div>

                        <form className="form-inline">
                            <input className="input-col-4"

                                name="first_name_th"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={first_name_th}
                                placeholder="Thai Name"
                                onChange={(first_name_th) => setfirstname_th(first_name_th.target.value)}

                            />
                            <input className="input-col-4"
                                name="last_name_th"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={last_name_th}
                                placeholder="Thai Surname"
                                onChange={(last_name_th) => setlastname_th(last_name_th.target.value)}


                            />
                            <input className="input-col-4"

                                name="first_name_en"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={first_name_en}
                                placeholder="English Name"
                                onChange={(first_name_en) => setfirstname_en(first_name_en.target.value)}

                            />
                            <input className="input-col-4"
                                name="last_name_en"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={last_name_en}
                                placeholder="English Surname"
                                onChange={(last_name_en) => setlastname_en(last_name_en.target.value)}


                            />
                            <input className="input-col-4"
                                name="mobile_no"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                placeholder="Mobile No."
                                maxLength={10}
                                value={mobile_no}
                                onChange={(mobile_no) => setMobile_no(mobile_no.target.value)}


                            />
                            <input className="input-col-4"
                                name="email"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={email}
                                type="email"
                                placeholder="Email"
                                onChange={(email) => setEmail(email.target.value)}


                            />
                        </form>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113371",
                                defaultMessage: "Username / Password",
                            })}

                        </div>
                        <form className="form-inline">
                            <input className="input-col-4"

                                name="username"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={username}
                                placeholder="Username"
                                onChange={(username) => setUsername(username.target.value)}
                            />

                        </form>

                        <div className="form-inline mb-5">
                            <a
                                style={{
                                    textDecoration: "#007BFF underline",
                                    color: "#007BFF",
                                }}
                                data-toggle="modal"
                                data-target="#modal-EditPassword"
                                onClick={() => setOpenCheck(!openCheck)}
                            >
                                {openCheck ? intl.formatMessage({ id: "113093", defaultMessage: "cancel change password" })
                                    : intl.formatMessage({ id: "113068", defaultMessage: "change password" })}
                            </a>
                        </div>

                        {openCheck ? (
                            <div>
                                <div className="form-inline">
                                    <p
                                        style={{
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "109011", defaultMessage: "password" })} :{" "}
                                    </p>
                                    <input className="input-col-4"
                                        type="text"
                                        name="password"
                                        maxLength={12}
                                        placeholder={intl.formatMessage({ id: "109011", defaultMessage: "password" })}
                                        style={{
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        onChange={(password) => setPassword(password.target.value)}
                                    />
                                </div>
                                <div className="form-inline">
                                    <p
                                        style={{
                                            float: "left",
                                            width: "20%",
                                        }}
                                    ></p>
                                </div>
                                <div className="form-inline">
                                    <p
                                        style={{
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "113067", defaultMessage: "Confirm Password" })} :{" "}
                                    </p>
                                    <input className="input-col-4"
                                        type="text"
                                        name="confirmPassword"
                                        maxLength={12}
                                        placeholder={intl.formatMessage({ id: "113067", defaultMessage: "Confirm Password" })}
                                        style={{
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        onChange={(confirmPassword) => setConfirmPassword(confirmPassword.target.value)}
                                    />
                                </div>
                                <div className="form-inline">
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    ></p>

                                </div>
                            </div>
                        ) : ('')}

                    </div>
                    <div
                        className="mt-5 form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>

                        <Link to="/UserManagement">
                            <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => onCancel()}>
                                {intl.formatMessage({ id: "113030", defaultMessage: "Cancel" })}
                            </button>
                        </Link>
                        <button className="btn btn-primary" onClick={() => onSubmit()}>
                            {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
                        </button>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};
