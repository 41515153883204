import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { Menu } from "./pages/Menu";
import { CreateMenu } from "./pages/component/create/CreateMenu";
import { EditMenu } from "./pages/component/edit/EditMenu";

import { MenuNew } from "./pages/MenuNew";
import { CreateMenuNew } from "./pages/component/create/CreateMenuNew";
import { EditMenuNew } from "./pages/component/edit/EditMenuNew";

import { TableZone } from "./pages/TableZone";
import { CreateTable } from "./pages/component/create/CreateTable";
import { EditTable } from "./pages/component/edit/EditTable";
import { CreateZone } from "./pages/component/create/CreateZone";
import { EditZone } from "./pages/component/edit/EditZone";
import { CreateDynamicQR } from "./pages/component/create/CreateDynamicQR";

import { MenuBuffet } from "./pages/MenuBuffet";
import { CreateMenuBuffet } from "./pages/component/create/CreateMenuBuffet";
import { EditMenuBuffet } from "./pages/component/edit/EditMenuBuffet";

import { MenuGroup } from "./pages/MenuGroup";
import { CreateMenuGroup } from "./pages/component/create/CreateMenuGroup";
import { EditMenuGroup } from "./pages/component/edit/EditMenuGroup";

import { MenuOption } from "./pages/MenuOption";
import { CreateMenuOption } from "./pages/component/create/CreateMenuOption";
import { EditMenuOption } from "./pages/component/edit/EditMenuOption";

import { MenuOptionGroup } from "./pages/MenuOptionGroup";
import { CreateMenuOptionGroup } from "./pages/component/create/CreateMenuOptionGroup";
import { EditMenuOptionGroup } from "./pages/component/edit/EditMenuOptionGroup";

import { MenuOptionTemplate } from "./pages/MenuOptionTemplate";
import { CreateMenuOptionTemplate } from "./pages/component/create/CreateMenuOptionTemplate";
import { EditMenuOptionTemplate } from "./pages/component/edit/EditMenuOptionTemplate";

import { Promotion } from "./pages/Promotion";
import { CreatePromotion } from "./pages/component/create/CreatePromotion";

import { SettingPrinterZone } from "./pages/SettingPrinterZone";
import { CreateSettingPrinterZone } from "./pages/component/create/CreateSettingPrinterZone";
import { EditSettingPrinterZone } from "./pages/component/edit/EditSettingPrinterZone";

import { Shop } from "./pages/Shop";
import { CreateShop } from "./pages/component/create/CreateShop";
import { EditShop } from "./pages/component/edit/EditShop";

import { Branch } from "./pages/Branch";
import { EditBranch } from "./pages/component/edit/EditBranch";

import { User } from "./pages/User";
import { CreateUser } from "./pages/component/create/CreateUser";
import { EditUser } from "./pages/component/edit/EditUser";
import { Inventory } from "./pages/Inventory";

import { MenuBuffetAdmin} from "./pages/MenuBuffetAdmin"
import { MenuBuffetAdminList} from "./pages/MenuBuffetAdminList"
import { MenuNewAdmin} from "./pages/MenuNewAdmin"
import { MenuNewAdminList} from "./pages/MenuNewAdminList"

import { CreateMenuBuffetAdmin } from "./pages/component/create/CreateMenuBuffetAdmin";
import { EditMenuBuffetAdmin } from "./pages/component/edit/EditMenuBuffetAdmin";
import { CreateMenuNewAdmin } from "./pages/component/create/CreateMenuNewAdmin";
import { EditMenuNewAdmin } from "./pages/component/edit/EditMenuNewAdmin";
import { DeliveryNote } from "./pages/DeliveryNote";
import { CreateDeliveryNote } from "./pages/component/create/CreateDeliveryNote";
import { EditDeliveryNote } from "./pages/component/edit/EditDeliveryNote";

import { Role } from "./pages/Role";
import { ReportAccess } from "./pages/ReportAccess";
import { ShopGroupManagement } from "./pages/ShopGroupManagement";

import { CreateReportAccess } from "./pages/component/create/CreateReportAccess"
import { CreateRole } from "./pages/component/create/CreateRole"
import { CreateShopGroup } from "./pages/component/create/CreateShopGroup"
import { EditRole } from "./pages/component/edit/EditRole"
import { EditShopGroup } from "./pages/component/edit/EditShopGroup"
import { CreateInventory } from "./pages/component/create/CreateInventory"
import { MenuGroupAdmin } from "./pages/MenuGroupAdmin"
import { CreateMenuGroupAdmin } from "./pages/component/create/CreateMenuGroupAdmin"
import { EditMenuGroupAdmin } from "./pages/component/edit/EditMenuGroupAdmin"
import { MenuGroupAdminList } from "./pages/MenuGroupAdminList"
import { EditInventory } from "./pages/component/edit/EditInventory"
import { Material } from "./pages/Material"
import { Unit } from "./pages/Unit"
import { Stock } from "./pages/Stock"
import { CreateMaterial } from "./pages/component/create/CreateMaterial"
import { EditMaterial } from "./pages/component/edit/EditMaterial"
import { CreateUnit } from "./pages/component/create/CreateUnit"
import { EditUnit } from "./pages/component/edit/EditUnit"
import { CreateStock } from "./pages/component/create/CreateStock"
import { EditStock } from "./pages/component/edit/EditStock"
import { StockList } from "./pages/StockList"
import { UnitConvert } from "./pages/UnitConvert"
import { CreateUnitConvert } from "./pages/component/create/CreateUnitConvert"
import { EditUnitConvert } from "./pages/component/edit/EditUnitConvert"

import { SalesChannel } from "./pages/SalesChannel"
import { CreateSalesChannel } from "./pages/component/create/CreateSalesChannel"
import { EditSalesChannel } from "./pages/component/edit/EditSalesChannel"
import { EditPayment } from "./pages/component/edit/EditPayment"
import { EditWorkingHours } from "./pages/component/edit/EditWorkingHours"
import { EditDeliveryHours } from "./pages/component/edit/EditDeliveryHours"

import { CreateUserManagement } from "./pages/component/create/CreateUserManagement"
import { EditUserManagement } from "./pages/component/edit/EditUserManagement"

import { PurchaseOrder } from "./pages/PurchaseOrder";
import { PurchaseRequest } from "./pages/PurchaseRequest";
import { CreatePurchaseRequest } from "./pages/component/create/CreatePurchaseRequest";
import { EditPurchaseRequest } from "./pages/component/edit/EditPurchaseRequest";
import { ViewPurchaseRequest } from "./pages/component/view/ViewPurchaseRequest";
import { EditPurchaseOrder } from "./pages/component/edit/EditPurchaseOrder";
import { ViewPurchaseOrder } from "./pages/component/view/ViewPurchaseOrder";

import { WasteReport } from "./pages/WasteReport";
import { PurchasePlan } from "./pages/PurchasePlan";
import { MaterialTransfer } from "./pages/MaterialTransfer";
import { Transaction } from "./pages/Transaction" 

import { Authorization } from "./pages/Authorization"
import { CreateAccountRole } from "./pages/component/create/CreateAccountRole"
import { ViewShopGroup } from "./pages/component/view/ViewShopGroup";
import { ShopGroupRelation } from "./pages/ShopGroupRelation";
import { EditShopGroupRelation } from "./pages/component/edit/EditShopGroupRelation";
import { CreateShopGroupRelation } from "./pages/component/create/CreateShopGroupRelation";

import { ViewPromotion } from "./pages/component/view/ViewPromotion";


import { reportLayout } from "./pages/Report";
import { CreatePurchasePlan } from "./pages/component/create/CreatePurchasePlan";
import { EditPurchasePlan } from "./pages/component/edit/EditPurchasePlan";
import PageNew from "./pages/component/pdf/NewPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/Dashboard" component={DashboardPage} />

        {/* Add new route here */}
        <ContentRoute path="/NewPage" component={PageNew} />

        <ContentRoute path="/MenuGroup" component={MenuGroup} />
        <ContentRoute path="/CreateMenuGroup" component={CreateMenuGroup} />
        <ContentRoute path="/EditMenuGroup" component={EditMenuGroup} />

        <ContentRoute path="/Menu" component={Menu} />
        <ContentRoute path="/CreateMenu" component={CreateMenu} />
        <ContentRoute path="/EditMenu" component={EditMenu} />
        
        <ContentRoute path="/MenuNew" component={MenuNew} />
        <ContentRoute path="/CreateMenuNew" component={CreateMenuNew} />
        <ContentRoute path="/EditMenuNew" component={EditMenuNew} />
        
        <ContentRoute path="/TableZone" component={TableZone} />
        <ContentRoute path="/CreateTable" component={CreateTable} />
        <ContentRoute path="/EditTable" component={EditTable} />
        <ContentRoute path="/CreateZone" component={CreateZone} />
        <ContentRoute path="/EditZone" component={EditZone} />
        <ContentRoute path="/CreateDynamicQR" component={CreateDynamicQR} />
        
        <ContentRoute path="/MenuBuffet" component={MenuBuffet} />
        <ContentRoute path="/CreateMenuBuffet" component={CreateMenuBuffet} />
        <ContentRoute path="/EditMenuBuffet" component={EditMenuBuffet} />

        <ContentRoute path="/MenuOption" component={MenuOption} />
        <ContentRoute path="/CreateMenuOption" component={CreateMenuOption} />
        <ContentRoute path="/EditMenuOption" component={EditMenuOption} />

        <ContentRoute path="/MenuOptionGroup" component={MenuOptionGroup} />
        <ContentRoute path="/CreateMenuOptionGroup" component={CreateMenuOptionGroup} />
        <ContentRoute path="/EditMenuOptionGroup" component={EditMenuOptionGroup} />

        <ContentRoute path="/MenuOptionTemplate" component={MenuOptionTemplate} />
        <ContentRoute path="/CreateMenuOptionTemplate" component={CreateMenuOptionTemplate} />
        <ContentRoute path="/EditMenuOptionTemplate" component={EditMenuOptionTemplate} />
       
        <ContentRoute path="/SettingPrinterZone" component={SettingPrinterZone} />
        <ContentRoute path="/CreateSettingPrinterZone" component={CreateSettingPrinterZone} />
        <ContentRoute path="/EditSettingPrinterZone" component={EditSettingPrinterZone} />
       
        <ContentRoute path="/Shop" component={Shop} />
        <ContentRoute path="/CreateShop" component={CreateShop} />
        <ContentRoute path="/EditShop" component={EditShop} />

        <ContentRoute path="/Branch" component={Branch} />
        <ContentRoute path="/EditBranch" component={EditBranch} />
       
        <ContentRoute path="/User" component={User} />
        <ContentRoute path="/CreateUser" component={CreateUserManagement} />
        <ContentRoute path="/EditUser" component={EditUserManagement} />

        <ContentRoute path="/Promotion" component={Promotion} />
        <ContentRoute path="/CreatePromotion" component={CreatePromotion} />
        <ContentRoute path="/Report" component={reportLayout} />
        <ContentRoute path="/Inventory" component={Inventory} />

        <ContentRoute path="/MenuBuffetAdmin" component={MenuBuffetAdmin} />
        <ContentRoute path="/MenuBuffetAdminList" component={MenuBuffetAdminList} />
        <ContentRoute path="/MenuNewAdmin" component={MenuNewAdmin} />
        <ContentRoute path="/MenuNewAdminList" component={MenuNewAdminList} />
        <ContentRoute path="/CreateMenuBuffetAdmin" component={CreateMenuBuffetAdmin} />
        <ContentRoute path="/EditMenuBuffetAdmin" component={EditMenuBuffetAdmin} />
        <ContentRoute path="/CreateMenuNewAdmin" component={CreateMenuNewAdmin} />
        <ContentRoute path="/EditMenuNewAdmin" component={EditMenuNewAdmin} />

        <ContentRoute path="/DeliveryNote" component={DeliveryNote} />
        <ContentRoute path="/CreateDeliveryNote" component={CreateDeliveryNote} />
        <ContentRoute path="/EditDeliveryNote" component={EditDeliveryNote} />

        <ContentRoute path="/Role" component={Role} />
        <ContentRoute path="/ReportAccess" component={ReportAccess} />
        <ContentRoute path="/ShopGroupManagement" component={ShopGroupManagement} />
        <ContentRoute path="/ShopGroupRelation" component={ShopGroupRelation} />
        
        <ContentRoute path="/CreateReportAccess" component={CreateReportAccess} />
        <ContentRoute path="/CreateRole" component={CreateRole} />
        <ContentRoute path="/CreateShopGroup" component={CreateShopGroup} />
        <ContentRoute path="/EditRole" component={EditRole} />
        <ContentRoute path="/EditShopGroup" component={EditShopGroup} />
        <ContentRoute path="/CreateInventory" component={CreateInventory} />
        <ContentRoute path="/MenuGroupAdmin" component={MenuGroupAdmin} />
        <ContentRoute path="/CreateMenuGroupAdmin" component={CreateMenuGroupAdmin} />
        <ContentRoute path="/EditMenuGroupAdmin" component={EditMenuGroupAdmin} />
        <ContentRoute path="/MenuGroupAdminList" component={MenuGroupAdminList} />
        <ContentRoute path="/EditInventory" component={EditInventory} />
        <ContentRoute path="/Material" component={Material} />
        <ContentRoute path="/Unit" component={Unit} />
        <ContentRoute path="/CreateMaterial" component={CreateMaterial} />
        <ContentRoute path="/EditMaterial" component={EditMaterial} />
        <ContentRoute path="/CreateUnit" component={CreateUnit} />
        <ContentRoute path="/EditUnit" component={EditUnit} />
        <ContentRoute path="/Stock" component={Stock} />
        <ContentRoute path="/CreateStock" component={CreateStock} />
        <ContentRoute path="/EditStock" component={EditStock} />
        <ContentRoute path="/StockList" component={StockList} />
        <ContentRoute path="/UnitConvert" component={UnitConvert} />
        <ContentRoute path="/CreateUnitConvert" component={CreateUnitConvert} />
        <ContentRoute path="/EditUnitConvert" component={EditUnitConvert} />
        
        <ContentRoute path="/SalesChannel" component = {SalesChannel} />
        <ContentRoute path="/CreateSalesChannel" component = {CreateSalesChannel}/>
        <ContentRoute path="/EditSalesChannel" component = {EditSalesChannel}/>
        <ContentRoute path="/EditPayment" component = {EditPayment}/>

        <ContentRoute path="/EditWorkingHours" component = {EditWorkingHours}/>
        <ContentRoute path="/EditDeliveryHours" component = {EditDeliveryHours}/>

        <ContentRoute path="/PurchaseRequest" component={PurchaseRequest} />
        <ContentRoute path="/PurchaseOrder" component={PurchaseOrder} />
        <ContentRoute path="/CreatePurchaseRequest" component={CreatePurchaseRequest} />
        <ContentRoute path="/EditPurchaseRequest" component={EditPurchaseRequest} />
        <ContentRoute path="/EditPurchaseOrder" component={EditPurchaseOrder} />
        <ContentRoute path="/ViewPurchaseRequest" component={ViewPurchaseRequest} />
        <ContentRoute path="/ViewPurchaseOrder" component={ViewPurchaseOrder} />

        <ContentRoute path="/WasteReport" component={WasteReport} />
        <ContentRoute path="/PurchasePlan" component={PurchasePlan} />
        <ContentRoute path="/CreatePurchasePlan" component={CreatePurchasePlan} />
        <ContentRoute path="/EditPurchasePlan" component={EditPurchasePlan} />
        <ContentRoute path="/MaterialTransfer" component={MaterialTransfer} />
        <ContentRoute path="/Transaction" component = {Transaction}/>

        <ContentRoute path="/ViewShopGroup" component={ViewShopGroup} />
        <ContentRoute path="/ViewPromotion" component={ViewPromotion} />


        <ContentRoute path="/Authorization" component = {Authorization}/>
        <ContentRoute path="/CreateAccountRole" component = {CreateAccountRole}/>
        <ContentRoute path="/EditShopGroupRelation" component = {EditShopGroupRelation}/>
        <ContentRoute path="/CreateShopGroupRelation" component = {CreateShopGroupRelation}/>




        
        
        

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
