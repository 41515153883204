import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURL, baseURLDASHBOARD } from "../service/API.js";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import moment from "moment-timezone"
export const Menu = () => {
  const { user } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  const allData = [];
  const [menu, setMenu] = useState([]);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const [openImage, setOpenImage] = useState(false);
  const [imgSelect, setImgSelect] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [selestSearch, setSelestSearch] = useState(1);
  suhbeader.setTitle("Menu");
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem('edit')
    getMenu(1, 10);
  }, []);

  const getMenu = async (page, perPage) => {
    let apiUrls = `${baseURL}/management/menu?limit=${perPage}&page=${page}${search !== undefined && search !== "" ? "&search=" + search : ""
      }`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        await res.data.data.map(async (data, index) => {
          data = {
            id: data.id,
            shop_id: data.shop_id,
            branch_id: data.branch_id,
            group_id: data.group_id,
            template_id: data.template_id,
            item_no: data.item_no,
            item_name: data.item_name,
            item_name_en: data.item_name_en,
            description: data.description,
            description_en: data.description_en,
            description_receipt: data.description_receipt,
            item_image_path: data.item_image_path,
            displayOrder: data.display_order,
            zoneprinter_id: data.zoneprinter_id,
            uom: data.uom,
            price: data.price,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
            enabled: data.enabled,
            printer_zone_id: data.printer_zone_id
          };

          allData.push(data);

        });
        var number = Number(res.data.total) / Number(res.data.limit);
        setLastPage(Math.ceil(number));

        await setTotal(res.data.total);
        await setMenu(allData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMenuPage = async (p) => {
    await getMenu(p, perPage);
  };

  const getMenuPerPage = async (p) => {
    await setPerPage(p);
    await getMenu(page, p);
  };

  const columns = [
    {
      dataField: "item_name",
      text: "Item name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Media",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {row.item_image_path !== "" ? (
              <a
                onClick={() => {
                  setOpenImage(true);
                  setImgSelect(row.item_image_path);
                }}
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                  src={row.item_image_path}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Created at",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "displayOrder",
      text: "Display Order",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description_receipt",
      text: "Description Receipt",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              แก้ไข
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              ลบ
            </button>
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setId("");
    setOpenImage(false);
    setImgSelect("");
  };

  const editItem = (data) => {
    history.push("/EditMenu", data);
  };

  const deleteItem = (data) => {
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Menu  !",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/management/menu/${data.row.id}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Swal.fire(
              "Deleted!",
              "Your Menu  has been deleted.",
              "success"
            );
            getMenu(page, perPage);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Menu  has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Menu is safe :)", "error");
      }
    });
  };

  const [search, setSearch] = useState();
  const handleSearch = () => {
    getMenu(page, perPage);
  };

  const onChanges = (e) => {
    setPerPage(e.target.value);
    getMenuPerPage(e.target.value);
  };

  const pagination = paginationFactory({
    page: lastPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    pageListRenderer: ({ pages, onPageChange }) => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            <Pagination
              currentPage={currentPage}
              totalPages={lastPage}
              changeCurrentPage={async (numPage) => {
                onPageChange(numPage);
                await setPage(numPage);
                await setCurrentPage(numPage);
                await getMenuPage(numPage);
              }}
              theme="bottom-border form form-control-sm"
            />
          </label>
        </div>
      );
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onChanges(e);
                onSizePerPageChange(e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    ),
  });

  const handleChangeSelestSearch = (event) => {
    setSelestSearch(Number(event.target.value));
  };

  return (
    <Card>
      <CardHeader title="Menu ">
        <CardHeaderToolbar>
          <Link to="/CreateMenu">
            <button type="button" className="btn btn-primary">
              + สร้าง
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-row align-items-center">
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              placeholder="Text Search"
              style={{ width: "100%" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="col-sm-2">
            <button
              type="button"
              id="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleSearch()}
            >
              Search
            </button>
          </div>
        </div>

        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          pagination={pagination}
          data={menu === undefined ? [] : menu}
          columns={columns}
        >
          <PleaseWaitMessage entities={menu} />
          <NoRecordsFoundMessage entities={menu} />
        </BootstrapTable>
      </CardBody>

      <Modal
        open={openImage}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent>
                    <div className="modal-body">
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "500px",
                          maxHeight: "500px",
                        }}
                        src={imgSelect}
                      />
                    </div>
                  </TransformComponent>
                  <div className="modal-footer">
                    <div className="tools">
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomIn}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomOut}
                      >
                        -
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </button>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Modal>
    </Card>
  );
};
