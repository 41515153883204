import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const EditShopGroupRelation = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113432",defaultMessage: "Shop Group Relation" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [test, setTest] = useState([]);
  const [order_item, setOrder_item] = useState(1);
  const [titleEN, setTitleEN] = useState("");
  const [role, setRole] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionTH, setDescriptionTH] = useState("");
  const [listPage, setListPage] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [brandList, setBrandtList] = useState([]);
  const [groupList, setGrouplist] = useState([]);
  const [group, setGroup] = useState([]);
  const [role_user_id,setRoleUserId] = useState([]);
  const [showGroupList, setShowGroupList] = useState([]);

  useEffect(() => {
    console.log("prop=============>",props)
    setRole(props.location.state.row.name)
    setRoleUserId(props.location.state.row.id)
    getShopList();
    getShowData();
  }, []);

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  const getShowData = async () => {
    console.log("rowId=========>",props.location.state.row.id)
    const apiUrls = `${baseURL}/shopGroup/relationGroup/${props.location.state.row.id}`;
    let token = localStorage.getItem("Token");
    let allDataShow = [];
    axios({
        method: "get",
        url: apiUrls,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    })
    .then(async (res) => {
        console.log("responseshowData===========>",res.data.data);
        res.data.data.shop_group_relation.map((data) => {
            allDataShow.push(data.posShopRoleGroups);
        });
        console.log("allDataShow========>",allDataShow)
        setGroup(allDataShow)
    })

  }

  const getShopList = async () => {
    let page = 1;
    let limit = 1000;
    let search = ""
    const apiUrls = `${baseURL}/shopGroup/role?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""}`
    
    let token = localStorage.getItem("Token");
    let allData0 = [];

    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        console.log("response===========>",res.data.data);
        res.data.data.shop_role_group.map((data) => {
          allData0.push(data);
        });
        setGrouplist(allData0);
      })
      .catch((err) => {
        if (err.response) {
        }
      });
  };
  const onSubmit = async () => {
    if (role == undefined || role == "") {
      return AlertError("Please insert Role");
    }
    console.log("data", data);
    // return false;
    let temp = [];
    console.log("group=============>",group)
    console.log("role=============>",role)

    group.map((item) => {
      temp.push({
        role_group_id: item.id,
        user_id: role_user_id,
      });
    });
    

    var events = {
      detail: temp,
    };
    console.log("events========>",events)
    let apiUrls = `${baseURL}/shopGroup/relationGroup/${props.location.state.row.id}`;
    
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: "",
      text: "Confirm Edit",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "put",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            Swal.fire("Create !", "Your can not edit. !! " + err, "error");
          });
      }
    });

    return false;
  };

  const addPermission = () => {
    let temp = {
      page: "",
      page_id: "",

      permission: [],
    };
    setData((data) => [...data, temp]);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113434",
            defaultMessage: "Edit Shop Group Relation",
          })}
        >
          <CardHeaderToolbar>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113435",
                defaultMessage: "Employee name",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              readOnly={true}
              value={role}
              onChange={(e) => setRole(e.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113184",
                defaultMessage: "Shop Group Name",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              id="grouped-demo"
              options={groupList}
              multiple
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id == value.id}
              value={group}
              onChange={(event, newValue) => {
                console.log("newValue=======>",newValue);
                setGroup(newValue);
                //updateFieldChanged(index, newValue, 'permission')
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
           <div className="row">
           <Link to="/ShopGroupRelation">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "099065", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "099064", defaultMessage: "Save" })}
            </button>
           </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
