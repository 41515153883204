/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { LanguageSelectorDropdown } from '../dropdowns/LanguageSelectorDropdown';
import { useIntl } from "react-intl";
import Modal from "@material-ui/core/Modal";
import { Card, Row, Form, Button } from 'react-bootstrap';
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL, imageURL } from "../../../../../app/service/API.js";


export function QuickUser() {
  const intl = useIntl();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };


  const [open, setOpen] = useState(false);
  const [showErrorChangePassword, setErrorChangePassword] = useState(false);
  const [textError, setTextErrorChangePassword] = useState(false);
  const [textOldPassword, setOldPassword] = useState("");
  const [textNewPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false)

  const changePassword = () => {
    handleOpen();
  };

  const handleClose = () => {
    setTextErrorChangePassword("");
    setErrorChangePassword(false);
    setOpen(false);
  };

  const handleOpen = () => {
    setLoading(false)
    setOpen(true);
  };

  const ErrorChangePassword = (text, flag) => {
    setTextErrorChangePassword(text);
    if (flag) {
      setErrorChangePassword(true);
    }
    else {
      setErrorChangePassword(false);
    }
  };

  const onSubmitChangePassword = () => {
    
    if (textOldPassword === undefined || textOldPassword === "") {
      return ErrorChangePassword("please insert old password.", true);
    }
    if (textNewPassword === undefined || textNewPassword === "") {
      return ErrorChangePassword("please insert new password.", true);
    }
    if (textOldPassword === textNewPassword) {
      return ErrorChangePassword("Duplicate password.", true);
    }

    let apiUrls = `${baseURL}/management/updatePasswordShop`;
    let token = localStorage.getItem("Token");
    let username = localStorage.getItem('email')
    let data = {
      username: username,
      oldPassword: textOldPassword,
      newPassword: textNewPassword
    }

    setLoading(true)

    const resultAll = axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
      data: data
    })
      .then(async (res) => {
        setLoading(false)
        console.log('onSubmitChangePassword', res);
        handleClose();

        Swal.fire({
          title: "Save !",  
          text: "Change password success.",
          icon: "success",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            logoutClick()
          }
        })
      //   Swal.fire("Save !", "Change password success.", "success", onclose: ());

      })
      .catch((error) => {
        if (error.response) {
          setLoading(false)
          let err = error.response.data;
          if (err) {
            ErrorChangePassword(err.system_response.message, true);
          }
        }
      });
  };


  const [openCancelBill, setOpenCancelBill] = useState(false);
  const [showErrorChangePasswordCancelBill, setErrorChangePasswordCancelBill] = useState(false);
  const [textErrorCancelBill, setTextErrorChangePasswordCancelBill] = useState(false);
  const [textOldPasswordCancelBill, setOldPasswordCancelBill] = useState("");
  const [textNewPasswordCancelBill, setNewPasswordCancelBill] = useState("");

  const changePasswordCancelBill = () => {
    handleOpenCancelBill();
  };

  const handleCloseCancelBill = () => {
    setTextErrorChangePasswordCancelBill("");
    setErrorChangePasswordCancelBill(false);
    setOldPasswordCancelBill('');
    setNewPasswordCancelBill('');
    setOpenCancelBill(false);
  };

  const handleOpenCancelBill = () => {
    setLoading(false)
    setOpenCancelBill(true);
  };

  const ErrorChangePasswordCancelBill = (text, flag) => {
    setTextErrorChangePasswordCancelBill(text);
    if (flag) {
      setErrorChangePasswordCancelBill(true);
    }
    else {
      setErrorChangePasswordCancelBill(false);
    }
  };

  const handleChangeOldPasswordCancelBill = event => {
    const result = event.target.value.replace(/\D/g, '');
    setOldPasswordCancelBill(result);
  };

  const handleChangeNewPasswordCancelBill = event => {
    const result = event.target.value.replace(/\D/g, '');
    setNewPasswordCancelBill(result);
  };

  const onSubmitChangePasswordCancelBill = () => {
    
    if (textOldPasswordCancelBill === undefined || textOldPasswordCancelBill === "") {
      return ErrorChangePasswordCancelBill("please insert old password.", true);
    }
    if (textOldPasswordCancelBill.length != 6) {
      return ErrorChangePasswordCancelBill("please insert old password 6 character.", true);
    }

    if (textNewPasswordCancelBill === undefined || textNewPasswordCancelBill === "") {
      return ErrorChangePasswordCancelBill("please insert new password.", true);
    }
    if (textNewPasswordCancelBill.length != 6) {
      return ErrorChangePasswordCancelBill("please insert new password 6 character.", true);
    }

    if (textOldPasswordCancelBill === textNewPasswordCancelBill) {
      return ErrorChangePasswordCancelBill("Duplicate password.", true);
    }

    let apiUrls = `${baseURL}/management/updatePasswordCancelBill`;
    let token = localStorage.getItem("Token");
    let username = localStorage.getItem('email')
    let data = {
      username: username,
      oldPassword: textOldPasswordCancelBill,
      newPassword: textNewPasswordCancelBill
    }

    setLoading(true)

    const resultAll = axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
      data: data
    })
      .then(async (res) => {
        setLoading(false)
        console.log('onSubmitChangePasswordCancelBill', res);

        handleCloseCancelBill();

        Swal.fire({
          title: "Save !",  
          text: "Change password for cancel bill success.",
          icon: "success",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // logoutClick()
          }
        })
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false)
          let err = error.response.data;
          if (err) {
            ErrorChangePasswordCancelBill(err.system_response.message, true);
          }
        }
      });
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <LanguageSelectorDropdown />
        <h3 className="font-weight-bold m-0">
          {intl.formatMessage({ id: "111001", defaultMessage: "User Profile" })}
          {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>

      </div>

      <div
        className="offcanvas-content pr-5 mr-n5"
      >
        <div className="d-flex align-items-center mt-5">
          <div
            className="symbol symbol-100 mr-5"
          >
            <div className="symbol-label" >BACK OFFICE</div>

          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {/* admin demo */}
              {user.data.email}
            </a>
            {/* <div className="text-muted mt-1">Application Developer</div> */}
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  {/* <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span>
                  </span> */}
                  {/* <span className="navi-text text-muted text-hover-primary">
                    jm@softplus.com
                  </span> */}
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button className="btn btn-light-success btn-bold my-1" onClick={changePassword}>{intl.formatMessage({ id: "113068", defaultMessage: "Change Password" })}</button>
            <button className="btn btn-light-warning btn-bold my-1" onClick={changePasswordCancelBill}>{intl.formatMessage({ id: "113254", defaultMessage: "Cancel Bill" })}</button>

            <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>{intl.formatMessage({ id: "111002", defaultMessage: "Sign out" })}</button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />


        <Modal
          onClose={handleClose}
          open={open}
          backdrop="static"
          keyboard={false}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <Card className="m-1">
                <Card.Body >
                  {/* <Row className="justify-content-center mb-3" >
                  </Row> */}
                  <div className="justify-content-center" >

                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                      <h3 className="mb-5" style={{ float: "left", width: "100%" }}>
                        {intl.formatMessage({ id: "113068", defaultMessage: "Change password" })}
                      </h3>

                      <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                        <span style={{ color: "red" }}>
                          *
                        </span>
                        {intl.formatMessage({ id: "113252", defaultMessage: "Old password" })}
                      </p>
                      <input
                        name="change_password"
                        maxLength="100"
                        type="password"
                        style={{
                          height: "38px",
                          width: "230px",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        onChange={(event) => setOldPassword(event.target.value)}
                      />
                    </div>
                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                      <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                        <span style={{ color: "red" }}>
                          *
                        </span>
                        {intl.formatMessage({ id: "113253", defaultMessage: "New password" })}
                      </p>
                      <input
                        name="change_password"
                        maxLength="100"
                        type="password"
                        style={{
                          height: "38px",
                          width: "230px",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        onChange={(event) => setNewPassword(event.target.value)}
                      />
                    </div>

                    <div className={"form-inline justify-content-center my-3 " + (showErrorChangePassword ? '' : 'd-none')}>
                      <p className="text-center" style={{ width: "100%", color: "red" }}>
                        {/* {intl.formatMessage({ id: "113068", defaultMessage: "Old password" })} */}
                        {textError}
                      </p>
                    </div>

                    {
                      loading ?
                        <div className="form-inline justify-content-center my-3">
                          <span className="spinner spinner-darker"></span>
                        </div>
                        :

                        <div className="form-inline justify-content-center my-3">
                          <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => handleClose()}>
                            {intl.formatMessage({ id: "104047", defaultMessage: "Cancel" })}
                          </button>
                          <button className="btn btn-primary" onClick={() => onSubmitChangePassword()}>
                            {intl.formatMessage({ id: "104048", defaultMessage: "Save" })}
                          </button>
                        </div>
                    }
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Modal>

        
        <Modal
          onClose={handleCloseCancelBill}
          open={openCancelBill}
          backdrop="static"
          keyboard={false}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <Card className="m-1">
                <Card.Body >
                  {/* <Row className="justify-content-center mb-3" >
                  </Row> */}
                  <div className="justify-content-center" >

                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                      <h3 className="mb-5" style={{ float: "left", width: "100%" }}>
                        {intl.formatMessage({ id: "113255", defaultMessage: "Change password for cancel bill" })}
                      </h3>

                      <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                        <span style={{ color: "red" }}>
                          *
                        </span>
                        {intl.formatMessage({ id: "113252", defaultMessage: "Old password" })}
                      </p>
                      <input
                        name="change_password"
                        maxLength="6"
                        type="password"
                        style={{
                          height: "38px",
                          width: "230px",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        value={textOldPasswordCancelBill}
                        onChange={(event) => handleChangeOldPasswordCancelBill(event)}
                      />
                    </div>
                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                      <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                        <span style={{ color: "red" }}>
                          *
                        </span>
                        {intl.formatMessage({ id: "113253", defaultMessage: "New password" })}
                      </p>
                      <input
                        name="change_password"
                        maxLength="6"
                        type="password"
                        style={{
                          height: "38px",
                          width: "230px",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        value={textNewPasswordCancelBill}
                        onChange={(event) => handleChangeNewPasswordCancelBill(event)}
                      />
                    </div>

                    <div className={"form-inline justify-content-center my-3 " + (showErrorChangePasswordCancelBill ? '' : 'd-none')}>
                      <p className="text-center" style={{ width: "100%", color: "red" }}>
                        {/* {intl.formatMessage({ id: "113068", defaultMessage: "Old password" })} */}
                        {textErrorCancelBill}
                      </p>
                    </div>

                    {
                      loading ?
                        <div className="form-inline justify-content-center my-3">
                          <span className="spinner spinner-darker"></span>
                        </div>
                        :

                        <div className="form-inline justify-content-center my-3">
                          <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => handleCloseCancelBill()}>
                            {intl.formatMessage({ id: "104047", defaultMessage: "Cancel" })}
                          </button>
                          <button className="btn btn-primary" onClick={() => onSubmitChangePasswordCancelBill()}>
                            {intl.formatMessage({ id: "104048", defaultMessage: "Save" })}
                          </button>
                        </div>
                    }
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Modal>

        {/* <div className="navi navi-spacer-x-0 p-0">
            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/Notification2.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted">
                    Account settings and more
                    {" "}
                    <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                  </div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Messages</div>
                  <div className="text-muted">Inbox and tasks</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Activities</div>
                  <div className="text-muted">Logs and notifications</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-opened.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Tasks</div>
                  <div className="text-muted">latest tasks and projects</div>
                </div>
              </div>
            </a>
          </div> */}

        {/* <div className="separator separator-dashed my-7"></div>

          <div>
            <h5 className="mb-5">Recent Notifications</h5>

            <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-warning mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Another purpose persuade
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-warning py-1 font-size-lg">
              +28%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-success mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Would be to people
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-success py-1 font-size-lg">
              +50%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-danger mr-5">
              <SVG
                  src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Purpose would be to persuade
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-danger py-1 font-size-lg">
              -27%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-info rounded p-5">
            <span className="svg-icon svg-icon-info mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  The best product
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-info py-1 font-size-lg">
              +8%
            </span>
            </div>
          </div>
         */}
      </div>
    </div>
  );
}
