import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Modal, Button } from "react-bootstrap"
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { isNull } from "lodash";
import { useIntl } from "react-intl";
import { temp_store } from "../../../modules/function/temp_store"
import { EditDeliveryHours } from "../edit/EditDeliveryHours.js";
import QRCode from "qrcode.react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditTable = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "113062", defaultMessage: "Edit Table" }));
  const history = useHistory();
  const classes = useStyles();
  const [zone_id, setzone_id] = useState();
  const [item_name, setItem_name] = useState();
  const [item_name_en, setItem_name_en] = useState();
  const [description, setDescription] = useState();
  const [description_en, setDescription_en] = useState();
  const [max_seat, setMax_seat] = useState()
  const [state, setState] = useState(true);
  const [qr_address, setQRAddress] = useState();
  const [is_pickup, setIsPickup] = useState(false);
  const [zone, setZone] = useState([]);
  const [is_delivery, setIsDelivery] = useState(false);
  const [is_member_no, setIsMemberNo] = useState(false);
  const [show, setShow] = useState(false);
  const [dynamicQR, setDynamicQR] = useState(false);
  const [staticQR, setStaticQR] = useState(false);
  const [time_period, setTimePeriod] = useState(0);
  const [qrLink, setQRLink] = useState();
  const [table_type,setTable_type] = useState();

  const allDataGroup = [];
  const table_type_name = ["Scan Menu","Dining Restaurants","Fast Service,Kiosk","Pickup / Delivery"]

  const AlertError = (message) => {
    Swal.fire(intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }), message, "error");
  };

  useEffect(() => {
    console.log("******(tempstore)*****",temp_store(props).location.state.row)
    getZone();
    setzone_id(temp_store(props).location.state.row.zone_id);
    setItem_name(temp_store(props).location.state.row.table_name);
    setItem_name_en(temp_store(props).location.state.row.table_name_en);
    setDescription(temp_store(props).location.state.row.table_desc);
    setDescription_en(temp_store(props).location.state.row.table_desc_en);
    setMax_seat(temp_store(props).location.state.row.max_seat);
    setState(temp_store(props).location.state.row.enabled);
    setQRAddress(temp_store(props).location.state.row.qr_address);
    setIsPickup(temp_store(props).location.state.row.is_pickup);
    setIsDelivery(temp_store(props).location.state.row.is_delivery)
    setIsMemberNo(temp_store(props).location.state.row.is_member_no)
    setDynamicQR(temp_store(props).location.state.row.is_dynamic)
    setQRLink(temp_store(props).location.state.row.qrLink)
    const table_type_name = temp_store(props).location.state.row.table_type
    if(table_type_name === 'VIEW_MENU')
    {
      setTable_type('0')
    } else if(table_type_name === 'PAY_AFTER'){
      setTable_type('1')
    } else if(table_type_name === 'PAY_FIRST'){
      setTable_type('2')
    } else {
      setTable_type('3')
    }
    
  }, []);


  const getZone = async () => {
    let apiUrls = `${baseURL}/management/tablezone?page=1&limit=10000`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setZone(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeGroup = (event) => {
    setzone_id(event.target.value);
  };

  const handleChangeDynamicQR = () => {
    setDynamicQR(!dynamicQR);
  };

  // const handleChangeStaticQR = () => {
  //   setStaticQR(!staticQR);
  // };
  // const handleQRstatus = () => {
  //   setStaticQR(!staticQR);
  // };

  const handleChangeSwitch = () => {
    setState(!state);
  };
  const handleChangeIsPickUp = () => {
    setIsPickup(!is_pickup);
  };
  const handleChangeDelivery = () => {
    setIsDelivery(!is_delivery);
  };
  const handleChangeMemberNo = () => {
    setIsMemberNo(!is_member_no);

  }
  const  handleChangeTableType = (event) => {
    setTable_type(event.target.value);
    console.log("*******tabletype after select********",event.target.value)
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const setTableTypeName = (index) =>{
    switch(index) {
      case 0:
        return(intl.formatMessage({ id: "113361", defaultMessage: "Scan Menu" }))
      case 1:
        return(intl.formatMessage({ id: "113362", defaultMessage: "Dining Restaurants" }))
      case 2:
        return(intl.formatMessage({ id: "113363", defaultMessage: "Fast Service,Kiosk" }))
      case 3:
        return(intl.formatMessage({ id: "113364", defaultMessage: "Pickup / Delivery" }))
    }

  }
  const handlePass = (data) => {
    console.log('handlePass');
    if (data === 'save') {

    }
    handleClose()
  }

  const onSubmit = async (data) => {
    if (item_name === undefined || item_name === "") {
      return AlertError(intl.formatMessage({ id: "113051", defaultMessage: "Please insert item name." }));
    }
    if (item_name_en === undefined || item_name_en === "") {
      return AlertError(intl.formatMessage({ id: "113054", defaultMessage: "Please insert item name en." }));
    }

    if (zone_id === undefined || zone_id === "") {
      return AlertError("please select group.");
    }
    if (table_type === "3") {
    var events = {
      zone_id,
      table_name: item_name,
      table_name_en: item_name_en,
      table_desc: description ? description : " ",
      table_desc_en: description_en ? description_en : " ",
      max_seat,
      qr_address: qr_address,
      enabled: state,
      is_dynamic: dynamicQR,
      time_period: time_period !== 0 ? time_period : null,
      is_pickup: true,
      is_member_no: is_member_no ? is_member_no : false,
      is_delivery: is_delivery ? is_delivery : false,
      table_type: table_type === '0' ? '1' : table_type === '1' ? '2' : table_type === '2' ? '3' : null,
    };
  } else {
    var events = {
      zone_id,
      table_name: item_name,
      table_name_en: item_name_en,
      table_desc: description ? description : " ",
      table_desc_en: description_en ? description_en : " ",
      max_seat,
      qr_address: qr_address,
      enabled: state,
      is_dynamic: dynamicQR,
      time_period: time_period !== 0 ? time_period : null,
      is_pickup: false,
      is_member_no: false,
      is_delivery: false,
      table_type: table_type === '0' ? '1' : table_type === '1' ? '2' : table_type === '2' ? '3' : "",
    };
  }
    console.log("event==============>", events)

    let apiUrls = `${baseURL}/management/table/${temp_store(props).location.state.row.id}`;
    let token = localStorage.getItem("Token");
    const resultAll = await axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire(
          intl.formatMessage({ id: "113019", defaultMessage: "Edit!" }),
          intl.formatMessage({ id: "113357", defaultMessage: "Your table has been edit." }),
          "success"
        ).then(() => {
          history.goBack();
        })
      })
      .catch((err) => {
        Swal.fire(
          intl.formatMessage({ id: "113056", defaultMessage: "Edit !" }),
          intl.formatMessage({ id: "113057", defaultMessage: "Sorry for the inconvenience. Please check your data again." }), + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "113062", defaultMessage: "Edit Table" })}></CardHeader>
        <CardBody>
          {/*------------QR Preview------------*/}
          <div className="container " style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
            <div className="form-inline justify-content-center" style={{ marginTop: "25px", marginBottom: "25px" }}>
              
              <div style={{ width: '300px' }}>
                {qrLink && <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  id={''}
                  value={qrLink} />}
            
              </div>
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                {intl.formatMessage({ id: "113358", defaultMessage: "QR Link" })}:{" "}
              </p>
              <input
                name="qrLink"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={qrLink}
                readOnly={true}

              />
            </div>
          </div>

          {/*-----------table type-----------*/}
          <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
            <div className="form-inline" style={{ marginBottom: "25px", marginTop: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>
                  *
                </span>
                {intl.formatMessage({ id: "113360", defaultMessage: "Table Type" })}:{" "}
              </p>
              <select
                as="select"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={table_type}
                onChange={handleChangeTableType}
              >
                <option value="">{intl.formatMessage({ id: "113023", defaultMessage: "Please Select" })}</option>
                {table_type_name &&
                  table_type_name.map((result, index) => {
                    return (
                      <option key={index} value={index}>
                      {setTableTypeName(index)}
                        
                      </option>
                    );
                  })}
              </select>
            </div>
  
          </div>

          {/*-----------Table Detail-----------*/}
          <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
            <div className="form-inline" style={{ marginTop: "25px", marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>
                  *
                </span>
                {intl.formatMessage({ id: "113022", defaultMessage: "Zone" })}:{" "}
              </p>
              <select
                as="select"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={zone_id}
                onChange={handleChangeGroup}
              >
                <option value="">{intl.formatMessage({ id: "113023", defaultMessage: "Please select" })}</option>
                {zone &&
                  zone.map((result, index) => {
                    return (
                      <option key={index} value={result.id}>
                        {result.zone_name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>
                  *
                </span>
                {intl.formatMessage({ id: "113024", defaultMessage: "Table name th" })}:{" "}
              </p>
              <input
                name="item_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={item_name}
                maxLength={10}
                onChange={(item_name) => setItem_name(item_name.target.value)}
              />
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>
                  *
                </span>
                {intl.formatMessage({ id: "113025", defaultMessage: "Item name en" })}:{" "}
              </p>
              <input
                name="item_name_en"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                maxLength={10}
                value={item_name_en}
                onChange={(item_name_en) =>
                  setItem_name_en(item_name_en.target.value)
                }
              />
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({ id: "113026", defaultMessage: "Description" })}:{" "}
              </p>
              <input
                name="description"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description}
                onChange={(description) =>
                  setDescription(description.target.value)
                }
              />
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({ id: "113027", defaultMessage: "Description en" })}:{" "}
              </p>
              <input
                name="description_en"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description_en}
                onChange={(description_en) =>
                  setDescription_en(description_en.target.value)
                }
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({ id: "113028", defaultMessage: "Max Seat" })}:{" "}
              </p>
              <input
                name="max_seat"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={max_seat}
                onChange={(max_seat) =>
                  setMax_seat(max_seat.target.value)
                }
              />
            </div>
          </div>

          {/*-----------Enable-----------*/}
          <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
            <div className="form-inline" style={{ marginTop: "25px", marginBottom: "25px" }}>
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                {intl.formatMessage({ id: "113029", defaultMessage: "Enabled" })}:{" "}
              </p>
              <Switch
                checked={state}
                onChange={() => handleChangeSwitch()}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          </div>

          {/* ----------Dynamic------------ */}
          {table_type !== '3' && (
          <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                {intl.formatMessage({ id: "113347", defaultMessage: "Dynamic QR" })}:{" "}
              </p>
              <Switch
                checked={dynamicQR}
                onChange={() => handleChangeDynamicQR()}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({ id: "113259", defaultMessage: "Time period (minute)" })}:{" "}
              </p>
              <input
                type="number"
                name="time_period"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setTimePeriod(e.target.value)
                }
              />
            </div> */}
          </div>
          )}

          {/* ----------Static------------ */}
            {/* <div
              className="container"
              style={{
                border: "1px solid",
                borderRadius: "20px",
                borderColor: "#CECDCD",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  {intl.formatMessage({
                    id: "113493",
                    defaultMessage: "Static QR",
                  })}
                  :{" "}
                </p>
                <Switch
                  checked={staticQR}
                  onChange={() => handleChangeStaticQR()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div> */}
          {/* )} */}

          {/*-----------QR Pickup-----------*/}
          {table_type === '3' && (
          <div className="container" 
          style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                {intl.formatMessage({ id: "113321", defaultMessage: "Delivery" })}:{" "}
              </p>
              <div
                style={{
                  width: "530px",
                }}
              >

                {/* <div className="d-flex align-items-center">
                  <Switch
                    checked={is_}
                    onChange={() => handleChangeDelivery()}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="input-cheked-show"
                  />
                </div> */}
                {/* <div className={`box-cheked-show${is_pickup ? ' d-block' : ''}`}> */}
                  {/* <div className="template mb-3 d-flex align-items-center"
                    style={{
                      width: "530px",
                    }}>

                  </div> */}
                  <div className="d-flex align-items-center">
                    {/* <span className="ml-3">
                      {intl.formatMessage({ id: "113321", defaultMessage: "Delivery" })}:{" "}
                    </span> */}
                    <Switch
                      checked={is_delivery}
                      onChange={() => handleChangeDelivery()}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="input-cheked-show"
                    />

                  </div>
                  <div className={`box-cheked-show${is_delivery ? ' d-block' : ''}`}>
                    <div className="template mb-3 d-flex align-items-center"
                      style={{
                        width: "530px",
                      }}>
                    </div>
                    <div className="container" style={{ marginLeft: "60px" }}>
                      <Button variant="light" className="ml-3 text-primary" onClick={handleShow}>
                        <i className="fa fa-pen mr-3 text-primary" />
                        {intl.formatMessage({ id: "113330", defaultMessage: "Edit Delivery Hours" })}
                      </Button>
                      <Modal show={show} onHide={handleClose} animation={false}>
                        <Modal.Body>
                          <EditDeliveryHours type={'dialog'} handle={handlePass} />
                        </Modal.Body>
                        {/* <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                          Cancel
                          </Button>
                          <Button variant="primary" onClick={handleClose}>
                          Save
                          </Button>
                          </Modal.Footer> */}
                      </Modal>

                    </div>
                    <div className={`template name mt-3 mb-3 align-items-center${is_delivery ? ' d-flex' : ''}`}
                      style={{
                        width: "530px",
                      }}>
                      <span style={{ color: "red" }}>
                        *
                      </span>
                      <span className="mr-3">
                        {intl.formatMessage({ id: "113316", defaultMessage: "QR Address" })}:{" "}

                      </span>
                      <input
                        name="qr_address"
                        type="text"
                        min="0"
                        style={{
                          height: "38px",
                          flexGrow: "1",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        value={(qr_address)}
                        onChange={(qr_address) => setQRAddress(qr_address.target.value)}
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="ml-3">
                        {intl.formatMessage({ id: "113320", defaultMessage: "Member No." })}:{" "}
                      </span>
                      <Switch
                        checked={is_member_no}
                        onChange={() => handleChangeMemberNo()}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        className="input-cheked-show"
                      />
                    </div>
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          )}



          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/TableZone">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113030", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
