import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const CreateDynamicQR = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "113021", defaultMessage: "Create Table" }));
  const classes = useStyles();
  const history = useHistory();
  const [table_id, setTable_id] = useState();
  const [state, setState] = useState(true);
  const [table, setTable] = useState([]);
  const [time_period, setTimePeriod] = useState(0);
  const [tableSelectId, setTableSelectId] = useState([]);

  const allDataGroup = [];

  const AlertError = (message) => {
    Swal.fire(intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }), message, "error");
  };

  useEffect(() => {
    getTable();
  }, []);

  const getTable = async () => {
    let apiUrls = `${baseURL}/management/table-dynamic`;;
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setTable(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const onSubmit = async () => {
    if (tableSelectId.length === 0) {
      return AlertError("please select table.");
    }

    var events = {
      table_id: tableSelectId,
      enabled: state,
      time_period: time_period !== 0 ? time_period : null,
    };
    let apiUrls = `${baseURL}/management/table-qr-create`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save create dynamic success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          intl.formatMessage({ id: "113059", defaultMessage: "Create !" }),
          intl.formatMessage({ id: "113060", defaultMessage: "Sorry for the inconvenience. Please check your data again." }), + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "113260", defaultMessage: "Generate Dynamic QR" })}></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113250", defaultMessage: "Table" })}:{" "}
            </p>

            <Autocomplete
              id="grouped-demo"
              multiple
              options={table}
              getOptionLabel={(option) => option.table_name}
              getOptionSelected={(option, value) =>
                option.id === value.id
              }
              value={tableSelectId}
              onChange={(event, newValue) => {
                //console.log(newValue)
                //getListBranchGroup(null, newValue.id);
                setTableSelectId(newValue);
              }}
              style={{
                width: "500px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
                marginRight: "15px"
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113259", defaultMessage: "Time period (minute)" })}:{" "}
            </p>
            <input
              type="number"
              name="time_period"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={time_period}
              onChange={(e) =>
                setTimePeriod(e.target.value)
              }
            />
          </div> */}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113029", defaultMessage: "Enabled" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/TableZone">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113030", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
