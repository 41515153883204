import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { temp_store } from "../../../modules/function/temp_store"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const EditSettingPrinterZone = (props) => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: "107001" ,defaultMessage:"Set up printer zone"}));
    const { authToken } = useSelector((state) => state.auth);
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = useState();
    const [printerzonename, setPrinterzonename] = useState();
    const [printerzonenameen, setPrinterzonenameen] = useState();
    const [state, setState] = useState(true);
    const AlertError = (message) => {
        Swal.fire(intl.formatMessage({ id: "107017" ,defaultMessage:"Save"}));
    };
    const handleChangeSwitch = () => {
        setState(!state);
    };


    useEffect(() => {
        setId(temp_store(props).location.state.row.id);
        setPrinterzonename(temp_store(props).location.state.row.zone_name);
        setPrinterzonenameen(temp_store(props).location.state.row.zone_name_en);
        setState(temp_store(props).location.state.row.enabled ? true : false);
    }, []);

    const handleSend = async () => {
        if (printerzonename === undefined || printerzonename === "") {
            return AlertError("Please insert printer zone name th !!");
        }
        if (printerzonenameen === undefined || printerzonenameen === "") {
            return AlertError("Please insert printer zone name en !!");
        }

        var events = {
            printer_zone_name: printerzonename,
            printer_zone_name_en: printerzonenameen,
            enabled: state,
        };

        let apiUrls = `${baseURL}/management/printerzone/${id}`;
        axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            data: events,
        })
            .then(async (res) => {
                Swal.fire("Save !", "Save Edit Setting Printer Zone success", "success");
                history.goBack();
            })
            .catch((err) => {
                Swal.fire(
                    "Created !",
                    "Your can not Edit Setting Printer Zone. !! " + err,
                    "error"
                );
            });
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "107033" ,defaultMessage:"Edit Setting Printer Zone"})}></CardHeader>
            <CardBody>
                <div className="container">
                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "107013" ,defaultMessage:"Printer zone name th"})}:{" "}
                        </p>
                        <input
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={printerzonename}
                            onChange={(printerzonename) => setPrinterzonename(printerzonename.target.value)}
                        />
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "107014" ,defaultMessage:"Printer zone name en"})}:{" "}
                        </p>
                        <input
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={printerzonenameen}
                            onChange={(printerzonenameen) => setPrinterzonenameen(printerzonenameen.target.value)}
                        />
                    </div>


                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "107015" ,defaultMessage:"Enabled"})}:{" "}
                        </p>
                        <Switch
                            checked={state}
                            onChange={() => handleChangeSwitch()}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>
                        <Link to="/SettingPrinterZone">
                            <button type="button" className="btn btn-secondary wbt">
                                {intl.formatMessage({ id: "107016" ,defaultMessage:"Cancel"})}
              </button>
                        </Link>
                        <button className="btn btn-primary" onClick={() => handleSend()}>
                            {intl.formatMessage({ id: "107017" ,defaultMessage:"Save"})}
            </button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
