import React from 'react'

function A6HorizaltalOrder() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="0 0 425.2 298.65"
        >
            <defs>
                <style>
                    {`
            .cls-1 { fill: none; }
            .cls-2 { fill: #f1f1f1; }
            .cls-3 { fill: #efeeee; }
            .cls-4 { fill: #f9fcff; }
            .cls-5 { fill: #cacac9; }
            .cls-6 { fill: #bebfc0; }
            .cls-7 { fill: #dfdfdf; }
            .cls-8 { fill: #bec0c2; }
            .cls-9 { fill: #e4e5e5; }
            .cls-10 { fill: #adaeb0; }
            .cls-11 { fill: #eaeaea; }
            .cls-12 { fill: #d1d2d3; }
            .cls-13 { fill: #c1c3c4; }
            .cls-14 { fill: #f7fbff; }
            .cls-15 { fill: #c6c7c8; }
            .cls-16 { fill: #c8c9cb; }
            .cls-17 { fill: #e7e6e6; }
            .cls-18 { fill: #d4d6d6; }
            .cls-19 { fill: #e0e1e1; }
            .cls-20 { fill: #b9b9bc; }
            .cls-21 { fill: #e4e3e3; }
            .cls-22 { fill: #b0b3b5; }
            .cls-23 { isolation: isolate; }
            .cls-24 { fill: #dadbdb; }
            .cls-25 { fill: #b1b1b3; }
            .cls-26 { fill: #f0eff0; }
            .cls-27 { fill: #afb0b3; }
            .cls-28 { fill: #d0d1d2; }
            .cls-29 { fill: #f1f2f2; }
            .cls-30 { fill: #ededee; }
            .cls-31 { fill: #c8c9ca; }
            .cls-32 { fill: #cdcfd1; }
            .cls-33 { fill: #c9cacc; }
            .cls-34 { fill: #c4c5c7; }
            .cls-35 { fill: #e8e8e9; }
            .cls-36 { fill: #bbbcbe; }
            .cls-37 { fill: #b1b3b7; }
            .cls-38 { fill: #fff; }
            .cls-39 { fill: #b6b8bb; }
            .cls-40 { fill: #c1c1c2; }
            .cls-41 { fill: #d7d9d9; }
            .cls-42 { fill: #d6d6d7; }
            .cls-43 { fill: #f6fbfb; }
            .cls-44 { fill: #c5c6c8; }
            .cls-45 { fill: #f4f5f5; }
            .cls-46 { fill: #c5c5c5; }
            .cls-47 { fill: #f3f4f4; }
            .cls-48 { fill: #e2e3e4; }
            .cls-49 { fill: #bcbdbf; }
            .cls-50 { fill: #d2d4d6; }
            .cls-51 { fill: #ced1d2; }
            .cls-52 { fill: #b5b5b8; }
            .cls-53 { fill: #221f1f; }
            .cls-54 { fill: #221f1f; }
            .cls-55 { fill: #b6b7bb; }
            .cls-56 { fill: #cbced0; }
            .cls-57 { fill: #d0d2d5; }
            .cls-58 { fill: #dadcdc; }
            .cls-59 { fill: #dbdadb; }
            .cls-60 { fill: #f6f7f7; }
            .cls-61 { fill: #f6f7f8; }
            .cls-62 { fill: #e4e4e5; }
            .cls-63 { fill: #aeafb1; }
            .cls-64 { fill: #b3b4b7; }
            .cls-65 { fill: #dfe0e0; }
            .cls-66 { fill: #afb2b5; }
            .cls-67 { fill: #e8e8ea; }
            .cls-68 { fill: #bdbfc1; }
            .cls-69 { fill: #b6b8b9; }
            .cls-70 { fill: #efeeef; }
            .cls-71 { clip-path: url(#clippath); }
            .cls-72 { fill: #d6d7d7; }
            .cls-73 { fill: #c2c4c6; }
            .cls-74 { fill: #f2f3f4; }
            .cls-75 { fill: #dbdcdd; }
            .cls-76 { fill: #babbbd; }
            .cls-77 { fill: #eaeaeb; }
            .cls-78 { mix-blend-mode: darken; opacity: .58; }
          `}
                </style>
                <clipPath id="clippath">
                    <polygon
                        className="cls-1"
                        points="288.96 268.91 299.29 268.91 291.92 280.26 281.38 280.26 288.96 268.91"
                    />
                </clipPath>
            </defs>
            <g class="cls-23">
                <g id="Layer_1">
                    <rect class="cls-38" x=".8" y="-.69" width="196" height=".69" />
                    <polygon class="cls-54" points="425.19 0 425.19 298.65 0 298.65 0 0 .8 0 .8 298.31 196.8 298.31 196.8 0 425.19 0" />
                    <rect class="cls-53" x="196.8" width="228.4" height="85.5" />
                    <g>
                        <g>
                            <polygon class="cls-38" points="291.92 248.94 302.34 248.94 294.72 260.25 304.91 260.28 291.92 280.26 281.38 280.26 288.96 268.91 278.62 268.91 291.92 248.94" />
                            <polygon class="cls-38" points="291.92 248.94 302.34 248.94 294.72 260.25 304.91 260.28 291.92 280.26 281.38 280.26 288.96 268.91 278.62 268.91 291.92 248.94" />
                            <g class="cls-78">
                                <g class="cls-71">
                                    <rect class="cls-10" x="281.38" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-63" x="281.6" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-27" x="281.82" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-25" x="282.05" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-66" x="282.27" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-22" x="282.49" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-37" x="282.71" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-64" x="282.93" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-52" x="283.15" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-69" x="283.37" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-55" x="283.59" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-39" x="283.81" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-20" x="284.04" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-76" x="284.26" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-76" x="284.48" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-36" x="284.7" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-49" x="284.92" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-6" x="285.14" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-68" x="285.36" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-8" x="285.58" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-40" x="285.8" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-13" x="286.02" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-13" x="286.25" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-73" x="286.47" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-46" x="286.69" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-34" x="286.91" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-44" x="287.13" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-15" x="287.35" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-31" x="287.57" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-16" x="287.79" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-33" x="288.01" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-56" x="288.24" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-32" x="288.46" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-51" x="288.68" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-51" x="288.9" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-28" x="289.12" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-12" x="289.34" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-57" x="289.56" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-50" x="289.78" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-50" x="290" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-18" x="290.23" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-42" x="290.45" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-72" x="290.67" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-41" x="290.89" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-59" x="291.11" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-24" x="291.33" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-58" x="291.55" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-75" x="291.77" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-7" x="291.99" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-7" x="292.22" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-65" x="292.44" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-65" x="292.66" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-19" x="292.88" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-48" x="293.1" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-21" x="293.32" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-62" x="293.54" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-9" x="293.76" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-17" x="293.98" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-35" x="294.2" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-35" x="294.43" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-67" x="294.65" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-77" x="294.87" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-11" x="295.09" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-30" x="295.31" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-3" x="295.53" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-70" x="295.75" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-26" x="295.97" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-2" x="296.19" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-29" x="296.42" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-74" x="296.64" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-74" x="296.86" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-47" x="297.08" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-45" x="297.3" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-60" x="297.52" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-61" x="297.74" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-43" x="297.96" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-14" x="298.18" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-4" x="298.41" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-38" x="298.63" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-38" x="298.85" y="268.91" width=".22" height="11.35" />
                                    <rect class="cls-38" x="299.07" y="268.91" width=".22" height="11.35" />
                                </g>
                            </g>
                            <polygon class="cls-5" points="288.96 268.91 299.26 268.9 286.67 272.33 288.96 268.91" />
                        </g>
                        <g>
                            <path class="cls-38" d="M311.89,268.16c1.94.31,3.87.5,5.83.5.56,0,1.76,0,1.76-1.03,0-.76-.31-.92-3.36-.92-1.57,0-3.29-.68-3.29-3.12,0-3.36,1.36-6.09,3.41-6.83,1.14-.42,2.8-.46,4.06-.46,1.7,0,4.45.11,6.94.59l-1.07,4.83c-1.65-.26-3.29-.48-4.96-.48-2.03,0-2.5.37-2.5,1.11,0,.63.34.81,3.02.81,1.94,0,3.65.11,3.65,3.39,0,1.22-.44,2.93-.8,3.67-1.06,2.18-2.76,2.6-6.12,2.6-2.73,0-5.04-.2-7.31-.72l.77-3.93Z" />
                            <path class="cls-38" d="M329.04,256.76h5.27l-2.06,10.24h5.28l-1.09,5.35h-10.53l3.14-15.59Z" />
                            <path class="cls-38" d="M345.12,256.76h9.12v15.59h-5.25v-2.27h-4.29l-1.06,2.27h-5.83l7.31-15.59ZM348.99,260.71h-.03l-2.69,5.61h2.73v-5.61Z" />
                            <path class="cls-38" d="M356.06,268.16c1.94.31,3.87.5,5.83.5.56,0,1.76,0,1.76-1.03,0-.76-.31-.92-3.36-.92-1.57,0-3.29-.68-3.29-3.12,0-3.36,1.36-6.09,3.41-6.83,1.14-.42,2.79-.46,4.06-.46,1.7,0,4.45.11,6.94.59l-1.07,4.83c-1.65-.26-3.29-.48-4.96-.48-2.03,0-2.51.37-2.51,1.11,0,.63.34.81,3.02.81,1.94,0,3.65.11,3.65,3.39,0,1.22-.44,2.93-.8,3.67-1.06,2.18-2.76,2.6-6.12,2.6-2.73,0-5.04-.2-7.31-.72l.77-3.93Z" />
                            <path class="cls-38" d="M373.15,256.76h5.18l-1.18,5.66h3.19l1.18-5.66h5.18l-3.25,15.59h-5.18l1.16-5.57h-3.19l-1.16,5.57h-5.18l3.25-15.59Z" />
                        </g>
                    </g>
                    <g>
                        <path class="cls-38" d="M280,220.75c0,.24.04.45.13.63s.2.33.34.44c.15.12.31.21.51.26.19.06.39.09.61.09.09,0,.2,0,.31-.01.11,0,.22-.03.32-.05s.21-.06.31-.11c.1-.04.18-.1.26-.17.07-.07.14-.15.18-.24.04-.09.07-.2.07-.32s-.03-.24-.08-.33c-.06-.09-.13-.17-.23-.24-.1-.07-.22-.12-.35-.17-.14-.05-.28-.09-.44-.13-.16-.04-.33-.08-.5-.12s-.35-.08-.53-.13c-.27-.07-.54-.16-.79-.26s-.48-.23-.67-.39-.35-.35-.47-.59c-.12-.23-.17-.51-.17-.85,0-.25.04-.47.11-.67.07-.2.17-.39.3-.55.13-.16.28-.3.46-.42s.36-.22.57-.3c.2-.08.42-.14.64-.18.22-.04.44-.06.66-.06.25,0,.5.02.74.06.24.04.47.11.68.19.21.08.41.19.58.32.18.13.33.28.45.46s.22.38.29.6c.07.23.11.48.11.76h-1.57c-.01-.22-.05-.4-.12-.54-.07-.15-.16-.26-.28-.35-.12-.09-.26-.15-.42-.19-.16-.04-.33-.06-.53-.06-.13,0-.27.01-.4.04-.13.03-.25.07-.36.14-.11.06-.2.15-.26.25-.07.1-.1.23-.1.38,0,.09.01.17.03.24s.06.13.12.19c.06.06.14.11.24.16.1.05.23.1.39.15.16.05.35.11.58.17s.49.13.8.21c.09.02.21.05.35.08.14.03.29.08.44.14.16.06.31.14.47.24.16.1.3.22.43.36.13.14.23.32.31.52s.12.44.12.71c0,.33-.07.64-.2.92-.13.28-.33.53-.59.73-.26.21-.58.37-.96.48-.38.12-.82.17-1.32.17-.27,0-.54-.03-.8-.08-.26-.05-.5-.13-.73-.23s-.44-.23-.63-.38c-.19-.15-.36-.33-.5-.52s-.25-.42-.32-.67-.11-.52-.11-.82h1.57Z" />
                        <path class="cls-38" d="M290.38,218.54c-.02-.18-.08-.35-.18-.51-.09-.16-.21-.3-.35-.41-.14-.12-.3-.21-.48-.28-.18-.07-.37-.1-.56-.1-.23,0-.45.03-.64.1s-.35.15-.5.26c-.14.11-.27.25-.37.4-.1.15-.19.32-.25.5-.07.18-.11.38-.15.58-.03.2-.05.41-.05.62s.02.4.05.59c.03.2.08.38.15.56s.15.34.25.49.23.28.37.39c.14.11.31.2.5.26s.4.1.64.1.46-.04.65-.12.36-.19.49-.33c.14-.14.25-.29.33-.47.08-.18.14-.36.16-.55h1.52c-.04.42-.14.8-.31,1.14s-.39.64-.67.88c-.27.25-.6.44-.97.57s-.77.2-1.21.2c-.37,0-.71-.04-1.03-.13s-.61-.21-.87-.37c-.26-.16-.49-.36-.69-.58s-.37-.48-.51-.76c-.14-.28-.24-.57-.32-.89-.07-.32-.11-.65-.11-1s.04-.69.11-1.02c.07-.32.18-.63.32-.91.14-.28.31-.54.51-.76s.43-.43.69-.59c.26-.16.55-.29.87-.38.32-.09.66-.13,1.03-.13.39,0,.77.06,1.12.18.35.12.67.29.95.51.28.22.5.5.68.82s.29.7.34,1.11h-1.53Z" />
                        <path class="cls-38" d="M299.6,223.26h-1.67l-.61-1.72h-2.69l-.61,1.72h-1.67l2.8-7.2h1.66l2.8,7.2ZM295.07,220.36h1.8l-.89-2.52-.91,2.52Z" />
                        <path class="cls-38" d="M300.27,216.06h1.58l3.1,4.82h.01v-4.82h1.52v7.2h-1.59l-3.09-4.82h-.01v4.82h-1.52v-7.2Z" />
                        <path class="cls-38" d="M309.82,217.43v-1.37h5.69v1.37h-2.07v5.83h-1.59v-5.83h-2.03Z" />
                        <path class="cls-38" d="M315.9,219.66c0-.33.03-.65.1-.96.07-.31.17-.61.3-.89.13-.28.3-.54.5-.77s.44-.44.71-.6c.27-.17.57-.3.91-.4.34-.1.71-.14,1.11-.14s.78.05,1.12.14c.34.1.65.23.92.4.27.17.51.37.71.6s.38.49.51.77c.14.28.24.58.3.89.07.31.1.63.1.96s-.03.64-.1.96c-.07.31-.17.6-.3.88-.14.28-.31.53-.51.76-.2.23-.44.43-.71.6-.27.17-.58.3-.92.39-.34.09-.71.14-1.12.14s-.78-.05-1.11-.14c-.34-.09-.64-.22-.91-.39-.27-.17-.51-.37-.71-.6-.2-.23-.37-.49-.5-.76-.13-.28-.23-.57-.3-.88s-.1-.63-.1-.96ZM317.49,219.66c0,.29.04.58.11.87s.2.54.36.77c.17.23.38.41.64.55.26.14.57.21.94.21s.69-.07.95-.21c.26-.14.48-.32.65-.55.17-.23.29-.48.36-.77s.11-.57.11-.87c0-.2-.02-.39-.05-.59-.03-.2-.08-.38-.16-.56s-.16-.34-.27-.5c-.11-.15-.24-.29-.4-.4-.15-.11-.33-.2-.53-.27s-.42-.1-.67-.1-.46.03-.66.1c-.2.06-.37.15-.52.27s-.28.25-.39.4c-.11.15-.2.32-.27.5-.07.18-.12.36-.16.56-.03.2-.05.39-.05.59Z" />
                        <path class="cls-38" d="M328.58,223.26l-2.51-7.2h1.72l1.59,4.97,1.6-4.97h1.69l-2.54,7.2h-1.53Z" />
                        <path class="cls-38" d="M335.01,216.06v7.2h-1.57v-7.2h1.57Z" />
                        <path class="cls-38" d="M341.9,216.06v1.3h-3.85v1.54h3.4v1.27h-3.4v1.81h3.87v1.28h-5.45v-7.2h5.43Z" />
                        <path class="cls-38" d="M345.78,223.26h-1.64l-1.91-7.2h1.61l1.17,4.98h.01l1.31-4.98h1.41l1.32,4.98h.01l1.17-4.98h1.61l-1.91,7.2h-1.64l-1.27-4.86-1.28,4.86Z" />
                        <path class="cls-38" d="M355.17,216.06h2.16l1.81,4.94h.01l1.71-4.94h2.15v7.2h-1.51v-5.1h-.03l-1.87,5.1h-1.01l-1.88-5.06h-.01v5.06h-1.52v-7.2Z" />
                        <path class="cls-38" d="M369.8,216.06v1.3h-3.85v1.54h3.4v1.27h-3.4v1.81h3.87v1.28h-5.45v-7.2h5.43Z" />
                        <path class="cls-38" d="M370.74,216.06h1.58l3.1,4.82h.01v-4.82h1.52v7.2h-1.59l-3.09-4.82h-.01v4.82h-1.52v-7.2Z" />
                        <path class="cls-38" d="M384.56,220.49c0,.47-.07.89-.2,1.25-.14.36-.34.66-.6.91-.26.24-.58.43-.96.55-.38.12-.81.19-1.29.19s-.95-.06-1.35-.18c-.39-.12-.73-.3-1-.53-.27-.24-.48-.54-.62-.9s-.21-.79-.21-1.28v-4.42h1.58v4.42c0,.25.03.47.09.67s.16.36.28.5c.13.14.29.24.5.32.2.07.44.11.73.11.26,0,.49-.04.67-.11.19-.07.34-.18.46-.32.12-.14.21-.3.26-.5s.08-.42.08-.67v-4.42h1.58v4.42Z" />
                    </g>
                    <g>
                        <path class="cls-38" d="M282.77,204.84v-6.7h-3.34c-1.02,0-1.84.15-2.45.45-.61.3-.92.85-.92,1.66,0,.66.21,1.17.63,1.55.42.37.94.56,1.56.56.33,0,.67-.03,1.03-.1.36-.06.72-.19,1.08-.37l.57,2.6c-.48.22-.96.37-1.46.47s-1.03.14-1.6.14c-.75,0-1.44-.1-2.08-.3-.64-.2-1.2-.5-1.67-.9s-.85-.9-1.12-1.51-.41-1.3-.41-2.11c0-1.7.59-2.93,1.76-3.71,1.18-.78,2.96-1.16,5.35-1.16h3.07v-.52c0-.38-.06-.75-.19-1.11s-.32-.67-.57-.94c-.26-.27-.58-.49-.97-.66-.39-.16-.85-.25-1.38-.25-1.13,0-2.12.16-2.97.49s-1.64.76-2.37,1.29l-1.12-2.46c.71-.53,1.64-1,2.78-1.42,1.14-.42,2.42-.63,3.84-.63.42,0,.84.04,1.25.11s.77.17,1.08.3l.93-1.15h3.91l-2.24,2.68c.47.51.84,1.09,1.11,1.72.26.64.4,1.31.4,2.02v9.96h-3.48Z" />
                        <path class="cls-38" d="M293.45,189.52v15.32h-3.56v-15.32h3.56ZM299.69,189.52v15.32h-3.56v-15.32h3.56Z" />
                        <path class="cls-38" d="M313.23,204.84v-9.52c0-1.09-.3-1.92-.89-2.46-.59-.55-1.4-.82-2.42-.82-.38,0-.76.05-1.13.15-.38.1-.72.24-1.04.41s-.6.37-.85.6c-.25.23-.43.46-.56.7l2.3,1.94c-.57.2-.99.51-1.29.92-.29.41-.44.96-.44,1.66v6.43h-3.56v-6.4c0-.58.14-1.1.41-1.56.27-.46.62-.81,1.04-1.07l-2.22-1.81c.18-.62.5-1.22.95-1.79.45-.57.99-1.09,1.63-1.53s1.37-.81,2.19-1.08c.82-.27,1.71-.41,2.66-.41,1.1,0,2.06.15,2.9.45.84.3,1.55.72,2.12,1.26s1.01,1.19,1.31,1.94.45,1.58.45,2.48v9.52h-3.56Z" />
                        <path class="cls-38" d="M323.9,199.45c0,1.04.22,1.78.66,2.22.44.44,1.06.66,1.86.66s1.52-.23,2.2-.7c.68-.47,1.2-1.06,1.55-1.79v-10.32h3.56v15.32h-3.18l-.38-1.89c-.2.27-.46.54-.78.81-.32.26-.67.5-1.07.71-.39.21-.82.38-1.3.51s-.97.19-1.48.19c-1.62,0-2.9-.45-3.82-1.34s-1.38-2.3-1.38-4.21v-10.1h3.56v9.93Z" />
                        <path class="cls-38" d="M347.9,204.84v-9.28c0-1.08-.31-1.93-.94-2.57s-1.49-.96-2.59-.96c-1.33,0-2.36.48-3.08,1.44-.72.96-1.08,2.26-1.08,3.9,0,.91.1,1.67.29,2.29.19.61.44,1.11.75,1.49s.65.66,1.03.82c.37.16.74.25,1.11.25s.71-.03,1.03-.08c.32-.05.66-.16,1.03-.33l.63,2.57c-.38.24-.81.41-1.27.52-.46.11-1.04.16-1.74.16-.82,0-1.61-.15-2.38-.45-.77-.3-1.45-.77-2.05-1.4-.6-.63-1.08-1.43-1.44-2.39-.36-.97-.53-2.12-.53-3.45,0-1.15.17-2.22.51-3.21.34-.99.82-1.86,1.46-2.6.64-.74,1.43-1.32,2.38-1.74.95-.42,2.04-.63,3.28-.63,1.17,0,2.2.16,3.09.49.89.33,1.64.78,2.24,1.35.6.57,1.06,1.25,1.37,2.04.31.79.46,1.62.46,2.52v9.25h-3.56Z" />
                        <path class="cls-38" d="M347.36,212.78c-1.31,0-2.32-.29-3.01-.86-.69-.57-1.04-1.32-1.04-2.23v-.57h-1.59v-2.49h4.65v3.09c0,.31.1.54.3.68.2.14.43.21.69.21.27,0,.51-.07.7-.21.19-.14.29-.36.29-.68v-3.09h3.12v3.07c0,.91-.36,1.66-1.07,2.23s-1.72.86-3.04.86Z" />
                        <path class="cls-38" d="M358.66,189.52v15.32h-3.56v-15.32h3.56Z" />
                        <path class="cls-38" d="M372.18,189.52h3.56v9.82c0,1.93-.47,3.39-1.4,4.36-.93.98-2.21,1.46-3.86,1.46-.53,0-1.02-.07-1.48-.21-.46-.14-.88-.31-1.27-.52-.39-.21-.74-.45-1.05-.71-.31-.26-.56-.52-.77-.78l-.38,1.89h-3.17v-15.32h3.56v10.32c.16.35.37.67.63.97.26.3.55.57.88.79.33.23.68.41,1.05.53.37.13.75.19,1.13.19,1.71,0,2.57-1.05,2.57-3.15v-9.66Z" />
                        <path class="cls-38" d="M382.85,199.45c0,1.04.22,1.78.66,2.22.44.44,1.06.66,1.86.66s1.52-.23,2.2-.7c.68-.47,1.2-1.06,1.55-1.79v-10.32h3.56v15.32h-3.18l-.38-1.89c-.2.27-.46.54-.78.81-.32.26-.67.5-1.07.71-.39.21-.82.38-1.3.51s-.97.19-1.48.19c-1.62,0-2.9-.45-3.82-1.34s-1.38-2.3-1.38-4.21v-10.1h3.56v9.93Z" />
                        <path class="cls-38" d="M388.62,212.78c-1.31,0-2.32-.29-3.01-.86-.69-.57-1.04-1.32-1.04-2.23v-.57h-1.59v-2.49h4.65v3.09c0,.31.1.54.3.68.2.14.43.21.69.21.27,0,.51-.07.7-.21.19-.14.29-.36.29-.68v-3.09h3.12v3.07c0,.91-.36,1.66-1.07,2.23s-1.72.86-3.04.86Z" />
                    </g>
                    <circle class="cls-38" cx="336.32" cy="133.06" r="38.3" />
                    <rect class="cls-38" x="100.84" y="85.5" width="142.46" height="142.46" rx="4.09" ry="4.09" />
                </g>
            </g>
        </svg>
    )
}

export default A6HorizaltalOrder