import { jsPDF } from 'jspdf'
export const generatePdfInvoiceTax = async (data,branchData,menu) => {
    console.log("data---------->",data)
    console.log("menu---------->",menu)


        const doc = new jsPDF();
        let yPosition = 115;
        let imageUrl = "./media/svg/icons/Design/SLASH-LOGO.png"
        let totalAfterDiscount = parseFloat(data?.total_amount).toFixed(2) - parseFloat(data?.discount_amount).toFixed(2);
        let lineText = "--------------------------------------------------------------------------------------------------------------------------------------------------------"
        doc.setFont('SukhumvitSet-Medium');
        doc.setFontSize(10);
        doc.addImage(imageUrl, 'PNG',95,15, 20, 20);
        doc.text(`ชื่อร้าน : ${branchData[0]?.shop_name}`,10, 45);
        doc.text(`สาขา : ${branchData[0]?.branch_name}`, 10, 50);
        doc.text(`ที่อยู่ : ${branchData[0]?.address}`, 10, 55);
        doc.text(`เบอร์โทรศัพท์ : ${branchData[0]?.tel}`, 10, 60);
        doc.text(`ใบเสร็จรับเงินและใบกำกับภาษี`, 80, 65);
        doc.text(`VAT EXCLUDED`, 80, 70);
        doc.text(`${lineText}`, 10, 75);

        doc.text(`วันที่ : ${data?.table_close}`, 10, 80);
        doc.text(`INV ID : ${data?.invoice_no}`, 10, 85);
        doc.text(`แคชเชียร์ : ${data?.employee_name}`, 10, 90);
        doc.text(`โต๊ะ : ${data?.table_name}`, 10, 95);
        doc.text(`โซน : ${data?.zone_name}`, 40, 95);

        doc.text(`${lineText}`, 10,100);
        doc.text(`ชำระเงินโดย : ${data?.employee_name}`, 10, 105);
        doc.text(`${lineText}`, 10,110);
    if (menu[0]) {
        menu[0].forEach(item => {
            const qty = `${item.qty} x ${item.menu_name} `;
            const unitprice = ` ${parseFloat(item.unit_price).toFixed(2)}`
            doc.text(qty, 10, yPosition);
            doc.text(unitprice, 180, yPosition);
            yPosition += 5;
        });
    }


        doc.text(`${lineText}`, 10, yPosition+5);
        doc.text(`ราคารวม`, 10, yPosition+10);
        doc.text(`${parseFloat(data?.sub_total_detail).toFixed(2)}`, 180, yPosition+10);

        doc.text(`ส่วนลดบิล`, 10, yPosition+15);
        doc.text(`- ${parseFloat(data?.discount_amount).toFixed(2)}`, 180, yPosition+15);
        doc.text(`${lineText}`, 10, yPosition+20);

        doc.text(`รวมหลังหักส่วนลด `, 10, yPosition+25);
        doc.text(`${parseFloat(totalAfterDiscount).toFixed(2)}`, 180, yPosition+25);

        doc.text(`ค่าบริการ (${data?.service_charge_rate})%`, 10, yPosition+30);
        doc.text(`${parseFloat(data?.service_charge).toFixed(2)}`, 180, yPosition+30);

        doc.text(`ราคารวมก่อน VAT `, 10, yPosition+35);
        doc.text(`${parseFloat(data?.sub_total).toFixed(2)}`, 180, yPosition+35);

        doc.text(`ภาษีมูลค่าเพิ่ม(${data?.vat_rate})%`, 10, yPosition+40);
        doc.text(`${parseFloat(data?.vat_amount).toFixed(2)}`, 180,yPosition+40);

        doc.text(`รวมทั้งสิ้น : `, 10, yPosition+45);
        doc.text(`${parseFloat(data?.total_amount).toFixed(2)}`, 180, yPosition+45);

        doc.text(`เงินที่ได้รับ :`, 10, yPosition+50);
        doc.text(`${parseFloat(data?.payment_cash_receive ?? data?.total_amount).toFixed(2)}`, 180, yPosition+50);

        doc.text(`เงินทอน : `, 10, yPosition+55);
        doc.text(`${parseFloat(data?.payment_cash_change ?? 0.00).toFixed(2)}`, 180, yPosition+55);

        doc.text(`${lineText}`, 10, yPosition+60);
        
        doc.text(`ลูกค้า : ${data?.customer_name}`, 10, yPosition+65);
        doc.text(`ที่อยู่ : ${data?.customer_address}`, 10, yPosition+70);
        doc.text(`หมายเลขโทรศัพท์ : ${data?.customer_phone}`, 10, yPosition+75);
        doc.text(`อีเมล : ${data?.customer_email}`, 10, yPosition+80);
        doc.text(`เลขประจำตัวผู้เสียภาษี : ${data?.customer_tax_id}`, 10, yPosition+85);
        const filename = `${data?.invoice_no}.pdf`;
        doc.save(filename);
    
}
