import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from '../service/role';
import { Modal } from "react-bootstrap"
import TextField from '@material-ui/core/TextField'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { jsPDF } from 'jspdf'
import '../../_metronic/i18n/Sarabun-Regular-normal.js';
import '../../_metronic/i18n/SukhumvitSet-Medium-normal.js';
import { generatePdfInvoiceTax } from './component/ButtonGenerateTaxInvoicePdf.js'
import { generatePdfInvoiceTaxFull } from './component/ButtonGenerateFullTaxInvoicePdf.js'
import EditIcon from '@material-ui/icons/Edit';

export const Transaction = () => {
  const buttonOnclick = { border: "2px solid", borderRadius: "10px", borderColor: "#8FC5FF" }
  const buttonDefault = { border: "2px solid", borderRadius: "10px", borderColor: "#F4F4F4" }
  const buttonStyle = { border: "2px solid", borderRadius: "5px", borderColor: "#F4F4F4", height: "80%", width: "100%", justifyContent: "flex-start" }
  const intl = useIntl();
  const suhbeader = useSubheader();
  const allData = [];


  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  suhbeader.setTitle(intl.formatMessage({ id: "113402", defaultMessage: "Transaction" }));
  const { authToken } = useSelector((state) => state.auth);
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [roleList, setRoleList] = useState([]);
  const [show, setShow] = useState(false);
  const [buttonRange, setButtonRange] = useState(buttonDefault);
  const [startDateShow, setStartDateShow] = useState(moment().format('YYYY-MM-DD'))
  const [endDateShow, setEndDateShow] = useState(moment().format('YYYY-MM-DD'))
  const [search, setSearch] = useState();

  const [totalSum, setTotalSum] = useState();
  const [canceled, setCanceled] = useState();
  const [completed, setCompleted] = useState();
  const [showModal, setShowModal] = useState(false);
  const [invoice_id, setInvoiceId] = useState();
  const [invoice_no, setTnvoiceNo] = useState();
  const [taxType, setTaxType] = useState();
  const [taxInvoiceData, setTaxInvoiceData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [menuDetail, setMenuDetail] = useState([]);
  const [cusTaxId, setCusTaxId] = useState();
  const [cusName, setCusName] = useState();
  const [cusAddress, setCusAddress] = useState();
  const [cusTel, setCusTel] = useState();
  const [cusEmail, setCusEmail] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [invoiceTaxId, setInvoiceTaxId] = useState();
  const [invoiceType, setInvoiceType] = useState();

  var d = new Date()
  d.setDate(d.getDate() - 30)
  const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))


  const history = useHistory();
  useEffect(() => {

    checkAuth()

    console.log("-------(rolelist)------->", roleList)
    getShopDetail()


  }, []);
  useEffect(() => {
    if(taxInvoiceData){
      console.log("taxInvoiceData after Click",taxInvoiceData)
    }
  }, [taxInvoiceData])

  useEffect(() => {
    if (menuDetail.length >= 0) {
      if (taxType === 1) {
        generatePdfInvoiceTax(taxInvoiceData, branchData, menuDetail);
      } else if (taxType === 2) {
        generatePdfInvoiceTaxFull(taxInvoiceData, branchData, menuDetail);
      }
    }
  }, [menuDetail]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkAuth = async () => {

    const data = await roleCheck('ACCOUNT')
    setRoleList(data)
    console.log(data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }
    let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
    setCampaigns_id_headder(campaigns_id_headders);

    let value = 1
    if (localStorage.getItem("userPage") != null) {
      value = localStorage.getItem("userPage")
    }

    var dataPie = {
      date_from: moment().format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD')
    }
    getInvoice(1, 10, dataPie, "all", campaigns_id_headders);
    setSearch("all")

  }
  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  const handleSearch = () => {
    setPage(1);
    var startDate = moment(dateFrom).format('YYYY-MM-DD')
    var endDate = moment(dateTo).format('YYYY-MM-DD')
    var dataPie = {
      date_from: startDate,
      date_to: endDate
    }
    getInvoice(1, 10, dataPie, search, campaigns_id_headder);
    setStartDateShow(startDate);
    setEndDateShow(endDate);
    handleClose();
  };

  const handleSelect = (event) => {
    console.log(event.target.value)
    var startDate = moment(dateFrom).format('YYYY-MM-DD')
    var endDate = moment(dateTo).format('YYYY-MM-DD')
    var dataPie = {
      date_from: startDate,
      date_to: endDate
    }
    getInvoice(1, 10, dataPie, event.target.value, campaigns_id_headder);
    setSearch(event.target.value)
  }
  const handleEditClick = () => {
    setIsEditMode(false);
  };
  const handleSaveClick = () => {
    setIsEditMode(false);
    if (cusTaxId === undefined || cusTaxId === "") {
      return AlertError("Please Insert Tax Payer ID.");
    } 
    if (cusName === undefined || cusName === "") {
      return AlertError("Please Insert Customer Name.");
    } 
    if (cusAddress === undefined || cusAddress === "") {
      return AlertError("Please Insert Customer Address.");
    } 
    if (cusEmail === undefined || cusEmail === "") {
      return AlertError("Please Insert Customer Email.");
    } 
    if (cusTel === undefined || cusTel === "") {
      return AlertError("Please Insert Customer Tel.");
    } 
    addCusTaxData();
  };

  

  const addCusTaxData = async () => {
    var events = {
      customer_phone: cusTel,
      customer_name: cusName,
      customer_address: cusAddress,
      customer_email: cusEmail,
      customer_tax_id: cusTaxId,
    };
    console.log("events========>", events)


    let apiUrls = `${baseURL}/tax/invoiceTaxDetail/${invoice_id}`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Tax Invoice Detail", "success");
        if(invoiceType === "dinein"){
        setDataInvoice(invoice_id)
        setIsEditMode(true)
      }else if (invoiceType === "takeaway"){
        setDataInvoiceTakeAway(invoice_id)
        setIsEditMode(true)

      }
        
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "Your can not create customer taxinvoice detail. !! " + err,
          "error"
        );
      });

  };

  const getInvoice = async (page, limit, data, search, campaigns_id_headders) => {
    console.log("*********(data getInvoice)*********")
    console.log(data)

    let lang = localStorage.getItem("Language");
    setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/transaction/renderInvoice?page=${page}&limit=${limit}&search=${search}&dateFrom=${data.date_from}&dateTo=${data.date_to}`,
      headers: {
        Accept: "application/json",
        "Accept-Language": (lang ? lang : "en"),
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        console.log(res.data.data)
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            invoice_id: data.invoice_no,
            status: data.status,
            total_amount: data.total_amount,
          };
          allData.push(data);
        });
        console.log("-------(allData)------>", allData)
      }

      var totalpage = Math.ceil(res.data.total / res.data.limit);
      console.log("totalsum=========>", res.data.sum)
      setTotalSum(res.data.sum);
      setCompleted(res.data.complete);
      setCanceled(res.data.canceled);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.limit);
      setUserData(allData);
    });
  };

  const checkNull = (data) => {
    if (data) {
      return data
    }
    return intl.locale == 'th' ? 'ไม่มีข้อมูล' : 'No Data'
  }
  const handleChangeDateFrom = date => {
    setDateFrom(date)
  }
  const handleChangeDateTo = date => {
    setDateTo(date)
  }

  const currencyFormatter = (cell, row) => {
    return `฿ ${cell}`;
  };
  const getShopDetail = async () => {
    axios({
      method: "get",
      url: `${baseURL}/management/shopBranchDetail`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        const branchData = {
          address: res.data.data[0].address,
          tel: res.data.data[0].tel,
          branch_name: res.data.data[0].name,
          shop_name: res.data.data[1].name,
          tax_id: res.data.data[0].tax_id
        };
        console.log("type0f======>", branchData)
        setBranchData(prevData => [...prevData, branchData]);
      }
      console.log("taxinvoice_branch===>", taxInvoiceData)
    });
  };
  const checkInvoiceTax = (id) => {
    console.log("taxInvoiceDatabefore===>", taxInvoiceData)
    let lang = localStorage.getItem("Language");
    axios({
      method: "get",
      url: `${baseURL}/transaction/getInvoiceData/${id}`,
      headers: {
        Accept: "application/json",
        "Accept-Language": (lang ? lang : "en"),
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {

        history.push("/logout");
      } else {
        console.log("checkInvoiceTax Response====>", res.data.data.invoice_tax_id)
        if (res.data.data.invoice_tax_id && res.data.data.invoice_tax_id !== null) {
          if (res.data.data.is_dinein_order === true && res.data.data.is_takeaway_order === false ) {
            setDataInvoice(id)
            setInvoiceType("dinein")
            setInvoiceTaxId(res.data.data.invoice_tax_id)
            setIsEditMode(true)
            setCusTaxId()
            setCusName()
            setCusAddress()
            setCusTel()
            setCusEmail()
        }else if(res.data.data.is_dinein_order === false && res.data.data.is_takeaway_order === true) {
            setDataInvoiceTakeAway(id)
            setInvoiceType("takeaway")
            setInvoiceTaxId(res.data.data.invoice_tax_id)
            setIsEditMode(true)
            setCusTaxId()
            setCusName()
            setCusAddress()
            setCusTel()

        }

          

        } else {
          setIsEditMode(false)

        }
      }
    })


  }
  const setDataInvoice = (id) => {

    console.log("taxInvoiceDatabefore===>", taxInvoiceData)
    let lang = localStorage.getItem("Language");
    axios({
      method: "get",
      url: `${baseURL}/transaction/bill/table/segment/${id}`,
      headers: {
        Accept: "application/json",
        "Accept-Language": (lang ? lang : "en"),
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {

        history.push("/logout");
      } else {
        console.log('response tax===========>', res.data.data)
        const invoiceData = {
          invoice_no: res.data.data.invoice_no,
          vat_rate: res.data.data.vat_rate,
          vat_amount: res.data.data.vat_amount,
          service_charge_rate: res.data.data.service_charge_rate,
          service_charge: res.data.data.service_charge,
          total_amount: res.data.data.total_amount,
          discount_amount: res.data.data.discount_amount,
          customer_phone: res.data.data.invoice_tax.customer_phone,
          customer_name: res.data.data.invoice_tax.customer_name,
          customer_address: res.data.data.invoice_tax.customer_address,
          customer_email: res.data.data.invoice_tax.customer_email,
          customer_tax_id: res.data.data.invoice_tax.customer_tax_id,
          table_close: res.data.data.table_close,
          sub_total: res.data.data.sub_total,
          sub_total_detail: res.data.data.sub_total_detail,
          payment_amount: res.data.data.invoice_payment.payment_amount,
          payment_cash_receive: res.data.data.invoice_payment.payment_cash_receive,
          payment_cash_change: res.data.data.invoice_payment.payment_cash_change,
          employee_name: res.data.data.employee_name,
          table_name: res.data.data.table_name,
          zone_name: res.data.data.zone_name

        };
        setCusTaxId(res.data.data.invoice_tax.customer_tax_id)
        setCusName(res.data.data.invoice_tax.customer_name)
        setCusAddress(res.data.data.invoice_tax.customer_address)
        setCusTel(res.data.data.invoice_tax.customer_phone)
        setCusEmail(res.data.data.invoice_tax.customer_email)

        console.log("invoiceData=======>", invoiceData)
        setTaxInvoiceData(invoiceData)

      }
    })

  }
  const setDataInvoiceTakeAway = (id) => {

    console.log("taxInvoiceDatabefore===>", taxInvoiceData)
    let lang = localStorage.getItem("Language");
    axios({
      method: "get",
      url: `${baseURL}/transaction/bill/takeaway/${id}`,
      headers: {
        Accept: "application/json",
        "Accept-Language": (lang ? lang : "en"),
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {

        history.push("/logout");
      } else {
        console.log('response tax===========>', res.data.data)
        const invoiceData = {
          invoice_no: res.data.data.invoice_no,
          vat_rate: res.data.data.vat_rate,
          vat_amount: res.data.data.vat_amount,
          service_charge_rate: res.data.data.service_charge_rate,
          service_charge: res.data.data.service_charge,
          total_amount: res.data.data.total_amount,
          discount_amount: res.data.data.discount_amount,
          customer_phone: res.data.data.invoice_tax.customer_phone,
          customer_name: res.data.data.invoice_tax.customer_name,
          customer_address: res.data.data.invoice_tax.customer_address,
          customer_email: res.data.data.invoice_tax.customer_email,
          customer_tax_id: res.data.data.invoice_tax.customer_tax_id,
          table_close: res.data.data.invoice_date,
          sub_total: res.data.data.sub_total,
          sub_total_detail: res.data.data.sub_total_detail,
          payment_amount: res.data.data.invoice_payment.payment_amount,
          payment_cash_receive: res.data.data.invoice_payment.payment_cash_receive,
          payment_cash_change: res.data.data.invoice_payment.payment_cash_change,
          employee_name: res.data.data.employee_name,
          table_name: "สั่งกลับบ้าน",
          zone_name: "-"

        };
        setCusTaxId(res.data.data.invoice_tax.customer_tax_id)
        setCusName(res.data.data.invoice_tax.customer_name)
        setCusAddress(res.data.data.invoice_tax.customer_address)
        setCusTel(res.data.data.invoice_tax.customer_phone)
        setCusEmail(res.data.data.invoice_tax.customer_email)

        console.log("invoiceData=======>", invoiceData)
        setTaxInvoiceData(invoiceData)

      }
    })

  }
  const updateInvoice = (id) => {
    console.log("id=======>",id)
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this invoice !",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      let lang = localStorage.getItem("Language");
      axios({
        method: "put",
        url: `${baseURL}/transaction/deleteInvoice/${id}`,
        headers: {
          Accept: "application/json",
          "Accept-Language": (lang ? lang : "en"),
          Authorization: `Bearer ${authToken}`,
        },
      }).then((res) => {
        Swal.fire("Deleted!", `Invoice ${invoice_no} has been deleted.`, "success");
        window.location.reload();
      }).catch((err) => {
        Swal.fire(
          "Deleted!",
          `Invoice ${invoice_no} has been fail to deleted.`,
          "error"
        );
    });
    })

   

    


  }

  const getDataInvoiceDetail = (id) => {
    setTaxType(id)
    let lang = localStorage.getItem("Language");
    axios({
      method: "get",
      url: `${baseURL}/transaction/details/${invoice_id}`,
      headers: {
        Accept: "application/json",
        "Accept-Language": (lang ? lang : "en"),
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        console.log('response===========>', res.data.data.length)
        if(res.data.data.length>0){
          const menus = res.data.data[0].menus;

        const menus_detail = menus.map(menu => ({
          qty: menu.qty,
          menu_name: menu.menu_name,
          unit_price: menu.unit_price
        }));
        setMenuDetail(prevData => {
          const menu_details = [...prevData, menus_detail];
          console.log("menu_detail=======>", menu_details);
          return menu_details;
        });

        }else{
          setMenuDetail([]);

        }
        
      }
    })

  }



  const columns = [

    {
      dataField: "invoice_id",
      text: intl.formatMessage({ id: "113403", defaultMessage: "INVOICE ID" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "total_amount",
      text: intl.formatMessage({ id: "113404", defaultMessage: "TOTAL AMOUNT" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: currencyFormatter
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "113405", defaultMessage: "STATUS" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row) => {
        if (row.status === 'COMPLETED') {
          return <span style={{ backgroundColor: '#D4FFE0', color: '#048946', padding: '5px', borderRadius: '3px' }}>{row.status}</span>;
        } else if (row.status === 'CANCELED') {
          return <span style={{ backgroundColor: '#FFDAD2', color: '#D82900', padding: '5px', borderRadius: '3px' }}>{row.status}</span>;
        }
      }

    },
    {
      text: intl.formatMessage({ id: "109034", defaultMessage: "default content" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {roleList.includes('EDIT') && row.status === 'COMPLETED' &&
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => handlePrint(id, row)}
              >

                {intl.formatMessage({ id: "113406", defaultMessage: "tax invoice" })}
              </button>

            }

            {roleList.includes('DELETE') && row.status === 'COMPLETED' && <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleDelete(id)}
            >
              {intl.formatMessage({ id: "109005", defaultMessage: "delete" })}
            </button>}

          </div>
        );
      },
    },
  ];
  const handlePrint = (id, data) => {
    console.log("*****print data*****", id)
    console.log("********invoice_id*******", invoice_id)
    setInvoiceId(id)
    setShowModal(true)
    setTnvoiceNo(data.invoice_id)
    checkInvoiceTax(id)
  }
  const handleDelete = (id) => {
    updateInvoice(id);
  }
  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "113402", defaultMessage: "Transaction" })}>
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>

        <div className="row form-inline mb-2">



          <button
            type="button"
            id="button"
            className="btn mb-3 mr-3 ml-3 mt-1"
            style={buttonRange}
            onClick={() => handleShow()}
          >
            <CalendarTodayIcon style={{ fontSize: "small", paddingBottom: "2px" }} />
            {moment(startDateShow).format('DD MMMM YYYY')} - {moment(endDateShow).format('DD MMMM YYYY')}
          </button>

          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header>
              <div className='h3'>
                Select Date
              </div>
            </Modal.Header>
            <Modal.Body>
              <TextField
                id="DateFrom"
                type="date"
                label={intl.formatMessage({ id: "102011", defaultMessage: "Date From" })}
                defaultValue={startDateShow}
                className=" mb-3 mr-3 ml-3"
                style={{
                  height: '38px',
                  width: '185px',
                  backgroundColor: '#F4F6F9',
                  borderRadius: '5px',
                  borderWidth: '0'
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={name => {
                  handleChangeDateFrom(name.target.value)
                }}
              />

              <TextField
                id="DateFrom"
                type="date"
                label={intl.formatMessage({ id: "102012", defaultMessage: "Date To" })}
                defaultValue={endDateShow}
                className=" mb-3 mr-3"
                style={{
                  height: '38px',
                  width: '185px',
                  backgroundColor: '#F4F6F9',
                  borderRadius: '5px',
                  borderWidth: '0'
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={name => {
                  // handleChangeDateTo(name.target.value)
                }}
              />
              <div class="row justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary mb-3 mr-5 mt-1"
                  onClick={() => handleClose()}
                >
                  {intl.formatMessage({ id: "103017", defaultMessage: "Cancel" })}
                </button>
                <button
                  type="button"
                  id="button"
                  className="btn btn-primary mb-3 mr-3 ml-3 mt-1"
                  onClick={() => handleSearch()}
                >
                  {intl.formatMessage({ id: "102013", defaultMessage: "Search" })}
                </button>


              </div>

            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
          <select
            className="btn mb-3 mr-3 ml-3 mt-1"
            value={search}
            style={buttonRange}
            onChange={handleSelect} >

            <option value="all">All</option>
            <option value="completed">Completed</option>
            <option value="canceled">Cancelled</option>

          </select>
        </div>
        <div className="row form-inline">
          <div className="col-2 col-sm-2 mr-3" style={{ minWidth: "250px" }}  >
            <div><span className="h6 mb-5 mt-5"> Summary </span></div>
            <div><span style={{ color: "#858585", }}>{moment(startDateShow).format('DD MMMM YYYY')} - {moment(endDateShow).format('DD MMMM YYYY')}</span></div>
          </div>
          <div className="col-3 col-sm-3 ">
            <CardBody
              className="card-style pt-5 pb-5"
              style={buttonStyle}
            >
              <span className="mb-5 mt-5"
                style={{
                  fontSize: "1em",
                  color: "#858585",
                }}
              >
                Net sales
              </span>
              <div><span className="h6">{totalSum}</span></div>
            </CardBody>
          </div>
          <div className="col-3 col-sm-3">
            <CardBody
              className="card-style pt-5 pb-5"
              style={buttonStyle}
            >
              <span className="mb-5 mt-5"
                style={{
                  fontSize: "1em",
                  color: "#858585",
                }}
              >
                Completed orders
              </span>
              <div><span className="h6">{completed}</span></div>
            </CardBody>
          </div>
          <div className="col-3 col-sm-3">
            <CardBody
              className="card-style  pt-5 pb-5"
              style={buttonStyle}
            >
              <span className="mb-5 mt-5"
                style={{
                  fontSize: "1em",
                  color: "#858585",
                }}
              >
                Cancelled orders
              </span>
              <div><span className="h6">{canceled}</span></div>
            </CardBody>
          </div>
        </div>
        <div className="table-wrapper" style={{ height: '400px', overflowY: 'auto' }}>
          <BootstrapTable
            keyField="id"
            data={userData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden "
            bootstrap4

          />
          <Modal show={showModal} onHide={closeModal}>
            {isEditMode ? (
              <Modal.Header closeButton>
                <div className="row"  >
                  <Modal.Title>
                    <div className="mt-2 ml-3">{intl.formatMessage({ id: "113416", defaultMessage: "Tax Invoice Detail" })}</div>
                    <div className="mt-2 ml-3">{intl.formatMessage({ id: "113403", defaultMessage: "Invoice ID" })} : {invoice_no} </div>
                  </Modal.Title>


                </div>
                <div className="ml-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button className="btn btn-primary" onClick={() => handleEditClick()} >
                    <EditIcon style={{ paddingBottom: "2px", color: "#ffffff" }} /> Edit
                  </button>
                </div>


              </Modal.Header>
            ) : (
              <Modal.Header closeButton>
                <div className="row"  >
                  <Modal.Title>
                    <div className="mt-2 ml-3">{intl.formatMessage({ id: "113416", defaultMessage: "Tax Invoice Detail" })}</div>
                    <div className="mt-2 ml-3">{intl.formatMessage({ id: "113403", defaultMessage: "Invoice ID" })} : {invoice_no} </div>
                  </Modal.Title>


                </div>
  


              </Modal.Header>

            )}

            <Modal.Body>
              {isEditMode ? (
                <div className="row">
                  <div className="container mt-3 mb-5">
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113411", defaultMessage: "Taxpayer Identification No" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} maxLength={13} value={cusTaxId} readOnly  />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113415", defaultMessage: "Customer Tax Invoice Address" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="text" value={cusName} readOnly  />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113413", defaultMessage: "Customer Tax Invoice Address" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="text" value={cusAddress} readOnly />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113414", defaultMessage: "Customer Tel." })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="text" maxLength={10} value={cusTel} readOnly  />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "108023", defaultMessage: "Email" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="email" value={cusEmail} readOnly  />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="container mt-3 mb-5">
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113411", defaultMessage: "Taxpayer Identification No" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} maxLength={13}  onChange={(e) => setCusTaxId(e.target.value)} />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113415", defaultMessage: "Customer Tax Invoice Address" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="text"  onChange={(e) => setCusName(e.target.value)} />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113413", defaultMessage: "Customer Tax Invoice Address" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="text"  onChange={(e) => setCusAddress(e.target.value)} />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "113414", defaultMessage: "Customer Tel." })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="text" maxLength={10}  onChange={(e) => setCusTel(e.target.value)} />
                    </div>
                    <div className="form-inline">
                      <span className="mr-5">{intl.formatMessage({ id: "108023", defaultMessage: "Email" })} :</span>
                      <input className="col-6" style={{ borderRadius: "10px" }} type="email"  onChange={(e) => setCusEmail(e.target.value)} />
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              {isEditMode ? (
                 <div className="row">
                 <div className="container mt-5">
                   <button className="btn btn-primary mr-2" onClick={() => getDataInvoiceDetail(1)}>TAX INVOICE (ABB)</button>
                   <button className="btn btn-primary mr-2" onClick={() => getDataInvoiceDetail(2)}>TAX INVOICE</button>
                   <button variant="secondary" className="btn btn-primary mr-2" onClick={()=>closeModal()}>Close</button>
                 </div>
               </div>

              ) : (
                <div className="row">
                  <div className="container mt-5">
                    <button className="btn btn-primary mr-2" onClick={()=>handleSaveClick()}>Save</button>
                    <button className="btn btn-secondary mr-2" onClick={()=>closeModal()}>Cancel</button>
                  </div>
                </div>
              )}

            </Modal.Footer>
          </Modal>
        </div>

      </CardBody>
    </Card>
  );
};
