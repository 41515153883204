import { jsPDF } from 'jspdf'
import 'jspdf-autotable';

export const generatePdfOrder = async (data, branchData,listData) => {
    console.log("data---------->", data)
    console.log("branchdata---------->", branchData)
    console.log("listdata---------->", listData)
    const date = new Date();
    const options = { day: 'numeric', month: 'numeric', year: 'numeric'  };
    const formattedDate = date.toLocaleDateString(undefined, options);


    const sum_total = listData.reduce((data, item) => {
        return data + item.price_per_unit;
    }, 0);
    const sum_qty = listData.reduce((data, item) => {
        return data + item.qty;
    }, 0);
    
    const transformedData = listData.map((item, index) => {
        return {
            ...item,
            number: index + 1
        };
    });


        const doc = new jsPDF();
        let yPosition = 110;
        let yPositionEnd ;
        let lineText = "............................................"
        doc.setFont('SukhumvitSet-Medium');
       
        doc.setFontSize(10);
        doc.text(`ชื่อบริษัท : ${branchData[0]?.shop_name}`,15, 20);
        doc.text(`สาขา : ${branchData[0]?.branch_name}`, 80, 20);
        doc.text(`ที่อยู่ : ${branchData[0]?.address}`, 15, 25);
        doc.text(`เลขประจำตัวผู้เสียภาษี : ${branchData[0]?.tax_id}`, 15, 30);

        doc.setFontSize(20);
        doc.text(`ใบสั่งซื้อ: Purchase Order (PO)`,60, 50);

        doc.setFontSize(10);
        doc.text(`เลขที่ : ${data[0]?.invoice_no}`,150,60);
        doc.text(`วันที่ : ${formattedDate}`,150, 65);
        
        doc.text(`ผู้จำหน่าย : ${data[0]?.supplier}`, 15, 60);
        doc.text(`ที่อยู่ : ${data[0]?.address}`, 15, 65);
        doc.text(`เลขประจำตัวผู้เสียภาษี : ${data[0]?.tax_id}`, 15,70 );
        doc.text(`โทร : ${data[0]?.po_tel == undefined?"-":data[0]?.po_tel}`, 15,75 );
        doc.text(`อ้างอิง : ${data[0]?.pr_no}`, 15,80 );

        doc.setFont('SukhumvitSet-Medium');
        const columns = [
            { header: 'ลำดับ', dataKey: 'no' },
            { header: 'รายการ', dataKey: 'listData_name' },
            { header: 'จำนวน', dataKey: 'qty' },
            { header: 'ราคาต่อหน่วย', dataKey: 'unit_price' },
        ];

        const rows = transformedData.map(item => ({
            no: item.number,
            listData_name: item.name,
            qty: item.qty+"("+item.unit+")",
            unit_price: parseFloat(item.price_per_unit).toFixed(2),
        }));
        const rowCount = rows.length;
        yPositionEnd = yPosition+((rowCount+1)*10)

        const columnWidths = [30, 75, 40, 40];

      
          // Define additional row with qty and unit_price
          const table2Row = [
            [`หมายเหตุ:${data[0]?.po_condition}`, "รวมเป็นเงิน", `${parseFloat(data[0]?.pre_vat).toFixed(2)}`],
            ["", "จำนวนภาษีมูลค่าเพิ่ม 7%", `${parseFloat(data[0]?.vat).toFixed(2)}`],
            ["", "จำนวนเงินรวมทั้งสิ้น", `${parseFloat(data[0]?.grand_total).toFixed(2)}`],
        ];
    


        try {
        doc.autoTable({
            startY: yPosition,
            
            head: [columns.map(col => col.header)],
            body: rows.map(row => Object.values(row)),
            theme: 'grid',
            headStyles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 12,
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255]
            },
            styles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                total: { halign: 'right' }, // Align 'จำนวนเงิน' column to the right
                0: { cellWidth: columnWidths[0] },
                1: { cellWidth: columnWidths[1] },
                2: { cellWidth: columnWidths[2],halign: 'right' },
                3: { cellWidth: columnWidths[3] },
              },
        });

        const finalY = doc.lastAutoTable.finalY;
        doc.autoTable({
            theme: 'grid',
            head: [],
            body: table2Row,
            startY: finalY,
            styles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                0: { cellWidth: columnWidths[0] + columnWidths[1],halign: 'left' }, // Merging two columns
                1: { cellWidth: columnWidths[2],halign: 'right' },
                2: { cellWidth: columnWidths[3],halign: 'center' },
              },
          });
    




    doc.text(`${lineText}`, 10,260)
    doc.text(`(${lineText})`, 10,270)
    doc.text(`ผู้สั่งซื้อสินค้า`, 20,275)
    doc.text(`วันที่`, 10,280)
    doc.text(`${lineText}`, 15,280)



    doc.text(`${lineText}`, 150,260)
    doc.text(`(${lineText})`, 150,270)
    doc.text(`ผู้รับมอบอำนาจ`, 160,275)
    doc.text(`วันที่`, 150,280)
    doc.text(`${lineText}`, 150,280)
    

   

        const filename = `${data[0]?.invoice_no}.pdf`;
        doc.save(filename);
    } catch (error) {
        console.error('Error while generating PDF:', error);
    }
    
}

