import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditShop = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  const classes = useStyles();
  suhbeader.setTitle(
    intl.formatMessage({ id: "102007", defaultMessage: "Shop/Branch" })
  );
  const history = useHistory();
  const [name, setname] = useState();
  const [name_en, setname_en] = useState();
  const [description, setdescription] = useState();
  const [description_en, setdescription_en] = useState();
  const [address, setaddress] = useState();
  const [address_en, setaddress_en] = useState();
  const [fullsize, setFullsize] = useState(false);
  const [enableCN, setenableCN] = useState(false);
  const [enableStock, setenableStock] = useState(false);
  const [autoPrint, setAutoPrint] = useState(false);
  const [callStaff, setCallStaff] = useState(false);
  const [enableFeedback, setEnableFeedback] = useState(false);
  const [efeedbackLink, setEfeedbackLink] = useState('');
  const [enableShowOrder, setEnableShowOrder] = useState(false);
  const [enableQueueNo,setEnableQueueNo]= useState(false);
  const AlertError = (message) => {
    Swal.fire("EditShop!", message, "error");
  };

  const handleSend = async () => {
    if (name === undefined || name === "") {
      return AlertError("Please insert name th !!");
    }
    // if (name_en === undefined || name_en === "") {
    //     return AlertError("Please insert Restaurant branch !!");
    // }
    // if (description === undefined || description === "") {
    //     return AlertError("Please insert description th !!");
    // }
    // if (description_en === undefined || description_en === "") {
    //     return AlertError("Please insert Restaurant branch !!");
    // }
    // if (address === undefined || address === "") {
    //     return AlertError("Please insert address th !!");
    // }
    // if (address_en === undefined || address_en === "") {
    //     return AlertError("Please insert Restaurant branch !!");
    // }
    if (enableFeedback && (efeedbackLink === undefined || efeedbackLink === '') ) {
        return AlertError("Please insert E-feedback link in menu edit branch !!");
    }

    var events = {
      name: name,
      name_en: name_en,
      description: description,
      description_en: description_en,
      address: address,
      address_en: address_en,
      is_enable_cn: enableCN,
      is_enable_transaction: enableStock,
      is_auto_print_order: autoPrint ? autoPrint: false,
      is_enable_calling: callStaff,
      is_enable_feedback: enableFeedback,
      is_enable_showorder: enableShowOrder,
      is_queue_order: enableQueueNo,
    };

    let apiUrls = `${baseURL}/management/shop`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Shop success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire("Save !", "Your can not Shop. !! " + err, "error");
      });
  };

  useEffect(() => {
    getShop();
    getBranch();
  }, []);

  const getShop = async () => {
    axios({
      method: "get",
      url: `${baseURL}/management/shop`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        setname(res.data.data.name);
        setname_en(res.data.data.name_en);
        setdescription(res.data.data.description);
        setdescription_en(res.data.data.description_en);
        setaddress(res.data.data.address);
        setaddress_en(res.data.data.address_en);
        setenableCN(res.data.data.is_enable_cn)
        setenableStock(res.data.data.is_enable_transaction)
        setAutoPrint(res.data.data.is_auto_print_order)
        setCallStaff(res.data.data.is_enable_calling)
        setEnableFeedback(res.data.data.is_enable_feedback)
        setEnableShowOrder(res.data.data.is_enable_showorder)
        setEnableQueueNo(res.data.data.is_queue_order)
      }
    });
  };

  const getBranch = async () => {
      axios({
          method: "get",
          url: `${baseURL}/management/branch`,
          headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
          },
      }).then((res) => {
          if (res.message) {
              history.push("/logout");
          } else {
              setEfeedbackLink(res.data.data.e_feedback_link);
          }
      });
  };

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "108037",
          defaultMessage: "Edit Shop",
        })}
      ></CardHeader>
      <CardBody>
        <div className="container">
        <div className="container" style={{border:"1px solid",borderRadius:"20px",borderColor:"#CECDCD",marginBottom: "20px",marginTop: "20px"}}>
          <div className="h6" style={{ paddingTop:"20px" }}> 
        
              {intl.formatMessage({
                id: "113301",
                defaultMessage: "shop detail",
              })}
            
            </div>
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              <span style={{ color: "red" }}>*</span>
              {intl.formatMessage({
                id: "108005",
                defaultMessage: "Thai name:",
              })}
              :{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(name) => setname(name.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({
                id: "108006",
                defaultMessage: "English name:",
              })}
              :{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name_en}
              onChange={(name_en) => setname_en(name_en.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "108007", defaultMessage: "Details:" })}
              :{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(description) =>
                setdescription(description.target.value)
              }
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({
                id: "108008",
                defaultMessage: "English details:",
              })}
              :{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description_en}
              onChange={(description_en) =>
                setdescription_en(description_en.target.value)
              }
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "108009", defaultMessage: "Address:" })}
              :{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={address}
              onChange={(address) => setaddress(address.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({
                id: "108010",
                defaultMessage: "English address:",
              })}
              :{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={address_en}
              onChange={(address_en) => setaddress_en(address_en.target.value)}
            />
          </div>
          </div>
          <div className="container" style={{border:"1px solid",borderRadius:"20px",borderColor:"#CECDCD",marginBottom: "20px",marginTop: "20px"}}>
          <div className="h6" style={{ paddingTop:"20px" }}> 
        
              {intl.formatMessage({
                id: "113302",
                defaultMessage: "Language option",
              })}
            
            </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113290", defaultMessage: "Chinese menu" })}
            </p>
            <Switch
              checked={enableCN}
              onChange={() => {setenableCN(!enableCN)}}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          </div>

          <div className="container" style={{border:"1px solid",borderRadius:"20px",borderColor:"#CECDCD",marginBottom: "20px",marginTop: "20px"}}>
          <div className="h6" style={{ paddingTop:"20px" }}> 
        
              {intl.formatMessage({
                id: "113303",
                defaultMessage: "Inventory option",
              })}
            
            </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113287", defaultMessage: "Inventory" })}
            </p>
            <Switch
              checked={enableStock}
              onChange={() => {setenableStock(!enableStock)}}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          </div>

          <div className="container" style={{border:"1px solid",borderRadius:"20px",borderColor:"#CECDCD",marginBottom: "20px",marginTop: "20px"}}>
          <div className="h6" style={{ paddingTop:"20px" }}> 
        
              {intl.formatMessage({
                id: "113304",
                defaultMessage: "Auto accept option",
              })}
            
            </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113288", defaultMessage: "Auto accept order" })}
            </p>
            <Switch
              checked={autoPrint}
              onChange={() => {setAutoPrint(!autoPrint)}}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          </div>
          
          <div className="container" style={{border:"1px solid",borderRadius:"20px",borderColor:"#CECDCD",marginBottom: "20px",marginTop: "20px"}}>
          <div className="h6" style={{ paddingTop:"20px" }}> 
        
              {intl.formatMessage({
                id: "113305",
                defaultMessage: "QR Menu : pop-up option",
              })}
            
            </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113289", defaultMessage: "Call staff" })}
            </p>
            <Switch
              checked={callStaff}
              onChange={(e) => setCallStaff(e.target.checked)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113291", defaultMessage: "E-Feedback" })}
            </p>
            <Switch
              checked={enableFeedback}
              onChange={(e) => setEnableFeedback(e.target.checked)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113300", defaultMessage: "Show order status" })}
            </p>
            <Switch
              checked={enableShowOrder}
              onChange={(e) => setEnableShowOrder(e.target.checked)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113314", defaultMessage: "Queue Number" })}
            </p>
            <Switch
              checked={enableQueueNo}
              onChange={(e) => setEnableQueueNo(e.target.checked)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            
            <Link to="/Shop">
              <button type="button" className="btn btn-secondary wbt">
                {intl.formatMessage({ id: "108011", defaultMessage: "cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              {intl.formatMessage({ id: "108032", defaultMessage: "Save" })}
              {/* {intl.formatMessage({ id: "108012", defaultMessage: "record" })} */}
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
