import React from 'react'

function A5A6Order() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="0 0 419.53 595.28"
        >
            <defs>
                <style>
                    {`
          .cls-1 { fill: none; }
          .cls-2 { fill: #f1f1f1; }
          .cls-3 { fill: #efeeee; }
          .cls-4 { fill: #f9fcff; }
          .cls-5 { fill: #cacac9; }
          .cls-6 { fill: #bebfc0; }
          .cls-7 { fill: #dfdfdf; }
          .cls-8 { fill: #bec0c2; }
          .cls-9 { fill: #e4e5e5; }
          .cls-10 { fill: #adaeb0; }
          .cls-11 { fill: #eaeaea; }
          .cls-12 { fill: #d1d2d3; }
          .cls-13 { fill: #c1c3c4; }
          .cls-14 { fill: #f7fbff; }
          .cls-15 { fill: #c6c7c8; }
          .cls-16 { fill: #c8c9cb; }
          .cls-17 { fill: #e7e6e6; }
          .cls-18 { fill: #d4d6d6; }
          .cls-19 { fill: #e0e1e1; }
          .cls-20 { fill: #b9b9bc; }
          .cls-21 { fill: #e4e3e3; }
          .cls-22 { fill: #b0b3b5; }
          .cls-23 { isolation: isolate; }
          .cls-24 { fill: #dadbdb; }
          .cls-25 { fill: #b1b1b3; }
          .cls-26 { fill: #f0eff0; }
          .cls-27 { fill: #afb0b3; }
          .cls-28 { fill: #d0d1d2; }
          .cls-29 { fill: #f1f2f2; }
          .cls-30 { fill: #ededee; }
          .cls-31 { fill: #c8c9ca; }
          .cls-32 { fill: #cdcfd1; }
          .cls-33 { clip-path: url(#clippath-1); }
          .cls-34 { fill: #c9cacc; }
          .cls-35 { fill: #c4c5c7; }
          .cls-36 { fill: #e8e8e9; }
          .cls-37 { fill: #bbbcbe; }
          .cls-38 { fill: #211e1f; }
          .cls-39 { fill: #b1b3b7; }
          .cls-40 { fill: #fff; }
          .cls-41 { fill: #b6b8bb; }
          .cls-42 { fill: #c1c1c2; }
          .cls-43 { fill: #d7d9d9; }
          .cls-44 { fill: #d6d6d7; }
          .cls-45 { fill: #f6fbfb; }
          .cls-46 { fill: #c5c6c8; }
          .cls-47 { fill: #f4f5f5; }
          .cls-48 { fill: #c5c5c5; }
          .cls-49 { fill: #f3f4f4; }
          .cls-50 { fill: #e2e3e4; }
          .cls-51 { fill: #bcbdbf; }
          .cls-52 { fill: #d2d4d6; }
          .cls-53 { fill: #ced1d2; }
          .cls-54 { fill: #b5b5b8; }
          .cls-55 { fill: #b6b7bb; }
          .cls-56 { fill: #cbced0; }
          .cls-57 { fill: #d0d2d5; }
          .cls-58 { fill: #dadcdc; }
          .cls-59 { fill: #dbdadb; }
          .cls-60 { fill: #f6f7f7; }
          .cls-61 { fill: #f6f7f8; }
          .cls-62 { fill: #e4e4e5; }
          .cls-63 { fill: #aeafb1; }
          .cls-64 { fill: #b3b4b7; }
          .cls-65 { fill: #dfe0e0; }
          .cls-66 { fill: #afb2b5; }
          .cls-67 { fill: #e8e8ea; }
          .cls-68 { fill: #bdbfc1; }
          .cls-69 { fill: #b6b8b9; }
          .cls-70 { fill: #efeeef; }
          .cls-71 { clip-path: url(#clippath); }
          .cls-72 { fill: #d6d7d7; }
          .cls-73 { fill: #c2c4c6; }
          .cls-74 { fill: #f2f3f4; }
          .cls-75 { fill: #dbdcdd; }
          .cls-76 { fill: #babbbd; }
          .cls-77 { fill: #eaeaeb; }
          .cls-78 { mix-blend-mode: darken; opacity: .58; }
          `}
                </style>
                <clipPath id="clippath">
                    <rect className="cls-1" x="-1.49" y="-3.07" width="423.81" height="601.27" />
                </clipPath>
                <clipPath id="clippath-1">
                    <polygon
                        className="cls-1"
                        points="169.57 562.96 179.16 562.96 172.32 573.49 162.54 573.49 169.57 562.96"
                    />
                </clipPath>
            </defs>
            <g class="cls-23">
                <g id="Layer_1">
                    <g class="cls-71">
                        <rect class="cls-38" x="-35.05" y="-33.41" width="497.39" height="135.2" />
                        <rect class="cls-38" x="-1.05" y="297.59" width="423" height="301" />
                        <rect class="cls-1" x="-.35" y="2.49" width="422.14" height="598.81" />
                        <rect class="cls-1" x="-5.39" y="101.79" width="431.05" height="195.78" />
                    </g>
                    <circle class="cls-40" cx="50.71" cy="52.39" r="41.25" />
                    <g>
                        <g>
                            <path class="cls-40" d="M161.31,477.98c-.64,0-1.22-.06-1.72-.19-.51-.13-.96-.31-1.36-.54l.47-1.52c.34.19.73.35,1.17.49.44.13.87.2,1.3.2.55,0,1.01-.11,1.38-.32.37-.22.56-.55.56-1,0-.22-.06-.41-.16-.56-.11-.15-.26-.29-.45-.4-.19-.12-.4-.21-.63-.3-.23-.08-.48-.16-.73-.25-.3-.1-.61-.21-.95-.35s-.64-.32-.93-.55c-.28-.23-.52-.51-.7-.84s-.28-.75-.28-1.24c0-.36.06-.71.19-1.03.13-.33.33-.61.61-.85.28-.24.63-.43,1.06-.57.43-.14.95-.21,1.55-.21.55,0,1.05.05,1.5.16.45.11.89.26,1.3.47l-.47,1.44c-.36-.16-.74-.29-1.13-.37-.39-.09-.76-.13-1.09-.13-.57,0-1,.1-1.28.3-.28.2-.42.45-.42.76,0,.22.05.41.16.57.11.16.24.29.42.41.17.12.37.22.59.3.22.09.45.17.68.24.34.12.69.25,1.05.39.35.15.68.34.96.57.29.23.52.51.7.84.18.33.27.74.27,1.21s-.09.91-.28,1.26c-.19.35-.44.65-.76.89-.32.24-.7.42-1.14.53-.44.12-.92.17-1.44.17Z" />
                            <path class="cls-40" d="M170.5,477.98c-.75,0-1.41-.13-1.99-.38-.58-.25-1.07-.61-1.46-1.06-.39-.45-.69-.98-.89-1.59-.2-.61-.3-1.27-.3-1.98s.1-1.37.3-1.98c.2-.61.5-1.14.89-1.59.39-.45.88-.8,1.46-1.06.58-.25,1.24-.38,1.99-.38.53,0,1.01.05,1.45.16.44.11.87.27,1.28.49l-.46,1.42c-.68-.36-1.39-.55-2.13-.55-.97,0-1.69.31-2.18.92s-.73,1.47-.73,2.57.25,1.96.74,2.57c.49.61,1.22.92,2.18.92.29,0,.63-.05,1.03-.14.4-.1.79-.25,1.18-.46l.43,1.44c-.38.22-.81.39-1.29.51-.47.12-.97.18-1.5.18Z" />
                            <path class="cls-40" d="M180,475.64h-3.5l-.73,2.17h-1.95l3.49-9.68h1.9l3.49,9.68h-1.95l-.73-2.17ZM177.04,474.09h2.41l-.47-1.45-.72-2.47h-.03l-.72,2.47-.47,1.45Z" />
                            <path class="cls-40" d="M189.69,468.12h1.87v9.68h-1.87l-3.3-5.49c-.2-.34-.38-.67-.54-.99-.16-.33-.29-.67-.41-1.02h-.04c.08.4.14.79.18,1.17s.06.72.06,1.03v5.3h-1.87v-9.68h2.01l3.16,5.49c.2.34.38.67.54.99.16.33.29.66.41,1.02h.04c-.08-.4-.14-.79-.18-1.17s-.06-.72-.06-1.03v-5.3Z" />
                            <path class="cls-40" d="M200.54,477.81h-1.87v-8.1l-2.77.03v-1.61h7.41v1.61l-2.77-.03v8.1Z" />
                            <path class="cls-40" d="M203.73,472.96c0-.71.1-1.37.29-1.98.19-.61.48-1.14.85-1.59.38-.45.85-.8,1.41-1.06.56-.25,1.22-.38,1.97-.38s1.4.13,1.97.38c.56.25,1.04.61,1.41,1.06.38.45.66.98.85,1.59.19.61.29,1.27.29,1.98s-.1,1.37-.29,1.98c-.19.61-.48,1.14-.85,1.59-.38.45-.85.8-1.41,1.06s-1.22.38-1.97.38-1.4-.13-1.97-.38c-.57-.25-1.04-.61-1.41-1.06-.38-.45-.66-.98-.85-1.59-.19-.61-.29-1.27-.29-1.98ZM205.59,472.96c0,1.1.23,1.96.67,2.57.45.61,1.11.92,1.98.92s1.53-.31,1.98-.92c.45-.61.68-1.47.68-2.57s-.23-1.96-.68-2.57c-.45-.61-1.11-.92-1.98-.92s-1.53.31-1.98.92c-.45.61-.67,1.47-.67,2.57Z" />
                            <path class="cls-40" d="M217.11,472.96c0-.71.1-1.37.29-1.98.19-.61.48-1.14.85-1.59.38-.45.85-.8,1.41-1.06.56-.25,1.22-.38,1.97-.38s1.4.13,1.97.38c.57.25,1.04.61,1.41,1.06.38.45.66.98.85,1.59.19.61.29,1.27.29,1.98s-.1,1.37-.29,1.98c-.19.61-.48,1.14-.85,1.59-.38.45-.85.8-1.41,1.06-.57.25-1.22.38-1.97.38s-1.4-.13-1.97-.38c-.57-.25-1.04-.61-1.41-1.06-.38-.45-.66-.98-.85-1.59-.19-.61-.29-1.27-.29-1.98ZM218.98,472.96c0,1.1.22,1.96.68,2.57.45.61,1.11.92,1.98.92s1.53-.31,1.98-.92c.45-.61.68-1.47.68-2.57s-.23-1.96-.68-2.57c-.45-.61-1.11-.92-1.98-.92s-1.53.31-1.98.92-.68,1.47-.68,2.57Z" />
                            <path class="cls-40" d="M234.79,471.13c0,.67-.19,1.24-.57,1.71-.38.47-.86.81-1.46,1.02l2.28,3.95h-2l-2.08-3.62h-1.42v3.62h-1.87v-9.68h3.63c.56,0,1.05.08,1.48.24.43.16.79.37,1.09.64.3.27.52.59.68.96.15.37.23.76.23,1.17ZM232.93,471.13c0-.42-.13-.76-.4-1.01-.26-.25-.66-.38-1.2-.38h-1.78v2.84h1.78c.5,0,.89-.13,1.17-.39.28-.26.42-.61.42-1.06Z" />
                            <path class="cls-40" d="M244.29,472.92c0,1.61-.42,2.82-1.27,3.65-.85.82-2.08,1.24-3.68,1.24h-3.02v-9.68h3.02c1.61,0,2.84.41,3.68,1.23.85.82,1.27,2.01,1.27,3.57ZM242.42,472.92c0-.57-.07-1.07-.22-1.47-.14-.41-.35-.74-.61-.99-.26-.25-.59-.44-.97-.55-.38-.12-.81-.17-1.29-.17h-1.15v6.46h1.15c.48,0,.91-.06,1.29-.19.38-.13.71-.32.97-.59.26-.27.47-.61.61-1.02.14-.41.22-.91.22-1.48Z" />
                            <path class="cls-40" d="M245.84,468.12h6.29v1.58h-4.48v2.37h4.21v1.58h-4.21v2.57h4.57v1.58h-6.38v-9.68Z" />
                            <path class="cls-40" d="M260.9,471.13c0,.67-.19,1.24-.57,1.71-.38.47-.86.81-1.46,1.02l2.28,3.95h-2l-2.08-3.62h-1.42v3.62h-1.87v-9.68h3.63c.56,0,1.05.08,1.48.24.43.16.79.37,1.09.64.3.27.52.59.68.96.15.37.23.76.23,1.17ZM259.04,471.13c0-.42-.13-.76-.4-1.01-.26-.25-.66-.38-1.2-.38h-1.78v2.84h1.78c.5,0,.89-.13,1.17-.39.28-.26.42-.61.42-1.06Z" />
                        </g>
                        <g>
                            <path class="cls-40" d="M120.46,457.93v-8.14h-4.05c-1.24,0-2.23.18-2.97.55-.74.37-1.11,1.04-1.11,2.01,0,.8.25,1.42.76,1.88.51.45,1.14.68,1.89.68.4,0,.81-.04,1.25-.12s.87-.23,1.31-.45l.7,3.15c-.58.27-1.17.45-1.78.56-.61.11-1.26.17-1.94.17-.91,0-1.75-.12-2.52-.36-.78-.24-1.45-.61-2.03-1.1-.58-.49-1.03-1.1-1.36-1.83-.33-.73-.5-1.58-.5-2.56,0-2.06.71-3.56,2.14-4.5,1.43-.94,3.59-1.41,6.49-1.41h3.72v-.63c0-.47-.08-.91-.23-1.34-.16-.43-.39-.81-.7-1.15-.31-.33-.7-.6-1.18-.8-.48-.2-1.04-.3-1.68-.3-1.37,0-2.57.2-3.6.6-1.03.4-1.99.92-2.87,1.56l-1.36-2.99c.86-.64,1.99-1.22,3.37-1.73,1.38-.51,2.94-.76,4.67-.76.51,0,1.01.04,1.51.13.5.09.93.21,1.31.36l1.13-1.39h4.75l-2.72,3.25c.58.62,1.02,1.32,1.34,2.09.32.77.48,1.59.48,2.46v12.09h-4.22Z" />
                            <path class="cls-40" d="M133.42,439.33v18.6h-4.32v-18.6h4.32ZM140.99,439.33v18.6h-4.32v-18.6h4.32Z" />
                            <path class="cls-40" d="M157.43,457.93v-11.56c0-1.33-.36-2.32-1.08-2.99-.72-.66-1.7-1-2.94-1-.46,0-.92.06-1.38.18-.45.12-.87.29-1.26.5-.39.21-.73.45-1.03.73-.3.28-.53.56-.68.85l2.79,2.36c-.69.24-1.21.61-1.56,1.11-.35.5-.53,1.17-.53,2.01v7.8h-4.32v-7.77c0-.71.17-1.34.5-1.89.33-.55.75-.98,1.26-1.3l-2.69-2.19c.22-.75.6-1.48,1.15-2.17.54-.7,1.2-1.32,1.98-1.86.77-.54,1.66-.98,2.66-1.31,1-.33,2.07-.5,3.22-.5,1.33,0,2.5.18,3.52.55,1.02.36,1.88.88,2.57,1.53.7.65,1.23,1.44,1.59,2.36.37.92.55,1.92.55,3v11.56h-4.32Z" />
                            <path class="cls-40" d="M170.38,451.38c0,1.26.27,2.16.8,2.69.53.53,1.28.8,2.26.8s1.84-.28,2.67-.85c.83-.56,1.46-1.29,1.88-2.18v-12.52h4.32v18.6h-3.85l-.47-2.29c-.24.33-.56.66-.95.98s-.82.61-1.29.86c-.48.25-1,.46-1.58.61-.58.15-1.17.23-1.79.23-1.97,0-3.52-.54-4.63-1.63-1.12-1.08-1.68-2.79-1.68-5.11v-12.25h4.32v12.06Z" />
                            <path class="cls-40" d="M197.94,457.93v-8.14h-4.05c-1.24,0-2.23.18-2.97.55-.74.37-1.11,1.04-1.11,2.01,0,.8.25,1.42.76,1.88.51.45,1.14.68,1.89.68.4,0,.81-.04,1.25-.12s.87-.23,1.31-.45l.7,3.15c-.58.27-1.17.45-1.78.56-.61.11-1.26.17-1.94.17-.91,0-1.75-.12-2.52-.36-.78-.24-1.45-.61-2.03-1.1-.58-.49-1.03-1.1-1.36-1.83-.33-.73-.5-1.58-.5-2.56,0-2.06.71-3.56,2.14-4.5,1.43-.94,3.59-1.41,6.49-1.41h3.72v-.63c0-.47-.08-.91-.23-1.34-.16-.43-.39-.81-.7-1.15-.31-.33-.7-.6-1.18-.8-.48-.2-1.04-.3-1.68-.3-1.37,0-2.57.2-3.6.6-1.03.4-1.99.92-2.87,1.56l-1.36-2.99c.86-.64,1.99-1.22,3.37-1.73,1.38-.51,2.94-.76,4.67-.76.51,0,1.01.04,1.51.13.5.09.93.21,1.31.36l1.13-1.39h4.75l-2.72,3.25c.58.62,1.02,1.32,1.35,2.09.32.77.48,1.59.48,2.46v12.09h-4.22Z" />
                            <path class="cls-40" d="M197.74,432.99c0,.51.25.76.76.76h8.5v3.25h-9.5c-.53,0-1.02-.07-1.48-.2-.45-.13-.85-.35-1.18-.65-.33-.3-.6-.69-.8-1.16-.2-.48-.3-1.07-.3-1.78v-1.66h3.98v1.43Z" />
                            <path class="cls-40" d="M198.31,428.8v-5.45h3.85v5.45h-3.85Z" />
                            <path class="cls-40" d="M209.93,457.93l-5.61-18.6h4.25l4.55,15.14h.36c.53,0,1.04-.15,1.53-.47.49-.31.92-.75,1.31-1.31.39-.56.69-1.25.91-2.06.22-.81.33-1.7.33-2.67,0-2.01-.33-3.45-.98-4.3-.65-.85-1.49-1.28-2.51-1.28-.69,0-1.43.14-2.22.43l-.63-3.16c.44-.24.98-.43,1.61-.55.63-.12,1.29-.18,1.98-.18,1,0,1.93.2,2.79.6.86.4,1.61.99,2.24,1.76.63.78,1.13,1.72,1.49,2.84.36,1.12.55,2.4.55,3.84,0,1.55-.21,2.94-.63,4.17-.42,1.23-1.01,2.27-1.78,3.14-.76.86-1.67,1.52-2.71,1.98-1.04.45-2.18.68-3.42.68h-3.42Z" />
                            <path class="cls-40" d="M229.19,450.59c-.4,0-.6.21-.6.63v.1c0,2.37,1.32,3.55,3.95,3.55,1.57,0,2.81-.52,3.72-1.56.91-1.04,1.36-2.67,1.36-4.88,0-1.95-.49-3.44-1.48-4.48-.99-1.04-2.27-1.56-3.87-1.56-1.35,0-2.57.24-3.65.71-1.08.48-2.05,1.03-2.89,1.68l-1.4-3.22c.93-.66,2.07-1.27,3.42-1.81s2.96-.81,4.82-.81c1.37,0,2.63.21,3.79.63,1.15.42,2.14,1.04,2.97,1.86.83.82,1.47,1.83,1.93,3.02.45,1.2.68,2.57.68,4.12,0,3.28-.81,5.72-2.44,7.34-1.63,1.62-3.84,2.42-6.63,2.42-1.42,0-2.66-.17-3.72-.5-1.06-.33-1.94-.81-2.64-1.43-.7-.62-1.22-1.36-1.58-2.22-.35-.86-.53-1.81-.53-2.86v-.56c0-1.22.27-2.12.8-2.72.53-.6,1.39-.9,2.59-.9h4.25v3.45h-2.86Z" />
                            <path class="cls-40" d="M253,457.93v-11.96c0-2.39-1-3.59-2.99-3.59-1,0-1.98.18-2.94.53-.96.35-1.82.78-2.57,1.26l-1.1-3.09c.91-.6,1.97-1.11,3.17-1.53,1.21-.42,2.47-.63,3.8-.63,2.28,0,4.01.64,5.18,1.93,1.17,1.28,1.76,3.03,1.76,5.25v11.82h-4.32Z" />
                            <path class="cls-40" d="M274.79,457.93l-2.62-7.94c-.33-.93-.75-1.59-1.25-1.98-.5-.39-1.05-.6-1.64-.65l-3.25,2.79v7.77h-4.32v-18.6h4.32v6.31l7.54-6.31h5.45l-6.77,5.58c1.13.31,1.99.81,2.57,1.49.59.69,1.06,1.53,1.41,2.52l2.99,9h-4.42Z" />
                            <path class="cls-40" d="M289.4,457.93v-11.96c0-2.39-1-3.59-2.99-3.59-1,0-1.98.18-2.94.53-.96.35-1.82.78-2.57,1.26l-1.1-3.09c.91-.6,1.97-1.11,3.17-1.53,1.21-.42,2.47-.63,3.8-.63,2.28,0,4.01.64,5.18,1.93,1.17,1.28,1.76,3.03,1.76,5.25v11.82h-4.32Z" />
                            <path class="cls-40" d="M303.61,454.87c1.15,0,2.04-.17,2.67-.5s.95-.84.95-1.53c0-.4-.12-.74-.35-1.03-.23-.29-.54-.54-.93-.75-.39-.21-.82-.4-1.3-.56-.48-.17-.95-.33-1.41-.48-.82-.27-1.6-.56-2.34-.88-.74-.32-1.39-.71-1.94-1.18-.55-.47-1-1.01-1.33-1.64-.33-.63-.5-1.39-.5-2.27,0-.73.17-1.41.51-2.03.34-.62.82-1.16,1.44-1.63.62-.47,1.36-.82,2.21-1.08.85-.25,1.79-.38,2.81-.38,1.42,0,2.66.15,3.74.46,1.07.31,2.08.71,3.01,1.2l-1.13,3.05c-.86-.47-1.73-.79-2.61-.98-.87-.19-1.81-.28-2.81-.28-.84,0-1.55.13-2.13.38-.58.25-.86.67-.86,1.25,0,.64.37,1.15,1.1,1.51.73.36,1.68.75,2.86,1.14.93.31,1.77.64,2.52,1,.75.35,1.4.77,1.94,1.25.54.48.96,1.04,1.25,1.69.29.65.43,1.42.43,2.31,0,.8-.16,1.52-.47,2.18-.31.65-.78,1.22-1.39,1.71-.62.49-1.39.86-2.32,1.13-.93.27-2.03.4-3.29.4-1.33,0-2.56-.14-3.7-.42-1.14-.28-2.15-.67-3.04-1.18l1.03-3.29c.82.44,1.68.79,2.59,1.05.91.25,1.84.38,2.79.38Z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <polygon class="cls-40" points="172.32 544.43 181.98 544.43 174.91 554.92 184.38 554.95 172.32 573.49 162.54 573.49 169.57 562.96 159.98 562.96 172.32 544.43" />
                            <polygon class="cls-40" points="172.32 544.43 181.98 544.43 174.91 554.92 184.38 554.95 172.32 573.49 162.54 573.49 169.57 562.96 159.98 562.96 172.32 544.43" />
                            <g class="cls-78">
                                <g class="cls-33">
                                    <rect class="cls-10" x="162.54" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-63" x="162.75" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-27" x="162.95" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-25" x="163.16" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-66" x="163.36" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-22" x="163.57" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-39" x="163.77" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-64" x="163.98" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-54" x="164.18" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-69" x="164.39" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-55" x="164.59" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-41" x="164.8" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-20" x="165" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-76" x="165.21" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-76" x="165.41" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-37" x="165.62" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-51" x="165.83" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-6" x="166.03" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-68" x="166.24" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-8" x="166.44" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-42" x="166.65" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-13" x="166.85" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-13" x="167.06" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-73" x="167.26" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-48" x="167.47" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-35" x="167.67" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-46" x="167.88" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-15" x="168.08" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-31" x="168.29" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-16" x="168.49" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-34" x="168.7" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-56" x="168.9" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-32" x="169.11" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-53" x="169.31" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-53" x="169.52" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-28" x="169.72" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-12" x="169.93" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-57" x="170.13" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-52" x="170.34" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-52" x="170.54" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-18" x="170.75" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-44" x="170.95" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-72" x="171.16" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-43" x="171.36" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-59" x="171.57" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-24" x="171.77" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-58" x="171.98" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-75" x="172.18" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-7" x="172.39" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-7" x="172.59" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-65" x="172.8" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-65" x="173" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-19" x="173.21" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-50" x="173.41" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-21" x="173.62" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-62" x="173.82" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-9" x="174.03" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-17" x="174.23" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-36" x="174.44" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-36" x="174.64" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-67" x="174.85" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-77" x="175.05" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-11" x="175.26" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-30" x="175.47" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-3" x="175.67" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-70" x="175.88" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-26" x="176.08" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-2" x="176.29" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-29" x="176.49" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-74" x="176.7" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-74" x="176.9" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-49" x="177.11" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-47" x="177.31" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-60" x="177.52" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-61" x="177.72" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-45" x="177.93" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-14" x="178.13" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-4" x="178.34" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-40" x="178.54" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-40" x="178.75" y="562.96" width=".21" height="10.53" />
                                    <rect class="cls-40" x="178.95" y="562.96" width=".21" height="10.53" />
                                </g>
                            </g>
                            <polygon class="cls-5" points="169.58 562.96 179.13 562.95 167.45 566.13 169.58 562.96" />
                        </g>
                        <g>
                            <path class="cls-40" d="M190.85,562.26c1.8.28,3.59.47,5.41.47.52,0,1.63,0,1.63-.95,0-.71-.28-.85-3.11-.85-1.45,0-3.05-.63-3.05-2.9,0-3.12,1.26-5.65,3.16-6.34,1.06-.39,2.59-.43,3.76-.43,1.58,0,4.13.1,6.43.55l-1,4.48c-1.53-.24-3.05-.45-4.6-.45-1.88,0-2.32.34-2.32,1.03,0,.59.32.75,2.8.75,1.8,0,3.38.1,3.38,3.14,0,1.13-.41,2.71-.74,3.4-.98,2.03-2.56,2.41-5.68,2.41-2.53,0-4.68-.18-6.78-.67l.71-3.65Z" />
                            <path class="cls-40" d="M206.75,551.69h4.89l-1.91,9.5h4.9l-1.01,4.96h-9.77l2.91-14.46Z" />
                            <path class="cls-40" d="M221.67,551.69h8.46v14.46h-4.87v-2.11h-3.98l-.98,2.11h-5.41l6.78-14.46ZM225.26,555.35h-.03l-2.5,5.21h2.53v-5.21Z" />
                            <path class="cls-40" d="M231.83,562.26c1.8.28,3.59.47,5.41.47.52,0,1.63,0,1.63-.95,0-.71-.28-.85-3.11-.85-1.45,0-3.05-.63-3.05-2.9,0-3.12,1.26-5.65,3.16-6.34,1.06-.39,2.59-.43,3.76-.43,1.58,0,4.13.1,6.43.55l-1,4.48c-1.53-.24-3.05-.45-4.6-.45-1.88,0-2.32.34-2.32,1.03,0,.59.32.75,2.8.75,1.8,0,3.38.1,3.38,3.14,0,1.13-.41,2.71-.74,3.4-.98,2.03-2.56,2.41-5.68,2.41-2.53,0-4.68-.18-6.78-.67l.71-3.65Z" />
                            <path class="cls-40" d="M247.68,551.69h4.81l-1.09,5.25h2.96l1.09-5.25h4.81l-3.02,14.46h-4.81l1.08-5.17h-2.96l-1.07,5.17h-4.81l3.02-14.46Z" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default A5A6Order