import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { baseURL } from "../../../service/API.js";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import "../../component.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const CreateUser = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({
      id: "109036",
      defaultMessage: "Create Account management",
    })
  );
  const history = useHistory();
  const [id, setId] = useState();
  const [firstname, setfirstname] = useState("");
  const [firstnameEn, setfirstnameEn] = useState("");
  const [lastname, setlastname] = useState("");
  const [lastnameEn, setlastnameEn] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [mobile, setMobile] = useState("");
  const [openCheck, setOpenCheck] = useState(false);
  const { authToken } = useSelector((state) => state.auth);
  const [state, setState] = useState(false);

  useEffect(() => {
    //getList();
  }, []);

  const getList = async () => {
    //setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/role?page=${1}&limit=${10000}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        console.log(res.data.data);
      }
      setRoleList(res.data.data);
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // await setSubmitting(true);
    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");

    var data = {
      shopId: get_shop_id,
      branchId: get_branch_id,
      firstname: values.firstname,
      firstname_en: values.firstnameEn,
      lastname: values.lastname,
      lastname_en: values.lastnameEn,
      mobile: values.mobile,
      username: values.username,
      password: values.password,
      //role_id: role.id,
    };

    if (
      data.firstname &&
      data.firstname_en &&
      data.lastname &&
      data.lastname_en
    ) {
      let apiUrls = `${baseURL}/management/employee`;
      axios({
        method: "post",
        url: apiUrls,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        data: data,
      })
        .then((res) => {
          Swal.fire({
            title: intl.formatMessage({
              id: "113101",
              defaultMessage: "Save !",
            }),
            text: intl.formatMessage({
              id: "113106",
              defaultMessage: "Create user success",
            }),
            icon: "success",
            confirmButtonText: intl.formatMessage({
              id: "113116",
              defaultMessage: "ok",
            }),
          });
          history.goBack();
        })
        .catch((err) => {
          console.log(
            err.toString().substring(39),
            err.toString().substring(39) == "422"
          );
          if (err.toString().substring(39) == "422") {
            Swal.fire({
              title: intl.formatMessage({
                id: "113101",
                defaultMessage: "Save !",
              }),
              text: intl.formatMessage({
                id: "113117",
                defaultMessage: "Your can not update user. !! ",
              }),
              icon: "error",
              confirmButtonText: intl.formatMessage({
                id: "113116",
                defaultMessage: "ok",
              }),
            });
          } else {
            Swal.fire({
              title: intl.formatMessage({
                id: "113101",
                defaultMessage: "Save !",
              }),
              text:
                intl.formatMessage({
                  id: "113118",
                  defaultMessage: "Your can not update user. !! ",
                }) + err,
              icon: "error",
              confirmButtonText: intl.formatMessage({
                id: "113116",
                defaultMessage: "ok",
              }),
            });
          }
        });
    } else {
      Swal.fire({
        title: intl.formatMessage({ id: "113101", defaultMessage: "Save !" }),
        text: intl.formatMessage({
          id: "113104",
          defaultMessage: "Your can not update user. !! ",
        }),
        icon: "error",
        confirmButtonText: intl.formatMessage({
          id: "113116",
          defaultMessage: "ok",
        }),
      });
    }
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const editUser = (values) => {};

  const handleClose = () => {
    setOpenCheck(false);
  };
  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "109036",
            defaultMessage: "Create Account management",
          })}
        ></CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              username,
              firstname,
              firstnameEn,
              lastname,
              lastnameEn,
              username,
              mobile,
              role,
              password,
            }}
            validate={(values) => {
              let errors = {};

              if (!values.firstname)
                errors.firstname = intl.formatMessage({
                  id: "109012",
                  defaultMessage: "Firstname is required",
                });
              if (!values.firstnameEn)
                errors.firstnameEn = intl.formatMessage({
                  id: "109013",
                  defaultMessage: "Firstnameen is required",
                });
              if (!values.lastname)
                errors.lastname = intl.formatMessage({
                  id: "109014",
                  defaultMessage: "lastname is required",
                });
              if (!values.lastnameEn)
                errors.lastnameEn = intl.formatMessage({
                  id: "109015",
                  defaultMessage: "lastnameen is required",
                });
              if (!values.username || values.username.length < 6)
                errors.username = intl.formatMessage({
                  id: "113074",
                  defaultMessage: "username is required at least 6 characters",
                });
              /*if (!values.role)
                errors.role = intl.formatMessage({
                  id: "113192",
                  defaultMessage: "must select role",
                });*/
              if (
                !values.password ||
                values.password.length < 6 ||
                values.password.length > 12
              )
                errors.password = intl.formatMessage({
                  id: "113075",
                  defaultMessage:
                    "password is required at least 6 characters and most 12 characters",
                });
              if (
                !values.mobile ||
                values.mobile.length < 10 ||
                values.mobile.length > 10
              )
                errors.mobile = intl.formatMessage({
                  id: "113149",
                  defaultMessage:
                    "password is required at least 6 characters and most 12 characters",
                });

              //check if my values have errors
              return errors;
            }}
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <Form>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "109007",
                        defaultMessage: "Thai name",
                      })}{" "}
                      :{" "}
                    </p>
                    <Field
                      type="text"
                      name="firstname"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "109007",
                        defaultMessage: "Thai name",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="firstname">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "109008",
                        defaultMessage: "English name",
                      })}{" "}
                      :{" "}
                    </p>
                    <Field
                      type="text"
                      name="firstnameEn"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "109008",
                        defaultMessage: "English name",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="firstnameEn">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "109009",
                        defaultMessage: "Thai surname",
                      })}{" "}
                      :{" "}
                    </p>
                    <Field
                      type="text"
                      name="lastname"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "109009",
                        defaultMessage: "Thai surname",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="lastname">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "109010",
                        defaultMessage: "english surname",
                      })}{" "}
                      :{" "}
                    </p>
                    <Field
                      type="text"
                      name="lastnameEn"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "109010",
                        defaultMessage: "english surname",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>

                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="lastnameEn">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "113150",
                        defaultMessage: "english surname",
                      })}{" "}
                      :{" "}
                    </p>
                    <Field
                      type="numbers"
                      name="mobile"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "113150",
                        defaultMessage: "english surname",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="mobile">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "113065",
                        defaultMessage: "Username",
                      })}
                    </p>
                    <Field
                      type="text"
                      name="username"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "113065",
                        defaultMessage: "Username",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="username">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/*<div className="form-inline" style={{ marginBottom: "25px" }}>
                    <p
                      style={{ marginTop: "15px", float: "left", width: "20%" }}
                    >
                      {intl.formatMessage({
                        id: "113190",
                        defaultMessage: "Page",
                      })}
                      <span style={{ color: "red" }}>*</span>:{" "}
                    </p>
                    <Autocomplete
                      id="grouped-demo"
                      options={roleList}
                      getOptionLabel={(option) => option.role}
                      /*getOptionSelected={(option, value) =>
                        option.id == value.id
                      }
                      //value={item.listPage}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        setRole(newValue);
                      }}
                      style={{
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                    
                  </div>
                  <div className="form-inline">
                      <p
                        style={{
                          marginTop: "15px",
                          float: "left",
                          width: "20%",
                        }}
                      ></p>
                      <ErrorMessage className="colorError" name="role">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                      </div>*/}

                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "101006",
                        defaultMessage: "password",
                      })}{" "}
                      :{" "}
                    </p>

                    <Field
                      type="text"
                      name="password"
                      className="form-control"
                      maxLength={12}
                      placeholder={intl.formatMessage({
                        id: "101006",
                        defaultMessage: "Password",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="password">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <CardHeaderToolbar>
                    <div
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></div>
                    <Link to="/User">
                      <button type="button" className="btn btn-secondary wbt">
                        {intl.formatMessage({
                          id: "109017",
                          defaultMessage: "cancel",
                        })}
                      </button>
                    </Link>
                    <button
                      className="btn btn-primary wbt"
                      type="submit"
                      disabled={formProps.isSubmitting}
                    >
                      {intl.formatMessage({
                        id: "109018",
                        defaultMessage: "save",
                      })}
                    </button>
                  </CardHeaderToolbar>
                </Form>
              );
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};
