import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../../../_metronic/_helpers'
import BootstrapTable from 'react-bootstrap-table-next'
import TableHeaderColumn from 'react-bootstrap-table-next'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit'
import Pagination from '@material-ui/lab/Pagination'
import { useSubheader } from '../../../../_metronic/layout'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import moment from 'moment'
import { baseURL } from '../../../service/API.js'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import { tr } from 'date-fns/locale'
import { set } from 'object-path'
import * as XLSX from 'xlsx'
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { roleCheck } from '../../../service/role';


const queryString = require('query-string');

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
})

export const ReportIndex = () => {
  const intl = useIntl();
  const [token, setToken] = useState(null)
  const [ref, setRef] = useState(null)
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  const [roleList, setRoleList] = useState([]);
  const [reportData, setReportData] = useState([])
  const [listReport, setListReport] = useState([])
  const [listBranchGroup, setlistBranchGroup] = useState([])
  const [listBranch, setListBranch] = useState([])
  const [branchGroupID, setBranchGroupID] = useState(null)
  const [branchID, setBranID] = useState(null)
  const [col, setCol] = useState([])
  const [reportValue, setReportValue] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [totalSize, setTotalSize] = useState(100)
  const [rotation, setRotation] = useState(0)
  const [loading, setLoading] = useState(false)
  const [is_loading, setIs_Loading] = useState(false)
  const [placeholder, setPlaceholder] = useState()
  const Token = localStorage.getItem("Token");
  const { authToken } = useSelector((state) => state.auth);
  const [listGroupShop, setListGroupShop] = useState([])
  const [branchIDGroupShop, setBranchIDGroupShop] = useState({})
  const [shopIdsByRole, setShopIdsByRole] = useState({});
  const [branchIdsByRole, setBranchIdsByRole] = useState({});
  const [selectedRoleGroupId, setSelectedRoleGroupId] = useState(null);
  const [shopGroupID,setShopGroupID] = useState();

  var d = new Date()
  d.setDate(d.getDate() - 30)
  const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))
  suhbeader.setTitle(intl.formatMessage({ id: "110001" ,defaultMessage:"summary report"}))
  const history = useHistory();
  useEffect(() => {
    checkAuth()
    const query = queryString.parse(window.location.search);
    if (query.branch_ref !== undefined) {
      console.log('branch_ref', query.branch_ref);
      localStorage.setItem('isPos', true)
      setRef(query.branch_ref)
      getListReport(null, query.branch_ref);
      // getListBranchGroup(null, query.branch_ref);
    }
    else {
      setRef(null)
      setToken(authToken)
      getListReport(authToken, null);
      // getListBranchGroup(authToken, null);
      getListGroupShop()
    }

  }, []);

  const checkAuth =async()=> {
    const data = await roleCheck('REPORT')
    setRoleList(data)
    console.log(data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }
  }

  const getListReport = (token, ref) => {
    var REF = ref === null ? '' : { "pos-branch": ref }
    setIs_Loading(true)
    const apiUrls = `${baseURL}/report2?page=1&limit=10000`;
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
        ...REF
      },
    })
      .then(res => {
        setListReport(res.data.data)
        setIs_Loading(false)

      })
      .catch(err => {
        setIs_Loading(false)
        console.log(err)
      })
  }

  const getListGroupShop = () => {
    setIs_Loading(true)
    const apiUrls = `${baseURL}/transaction/shopRoleGroup`;
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then(res => {
        console.log('getListGroupShop', res.data.data);
        let result = res.data.data.map(item => item.posShopRoleGroups)
        console.log("result========>",result)

        setListGroupShop(result)
        const shopIds = {};
        const branchIds = {};
        res.data.data.forEach(item => {
          const roleId = item.posShopRoleGroups.id;
          const configs = item.posGroupShopConfigs.filter(config => config.role_group_id === roleId);
          
          shopIds[roleId] = configs.map(config => config.shop_id);
          branchIds[roleId] = configs.map(config => ({
            branch_id: config.branch_id,
            branch_name: config.Branchs.name
          }));
        });
    
        console.log("shopIdsByRole========>", shopIds);
        console.log("branchIdsByRole========>", branchIds);
    
        setShopIdsByRole(shopIds);
        setBranchIdsByRole(branchIds);
        console.log("branchIds======>",branchIds)
      })
      .catch(err => {
        setIs_Loading(false)
        console.log(err)
      })
    // setIs_Loading(true)
    // const apiUrls = `${baseURL}/transaction/getGroupShopConfig`;
    // const resultAll = axios({
    //   method: 'get',
    //   url: apiUrls,
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${Token}`,
    //   },
    // })
    //   .then(res => {
    //     console.log('getListGroupShop', res.data.data);
    //     let result = res?.data?.data?.posGroupShopConfigs

    //     setListGroupShop(result)
    //   })
    //   .catch(err => {
    //     setIs_Loading(false)
    //     console.log(err)
    //   })
  }

  const getListBranchGroup = (token, ref, filter=null) => {
    var REF = ref === null ? '' : { "pos-branch": ref }
    setIs_Loading(true)
    const apiUrls = `${baseURL}/branchGroup${filter ? `?branchGroup=${filter}` : ''}`;
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
        ...REF
      },
    })
      .then(res => {
        setlistBranchGroup(res.data.data.branch_group)
        setListBranch(res.data.data.branch)
        setIs_Loading(false)

      })
      .catch(err => {
        setIs_Loading(false)
        console.log(err)
      })
  }

  const bindReport = (id, _page, _sizePerPage, search) => {
    var data = {
      report_id: id,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    var REF = ref === null ? '' : { "pos-branch": ref }
    const apiUrls = `${baseURL}/report2/render/${data.report_id}?page=${_page}&limit=${_sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}${branchGroupID ? `&branchGroup=${branchGroupID}` : ''}${branchGroupID ? `${branchID ? `&branch=${branchID}` : ''}` : ''}${search !== undefined && search !== "" ? "&search=" + search : ""}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
        ...REF
      },
    })
      .then(async res => {
        setIs_Loading(false)
        var keys = []
        var newState = []
        var array_width = []

        if (res.data.displayColumnWidth) {
          array_width = res.data.displayColumnWidth.split(',');
        }

        keys = Object.keys(res.data.data[0])
        keys.map(async (item, key) => {
          newState.push({
            dataField: item,
            text: item,
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (col) => {
              return (
                <span
                  style={{ display: 'block', width: array_width[key] ? array_width[key] : 100 }}
                >
                  {col}
                </span>
              );
            },
          })
        })
        var totalpage = Math.ceil(res.data.total / res.data.limit)
        setTotalPage(totalpage)
        setPlaceholder(res.data.filteredPlaceHolder ? res.data.filteredPlaceHolder : "Text Search")
        setTotalSize(res.data.total)
        setCol(newState)
        setReportData(res.data.data)
      })
      .catch(err => {
        setIs_Loading(false)
        console.log(err)
      })
  }

  const changeReportDateFrom = (_dateFrom, _page, _sizePerPage) => {
    setDateFrom(_dateFrom)
    setPage(1)
    setSizePerPage(10)
  }

  const changeReportDateTo = (_dateTo, _page, _sizePerPage) => {
    setDateTo(_dateTo)
    setPage(1)
    setSizePerPage(10)
  }

  function imageFormatter(cell, row) {
    return (
      <input
        type="image"
        src={cell}
        alt="priv-image"
        width="48"
        height="48"
      ></input>
    )
  }

  const handleSearch = (reportValue, search) => {
    setPage(1);
    setSizePerPage(10)
    setIs_Loading(true)

    if (!reportValue) {
      setIs_Loading(false)
      return Swal.fire(
        intl.formatMessage({ id: "110006",defaultMessage:"Error" }),
        intl.formatMessage({ id: "110007",defaultMessage:"Please Select Report" }),
        "error"
      );
    }

    if (!dateFrom) {
      setIs_Loading(false)
      return Swal.fire(
        "Error !",
        "Please Select Date From",
        "error"
      );
    }

    if (!dateTo) {
      setIs_Loading(false)
      return Swal.fire(
        "Error !",
        "Please Select Date To",
        "error"
      );
    }

    setReportData([])
    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }

    var REF = ref === null ? '' : { "pos-branch": ref }
    // `${baseURL}/report2/render/${data.report_id}?page=1&limit=${totalSize}&dateFrom=${data.date_from}&dateTo=${data.date_to}${branchGroupID ? `&branchGroup=${branchGroupID}`: ''}${branchGroupID ? (branchGroupID ? `&branchGroup=${branchGroupID}`:'') : ''}`
    const apiUrls = `${baseURL}/report2/render/${reportValue}?page=${page}&limit=${sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}${search !== undefined && search !== "" ? "&search=" + search : ""}${shopGroupID ? `&array_shop_id=${shopGroupID}`: ''}${branchGroupID ? `&array_branch_id=${branchGroupID}`: ''}`

    
    // const apiUrls = `${baseURL}/report2/render/${reportValue}?page=${page}&limit=${sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}${branchGroupID ? `&branchGroup=${branchGroupID}` : ''}${branchGroupID ? `${branchID ? `&branch=${branchID}` : ''}` : ''}${search !== undefined && search !== "" ? "&search=" + search : ""}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
        ...REF
      },
    })
      .then(res => {
        setIs_Loading(false)
        setBranchGroupID();
        var keys = []
        var newState = []
        var array_width = []

        if (res.data.displayColumnWidth) {
          array_width = res.data.displayColumnWidth.split(',');
        }
        // console.log(array_width)

        // const array_width_cover = eval(array_width)
        // console.log(array_width_cover)
        if(res.data.data[0]){
          keys = Object.keys(res?.data?.data[0])
          keys.map(async (item, key) => {
            newState.push({
              dataField: item,
              text: item,
              sort: true,
              sortCaret: sortCaret,
              headerSortingClasses,
              formatter: (col) => {
                return (
                  <span
                    style={{ display: 'block', width: array_width[key] ? array_width[key] : 100 }}
                  >
                    {col}
                  </span>
                );
              },
            })
          })
        }

        var totalpage = Math.ceil(res.data.total / res.data.limit)
        setTotalPage(totalpage)
        setPlaceholder(res.data.filteredPlaceHolder ? res.data.filteredPlaceHolder : "Text Search")
        setTotalSize(res.data.total)
        setCol(newState)
        setReportData(res.data.data)

      })
      .catch(err => {
        setIs_Loading(false)
        console.log(err)
      })

  };

  const [search, setSearch] = useState();

  const handlePageChange = (event, value) => {
    setPage(value)
    setIs_Loading(true)
    bindReport(reportValue, value, sizePerPage, search)
  }

  const handlePageSizeChange = event => {
    setSizePerPage(event.target.value)
    setPage(1)
    setIs_Loading(true)
    bindReport(reportValue, 1, event.target.value, search)
  }
  let branchQueryReport
  let shopQueryReport
  let branchQuerys

  const handleRoleGroupChange = (e) => {
    setSelectedRoleGroupId(Number(e.target.value));
    
    branchQuerys = Array.from(new Set(branchIdsByRole[Number(e.target.value)]))
    console.log("branchQuerys===========>",branchQuerys)
    if(branchQuerys.length > 1){
     branchQueryReport = branchQuerys.map(branch => branch.branch_id).join(',');
    }else{
      branchQueryReport = branchQuerys[0].branch_id
    }
    shopQueryReport = Array.from(new Set(shopIdsByRole[Number(e.target.value)]))?.join(',')
    
    console.log("setShopGroupID====>",shopQueryReport)
    console.log("setBranchGroupID====>",branchQueryReport)
    setShopGroupID(shopQueryReport)
    setBranchGroupID(branchQueryReport)
    
  };
  const handleBranchGroupChange = (e) => {
    setBranchGroupID(e.target.value)
  }


  const exportExcel = () => {
    setLoading(true)
    const headers = []
    const rows = []
    col.map(c => {
      headers.push(c.dataField)
    })

    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    var REF = ref === null ? '' : { "pos-branch": ref }
    const apiUrls = `${baseURL}/report2/render/${data.report_id}?page=1&limit=${totalSize}&dateFrom=${data.date_from}${branchGroupID ? `&branchGroup=${branchGroupID}` : ''}${branchGroupID ? `${branchID ? `&branch=${branchID}` : ''}` : ''}&dateTo=${data.date_to}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
        ...REF
      },
    })
      .then(async res => {
        var _data = res.data.data
        var key = []

        _data.map((item, num) => {
          try {
            if (item['created_at']) {
              item['created_at'] = moment(item['created_at']).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            }
          } catch (err) { }

          const alphaNumOut = Object.keys(item).map(key => item[key])

          rows.push(alphaNumOut)
        })
        const datas = [headers, ...rows]

        const reportTitle = listReport.find(
          d => Number(d.id) === Number(reportValue)
        ).title

        const fileName = `export-pos-${reportTitle}.xlsx`
        const ws = (XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[]]))
        const wb = (XLSX.WorkBook = XLSX.utils.book_new())

        XLSX.utils.sheet_add_aoa(ws, datas)
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, fileName)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "110001",defaultMessage:"summary report" })}>
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
          <select
            as="select"
            className="mb-3 mr-2"
            style={{
              width: '305px',
              height: '38px',
              borderColor: '#D4E6F6',
              borderRadius: '5px'
            }}
            defaultValue={0}
            onChange={even => {
              setReportValue(even.target.value)
              setPage(1)
              setSizePerPage(10)
            }
            }
          >
            <option disabled value={0}>
              {' '}
              {intl.formatMessage({ id: "110002" ,defaultMessage:"Please Select"})} ...
            </option>
            {listReport.map((data, index) => {
              return (
                <option key={index} value={data.id}>
                  {data.title}
                </option>
              )
            })}
          </select>
          

            {/* {<select
              as="select"
              className="mb-3"
              style={{
                width: '200px',
                height: '38px',
              }}
              defaultValue={0}
              onChange={event => {
                // console.log('event', event.target.value)
                setBranchIDGroupShop(event.target.value)
              }
              }
            >
              <option disabled value={0}>
                {' '}
                {intl.formatMessage({ id: "113151", defaultMessage: "Please Select branch group" })} ...
              </option>
              {listGroupShop.map((data, index) => {
                return (
                  <option key={index} value={data.Branchs.id}>
                    {data.Branchs.name}
                  </option>
                )
              })}
            </select>} */}


          {/* {roleList.includes('ADMIN') && <select
            as="select"
            className="mb-3"
            style={{
              width: '200px',
              height: '38px',
            }}
            defaultValue={0}
            onChange={even => {
              getListBranchGroup(null, null, even.target.value);
              setBranchGroupID(even.target.value)
            }
            }
          >
            <option disabled value={0}>
              {' '}
              {intl.formatMessage({ id: "113151" ,defaultMessage:"Please Select branch group"})} ...
            </option>
            {listBranchGroup.map((data, index) => {
              return (
                <option key={index} value={data.id}>
                  {data.name}
                </option>
              )
            })}
          </select>}

          {roleList.includes('ADMIN') &&<select
            as="select"
            className="mb-3 ml-3"
            style={{
              width: '200px',
              height: '38px',
            }}
            defaultValue={0}
            onChange={even => {
              setBranID(even.target.value)
            }
            }
          >
            <option disabled value={0}>
              {' '}
              {intl.formatMessage({ id: "113173" ,defaultMessage:"Please Select branch"})} ...
            </option>
            {listBranch.map((data, index) => {
              return (
                <option key={index} value={data.branch_id}>
                  {data.posShop.name}
                </option>
              )
            })}
          </select>} */}
          
          </div>
          

        </div>

        <div className="d-flex justify-content-between mt-1">
          <div >
          {intl.formatMessage({ id: "110003",defaultMessage:"Search" })} :
            <input
              type="text"
              className="mr-2 ml-1 input-col-4"
              placeholder={placeholder ? placeholder : intl.formatMessage({ id: "110004" ,defaultMessage:"Text Search"})}
              label=" "
              style={{
                height: '38px',
                width: '150px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
             {<select
              as="select"
              className="mb-3 form-select"
              style={{
                width: '200px',
                height: '38px',
                borderRadius: '5px',
                borderColor: '#D4E6F6'
              }}
              defaultValue={0}
              onChange={(e)=>handleRoleGroupChange(e)}
            >
              <option disabled value={0}>
                {' '}
                {intl.formatMessage({ id: "113426", defaultMessage: "Please Select Group Shop" })}
              </option>
              {listGroupShop.map((data, index) => {
                return (
                  <option key={index} value={data.id}>
                    {data.name}
                  </option>
                )
              })}
            </select>}
            
              <select
              as="select"
              className="mb-3 ml-3 form-select"
              style={{
                width: '200px',
                height: '38px',
                borderRadius: '5px',
                borderColor: '#D4E6F6'
              }}
              defaultValue={0}
              onChange={(e)=>handleBranchGroupChange(e)}

            >
              <option disabled value={0}>
                {' '}
                {intl.formatMessage({ id: "113427", defaultMessage: "Please Select Branch Name" })} ...
              </option>
              {selectedRoleGroupId &&
                branchIdsByRole[selectedRoleGroupId]?.map((branchId, index) => (
                  <option key={index} value={branchId.branch_id}>
                    {branchId.branch_name}
                  </option>
                ))
              }
              </select>
          

          </div>
          
          <div>

            <TextField
              id="DateFrom"
              type="date"
              label= {intl.formatMessage({ id: "110009" ,defaultMessage:"Date From"})}
              defaultValue={dateFrom}
              style={{
                height: '38px',
                width: '185px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={name =>
                changeReportDateFrom(name.target.value, page, sizePerPage)
              }
            />
            <TextField
              id="DateFrom"
              type="date"
              label={intl.formatMessage({ id: "110010" ,defaultMessage:"Date To"})}
              defaultValue={dateTo}
              style={{
                height: '38px',
                width: '185px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={name =>
                changeReportDateTo(name.target.value, page, sizePerPage)
              }
            />
            <button
              type="button"
              id="button"
              className="btn btn-primary mt-1 mb-3 ml-3"
              onClick={() => handleSearch(reportValue, search)}
            >
              {intl.formatMessage({ id: "110005" ,defaultMessage:"Search"})}
</button>
          </div>
        </div>

        {
          is_loading ? <div className="row">
            <div style={{
              position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
            }}>

              <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-secondary spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-success spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-danger spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-warning spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-info spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-light spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-dark spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
            : null
        }


        {Number(reportValue) !== 0 && reportData.length > 0 ? (
          <>
            <ToolkitProvider keyField={reportValue} exportCSV>
              {props => (
                <div>
                  {loading === true ? (
                    <button
                      style={{ width: '150px' }}
                      className="btn btn-primary"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : roleList.includes('EXPORT') ? (
                    <button
                      type="button"
                      id="button"
                      className="btn btn-primary"
                      style={{ width: '150px' }}
                      onClick={() => exportExcel()}
                      disabled={is_loading}
                    >
                      Export CSV
                    </button>
                  ) : null}

                  <BootstrapTable
                    {...props.baseProps}
                    data={reportData}
                    columns={col}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                  />

                  <div className=" col-lg-12">
                    {totalPage > 1 ? (
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mt-3">
                            {'Items per Page: '}
                            <select
                              onChange={handlePageSizeChange}
                              value={sizePerPage}
                              disabled={is_loading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <Pagination
                            disabled={is_loading}
                            className="mt-3 "
                            count={totalPage}
                            page={page}
                            siblingCount={1}
                            boundaryCount={1}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <span className="react-bootstrap-table-pagination-total">
                    Showing {page === 1 ? 1 : sizePerPage * (page - 1) + 1} to{' '}
                    {page === 1
                      ? sizePerPage
                      : page === totalPage
                      ? totalSize
                      : sizePerPage * page}{' '}
                    of {totalSize} Results
                  </span> */}
                </div>
              )}
            </ToolkitProvider>
          </>
        ) : (
          ''
        )}
      </CardBody>
    </Card >
  )
}
export default ReportIndex;