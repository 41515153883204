// src/pages/NewPage.js
import React from "react";
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   CardHeaderToolbar,
// } from "../../_metronic/_partials/controls";
// import { Link } from "react-router-dom";

export default function PageNew() {
  return (
    <div>
      NewPage
    </div>
    // <Card>
    //   <CardHeader title="Menu ">
    //     <CardHeaderToolbar>
    //     </CardHeaderToolbar>
    //   </CardHeader>
    //   <CardBody>
    //     NewPage
    //   </CardBody>
    // </Card>
  );
};