import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { temp_store } from "../../../modules/function/temp_store"

export const EditMenuOption = (props) => {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "106038",defaultMessage:"Edit Menu Option" }));
  const history = useHistory();
  const [option_group_id, setOption_group_id] = useState();
  const [option_name, setOption_name] = useState();
  const [option_name_en, setOption_name_en] = useState();
  const [is_show_price, setIs_show_price] = useState(true);
  const [option_price, setOption_price] = useState(0);
  const [state, setState] = useState(true);
  const [group, setGroup] = useState([]);

  const AlertError = (message) => {
    Swal.fire("Menu Option!", message, "error");
  };

  useEffect(() => {
    getGroup();
    // setOption_group_id(temp_store(props).location.state.row.option_group_id);
    setOption_name(temp_store(props).location.state.row.option_name);
    setOption_name_en(temp_store(props).location.state.row.option_name_en);
    setIs_show_price(temp_store(props).location.state.row.is_show_price);
    setOption_price(temp_store(props).location.state.row.option_price);
    setState(temp_store(props).location.state.row.enabled);
  }, []);



  const getGroup = async () => {
    let apiUrls = `${baseURL}/management/optiongroup?page=1&limit=10000`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeGroup = (event) => {
    setOption_group_id(event.target.value);
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleChangeSwitchPrice = () => {
    setIs_show_price(!is_show_price);
  };

  const onSubmit = async (data) => {
    if (option_group_id === undefined || option_group_id === "") {
      return AlertError("please select option group.");
    }

    if (option_name === undefined || option_name === "") {
      return AlertError("please insert option name.");
    }

    if (option_name_en === undefined || option_name_en === "") {
      return AlertError("please insert option name en.");
    }

    var events = {
      option_group_id: Number(option_group_id),
      option_name,
      option_name_en,
      is_show_price,
      option_price,
      option_order: 1,
      enabled: state
    };


    let apiUrls = `${baseURL}/management/option/${temp_store(props).location.state.row.id}`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Edit !",
          "Your can not update option. !! " + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "106038",defaultMessage:"Edit Menu Option" })}></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              {intl.formatMessage({ id: "106014",defaultMessage:"Group" })}:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={option_group_id}
              onChange={handleChangeGroup}
            >
              <option value="">{intl.formatMessage({ id: "106015",defaultMessage:"Please select" })}</option>
              {group &&
                group.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.option_group_name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              {intl.formatMessage({ id: "106016",defaultMessage:"Name th" })}:{" "}
            </p>
            <input
              name="option_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={option_name}
              onChange={(e) => setOption_name(e.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              {intl.formatMessage({ id: "106017",defaultMessage:"Name en" })}:{" "}
            </p>
            <input
              name="option_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={option_name_en}
              onChange={(e) => setOption_name_en(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "106018",defaultMessage:"Show Price" })}:{" "}
            </p>
            <Switch
              checked={is_show_price}
              onChange={() => handleChangeSwitchPrice()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          {is_show_price ?
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({ id: "106019",defaultMessage:"Option Price" })}:{" "}
              </p>
              <input
                type="number"
                name="option_price"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={option_price}
                defaultValue={0}
                onChange={(e) => setOption_price(e.target.value)}
              />
            </div>
            : null}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "106020",defaultMessage:"Enable" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/MenuOption">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "106021",defaultMessage:"Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "106022",defaultMessage:"Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
