import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isNull } from "lodash";
import { useIntl } from "react-intl";
import { temp_store } from "../../../modules/function/temp_store";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditZone = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113063", defaultMessage: "Edit Zone" })
  );
  const history = useHistory();
  const classes = useStyles();
  const [item_name, setItem_name] = useState();
  const [item_name_en, setItem_name_en] = useState();
  const [description, setDescription] = useState();
  const [description_en, setDescription_en] = useState();
  const [displayOrder, setDisplayOrder] = useState(1);
  const [state, setState] = useState(true);

  const allDataGroup = [];

  const AlertError = (message) => {
    Swal.fire(
      intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }),
      message,
      "error"
    );
  };

  useEffect(() => {
    setItem_name(temp_store(props).location.state.row.zone_name);
    setItem_name_en(temp_store(props).location.state.row.zone_name_en);
    setDescription(temp_store(props).location.state.row.zone_desc);
    setDescription_en(temp_store(props).location.state.row.zone_desc_en);
    setDisplayOrder(temp_store(props).location.state.row.display_order);

    setState(temp_store(props).location.state.row.enabled);
  }, []);

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const onSubmit = async (data) => {
    if (item_name === undefined || item_name === "") {
      return AlertError(
        intl.formatMessage({
          id: "113051",
          defaultMessage: "Please insert item name.",
        })
      );
    }
    if (item_name_en === undefined || item_name_en === "") {
      return AlertError(
        intl.formatMessage({
          id: "113054",
          defaultMessage: "Please insert item name en.",
        })
      );
    }

    var events = {
      zone_name: item_name,
      zone_name_en: item_name_en,
      zone_desc: description ? description : " ",
      zone_desc_en: description_en ? description_en : " ",
      display_order: displayOrder ? displayOrder : 1,
      enabled: state,
    };

    let apiUrls = `${baseURL}/management/tablezone/${
      temp_store(props).location.state.row.id
    }`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          intl.formatMessage({ id: "113056", defaultMessage: "Edit !" }),
          intl.formatMessage({
            id: "113057",
            defaultMessage:
              "Sorry for the inconvenience. Please check your data again.",
          }),
          +err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113063",
            defaultMessage: "Edit Zone",
          })}
        ></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>*</span>
              {intl.formatMessage({
                id: "113033",
                defaultMessage: "Item name th",
              })}
              :{" "}
            </p>
            <input
              name="item_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={item_name}
              onChange={(item_name) => setItem_name(item_name.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>*</span>
              {intl.formatMessage({
                id: "113034",
                defaultMessage: "Item name en",
              })}
              :{" "}
            </p>
            <input
              name="item_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={item_name_en}
              onChange={(item_name_en) =>
                setItem_name_en(item_name_en.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113035",
                defaultMessage: "Description",
              })}
              :{" "}
            </p>
            <input
              name="description"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113036",
                defaultMessage: "Description en",
              })}
              :{" "}
            </p>
            <input
              name="description_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description_en}
              onChange={(description_en) =>
                setDescription_en(description_en.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>*</span>
              {intl.formatMessage({
                id: "104023",
                defaultMessage: "display order",
              })}
              :{" "}
            </p>
            <input
              name="displayOrder"
              min="1"
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={displayOrder}
              onChange={(displayOrder) =>
                setDisplayOrder(displayOrder.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113037", defaultMessage: "Enabled" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/TableZone">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113038", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113039", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
