import React from 'react';
import StickerOrder from './StickerOrder';
const StickerTemplate = ({ table_name, qr_img, qrCode }) => {
  return (
    <div
      id={`download-${qrCode}`}
      className="qrdiv qr-background"
      style={{
        position: 'relative',
        height: '80mm',
        width: '55mm',
        display: 'block',
      }}
    >
      {/* Background SVG */}
      <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}>
        <StickerOrder />
      </div>
      {/* QR Code */}
      <div
        style={{
          position: 'absolute',
          top: '67px',
          right: '33px',
          zIndex: 2,
        }}
      >
        <img src={qr_img} alt="QR Code" width={140} height={140} />
      </div>

      {/* Table Name */}
      <h1
        style={{
          position: 'absolute',
          left: '50%',
          top: '10px',
          transform: 'translateX(-50%)',
          fontSize: '40px',
          fontWeight: 'bold',
          color: '#ffffff',
          zIndex: 2,
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {table_name.substring(0, 9)}
      </h1>
    </div>
  );
};

// style = {{
//   position: 'absolute',
//     left: '65px',
//       top: '18px',
//         fontSize: '25px',
//           fontWeight: '900',
//             color: '#fff'
// }}>
export default StickerTemplate;