import React from 'react';

class SquareTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table_name: this.props.table_name,
      qr_img: this.props.qr_img,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div
          id="testqrdiv"
          className="qrdiv"
          style={{
            height: '103.5mm',
            width: '103.5mm',
            position: 'relative',
            display: 'block',
          }}
        >
          {/* แทนที่ backgroundImage ด้วย <img> สำหรับภาพพื้นหลัง */}
          <img
            src="/media/qr_template/sticker10x10-order.png"
            alt="background"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />

          {/* แสดง QR Code */}
          <div
            style={{
              position: 'absolute',
              top: '120px',
              right: '120px',
              zIndex: 2,
            }}
          >
            <img src={this.state.qr_img} alt="QR Code" width={150} height={150} />
          </div>

          {/* แสดง Table Name */}
          <h1
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              fontSize: '50px',
              fontWeight: '900',
              color: '#fff',
              zIndex: 2,
              whiteSpace: 'nowrap',

            }}
          >
            {this.state.table_name}
          </h1>
        </div>
      </React.Fragment>
    );
  }
}

export default SquareTemplate;
