import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditMenuGroupAdmin = (props) => {
  const intl = useIntl();
  console.log('props CreateMenuGroup', props);
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "113193" ,defaultMessage:"Menu Group Admin"}));
  const history = useHistory();
  const [group_name, setGroup_name] = useState();
  const [group_name_en, setGroup_name_en] = useState();
  const [group_name_cn, setGroup_name_cn] = useState('-');
  const [state, setState] = useState(true);
  const [displayOrder, setDisplayOrder] = useState(0)
  const [type, setType] = useState('component')
  const [branchlist, setBranchList] = useState([]);
  const [branch, setBranch] = useState([]);
  const [listBranchBroup, setListBranchBroup] = useState([])
  const [listBranch, setListBranch] = useState([])
  useEffect(() => {
    if(props.type === 'dialog' ){
      setType(props.type);
    }
    console.log(props)
    setGroup_name(props.location.state.row.group_name)
    setGroup_name_en(props.location.state.row.group_name_en)
    setGroup_name_cn(props.location.state.row.group_name_cn)
    getBranchByCenterID(props.location.state.row.center_itemGroup_id)
    setDisplayOrder(props.location.state.row.display_order)
    setState(props.location.state.row.enabled)
    getListBranchGroup(null);
  }, []);
  const getBranchByCenterID =async(centerID)=> {
    let apiUrls = `${baseURL}/branchGroup/branch/group/${centerID}`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setBranchList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    
  }

  const getListBranchGroup = (ref, filter=null) => {
    var REF = ref === null ? '' : { "pos-branch": ref }
    let token = localStorage.getItem("Token");
    const apiUrls = `${baseURL}/branchGroup${filter ? `?branchGroup=${filter}` : ''}`;
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        ...REF
      },
    })
      .then(res => {
        setListBranchBroup(res.data.data.branch_group)
        setListBranch(res.data.data.branch)
        console.log(res.data.data.branch)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const addAllGroup = () => {
    setBranch(listBranch)
  };
  const getBranch = async () => {
    let apiUrls = `${baseURL}/branchGroup/branch`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setBranchList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AlertError = (message) => {
    Swal.fire("Menu Group!", message, "error");
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const onSubmit = async (data) => {
    if (group_name === undefined || group_name === "") {
      return AlertError("please insert name th.");
    }

    if (group_name_en === undefined || group_name_en === "") {
      return AlertError("please insert name en.");
    }
    if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0 || Number(displayOrder) < 0) {
      return AlertError('please insert display order.')
    }
    if (branch === undefined || branch.length == 0) {
      return AlertError("please insert branch.");
    }
    let tempBranch = []
    branch.map((item, index)=>{
      tempBranch.push({
        branch_id: item.id,
        shop_id: item.shop_id,
        id: item.id
      })
    })

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");

    var events = {
      shop_id: Number(get_shop_id),
      branch_id: Number(get_branch_id),
      group_name,
      group_name_en,
      group_name_cn,
      enabled: state,
      display_order: displayOrder === 0 ? 0 : displayOrder,
      list_shop: tempBranch
    };

    let apiUrls = `${baseURL}/management/supergroup`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        if (type == 'dialog') {
          props.handle('save')
        } else {
          history.goBack();
        }
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "You can not create group. !! " + err,
          "error"
        );
      });
  };

  const cancelItem = (data) => {
    if (type == 'dialog') {
      props.handle('close')
    } else {
      history.goBack();
    }
  };

  // const handleChangezoneprinter = (event, values) => {
  //   if (values) {
  //     setzoneprinter_id(values.id);
  //   } else {
  //     setzoneprinter_id("");
  //   }
  // };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "103028" ,defaultMessage:"Edit menu group"})}></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
            <span style={{ color: "red" }}>
              *
            </span>
            {intl.formatMessage({ id: "103013",defaultMessage:"Thai name" })}:{" "}
            </p>
            <input
              value={group_name}
              name="group_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setGroup_name(e.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
            <span style={{ color: "red" }}>
              *
            </span>
            {intl.formatMessage({ id: "103014" ,defaultMessage:"english name"})}:{" "}
            </p>
            <input
              value={group_name_en}
              name="group_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setGroup_name_en(e.target.value)}
            />
          </div>
          {<div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%", minWidth: "120px" }}>
            <span style={{ color: "red" }}>
              *
            </span>
            {intl.formatMessage({ id: "103126" ,defaultMessage:"chinese name"})}:{" "}
            </p>
            <input
              value={group_name_cn}
              name="group_name_cn"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setGroup_name_cn(e.target.value)}
            />
          </div>}

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%', minWidth: "120px" }}>
            <span style={{ color: "red" }}>
              *
            </span>
            {intl.formatMessage({ id: "103015" ,defaultMessage:"display order"})}:{' '}
            </p>
            <input
              name="displayOrder"
              type="number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={displayOrder}
              onChange={displayOrder =>
                setDisplayOrder(displayOrder.target.value)
              }
            />
          </div>

          {/* <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Zone printer :{" "}
            </p>

            {zoneprinterData.length > 0 ? (
              <div>
                <Autocomplete
                  id="zone printer"
                  options={zoneprinterData}
                  getOptionLabel={(option) => option.name}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={handleChangezoneprinter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputColor}
                      style={{
                        borderWidth: 0,
                      }}
                      label="Zone printer"
                    />
                  )}
                />
              </div>
            ) : null}

          </div> */}

<div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113152",
                defaultMessage: "Advance setting",
              })}
            </p>
            <div
              style={{
                width: "530px",
              }}
            >
              <div className="d-flex align-items-center">
                
              </div>

              <div className={`box-cheked-show${true ? " d-block" : ""}`}>
                <div
                  className="template mb-3 d-flex align-items-center"
                  style={{
                    width: "530px",
                  }}
                >
                  
                  <Autocomplete
                    id="grouped-demo"
                    options={listBranchBroup}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    //value={branch}
                    onChange={(event, newValue) => {
                      console.log(newValue)
                      setBranch([])
                      if (!newValue) {
                        console.log('x')
                      } else getListBranchGroup(null, newValue.id);
                      //setBranch(newValue);
                    }}
                    style={{
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "15px"
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                  <Autocomplete
                    id="grouped-demo"
                    multiple
                    options={listBranch}
                    getOptionLabel={(option) => option.posShop.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={branch}
                    onChange={(event, newValue) => {
                      //console.log(newValue)
                      //getListBranchGroup(null, newValue.id);
                      setBranch(newValue);
                    }}
                    style={{
                      width: "500px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "15px"
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => addAllGroup()}
                  >
                    {intl.formatMessage({
                      id: "113155",
                      defaultMessage: "All Branch",
                    })}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "103016",defaultMessage:"enable" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => cancelItem()}>
            {intl.formatMessage({ id: "103017" ,defaultMessage:"Cancel"})}
            </button>
            <button  className="btn btn-primary" onClick={() => onSubmit()}>
            {intl.formatMessage({ id: "103018" ,defaultMessage:"Save"})}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
