import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";

export const CreateDeliveryNote = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113168" ,defaultMessage:"Delivery Note"})
  );
  const history = useHistory();
  const [printer_zone_name, setprinter_zone_name] = useState();
  const [printer_zone_name_en, setprinter_zone_name_en] = useState();
  const [state, setState] = useState(true);
  const [shopList, setShopList] = useState([]);
  const [itemList, setitemList] = useState([]);
  const [shop, setShop] = useState(null);
  const [shopShow, setShopShow] = useState(null);
  const [branch, setBranch] = useState(null);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [qty, setQty] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [maxIndex, setMaxIndex] = useState(0);
  const [description, setDescription] = useState('')
  const handleChangeShop = (event, values) => {
    console.log(event, values);
    if (values) {
      setShopShow(values)
      setShop(values.id);
      setBranch(values.posBranch.id)
    } else {
      setShop(null);
    }
  };

  const handleChangeItem = (event, values) => {
    console.log(event, values);
    if (values) {
      setItem({id:values.id, item_name: values.item_name});
    } else {
      setItem(null);
    }
  };

  useEffect(() => {
    gettingShop();
    gettingItem();
  }, []);

  const columns = [
    {
      dataField: "item_id",
      text: "ID",
    },

    {
      dataField: "item_name",
      text: "item_name",
    },

    {
      dataField: "qty",
      text: "quantity",
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem(rowIndex)}
            >
              {intl.formatMessage({ id: "107011", defaultMessage: "delete" })}
            </button>
          </div>
        );
      },
    },
  ];

  const deleteItem = async(rowIndex) => {
    let temp = []
    console.log(listData)
    var array = [...listData]; // make a separate copy of the array
    array.splice(rowIndex, 1);
    setListData(array);
  }
  

  const gettingShop = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/shopGroup`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setShopList(res.data.data);
    });
  };

  const gettingItem = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/shopGroup/item`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setitemList(res.data.data);
    });
  };

  const AlertError = (message) => {
    Swal.fire("Create Delivery Note!", message, "error");
  };
  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleAdd = async() => {
    /*setListData(listData => [...listData, {
      index: maxIndex+1,
      item_id: item.id,
      item_name: item.item_name,
      qty: qty,
    }])*/
    if (!shop) {
      await AlertError('please insert shop')
    } /*else if (!description || description == '') {
      await AlertError('please insert description')
    }*/ else if (!item) {
      await AlertError('please insert item')
    } else if (!qty || qty == 0) {
      await AlertError('please insert quantity ')
    } else {
      let temp = listData
      temp.push({
        index: maxIndex+1,
        item_id: item.id,
        item_name: item.item_name,
        qty: qty,
      })
      setListData(temp)
      setMaxIndex(maxIndex+1)
      setQty(0)
      setIsAdd(true)
      setItem(null)
      setDescription('')
      //setShop(null)
      console.log(listData)
    }
    
  }

  const handleSend = async () => {
    /*if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }*/
    if (shop === undefined || shop === null) {
      return AlertError("Please insert printer zone name en !!");
    }
    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");
    let now= new Date()
    var events = {
      //order_no: "10000",
      order_date: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      description: description == '' ? 'none' : description,
      shop_id: get_shop_id,
      branch_id: get_branch_id,
      list: listData,
      send_shop_id: shop,
      send_branch_id: branch,
  }

    let apiUrls = `${baseURL}/deliveryNote`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Create Delivery Note success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Create Delivery Note. !! " + err,
          "error"
        );
      });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113163",
            defaultMessage: "Create Delivery Note",
          })}
        ></CardHeader>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113164",
                  defaultMessage: "Shop",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="zone printer"
                  disabled={isAdd}
                  options={shopList}
                  getOptionLabel={(option) =>
                    option.posBranch ? option.posBranch.name : "ไม่มีชื่อ"
                  }
                  value={shopShow}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  se
                  onChange={handleChangeShop}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        borderWidth: 0,
                      }}
                      label="Shop"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="container">
          <div className="form-inline" style={{ marginBottom: "25px" }}>
          
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
            {intl.formatMessage({ id: "113169" ,defaultMessage:"Table name th" })}:{" "}
            </p>
            <input
              name="table_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(table_name) => setDescription(table_name.target.value)}
            />
          </div>
              </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113166",
                  defaultMessage: "Item",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="zone printer"
                  options={itemList}
                  value={item}
                  getOptionLabel={(option) =>
                    option.item_name ? option.item_name : "ไม่มีชื่อ"
                  }
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  se
                  onChange={handleChangeItem}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        borderWidth: 0,
                      }}
                      label="item"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113165",
                  defaultMessage: "Amount",
                })}
                :{" "}
              </p>
              <input
                value={qty}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setQty(e.target.value)
                }
              />
              <button
                className="ml-2 btn btn-primary"
                onClick={() => handleAdd()}
              >
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button>
            </div>

            <hr />

            {/*<div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "107015", defaultMessage: "Enabled" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>*/}

            
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
              <Link to="/DeliveryNote">
                <button type="button" className="btn btn-secondary wbt">
                  {intl.formatMessage({
                    id: "107016",
                    defaultMessage: "Cancel",
                  })}
                </button>
              </Link>
              <button className="btn btn-primary" onClick={() => handleSend()}>
                {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
              </button>
            </div>
        </CardBody>
      </Card>
    </div>
  );
};
