import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from "../service/role";
import { Box } from "@material-ui/core";

export const PurchasePlan = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const allData = [];
  const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  suhbeader.setTitle(
    intl.formatMessage({ id: "999999999999", defaultMessage: "Purchase Plan" })
  );
  const { authToken } = useSelector((state) => state.auth);
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [roleList, setRoleList] = useState([]);

  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem("edit");
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const data = await roleCheck("DELIVERY_NOTE");
    setRoleList(data);
    console.log(data);
    if (!data.includes("VIEW")) {
      history.push("/auth/login");
    }
    let value = 1;
    if (localStorage.getItem("pagePrinterZone") != null) {
      value = localStorage.getItem("pagePrinterZone");
    }
    let campaigns_id_headders = localStorage.getItem("campaigns_id_headder");
    setCampaigns_id_headder(campaigns_id_headders);
    getList(value, 10, search, campaigns_id_headders);
  };
  useEffect(()=>{
   setSettingPrinterZoneData(prev=>prev)
},[settingPrinterZone]);
  const getList = async (page, limit, search, campaigns_id_headders) => {
    setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/purchase/plan?page=${page}&limit=${limit}${
        search !== undefined && search !== "" ? "&search=" + search : ""
      }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((item, index) => {
          console.log(item);
          allData.push(item);
        });
      }

      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.limit);
      setSettingPrinterZoneData(allData);
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "created_at",
      text: "created at",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updated_at",
      text: "updated at",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "150px" }}>
            {roleList.includes("EDIT") && (
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => {
                  editItem({ id, row, rowIndex });
                }}
              >
                {intl.formatMessage({ id: "107010", defaultMessage: "Edit" })}
              </button>
            )}

            {roleList.includes("DELETE") && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteItem({ row, rowIndex })}
              >
                {intl.formatMessage({ id: "103011", defaultMessage: "delete" })}
              </button>
            )}
          </div>
        );
      },
    },
  ];
  const editItem = (data) => {
    history.push("/EditPurchasePlan", data);
  };

  
  const deleteItem = (data) => {
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this menu group !",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/purchase/plan/${data.row.id}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Swal.fire(
              "Deleted!",
              "Your Plan has been deleted.",
              "success"
            );
            setSettingPrinterZoneData(prev => prev.filter(x=>x.id !== data.row.id))
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Plan has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your menu group is safe :)", "error");
      }
    });
  };

  const viewItem = (data) => {
    history.push("/ViewPurchaseRequest", data);
  };

  /* const deleteItem = (data) => {
        let token = localStorage.getItem("Token");
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this template !",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                axios.delete(`${baseURL}/deliveryNote/${data.row.id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }).then((res) => {
                    Swal.fire("Deleted!", "Your template has been deleted.", "success");
                    getList(1, limit, search, campaigns_id_headder);
                }).catch((err) => {
                    Swal.fire(
                        "Deleted!",
                        "Your template has been fail to deleted.",
                        "error"
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your template is safe :)", "error");
            }
        });
    }; */
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getList(1, limit, search, campaigns_id_headder);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    localStorage.setItem("pagePrinterZone", value);
    getList(value, limit, search, campaigns_id_headder);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getList(1, event.target.value, search, campaigns_id_headder);
  };

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "999999999999",
          defaultMessage: "Purchase Plan",
        })}
      >
        <CardHeaderToolbar>
          {roleList.includes("CREATE") && (
            <Link to="/CreatePurchasePlan">
              <button type="button" className="btn btn-primary">
                {intl.formatMessage({ id: "107009", defaultMessage: "Create" })}
              </button>
            </Link>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "107002",
                  defaultMessage: "Text Search",
                })}
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                {intl.formatMessage({ id: "107003", defaultMessage: "search" })}
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          keyField="id"
          data={settingPrinterZone}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        >
          <PleaseWaitMessage entities={settingPrinterZone} />
          <NoRecordsFoundMessage entities={settingPrinterZone} />
        </BootstrapTable>
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {intl.formatMessage({
                  id: "107032",
                  defaultMessage: "Item per page",
                })}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={
                  localStorage.getItem("pagePrinterZone") != null
                    ? Number(localStorage.getItem("pagePrinterZone"))
                    : page
                }
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
