import React, { useState, useEffect, useRef } from 'react'
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls'
import { useSubheader } from '../../_metronic/layout'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import BootstrapTable from 'react-bootstrap-table-next'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import Chart from 'react-apexcharts'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from "react-bootstrap"
import { useSelector } from 'react-redux'
import { useIntl } from "react-intl";
import * as echarts from 'echarts';
import { Fragment } from 'react'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import "./component.css"


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 3
  },
  papers: {
    padding: theme.spacing(2),
    marginBottom: '20px',
    marginTop: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginBottom: '20px',
    marginTop: '20px',
    maxWidth: '500px'
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}))

export const DashboardPage = (props) => {
  const intl = useIntl();
  const chartRef = useRef(null);
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  const buttonOnclick = {border: "2px solid", borderRadius: "10px", borderColor: "#8FC5FF"}
  const buttonDefault = {border: "2px solid", borderRadius: "10px", borderColor: "#F4F4F4"}
  var d = new Date()

  d.setDate(d.getDate() - 30)
  const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [chart, setChart] = useState([])
  const [tableData, setTableData] = useState([])
  const [topproduct, settopproduct] = useState()
  const [topcategory,setTopCategory ] = useState()
  const [bill,setBill] = useState()
  const [tableCol, setTableCol] = useState([])
  const [buttonStyle, setButtonStyle] = useState(buttonDefault);
  const [buttonToday, setButtonToday] = useState(buttonOnclick);
  const [buttonMonth, setButtonMonth] = useState(buttonDefault);
  const [buttonWeek, setButtonWeek] = useState(buttonDefault);
  const [buttonRange, setButtonRange] = useState(buttonDefault);
  const [startDateShow , setStartDateShow] = useState(moment().format('YYYY-MM-DD'))
  const [endDateShow , setEndDateShow] = useState(moment().format('YYYY-MM-DD'))
  const [show, setShow] = useState(false);
 
  const token = localStorage.getItem("Token");

  const classes = useStyles()
  suhbeader.setTitle(intl.formatMessage({ id: "102010", defaultMessage: "Dashboard" }))
  useEffect(() => {
    var dataPie = {
      date_from: moment().format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD')
    }
    console.log("***********(date default)**********",dataPie)
    getPieChart(dataPie)
  }, [])
  const handleClose = () => setShow(false);
  console.log("***********(setShow)*************",show)

  const handleShow = () => setShow(true);
  const [checkData, setCheckData] = useState(false)
  const [barState, setBarState] = useState()
  const [barState2, setBarState2] = useState()
  const [barState3, setBarState3] = useState()
  const [pieState, setPieState] = useState()


  const handleSearch = () => {
    setChart([])
    setTableData([])
    var startDate = moment(dateFrom).format('YYYY-MM-DD')
    var endDate = moment(dateTo).format('YYYY-MM-DD')
    var dataPie = {
      date_from: startDate,
      date_to: endDate
    }
    getPieChart(dataPie);
    setStartDateShow(startDate);
    setEndDateShow(endDate);
    setButtonRange(buttonOnclick);
    setButtonToday(buttonDefault);
    setButtonWeek(buttonDefault);
    setButtonMonth(buttonDefault);
    handleClose();
  };
  const handleSearchToday = () => {
    setChart([])
    setTableData([])
    var dataPie = {
      date_from: moment().format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD')
    }
    console.log("********date Today*********",dataPie)
    getPieChart(dataPie);
    setButtonToday(buttonOnclick);
    setButtonWeek(buttonDefault);
    setButtonMonth(buttonDefault);
    setButtonRange(buttonDefault);
    setStartDateShow(moment().format('YYYY-MM-DD'));
    setEndDateShow(moment().format('YYYY-MM-DD'));
  };
  const handleSearchPrevWeek = () => {
    setChart([])
    setTableData([])
    var startDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
    var endDate = moment().format('YYYY-MM-DD')
    var dataPie = {   
      date_from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD')
    }
    console.log("********date 1 week Ago*********",dataPie)
    getPieChart(dataPie);
    setButtonToday(buttonDefault);
    setButtonWeek(buttonOnclick);
    setButtonMonth(buttonDefault);
    setButtonRange(buttonDefault);
    setStartDateShow(startDate);
    setEndDateShow(endDate);
  };
  const handleSearchPrevMonths = () => {
    setChart([])
    setTableData([])
    var startDate = moment().subtract(1, 'months').format('YYYY-MM-DD')
    var endDate = moment().format('YYYY-MM-DD')
    var dataPie = {
      date_from: startDate,
      date_to: endDate
    }
    console.log("********date 1 month Ago*********",dataPie)
    getPieChart(dataPie);
    setButtonToday(buttonDefault);
    setButtonWeek(buttonDefault);
    setButtonMonth(buttonOnclick);
    setButtonRange(buttonDefault);
    setStartDateShow(startDate);
    setEndDateShow(endDate);
  };

  const getPieChart = data => {
    let lang = localStorage.getItem("Language");
    
    axios({
      method: "get",
      url: `${baseURLDASHBOARD}/dashboard/render?dateFrom=${data.date_from}&dateTo=${data.date_to}`,
      headers: {
        Accept: "application/json",
        "Accept-Language": (lang ? lang : "en"),
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      const result = res.data.find(item => item.title === "Best Selling Menu/day");
      const result2 = res.data.find(item => item.title === "Total Week");
      const result3 = res.data.find(item => item.title === "Best Selling Category/day");
      const result4 = res.data.find(item => item.title === "Bill");

       
      console.log('*********result2**********',result2);
      
      if (result) {
        settopproduct(result.data)
      } else {
        settopproduct()
      }
      if (result3){
        setTopCategory(result3.data)

      }else{
        setTopCategory()

      }
      if (result4){
        setBill(result4.data)

      }else{
        setBill()

      }
    console.log(res.data);
    
      setPieState()
      setBarState()
      var count = 0
      var count_data = []
      var item_table = []
      console.log('item_table',item_table);
      console.log("*******res.data********",res.data)
      if (res.data.length > 0) {
        res.data.map((re_data, i) => {
          if (re_data.type === 'summary') {
            var option = {
              id: re_data.id,
              type: 'summary',
              boxBackgroundColor: re_data.boxBackgroundColor,
              boxBackgroundType: re_data.boxBackgroundType,
              boxSize: "mb-3 col-12 col-sm-6",
              title: re_data.title,
              titleFontSize: re_data.titleFontSize,
              titleFontColor: re_data.titleFontColor,
              dataFontSize: re_data.dataFontSize,
              dataFontColor: re_data.dataFontColor,
              data: re_data.data,
              titleIcon: re_data.titleIcon
            }
            if (re_data.dataMapping !== "totalTable" /*&& re_data.title !== "Total Bill"*/) {
              count_data.push(option);
            }
          }

          else {
            if (re_data.subType === 'pie') {
              var key = []
              var value = []

              re_data.data.map(data => {
                value.push(Number(data.total))
                key.push(data.name)
              })

              var option = {
                series: value,
                type: 'pie',
                boxBackgroundColor: re_data.boxBackgroundColor,
                boxBackgroundType: re_data.boxBackgroundType,
                boxSize: re_data.boxSize,
                options: {
                  colors: ['#E98C23', '#E12441', '#4D7AB6', '#2A2458', '#3E9C48', '#B5C8A4', '#592568', '#FEF9C4', '#F7B555', '#A41C32', '#D3242C', '#F17B20',],
                  title: {
                    text: re_data.title ? re_data.title : null
                  },
                  chart: {
                    toolbar: {
                      show: true,
                      offsetX: 0,
                      offsetY: 0,
                      tools: {
                        download: true
                      },
                      export: {
                        svg: {
                          filename: 'undefined',
                          columnDelimiter: ',',
                          headerCategory: 'category',
                          headerValue: 'value',
                          dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                          }
                        }
                      },
                      autoSelected: 'zoom'
                    },
                    width: 380,
                    type: 'pie'
                  },
                  labels: key,
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200
                        },
                        legend: {
                          position: 'bottom'
                        }
                      }
                    }
                  ]
                }
              }

              count_data.push(option)
            } else if (re_data.subType === 'bar') {
              var item = []

              re_data.data.map(data => {
                item.push({
                  data: [{ x: data.name, y: data.total }],
                  name: data.name,
                  group: data.group ? data.group : "group"
                })
              })
              var option = {
                type: 'bar',
                colors: ['#F44336', '#E91E63', '#9C27B0'],
                boxBackgroundColor: re_data.boxBackgroundColor,
                boxBackgroundType: re_data.boxBackgroundType,
                boxSize: re_data.boxSize,
                series: item,
                options: {
                  colors: re_data.colors ? re_data.colors : ['#E98C23', '#E12441', '#4D7AB6', '#2A2458', '#3E9C48', '#B5C8A4', '#592568', '#FEF9C4', '#F7B555', '#A41C32', '#D3242C', '#F17B20'],
                  title: {
                    text: re_data.title ? re_data.title : null
                  },
                  chart: {
                    type: 'bar',
                    toolbar: { tools: { download: true } },
                    height: 350
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  labels: item.map((data) => { return data.group }),
                  xaxis: {
                    type: 'category',
                    labels: {
                      show: false,
                    },
                    title: {
                      text: re_data.title ? re_data.title : null
                    }
                  }
                }
              }
              count_data.push(option)
            }
            else if (re_data.subType === 'line') {
              var item = []
              var itemX = []

              re_data.data.map(data => {
                item.push(data.total)
                itemX.push(data.name)
              })
              var option = {
                type: "line",
                boxSize: re_data.boxSize,
                options: {
                  title: {
                    text: re_data.title ? re_data.title : null
                  },
                  xaxis: {
                    categories: itemX,
                    tickPlacement: 'between',
                    title: {
                      text: re_data.title ? re_data.title : null
                    },
                    tickAmount: 14
                  },
                  chart: {
                    offsetX: 0
                  }
                },
                series: [{
                  name: re_data.title ? re_data.title : null,
                  data: item
                }],
              };
              count_data.push(option)
            }
            else if (re_data.type === 'table') {
              var keys = []
              var newState = []
              keys = Object.keys(re_data.data[0])
              keys.map(async key => {
                if (key === "Image") {
                  newState.push({
                    dataField: key,
                    text: key,
                    sort: true,
                    sortCaret: sortCaret,
                    headerSortingClasses,
                    headerStyle: (colum, colIndex) => {
                      return { width: "100px" };
                    },
                    formatter: (id, row, rowIndex) => {
                      return (
                        <div>
                          <img
                            src={row.Image}
                            style={{ width: "100px", height: "auto" }}
                          />
                        </div>
                      );
                    },
                  })
                } else {
                  newState.push({
                    dataField: key,
                    text: key,
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { width: "100px" };
                    },
                    sortCaret: sortCaret,
                    headerSortingClasses
                  })
                }
              })
              item_table.push([{ data: re_data.data }, { col: newState }, { title: re_data.title }])
        
            }
            
          }
        })

      }
      console.log("*********(another result)**********",count_data)
      const customOrder = [2, 28, 30, 26, 27, 9];

      // Sorting the array by custom order of ids
      count_data.sort((a, b) => customOrder.indexOf(a.id) - customOrder.indexOf(b.id));

      setChart(count_data)
     
      setTableData(item_table)

      const chartInstance2 = echarts.init(chartRef.current);
      console.log("***********(chart Ref)**********",chartRef);
      console.log("***********(chartInstance2)**********",chartInstance2);


      const option = {
        title: {
          text: intl.formatMessage({ id: "113265" , defaultMessage:"Sales" }) //ยอดขาย
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: ['Last week', 'This week']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          //data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
          data: Object.keys(result2.data[0]).filter(key => key !== 'data')
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [
          {
            name: 'Last week',
            type: 'line',
            //stack: 'Total',
            data: [result2.data[1].Sun, result2.data[1].Mon, result2.data[1].Tue, result2.data[1].Wed, result2.data[1].Thu, result2.data[1].Fri, result2.data[1].Sat]
            //data: [0, 0, 0, 0, 0, result2.data[0].Fri, result2.data[0].Sat]
            // data: Object.keys(result2.data[0])
            //   .filter(key => key !== 'data')
            //   .map(key => result2.data[1][key])
          },
          {
            name: 'This week',
            type: 'line',
            //stack: 'Total',
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: '#e7487d' },
                  { offset: 1, color: '#c42eaf' },
                  { offset: 1, color: '#a212dd' },
                ]
              }
            },
            data: [result2.data[0].Sun, result2.data[0].Mon, result2.data[0].Tue, result2.data[0].Wed, result2.data[0].Thu, result2.data[0].Fri, result2.data[0].Sat]
            // data: Object.keys(result2.data[0])
            //   .filter(key => key !== 'data')
            //   .map(key => result2.data[0][key])
          }
        ]
      };
      console.log("*******(option)********",option)


      option && chartInstance2.setOption(option);

      return () => {
        chartInstance2.dispose();
      };


    })
      .catch(err => {
        console.log(err)
      })
  }

  const handleChangeDateFrom = date => {
    setDateFrom(date)
  }
  const handleChangeDateTo = date => {
    setDateTo(date)
  }
  return (
    <div>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "102010", defaultMessage: "Dashboard" })}></CardHeader>
        <CardBody>
          <button
            type="button"
            id="button"
            className="btn mb-3 mr-3 ml-3 mt-1"
            style={buttonToday}
            onClick={() => handleSearchToday()}
          >
            {intl.formatMessage({ id: "113343", defaultMessage: "Today" })}
          </button>
          <button
            type="button"
            id="button"
            className="btn mb-3 mr-3 ml-3 mt-1"
            style={buttonWeek}
            onClick={() => handleSearchPrevWeek()}
          >
            {intl.formatMessage({ id: "113344", defaultMessage: "7 days" })}
          </button>
          <button
            type="button"
            id="button"
            className="btn mb-3 mr-3 ml-3 mt-1"
            style={buttonMonth}
            onClick={() => handleSearchPrevMonths()}
          >
            {intl.formatMessage({ id: "113345", defaultMessage: "30 days" })}
          </button>
          <button
            type="button"
            id="button"
            className="btn mb-3 mr-3 ml-3 mt-1"
            style={buttonRange}
            onClick={() => handleShow()}
          >
            <CalendarTodayIcon style={{ fontSize: "small", paddingBottom: "2px" }} />
            {moment(startDateShow).format('DD MMMM YYYY')} - {moment(endDateShow).format('DD MMMM YYYY')}
          </button>
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header>
              <div className='h3'>
                Select Date
              </div>
            </Modal.Header>
            <Modal.Body>
              <TextField
                id="DateFrom"
                type="date"
                label={intl.formatMessage({ id: "102011", defaultMessage: "Date From" })}
                defaultValue={startDateShow}
                className=" mb-3 mr-3 ml-3"
                style={{
                  height: '38px',
                  width: '185px',
                  backgroundColor: '#F4F6F9',
                  borderRadius: '5px',
                  borderWidth: '0'
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={name => {
                  handleChangeDateFrom(name.target.value)
                }}
              />

              <TextField
                id="DateFrom"
                type="date"
                label={intl.formatMessage({ id: "102012", defaultMessage: "Date To" })}
                defaultValue={endDateShow}
                className=" mb-3 mr-3"
                style={{
                  height: '38px',
                  width: '185px',
                  backgroundColor: '#F4F6F9',
                  borderRadius: '5px',
                  borderWidth: '0'
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={name => {
                  handleChangeDateTo(name.target.value)
                }}
              />
              <div class="row justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary mb-3 mr-5 mt-1"
                  onClick={() => handleClose()}
                >
                  {intl.formatMessage({ id: "103017", defaultMessage: "Cancel" })}
                </button>
                <button
                  type="button"
                  id="button"
                  className="btn btn-primary mb-3 mr-3 ml-3 mt-1"
                  onClick={() => handleSearch()}
                >
                  {intl.formatMessage({ id: "102013", defaultMessage: "Search" })}
                </button>


              </div>

            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
          {
            checkData ?
              <div className="row">
                <div style={{
                  position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
                }}>
                  {/* <h1>Dashboard No Data</h1> */}
                </div>
              </div>
              :
              chart.length > 0 ? null :
                <div className="row">
                  <div style={{
                    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
                  }}>

                    <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-danger spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-warning spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-info spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-light spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-dark spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
          }
          {/* tab group */}
          <div className="row">
           {
           chart.map((data, i) => {
            
              return (
                <>
                  {String(data.type) === 'summary' ? (
                    <div className= "mb-3 col-6 col-sm-4" key={i}>
                      <Paper
                        className={classes.paper}
                        style={buttonDefault}
                        //style={{ backgroundColor: `${data.boxBackgroundColor}` }}
                      >
       
                        <span
                          style={{
                            fontSize: `${data.titleFontSize}`,
                            color: `${data.titleFontColor}`,
                            fontWeight: "bold"
                          }}
                        >
                          {data.title}
                        </span>
                        <div className="d-flex justify-content-between">
                          {data.id !== 2 && data.id !== 28 ?(
                          <span 
                            style = {{
                              width: "10%",
                              fontSize: `${data.titleFontSize}`,
                              color: `${data.titleFontColor}`,
                              fontWeight: "bold"
                            }}
                            >
                            ฿
                          </span>
                          ):(
                          <span 
                          style = {{
                            width: "10%",
                            fontSize: `${data.titleFontSize}`,
                            color: `${data.titleFontColor}`,
                            fontWeight: "bold"
                          }}>

                          </span>  
                          )}

                          <span
                            style={{
                              width: "100%",
                             textAlign: "left",
                              fontSize: `${data.dataFontSize}`,
                              color: `${data.dataFontColor}`,
            
                            }}
                          >
                            {data.data.toLocaleString()}
                          </span>

                          {/* <img
                            src={data.titleIcon}
                            style={{
                              width: '50px',
                              height: '50px',
                              textAlign: 'right'
                            }}
                          /> */}
                        </div>
                        <br />
                      </Paper>
                    </div>
                  ) : null}
                </>
              );
            })
            }
          </div>


          {/* <div className="row">
            {chart.map((data, i) => {
              return (
                <>
                  {String(data.type) === 'summary' ? null : (
                    <div className={`${data.boxSize}`}>
                      <Paper className={classes.papers}>

                        <Chart
                          options={data.options}
                          series={data.series}
                          type={`${data.type}`}
                          height={300}
                        />

                      </Paper>
                    </div>
                  )}
                </>
              )
            })}
          </div>
          {tableData.length > 0 ?
            tableData.map((datas, key) => {

              return (
                <Paper className={classes.papers}>
                  <CardHeader title={datas[2].title}></CardHeader>
                  
                  <BootstrapTable
                    keyField="id"
                    {...props.baseProps}
                    data={datas[0].data === '' ? null : datas[0].data}
                    columns={datas[1].col === '' ? null : datas[1].col}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                  />
                </Paper>
              )
            })
            : null} */}
        </CardBody>
      </Card>
 


      <div className='row gy-5 gx-xl-8 mb-5'>
        <div className='col-xxl-4'>


          {/* <div className={`card card-flush  setScroll`} style={{ height: '330px' }}>
                <div className='card-header pt-5'>
                  <h3 className='card-title text-gray-800 fw-bold'>เมนูขายดี</h3>
                  <div className='card-toolbar'></div>
                </div>
                <div className='card-body pt-5'>
                  <Fragment key={`lw26-rows-${11}`}>
                    <div className='d-flex flex-stack'>
                      <button className='btn btn-icon btn-sm h-auto btn-color-gray-600 btn-active-color-primary justify-content-end'>
                        เมนู
                      </button>
                      <button
                        type='button'
                        className='btn btn-icon btn-sm h-auto btn-color-gray-600 btn-active-color-primary justify-content-end'
                      >
                        ออเดอร์
                      
                      </button>
                    </div>
                    <div className='separator separator-dashed my-3' />
                  </Fragment>
                  {topproduct?.map((data, i) => (
                    <Fragment key={`lw26-rows-${1}`}>
                      <div className='d-flex flex-stack'>
                        <a href='#' className='text-gray-700 fw-semibold fs-6 me-2'>
                          {data?.text}
                        </a>
                        <button
                          type='button'
                          className='btn btn-icon btn-sm h-auto btn-color-gray-600 btn-active-color-primary justify-content-end'
                        >
                          {data?.number}
                        
                        </button>
                      </div>
                    
                    </Fragment>
                  ))}
                </div>
              </div> */}

          {/*--------------best selling category-------------- */}
          <div className="card card-flush h-40 setScroll" style={{ borderRadius: '40px' }}>
            <div className="card-header gy-4" style={{ borderTopLeftRadius: '40px', borderTopRightRadius: '40px' }}>
              <h3 className="card-title text-gray-800 fw-bold" style={{ marginBottom: '-1rem', fontWeight: '700' }}>
                {intl.formatMessage({ id: "113341", defaultMessage: "Best selling category" })}
                {/* เมนูขายดี */}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            <div className="card-body pt-5">
              <div className='table-responsive'>

                <div className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' style={{ display: 'block', textAlign: '-webkit-center' }}>

                  <thead className="stickyHead">
                    <tr className="fw-bold text-muted">
                      <th className="min-w-150px" style={{ textAlign: 'initial' }}>{intl.formatMessage({ id: "113351", defaultMessage: "Category" })}</th>
                      <th className="min-w-100px">{intl.formatMessage({ id: "113349", defaultMessage: "Total" })}</th>
                      <th className="min-w-100px">{intl.formatMessage({ id: "113350", defaultMessage: "Price(฿)" })}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topcategory?.length > 0 ? (
                      topcategory?.map((data, i) => (
                        <tr key={i}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{data.group}</a>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href="#" className="text-dark fw-bold text-hover-primary d-block fs-6">{data.total.toLocaleString()}</a>
                          </td>
                          <td>
                            <a href="#" className="text-dark fw-bold text-hover-primary d-block fs-6">{data.value.toLocaleString()}</a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">
                          <p className="notdata">{intl.formatMessage({ id: "113352", defaultMessage: "no item found" })}</p>
                        </td>
                      </tr>
                    )}
                  </tbody>


                </div>





              </div>
              <div className="separator separator-dashed my-3">
              </div>


            </div>
          </div>



        </div>


        <div className='col-xxl-4'>              
        {/*--------------best selling menu-------------- */}
        <div className="card card-flush h-40 setScroll" style={{ borderRadius: '40px' }}>
          <div className="card-header gy-4" style={{ borderTopLeftRadius: '40px', borderTopRightRadius: '40px' }}>
            <h3 className="card-title text-gray-800 fw-bold" style={{ marginBottom: '-1rem', fontWeight: '700' }}>
              {intl.formatMessage({ id: "113266", defaultMessage: "Best selling menu" })}
              {/* เมนูขายดี */}
            </h3>
            <div className="card-toolbar"></div>
          </div>
          <div className="card-body pt-5">
            <div className='table-responsive'>

              <div className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' style={{ display: 'block', textAlign: '-webkit-center' }}>

                <thead className="stickyHead">
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px" style={{ textAlign: 'initial' }}>{intl.formatMessage({ id: "113348", defaultMessage: "Menu name" })}</th>
                      <th className="min-w-100px">{intl.formatMessage({ id: "113349", defaultMessage: "Total" })}</th>
                      <th className="min-w-100px">{intl.formatMessage({ id: "113350", defaultMessage: "Price(฿)" })}</th>
                  </tr>
                </thead>

                <tbody>
                  {topproduct?.length > 0 ? (
                    topproduct?.map((data, i) => (
                      <tr key={i}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{data.name}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-dark fw-bold text-hover-primary d-block fs-6">{data.total.toLocaleString()}</a>
                        </td>
                        <td>
                          <a href="#" className="text-dark fw-bold text-hover-primary d-block fs-6">{data.value.toLocaleString()}</a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">
                        <p className="notdata">{intl.formatMessage({ id: "113352", defaultMessage: "no item found" })}</p>
                      </td>
                    </tr>
                  )}
                </tbody>


              </div>





            </div>
            <div className="separator separator-dashed my-3">
            </div>


          </div>
        </div>
        </div>

        <div className='col-xxl-4'>              
        {/*--------------bill-------------- */}
        <div className="card card-flush h-40 setScroll" style={{ borderRadius: '40px' }}>
          <div className="card-header gy-4" style={{ borderTopLeftRadius: '40px', borderTopRightRadius: '40px' }}>
            <h3 className="card-title text-gray-800 fw-bold" style={{ marginBottom: '-1rem', fontWeight: '700' }}>
              {intl.formatMessage({ id: "113342", defaultMessage: "Bill" })}

            </h3>
            <div className="card-toolbar"></div>
          </div>
          <div className="card-body pt-5">
            <div className='table-responsive'>

              <div className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' style={{ display: 'block', textAlign: '-webkit-center' }}>

                <thead className="stickyHead">
                  <tr className="fw-bold text-muted">
                  <th className="min-w-140px"></th>
                    <th className="min-w-50px" style={{ textAlign: 'initial' }}>{intl.formatMessage({ id: "113353", defaultMessage: "Total Bills" })}</th>
                    <th className="min-w-100px">{intl.formatMessage({ id: "113350", defaultMessage: "Price(฿)" })}</th>
                  </tr>
                </thead>

                <tbody>
                  {bill?.length > 0 ? (
                    bill?.map((data, i) => (
                      <tr key={i}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              {data.status == "COMPLETED"?(
                              <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{intl.formatMessage({ id: "113354", defaultMessage: "Completed Bills" })}</a>
                              ):(
                                <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{intl.formatMessage({ id: "113355", defaultMessage: "Canceled Bills" })}</a>
                              )
                            }
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{data.count}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-dark fw-bold text-hover-primary d-block fs-6">{data.total.toLocaleString()}</a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">
                        <p className="notdata">{intl.formatMessage({ id: "113352", defaultMessage: "no item found" })}</p>
                      </td>
                    </tr>
                  )}
                </tbody>


              </div>





            </div>
            <div className="separator separator-dashed my-3">
            </div>


          </div>
        </div>
        </div>

      </div>

      {/* graph */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <Card style={{ borderRadius: '40px' }}>
            <CardBody>

              <div ref={chartRef} style={{ width: '100%', height: '300px' }} />

            </CardBody>
          </Card>

        </div>
      </div>

    </div>


  )
}
