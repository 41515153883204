import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
import { temp_store } from "../../../modules/function/temp_store"

export const ViewPurchaseRequest = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113383" ,defaultMessage:"Purchase Request"})
  );
  const history = useHistory();
  const [printer_zone_name, setprinter_zone_name] = useState();
  const [printer_zone_name_en, setprinter_zone_name_en] = useState();
  const [state, setState] = useState(true);
  const [id, setId] = useState();
  const [shopList, setShopList] = useState([]);
  const [itemList, setitemList] = useState([]);
  const [shop, setShop] = useState(null);
  const [shopShow, setShopShow] = useState(null);
  const [branch, setBranch] = useState(null);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [quantity, setQty] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [maxIndex, setMaxIndex] = useState(0);
  const [description, setDescription] = useState('')
  const [price_per_unit, setPricePerUnit] = useState(0);
  const [pr_no, setPRNO] = useState('')
  const handleChangeShop = (event, values) => {
    console.log(event, values);
    if (values) {
      setShopShow(values)
      setShop(values.id);
      setBranch(values.posBranch.id)
    } else {
      setShop(null);
    }
  };

  const handleChangeItem = (event, values) => {
    console.log(event, values);
    if (values) {
      setItem({id:values.id, name: values.name});
      setPricePerUnit(values.price_per_main_unit);
    } else {
      setItem(null);
    }
  };
  

  useEffect(() => {
    gettingShop();
    gettingData();
    gettingItem();
  }, []);

  const columns = [
    {
      dataField: "item_id",
      text: "ID",
    },

    {
      dataField: "name",
      text: "item name",
    },

    {
      dataField: "quantity",
      text: "quantity",
    },

    {
      dataField: "price_per_unit",
      text: "price per unit",
    },

    {
      dataField: "status",
      text: "status",
    }
  ];

  const deleteItem = async(rowIndex) => {
    let temp = []
    console.log(listData)
    var array = [...listData]; // make a separate copy of the array
    array.splice(rowIndex, 1);
    setListData(array);
  }
  

  const gettingShop = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/shopGroup`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setShopList(res.data.data);
    });
  };

  const gettingData = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/purchase/pr/${temp_store(props).location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setDescription(res.data.data.pr[0].description)
      setListData(res.data.data.list)
      setId(res.data.data.pr[0].id)
      setPRNO(res.data.data.pr[0].pr_no)
    });
  };

  const gettingItem = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/purchase/pr/item_list`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setitemList(res.data.data);
    });
  };

  const AlertError = (message) => {
    Swal.fire("Create Delivery Note!", message, "error");
  };
  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleAdd = async() => {
    /*setListData(listData => [...listData, {
      index: maxIndex+1,
      item_id: item.id,
      item_name: item.item_name,
      qty: qty,
    }])*/
    if (!item) {
      await AlertError('please insert item')
    } else if (!quantity || quantity == 0) {
      await AlertError('please insert quantity ')
    }else if (!price_per_unit || price_per_unit == 0) {
      await AlertError('please insert price per unit ')
    } else {
      let temp = listData
      temp.push({
        index: maxIndex+1,
        item_id: item.id,
        name: item.name,
        quantity: quantity,
        price_per_unit:price_per_unit,
      })
      setListData(temp)
      setMaxIndex(maxIndex+1)
      setQty(0)
      setPricePerUnit(0)
      setIsAdd(true)
      setItem(null)
      //setShop(null)
      console.log(listData)
    }
    
  }

  const handleSend = async () => {
    /*if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }*/

    var events = {
      description: description == '' ? 'none' : description,
      list: listData,
      id: temp_store(props).location.state.row.id
    }

    let apiUrls = `${baseURL}/purchase/pr/`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
    .then(async (res) => {
      Swal.fire("Save !", "Save Edit PR success", "success");
      history.goBack();
    })
    .catch((err) => {
      Swal.fire(
        "Created !",
        "Your can not Edit PR. !! " + err,
        "error"
      );
    });
  };

  const handleApprove = async () => {
    var events = {
      description: description == '' ? 'none' : description,
      list: listData,
      id: temp_store(props).location.state.row.id
    }

    let apiUrls = `${baseURL}/purchase/pr/approve/${temp_store(props).location.state.row.id}`;
    Swal.fire({
      title: "Are you sure?",
      text: "Purchase order will be approve.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Approve",
      cancelButtonText: "Cancel",
    }).then((result) => {
        if (result.value) {
          axios({
            method: "get",
            url: apiUrls,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            data: events,
          })
          .then(async (res) => {
            Swal.fire("Save !", "Purchase Request Approved", "success");
            history.goBack();
          })
          .catch((err) => {
            Swal.fire(
              "Created !",
              "Your can not approve PR. !! " + err,
              "error"
            );
          });
          
        } 
    });
    
  }

  const handleReject = async () => {
    let apiUrls = `${baseURL}/purchase/pr/reject/${temp_store(props).location.state.row.id}`;
    Swal.fire({
      title: "Are you sure?",
      text: "Purchase order will be reject.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Reject",
      cancelButtonText: "Cancel",
      }).then((result) => {
          if (result.value) {
            axios({
              method: "get",
              url: apiUrls,
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
              }
            })
            .then(async (res) => {
              Swal.fire("Save !", "PR reject!", "success");
              history.goBack();
            })
          } 
      });
    
  }

  return (
    <div>
      <Card>
      {( temp_store(props).location.state.row.status == 'pending') && 
        <CardHeader
        
          title={intl.formatMessage({
            id: "113408",
            defaultMessage: "Approve Purchase Request",
          })}
          
          
        ></CardHeader>
      }

      {( temp_store(props).location.state.row.status != 'pending') && 
        <CardHeader
          title={intl.formatMessage({
            id: "113410",
            defaultMessage: "View Purchase Request",
          })}
        ></CardHeader>
      }
            
        <CardBody>
          {/* <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113164",
                  defaultMessage: "Shop",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="zone printer"
                  disabled={isAdd}
                  options={shopList}
                  getOptionLabel={(option) =>
                    option.posBranch ? option.posBranch.name : "ไม่มีชื่อ"
                  }
                  value={shopShow}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  se
                  onChange={handleChangeShop}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        borderWidth: 0,
                      }}
                      label="Shop"
                    />
                  )}
                />
              </div>
            </div>
          </div> */}
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              PR NO:         {pr_no}
              </p>
            </div>
          </div>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113169" ,defaultMessage:"Description" })}:{" "}        {description}
              </p>
            </div>
          </div>
          <hr></hr>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
              <Link to="/PurchaseRequest">
                <button type="button" className="btn btn-secondary wbt">
                  {intl.formatMessage({
                    id: "113390",
                    defaultMessage: "Back",
                  })}
                </button>
              </Link>
              {( temp_store(props).location.state.row.status == 'pending') && <div>

                <button className="btn btn-primary wbt" onClick={() => handleApprove()}>
                  {intl.formatMessage({ id: "113389", defaultMessage: "Approve" })}
                </button>
                <button className="btn btn-danger " onClick={() => handleReject()}>
                  {intl.formatMessage({ id: "113388", defaultMessage: "Reject" })}
                </button>
              </div>}
            </div>
          
        </CardBody>
      </Card>
    </div>
  );
};
