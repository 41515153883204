import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const CreateMenu = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Menu Management");
  const classes = useStyles();
  const history = useHistory();
  const [group_id, setGroup_id] = useState();
  const [template_id, setTemplate_id] = useState();
  const [template, setTemplate] = useState();
  const [item_name, setItem_name] = useState();
  const [item_name_en, setItem_name_en] = useState();
  const [description, setDescription] = useState();
  const [description_en, setDescription_en] = useState();
  const [description_receipt, setDescription_receipt] = useState();
  const [item_image_path, setItem_image_path] = useState();
  const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
  const [uom, setUom] = useState();
  const [price, setPrice] = useState();
  const [state, setState] = useState(true);
  const [group, setGroup] = useState([]);
  const [displayOrder, setDisplayOrder] = useState(0)

  const [printer_zone_id, setprinter_zone_id] = useState();
  const [zoneprinter_data, setzoneprinter_data] = useState([]);

  const [stateItem_image_path, setStateItem_image_path] = useState({
    previewItem_image_path: null,
    rawItem_image_path: null,
    imageURLItem_image_path: null,
  });

  const allDataGroup = [];

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };

  useEffect(() => {
    getGroup();
    getZonePrint();
    getTemplateGroup();
  }, []);

  const getZonePrint = async () => {
    let apiUrls = `${baseURL}/management/printerzone?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setzoneprinter_data(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTemplateGroup = async () => {
    let apiUrls = `${baseURL}/management/optiontemplate?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setTemplate(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroup = async () => {
    let apiUrls = `${baseURL}/management/group?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeGroup = (event) => {
    setGroup_id(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    setTemplate_id(event.target.value);
  };


  const handleChangeItem_image_path = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      e.target.value = '';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = '';
            return false;
          } else {
            setStateItem_image_path({
              rawItem_image_path: file,
              imageURLItem_image_path: e.target.result,
            });
            e.target.value = '';
            return true;
          }
        };
      };
    }
  };
  const handleImageUploadItem_image_path = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(`${baseURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setStateItem_image_path({
          previewItem_image_path: null,
          rawItem_image_path: null,
          imageURLItem_image_path: null,
        });
        setItem_image_path(`${imageURL}/${res.filename}`);
        setNameImageItem_image_path(res.filename);
      })
      .catch((error) => console.log("error", error));
  };

  const removeImageItem_image_path = async () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
    setItem_image_path("");
  };

  const handleCancelUploadItem_image_path = () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleChangezoneprinter = (event, values) => {
    if (values) {
      setprinter_zone_id(values.id);
    } else {
      setprinter_zone_id("");
    }
  };

  const onSubmit = async () => {
    if (item_name === undefined || item_name === "") {
      return AlertError("please insert item name.");
    }
    if (item_name_en === undefined || item_name_en === "") {
      return AlertError("please insert item name en.");
    }

    if (group_id === undefined || group_id === "") {
      return AlertError("please select group.");
    }

    if (price === undefined || price === "") {
      return AlertError("please insert price.");
    }

    if (description_receipt === undefined || description_receipt === "") {
      return AlertError("please insert description receipt.");
    }

    if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0 || Number(displayOrder) < 0) {
      return AlertError('please insert display order.')
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");

    var events = {
      shop_id: get_shop_id,
      branch_id: get_branch_id,
      group_id,
      template_id,
      item_name,
      item_name_en,
      description,
      description_en,
      description_receipt,
      item_image_path,
      uom: uom === 0 ? 0 : displayOrder,
      price: Number(price),
      enabled: state,
      printer_zone_id: printer_zone_id,
      display_order: displayOrder === 0 ? 0 : displayOrder,
    };

    let apiUrls = `${baseURL}/management/menu`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "Your can not create menu. !! " + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title="Create Menu Management"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              Group:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={group_id}
              onChange={handleChangeGroup}
            >
              <option value="">Please select</option>
              {group &&
                group.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.group_name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Zone printer :{" "}
            </p>

            {zoneprinter_data.length > 0 ? (
              <div>
                <Autocomplete
                  id="zone printer"
                  options={zoneprinter_data}
                  getOptionLabel={(option) => option.printer_zone_name}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={handleChangezoneprinter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputColor}
                      style={{
                        borderWidth: 0,
                      }}
                      label="Zone printer"
                    />
                  )}
                />
              </div>
            ) : null}

          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Template Option:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={template_id}
              onChange={handleChangeTemplate}
            >
              <option value="">Please select</option>
              {template &&
                template.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.template_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
          
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              Item name th:{" "}
            </p>
            <input
              name="item_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(item_name) => setItem_name(item_name.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
        
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              Item name en:{" "}
            </p>
            <input
              name="item_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(item_name_en) =>
                setItem_name_en(item_name_en.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Description:{" "}
            </p>
            <input
              name="description"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Description en:{" "}
            </p>
            <input
              name="description_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(description_en) =>
                setDescription_en(description_en.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
        
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              Description receipt:{" "}
            </p>
            <input
              name="description_receipt"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(description_receipt) =>
                setDescription_receipt(description_receipt.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
            <span style={{ color: "red" }}>
              *
            </span>
              Display Order:{' '}
            </p>
            <input
              name="displayOrder"
              type="number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={displayOrder}
              onChange={displayOrder =>
                setDisplayOrder(displayOrder.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Item image path : <br></br>
              (512*512)
            </p>
            <input
              name="item_image_path"
              style={{
                height: "38px",
                width: "330px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              disabled
              value={item_image_path}
            />

            <span className="btn btn-primary btn-file wbt">
              เลือก / แก้ไข
              <input type="file" onChange={handleChangeItem_image_path} />
            </span>

            <button
              type="button"
              className="btn btn-danger wbt"
              onClick={removeImageItem_image_path}
            >
              ลบ
            </button>
          </div>

          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          >
            {stateItem_image_path.previewItem_image_path ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={stateItem_image_path.previewItem_image_path}
              />
            ) : item_image_path ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={item_image_path}
              />
            ) : null}
          </div>
          {stateItem_image_path.imageURLItem_image_path ? (
            <CropperImage
              image={stateItem_image_path.imageURLItem_image_path}
              handleCancel={handleCancelUploadItem_image_path}
              handleUpload={handleImageUploadItem_image_path}
              scale={1}
            />
          ) : (
            ""
          )}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Uom:{" "}
            </p>
            <input
              name="uom"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(uom) => setUom(uom.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
              Price:{" "}
            </p>
            <input
              name="price"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(price) => setPrice(price.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Enabled:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/Menu">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
